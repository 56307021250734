import { Size, Variation } from "./types";

export function handleVariationOutline(variation: Variation) {
  switch (variation) {
    case "primary":
      return {
        color: "#1E5BC6",
        "border-color": "#1E5BC6",
      };

    case "confirm":
      return {
        color: "#40AA60",
        "border-color": "#40AA60",
      };

    case "delete":
      return {
        color: "#E81E26",
        "border-color": "#E81E26",
      };

    case "warning":
      return {
        color: "#FF7A14",
        "border-color": "#FF7A14",
      };

    default:
      return {
        color: "#1E5BC6",
        "border-color": "#1E5BC6",
      };
  }
}

export function handleVariationOutlineHover(variation: Variation) {
  switch (variation) {
    case "primary":
      return {
        "background-color": "rgba(30, 91, 198, 0.2)",
      };

    case "confirm":
      return {
        "background-color": "rgba(64, 170, 96, 0.2)",
      };

    case "delete":
      return {
        "background-color": "rgba(232, 30, 38, 0.2)",
      };

    case "warning":
      return {
        "background-color": "rgba(255, 122, 20, 0.2)",
      };

    default:
      return {
        "background-color": "rgba(30, 91, 198, 0.2)",
      };
  }
}

export function handleSize(size: Size) {
  switch (size) {
    case "large":
      return {
        "font-size": "14px",
        padding: "12px 20px",
        "border-radius": "4px",
      };

    case "medium":
      return {
        "font-size": "14px",
        padding: "8px 12px",
        "border-radius": "4px",
      };

    case "small":
      return {
        "font-size": "12px",
        padding: "3px 8px",
        "border-radius": "2px",
      };

    default:
      return {
        "font-size": "14px",
        padding: "12px 20px",
        "border-radius": "4px",
      };
  }
}

export function handleVariation(variation: string) {
  switch (variation) {
    case "primary":
      return { color: "#fff", background: "#1E5BC6" };
    case "confirm":
      return { color: "#fff", background: "#40AA60" };
    case "delete":
      return { color: "#fff", background: "#E81E26" };
    case "warning":
      return { color: "#fff", background: "#FF7A14" };
    default:
      return { color: "#fff", background: "#1E5BC6" };
  }
}
