import styled from "styled-components";

export const InformativeTitle = styled.h2`
  font-size: 14px;
  font-weight: 700;
  text-align: center;

  color: #575757;

  margin-top: 24px;
  margin-bottom: 16px;
`;
