import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "src/store";
import { GiftCardState } from "@store/giftcard-reducer";
import { Row, Column } from "@components/utilities";
import imgShield from "@assets/shield.svg";
import iconInfo from "@assets/info.svg";
import iconArrowDownActive from "@assets/icon-arrow-down-active.svg";
import iconArrowDownDisable from "@assets/icon-arrow-down-disable.svg";
import { IGiftCard } from "@pages/order-detail/GiftCardResponse";
import { RedemptionCode } from "@pages/order-detail/RedemptionCodeResponse";
import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import {
  ItemsContainer,
  Label,
  ItemHeader,
  Item,
  ItemName,
  TextDesc,
  TextItem,
  ItemList,
  ImgDesc,
  ImgInfo,
  Warranty,
  Reason,
  Description,
  ButtonActive,
  ButtonDisable,
  ModalBundleContainer,
  RowBundle,
  TextHead,
  TextContent,
  RedeemGiftError,
  CloseGiftError,
  GiftCardStatus,
} from "./styles";

interface TItems {
  items: TItem[];
  consignment?: Consignment;
  width?: string;
  type?: string;
  giftCard?: IGiftCard;
  redemptionCode?: RedemptionCode;
}

interface TItem {
  type?: string;
  ean?: string;
  name?: string;
  quantity?: number;
  price?: number;
  discount?: number;
  basePrice?: number;
  imageUrl?: string;
  sellerSku?: string;
  warranty?: boolean;
  reason?: Reason;
  disruptionQuantity?: number;
  bundleItem?: any;
  bundle?: any;
}

interface Reason {
  description?: string;
}

function openBundleBox(e: any) {
  const el = e.currentTarget.nextSibling;
  const display = el.style.display;
  if (display === "none" || display === "" || display === null) {
    el.style.display = "flex";
  } else {
    el.style.display = "none";
  }
}

function closeGiftError(e: any) {
  const el = e.currentTarget.nextSibling;
  const display = el.style.display;
  e.currentTarget.style.display = "none";
  el.style.display = "none";
}

const Items = ({
  items,
  width,
  consignment,
  giftCard,
  redemptionCode,
}: TItems) => {
  const giftcardErrors = useSelector<AppState, GiftCardState>(
    (state) => state.giftcard
  );

  const getError = (itemId: string) => {
    if (giftcardErrors.giftCards.length > 0) {
      const exists = giftcardErrors.giftCards.find(
        (element) => element.item === itemId
      );
      if (exists) return exists.error;
    }
    return 0;
  };

  return (
    <>
      <ItemsContainer width={width}>
        <ItemHeader>
          <Column width="230px" margin="5px 25px 5px 0px">
            <Label justify="flex-start">DESCRIÇÃO DO PRODUTO</Label>
          </Column>
          <Column width="100px" margin="5px 4px">
            <Label>QUANTIDADE</Label>
          </Column>
          <Column width="130px" margin="5px 4px">
            <Label>PREÇO UNITÁRIO</Label>
          </Column>
          <Column width="100px" margin="5px 4px">
            <Label>DESCONTO</Label>
          </Column>
          <Column width="130px" margin="5px 4px">
            <Label>SUBTOTAL</Label>
          </Column>
          <Column width="80px" margin="5px 4px">
            <Label>KIT</Label>
          </Column>
        </ItemHeader>
        {items?.length > 0 ? (
          items.map((item, index) => {
            return (
              <ItemList key={index} data-testid="order-item">
                <Item>
                  <Column width="230px" margin="5px 25px 5px 0px">
                    <Description>
                      {item.disruptionQuantity > 0 && (
                        <ImgInfo src={iconInfo} alt="info" />
                      )}
                      <ImgDesc src={item.imageUrl} />
                      <div>
                        <ItemName>{item.name}</ItemName>
                        <TextDesc color="#B0BEC5">
                          Cód: {item.sellerSku}
                        </TextDesc>
                        <Row width="max-content" align="baseline">
                          {giftCard && (
                            <GiftCardStatus>
                              <span className={giftCard?.statusCard}>
                                GiftCard {giftCard?.statusCard}{" "}
                              </span>
                            </GiftCardStatus>
                          )}
                          {giftCard?.accountCode && (
                            <TextDesc color="#B0BEC5" margin="0px 20px">
                              ID: {giftCard?.accountCode}
                            </TextDesc>
                          )}
                          {redemptionCode?.redemptionCode && (
                            <TextDesc color="#B0BEC5" margin="0px 20px">
                              PIN: {redemptionCode?.redemptionCode}
                            </TextDesc>
                          )}
                          {!redemptionCode?.redemptionCode &&
                            giftCard?.statusCard === "Desbloqueado" && (
                              <TextDesc color="#B0BEC5" margin="0px 20px">
                                PIN: ************
                              </TextDesc>
                            )}
                        </Row>
                      </div>
                    </Description>
                  </Column>
                  <Column width="100px" margin="5px 4px">
                    <TextItem>{item.quantity}</TextItem>
                  </Column>
                  <Column width="130px" margin="5px 4px">
                    <TextItem>
                      {new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(item.basePrice)}
                    </TextItem>
                  </Column>
                  <Column width="100px" margin="5px 4px">
                    <TextItem color="#FF403F">
                      {new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(item.quantity * item.discount)}
                    </TextItem>
                  </Column>
                  <Column width="130px" margin="5px 4px">
                    <TextItem>
                      {new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(item.quantity * item.price)}
                    </TextItem>
                  </Column>
                  {item.type === "BUNDLE" ? (
                    <Column width="80px" margin="5px 4px">
                      <ButtonActive onClick={openBundleBox}>
                        <TextItem>Sim</TextItem>
                        <img
                          src={iconArrowDownActive}
                          alt="down"
                          height="8px"
                          style={{ paddingLeft: 4 }}
                        />
                      </ButtonActive>
                      <ModalBundleContainer>
                        {item.bundleItem.map((e: any) => {
                          return (
                            <RowBundle>
                              <Column width="100px">
                                <TextHead>SKU</TextHead>
                                <TextContent>{e.refId}</TextContent>
                              </Column>
                              <Column width="250px">
                                <TextHead>Descrição</TextHead>
                                <TextContent>{e.name}</TextContent>
                              </Column>
                              <Column width="50px">
                                <TextHead>Unidades</TextHead>
                                <TextContent>{e.quantity}</TextContent>
                              </Column>
                            </RowBundle>
                          );
                        })}
                      </ModalBundleContainer>
                    </Column>
                  ) : (
                    <Column width="80px" margin="5px 4px">
                      <ButtonDisable>
                        <TextItem>Não</TextItem>
                        <img
                          src={iconArrowDownDisable}
                          alt="down"
                          height="8px"
                          style={{ paddingLeft: 4 }}
                        />
                      </ButtonDisable>
                    </Column>
                  )}
                </Item>

                {item?.reason ? (
                  <Reason>
                    <strong>MOTIVO</strong>
                    <span>{item?.reason?.description}</span>
                  </Reason>
                ) : (
                  ""
                )}
                {item.warranty ? (
                  <Warranty>
                    <div>
                      <strong>GARANTIA ESTENDIDA</strong>
                      <Row justify="flex-start">
                        <img src={imgShield} alt="shield" />
                        <p>Lorem ipsum</p>
                      </Row>
                    </div>
                    <div>
                      <Label justify="flex-start">valor</Label>
                      <TextItem>R$ 00,00</TextItem>
                    </div>
                  </Warranty>
                ) : (
                  ""
                )}
                {getError(consignment?.consignmentCode) ? (
                  <RedeemGiftError>
                    <CloseGiftError onClick={closeGiftError}>X</CloseGiftError>
                    <div>
                      <b>Houve um erro ao tentar desbloquear o cartão.</b>
                      <br />
                      <br />
                      <b>Motivo:</b>{" "}
                      {String(getError(consignment?.consignmentCode))}
                    </div>
                  </RedeemGiftError>
                ) : null}
              </ItemList>
            );
          })
        ) : (
          <ItemList data-testid="order-item">
            <Item>Items not found</Item>
          </ItemList>
        )}
      </ItemsContainer>
    </>
  );
};

export default Items;
