import React from "react";
import { Column, Row } from "@components/utilities";
import { formatMoney } from "@utils/formatUtils";
import iconCarrefourCard from "@assets/icon-payment-carrefour-card.svg";
import iconCarrefourCardVirtual from "@assets/virtual-card-carrefour.svg";
import iconAlelo from "@assets/icon-payment-alelo.svg";
import iconVisa from "@assets/icon-payment-visa.svg";
import iconVisaCheckout from "@assets/icon-payment-visa-checkout.svg";
import iconMastercard from "@assets/icon-payment-mastercard.svg";
import iconMaestro from "@assets/maestro.svg";
import iconElo from "@assets/icon-payment-elo.svg";
import iconDiners from "@assets/icon-payment-diners.svg";
import iconAmericanExpress from "@assets/icon-payment-american-express.svg";
import iconHipercard from "@assets/icon-payment-hipercard.svg";
import iconAmex from "@assets/icon-payment-amex.svg";
import iconCartaoPresente from "@assets/icon-payment-cartao-presente.svg";
import iconValeTroca from "@assets/icon-payment-vale-troca.svg";
import iconBoleto from "@assets/icon-payment-boleto.svg";
import iconPix from "@assets/icon-payment-pix.svg";
import iconDownload from "@assets/download.svg";

import {
  RowBetweenCenter,
  PaymentItem,
  TextPayment,
  LinkUrl,
  PaymentValueInfo,
  ValueLabel,
  ValueText,
} from "../../styles";

type RenderPaymentTypeProps = {
  paymentType: string;
  paymentMethod: string;
  lastDigits: string;
  url?: string;
};

function RenderPaymentType({
  paymentType,
  paymentMethod,
  lastDigits,
  url,
}: RenderPaymentTypeProps) {
  switch (paymentType) {
    case "Mastercard":
      return (
        <>
          <img src={iconMastercard} alt="Mastercard" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );

    case "Maestro":
      return (
        <>
          <img src={iconMaestro} alt="Maestro" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );

    case "Visa":
      return (
        <>
          <img src={iconVisa} alt="Visa" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );

    case "Visa Electron":
      return (
        <>
          <img src={iconVisa} alt="Visa" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );

    case "Visa Checkout":
      return (
        <>
          <img src={iconVisaCheckout} alt="Visa Checkout" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Diners":
      return (
        <>
          <img src={iconDiners} alt="Diners" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );
    case "American Express":
      return (
        <>
          <img src={iconAmericanExpress} alt="American Express" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Amex":
      return (
        <>
          <img src={iconAmex} alt="Amex" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Hipercard":
      return (
        <>
          <img src={iconHipercard} alt="Hipercard" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Elo":
      return (
        <>
          <img src={iconElo} alt="Elo" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );

    case "Alelo":
      return (
        <>
          <img src={iconAlelo} alt="Alelo" />
          <TextPayment>
            {paymentType} Final {lastDigits}
          </TextPayment>
        </>
      );

    case "Boleto Bancário":
      return (
        <>
          <Column>
            <Row>
              <img src={iconBoleto} alt="Boleto" />
              <TextPayment>{paymentType}</TextPayment>
            </Row>
            <Row justify="flex-start">
              <LinkUrl href={url}>
                baixar boleto <img src={iconDownload} alt="Download" />
              </LinkUrl>
            </Row>
          </Column>
        </>
      );

    case "Pix":
      return (
        <>
          <Column>
            <Row>
              <img src={iconPix} alt="Pix" />
              <TextPayment>{paymentType}</TextPayment>
            </Row>
          </Column>
        </>
      );

    case "Cartão Carrefour Virtual":
      return (
        <>
          <Column>
            <Row>
              <img src={iconCarrefourCardVirtual} alt={paymentType} />
              <TextPayment>{paymentType}</TextPayment>
            </Row>
          </Column>
        </>
      );

    case "Cartão Carrefour":
      return (
        <>
          <Column>
            <Row>
              <img src={iconCarrefourCard} alt={paymentType} />
              <TextPayment>{paymentType}</TextPayment>
            </Row>
          </Column>
        </>
      );

    case "Vale":
      if (paymentMethod == "VIRTUAL_GIFT_CARD")
        return (
          <>
            <img src={iconValeTroca} alt={paymentType} />
            <TextPayment>{paymentType}-troca</TextPayment>
          </>
        );
      else if (paymentMethod == "GIFT_CARD")
        return (
          <>
            <img src={iconCartaoPresente} alt={paymentType} />
            <TextPayment> Cartão Presente </TextPayment>
          </>
        );

    default:
      return null;
  }
}

type PaymentProps = {
  item: any;
  index: number;
};

const Payments = ({ item, index }: PaymentProps) => {
  return (
    <PaymentItem key={index}>
      <RowBetweenCenter>
        <div>
          <RenderPaymentType
            paymentType={item.paymentSystemName}
            paymentMethod={item.paymentMethod}
            lastDigits={item.lastDigits}
            url={item.url}
          />
        </div>
        <PaymentValueInfo color="#37474F">
          <div>
            <ValueLabel>Total: &nbsp;</ValueLabel>
            <ValueText>{` ${formatMoney(item.paymentValue)}`}</ValueText>
          </div>

          {item.installments && (
            <TextPayment color="#37474F" weight="500">
              {item.installments === 1 && `à vista`}
              {item.installments !== 1 && `parcelado em ${item.installments} X`}
            </TextPayment>
          )}
        </PaymentValueInfo>
      </RowBetweenCenter>
      {item.paymentSystemName !== "Boleto Bancário" &&
      item.paymentSystemName !== "Vale" ? (
        <Row justify="space-between" margin="10px 0 0 0">
          <div>
            <TextPayment color="#37474F" weight="500">
              Autorização:
            </TextPayment>
            {item.authorizationCode ? (
              <TextPayment margin="0 25px 0 0">
                {item.authorizationCode}
              </TextPayment>
            ) : (
              " - "
            )}
          </div>
          <div>
            <TextPayment color="#37474F" weight="500">
              NSU:
            </TextPayment>
            {item.nsu ? <TextPayment>{item.nsu}</TextPayment> : " - "}
          </div>
        </Row>
      ) : (
        " "
      )}
    </PaymentItem>
  );
};

export default Payments;
