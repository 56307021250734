import React from "react";

const ArrowLeft = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Straight Arrows Left 007">
        <g id="Vector">
          <path
            d="M12.5303 5.53033C12.8232 5.23744 12.8232 4.76256 12.5303 4.46967C12.2374 4.17678 11.7626 4.17678 11.4697 4.46967L4.46967 11.4697C4.32322 11.6161 4.25 11.8081 4.25 12C4.25 12.1017 4.27024 12.1987 4.30691 12.2871C4.34324 12.3749 4.39696 12.4572 4.46808 12.5287L4.46967 12.5303L12.5303 5.53033Z"
            fill="#121212"
          />
          <path
            d="M12.5303 5.53033L6.81066 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H6.81066L12.5303 18.4697C12.8232 18.7626 12.8232 19.2374 12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303L4.46967 12.5303L12.5303 5.53033Z"
            fill="#121212"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeft;
