import axios from "axios";
import handleLogout from "@services/session";
function getEnvironment() {
  let url = "";
  switch (process.env.PARAM) {
    case "development":
      url = "https://customer-services-qa.cloud.carrefour.com.br";
      break;
    case "qa":
      url = "https://customer-services-qa.cloud.carrefour.com.br";
      break;
    default:
      url = "https://customer-services.cloud.carrefour.com.br";
      break;
  }
  if (location.host === "localhost:9000") {
    url = "https://customer-services-qa.cloud.carrefour.com.br";
    //url = "http://localhost:8081";
  }
  return url;
}
function getAuthorizationToken() {
  switch (process.env.PARAM) {
    case "qa":
      return { authorization: "Basic dnRleDpNaWdyYWNhbzIwMjAh" };

    default:
      return { "X-Authorization-Firebase": localStorage.getItem("token") };
  }
}

const http = axios.create({
  baseURL: getEnvironment(),
  headers: getAuthorizationToken(),
});

http.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);
export default http;
