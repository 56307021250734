import styled from "styled-components";

export const ButtonLink = styled.button`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #1e5bc6;
  border: none;
  background: transparent;
  cursor: pointer;
`;
