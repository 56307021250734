import styled from "styled-components";

export const Header = styled.div`
  margin-bottom: 24px;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;

  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const BackButtonLabel = styled.span`
  font-family: "Ubuntu";
  font-size: 14px;
  color: #454545;

  margin-left: 8px;
`;

export const Steps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 24px;
`;

export const Step = styled.div<{ active: boolean }>`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  background-color: ${(props) => (props.active ? "#0970E6" : "#E5E5E5")};

  font-size: 12px;
  color: #fff;
`;

export const Separator = styled.div`
  width: 56px;
  height: 1px;
  background-color: #e5e5e5;
`;

export const StepTitle = styled.div`
  font-size: 24px;
  text-align: center;

  color: #173eb4;

  padding-bottom: 24px;
  border-bottom: 1px solid #e5e5e5;
`;
