export const mockChecklist = {
  checkList: [
    {
      diagnosticName: "Indisponibilidade",
      diagnosticCode: "1",
      permission: "N2",
    },
    {
      diagnosticName: "Erro Crasso",
      diagnosticCode: "3",
      permission: "N2",
    },
    {
      diagnosticName: "Erro no anúncio",
      diagnosticCode: "4",
      permission: "N2",
    },
    {
      diagnosticName: "Volume Faltante",
      diagnosticCode: "6",
      permission: "N2",
    },
    {
      diagnosticName: "Desconto não concedido",
      diagnosticCode: "7",
      permission: "N2",
    },
    {
      diagnosticName: "Cupom não aplicado",
      diagnosticCode: "8",
      permission: "N2",
    },
    {
      diagnosticName: "Avaria – Compensação",
      diagnosticCode: "10",
      permission: "N2",
    },
    {
      diagnosticName: "Garantia Estendida",
      diagnosticCode: "13",
      permission: "N2",
    },
    {
      diagnosticName: "Frete Expresso",
      diagnosticCode: "14",
      permission: "N1",
    },

    {
      diagnosticName: "Frete Agendado",
      diagnosticCode: "15",
      permission: "N1",
    },
    {
      diagnosticName: "Frete - Retirou nas agências dos Correios",
      diagnosticCode: "16",
      permission: "N1",
    },
    {
      diagnosticName: "Frete Convencional",
      diagnosticCode: "17",
      permission: "N1",
    },
    {
      diagnosticName: "Frete - Retirou na Transportadora",
      diagnosticCode: "18",
      permission: "N1",
    },
    {
      diagnosticName: "Erro sistêmico - Botão de cancelamento",
      diagnosticCode: "21",
      permission: "N2",
    },
    {
      diagnosticName: "Erro sistêmico - Botão de Ocorrência de Entrega",
      diagnosticCode: "23",
      permission: "N2",
    },
    {
      diagnosticName: "Erro sistêmico - Botão de Insucesso",
      diagnosticCode: "24",
      permission: "N2",
    },
    {
      diagnosticName: "Erro sistêmico - Botão Indisponibilidade",
      diagnosticCode: "25",
      permission: "N2",
    },
    {
      diagnosticName: "Estorno Parcial",
      diagnosticCode: "26",
      permission: "N2",
    },
  ],
};
