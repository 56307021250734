import React, { useEffect, useState } from "react";
import useModal from "react-hooks-use-modal";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "carrefour-portal-backoffice-style-guide";

import http from "@services/http";
import { Item } from "@pages/order-detail/OrderDetailsResponse";
import { setReturnItem, removeReturnItem } from "@store/return-items-reducer";
import { AppState } from "@store/index";
import { useParams } from "react-router-dom";

import ProductSelect from "../product-select";
import { ProductInfo } from "./components/product-info";
import {
  Td,
  Th,
  TableWrapper,
  Table,
  CheckListWrapper,
  CheckListColumn,
  NoteColumn,
  NoteItems,
  Textarea,
  TextareaInfo,
  Heading,
  Subheading,
  CheckListResumeWrapper,
  CheckListResumeText,
  ChecklistResumeColumn,
  CheckListResumeLabel,
  CheckListResumeNote,
  CheckListLabel,
  CheckListOption,
  ActionsWrapper,
  RemoveTdWrapper,
  Block,
  ErrorAlert,
  ErrorButton,
} from "../../style";
import { selectStyle } from "@components/utilities";

export type optionType = {
  description: string;
  observationMandatory: boolean;
  reasonCode: string;
  orderCode: any;
};

type Props = {
  products: Item[];
  productOptions: {
    label: {
      title: string;
      code: string;
      imageUrl: string;
    };
    value: string;
  }[];
};

type ChecklistData = {
  id: string;
  checklist: {
    diagnosticCode: number;
    diagnosticName: string;
  }[];
}[];

type ChecklistOptionsType = {
  [key: string]: {
    diagnosticCode: number;
    diagnosticName: string;
  }[];
};
type OrderCodeType = {
  orderCode?: string;
  consignmentsCode?: string;
};

const ReturnReasonsItem = (props: Props) => {
  const { productOptions, products } = props;
  const { orderCode, consignmentsCode } = useParams<OrderCodeType>();
  const consignments = useSelector(
    (state: AppState) => state.returnItems.orderDetails?.consignments
  );
  const consignment = consignments?.find(
    (c: any) => c.consignmentCode === consignmentsCode
  );
  const dispatch = useDispatch();
  const returnItems = useSelector(
    (state: AppState) => state.returnItems?.returnItems
  );
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [checkListOptions, setCheckListOptions] = useState<
    ChecklistOptionsType
  >(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [hasBundle, setHasBundle] = useState(null);
  const [handleChecklistValidate, setHandleChecklistValidate] = useState(true);
  const [errorMessage, getErrorMessage] = useState("");

  async function getProductsChecklist() {
    try {
      if (
        consignment.type !== "FOOD" &&
        consignment.type !== "MARKETPLACE_IN"
      ) {
        const typeProduct = products?.map((item) => item.type);
        const typesProducts = typeProduct?.map((item) => {
          return item;
        });

        const getItemType = typesProducts?.map(async (t, index) => {
          if (t === "NORMAL") {
            const productsIds = products.map((item) => {
              const productId = item.id;
              return productId;
            });
            try {
              const { data }: { data: ChecklistData } = await http.get(
                `/v1/orders/${orderCode}/items/checklist`,
                {
                  params: {
                    orderItemIds: productsIds.join(","),
                  },
                }
              );

              if (data.length > 0) {
                const checklists = data.reduce((acc, curr) => {
                  return { ...acc, [curr.id]: curr.checklist };
                }, {});

                setCheckListOptions(checklists);
                setHasBundle(false);
              }
            } catch (error) {
              setHandleChecklistValidate(false);
              getErrorMessage(
                `Ah não! Tivemos um problema em nosso sistema. Por isso não conseguimos mostrar as informações solicitadas. Que tal tentar novamente`
              );
              console.error(error);
            }
          }
          if (t === "BUNDLE") {
            const productsIdsBundle = products?.map(
              (item, index) => item.bundleItem[index].id
            );
            try {
              const { data }: { data: ChecklistData } = await http.get(
                `/v1/orders/${orderCode}/items/checklist`,
                {
                  params: {
                    orderItemIds: productsIdsBundle.join(","),
                  },
                }
              );

              const checklistsBundle = data.reduce((acc, curr) => {
                return { ...acc, [curr.id]: curr.checklist };
              }, {});

              setCheckListOptions(checklistsBundle);
              setHasBundle(true);
            } catch (error) {
              getErrorMessage(
                `Ah não! Tivemos um problema em nosso sistema. Por isso não conseguimos mostrar as informações solicitadas. Que tal tentar novamente?`
              );
              setHandleChecklistValidate(false);
              console.error(error);
            }
          }
        });
      }
      setLoadingOptions(false);
    } catch (error) {
      getErrorMessage(
        `Ah não! Tivemos um problema em nosso sistema. Por isso não conseguimos mostrar as informações solicitadas. Que tal tentar novamente?`
      );
      console.error(error);
    }
  }

  async function getReasons() {
    const response = await http.get("/v1/orders/return/reasons");
    const { data }: { data: optionType[] } = response;
    const reasons = data.map((item) => ({
      value: item.reasonCode,
      label: item.description,
    }));

    setOptions(reasons);
  }

  useEffect(() => {
    if (products) {
      getProductsChecklist();
    }
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    getReasons();
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectView, getSelectView] = useState(true);

  const handleProductChange = (selectedOption: {
    value: any;
    label: string;
  }) => {
    const newCurrentProduct = {
      ...currentProduct,
      productValue: selectedOption.value,
    };

    const selectOptionRemain = remainProducts.map((item) => {
      return item?.id;
    });

    const selects = selectOptionRemain.includes(newCurrentProduct.productValue);

    getSelectView(selects);

    if (consignment.delivery.modality == "DRIVE") {
      const product = products.find((p) => p.id == selectedOption.value);
      newCurrentProduct.quantity = `${product.availableQuantity}`;
    }
    setCurrentProduct(newCurrentProduct);
  };

  const handleQuantityChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setCurrentProduct({ ...currentProduct, quantity: selectedOption.value });
  };

  const handleReturnReason = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setCurrentProduct({
      ...currentProduct,
      returnReason: selectedOption.value,
    });
  };

  const handleChecklistChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentProduct({ ...currentProduct, checkListOption: value });
  };

  const handleNoteChange = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLTextAreaElement>) => {
    setCurrentProduct({ ...currentProduct, returnNote: value });
  };

  const saveItem = () => {
    dispatch(setReturnItem(currentProduct));
    setCurrentProduct(null);
  };

  const removeItem = (id: string | Number) => {
    dispatch(removeReturnItem(id));
  };

  const currentEditProduct = products?.find(
    (p) => p.id === currentProduct?.productValue
  );

  const quantityArray = [
    ...new Array(currentEditProduct?.availableQuantity),
  ].map((_, i) => ({
    label: `${i + 1}`,
    value: `${i + 1}`,
  }));

  const remainProducts = products?.filter(
    (i) => !returnItems.find((r) => r.productValue == i.id)
  );

  const currentProductBundle: any = products?.map(function (item, index) {
    const setItemType = item.type;
    const setItemId = [];

    switch (setItemType) {
      case "BUNDLE":
        setItemId.push(item.bundleItem[index].id);
        return setItemId;
      case "NORMAL":
        setItemId.push(item.id);
        return setItemId;

      default:
        break;
    }
  });

  const [checkListOptionSelected, setCheckListOptionSelected] = useState(null);

  return (
    <>
      {returnItems.map((item: any) => {
        const product = products?.find((p) => p.id === item.productValue);
        const checkListValue = checkListOptions?.[item.productValue]?.find(
          (c) => c.diagnosticCode == item.checkListOption
        );
        const activeOption = options.find((i) => i.value === item.returnReason);

        return (
          <>
            <Heading>Item selecionado</Heading>
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <Th active>Descrição do produto</Th>
                    <Th active>Quantidade</Th>
                    <Th active>Preço unitário</Th>
                    <Th active>Desconto</Th>
                    <Th active>Subtotal</Th>
                    <Th active>
                      <RemoveTdWrapper>
                        <span>Selecione um motivo</span>
                        <Button
                          text="Remover"
                          variation="delete"
                          onClick={() => removeItem(product.id)}
                        />
                      </RemoveTdWrapper>
                    </Th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Td>
                      <ProductInfo
                        title={product.name}
                        cod={product.sellerSku}
                        imgUrl={product.imageUrl}
                      />
                    </Td>
                    <Td>{item.quantity}</Td>
                    <Td>
                      {product?.basePrice?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Td>
                    <Td>
                      {(item.quantity * product?.discount)?.toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </Td>
                    <Td>
                      {(item.quantity * product?.price)?.toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </Td>
                    <Td>
                      <Select
                        options={options}
                        placeholder="Selecione um motivo..."
                        menuIsOpen={false}
                        value={activeOption}
                        styles={selectStyle}
                      />
                    </Td>
                  </tr>
                </tbody>
              </Table>
              <CheckListResumeWrapper>
                <ChecklistResumeColumn>
                  <CheckListResumeText>Checklist</CheckListResumeText>
                  <CheckListResumeLabel>
                    {checkListValue?.diagnosticName
                      ? checkListValue?.diagnosticName
                      : " - "}
                  </CheckListResumeLabel>
                </ChecklistResumeColumn>
                <ChecklistResumeColumn>
                  <CheckListResumeText>Observação</CheckListResumeText>
                  <CheckListResumeNote>{item?.returnNote}</CheckListResumeNote>
                </ChecklistResumeColumn>
              </CheckListResumeWrapper>
            </TableWrapper>
          </>
        );
      })}
      {!!(remainProducts?.length || !returnItems.length) && products?.length && (
        <>
          <Heading>Selecione o item</Heading>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <Th active>Descrição do produto</Th>
                  <Th active={!!currentProduct?.productValue}>Quantidade</Th>
                  <Th active={!!currentProduct?.productValue}>
                    Preço unitário
                  </Th>
                  <Th active={!!currentProduct?.productValue}>Desconto</Th>
                  <Th active={!!currentProduct?.productValue}>Subtotal</Th>
                  <Th active={!!currentProduct?.productValue}>
                    Selecione um motivo
                  </Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>
                    {
                      <ProductSelect
                        isDisabled={loadingOptions}
                        options={productOptions}
                        placeholder="Selecione um produto..."
                        onChange={handleProductChange}
                      />
                    }
                  </Td>
                  <Td>
                    {currentProduct?.productValue ? (
                      consignment?.delivery?.modality == "DRIVE" &&
                      consignment?.status?.status == "FPR" ? (
                        currentProduct.quantity
                      ) : (
                        <Select
                          options={quantityArray}
                          placeholder="Qtd"
                          onChange={handleQuantityChange}
                          styles={selectStyle}
                        />
                      )
                    ) : null}
                  </Td>
                  <Td>
                    {currentEditProduct?.basePrice?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Td>
                  <Td>
                    {currentProduct?.quantity &&
                      (
                        parseInt(currentProduct?.quantity) *
                        currentEditProduct?.discount
                      )?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                  </Td>
                  <Td>
                    {(
                      currentProduct?.quantity &&
                      parseInt(currentProduct?.quantity) *
                        currentEditProduct?.price
                    )?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Td>
                  <Td>
                    {currentProduct?.productValue ? (
                      <Select
                        options={options}
                        placeholder="Selecione um motivo..."
                        onChange={handleReturnReason}
                        styles={selectStyle}
                      />
                    ) : null}
                  </Td>
                </tr>
              </tbody>
            </Table>
          </TableWrapper>
          {currentProduct?.returnReason && currentProduct?.quantity && (
            <>
              {handleChecklistValidate ? (
                <>
                  {checkListOptions?.[currentProduct.productValue] ? (
                    <>
                      {selectView ? (
                        <>
                          <Heading>Checklist</Heading>
                          <CheckListWrapper>
                            {!hasBundle && (
                              <>
                                {checkListOptions?.[currentProduct.productValue]
                                  ?.length && (
                                  <CheckListColumn
                                    onChange={handleChecklistChange}
                                  >
                                    {checkListOptions?.[
                                      currentProduct.productValue
                                    ]?.map((item, index) => (
                                      <CheckListOption
                                        onClick={() =>
                                          setCheckListOptionSelected(
                                            item.diagnosticName
                                          )
                                        }
                                      >
                                        <input
                                          type="radio"
                                          id={`${item.diagnosticCode}-${index}`}
                                          name="checklist"
                                          value={item.diagnosticCode}
                                        />
                                        <CheckListLabel
                                          htmlFor={`${item.diagnosticCode}-${index}`}
                                        >
                                          {item.diagnosticName}
                                        </CheckListLabel>
                                      </CheckListOption>
                                    ))}
                                  </CheckListColumn>
                                )}
                              </>
                            )}
                            {hasBundle && (
                              <>
                                <CheckListColumn>
                                  <CheckListOption
                                    onChange={handleChecklistChange}
                                  >
                                    {checkListOptions?.[
                                      currentProductBundle
                                    ]?.map((item: any, index: any) => (
                                      <CheckListOption
                                        onClick={() =>
                                          setCheckListOptionSelected(
                                            item.diagnosticName
                                          )
                                        }
                                      >
                                        <input
                                          type="radio"
                                          id={`${item.diagnosticCode}-${index}`}
                                          name="checklist"
                                          value={item.diagnosticCode}
                                        />
                                        <CheckListLabel
                                          htmlFor={`${item.diagnosticCode}-${index}`}
                                        >
                                          {item.diagnosticName}
                                        </CheckListLabel>
                                      </CheckListOption>
                                    ))}
                                  </CheckListOption>
                                </CheckListColumn>
                              </>
                            )}
                            <NoteColumn>
                              <NoteItems>
                                <Subheading>Observação</Subheading>
                                <Textarea
                                  onChange={handleNoteChange}
                                  maxLength={150}
                                />
                                <TextareaInfo>
                                  Máximo de 150 caracteres
                                </TextareaInfo>
                              </NoteItems>
                            </NoteColumn>
                          </CheckListWrapper>
                          <ActionsWrapper>
                            <Button
                              text="Salvar"
                              variation="confirm"
                              disabled={
                                !currentProduct.returnNote ||
                                !checkListOptionSelected
                              }
                              onClick={saveItem}
                            />
                          </ActionsWrapper>
                        </>
                      ) : (
                        <>
                          <Block data-testid="order-table">
                            <ErrorAlert>
                              Item já foi selecionado. Escolha outro
                            </ErrorAlert>
                          </Block>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Heading>Checklist</Heading>
                      <CheckListWrapper>
                        <CheckListColumn>
                          <CheckListOption>
                            Item não possui checklist.
                          </CheckListOption>
                        </CheckListColumn>
                        <NoteColumn>
                          <NoteItems>
                            <Subheading>Observação</Subheading>
                            <Textarea
                              onChange={handleNoteChange}
                              maxLength={150}
                            />
                            <TextareaInfo>
                              Máximo de 150 caracteres
                            </TextareaInfo>
                          </NoteItems>
                        </NoteColumn>
                      </CheckListWrapper>
                      <ActionsWrapper>
                        <Button
                          text="Salvar"
                          variation="confirm"
                          disabled={!currentProduct.returnNote}
                          onClick={saveItem}
                        />
                      </ActionsWrapper>
                    </>
                  )}
                </>
              ) : (
                <Block data-testid="order-table">
                  <ErrorAlert>
                    <h3>
                      {errorMessage}
                      <ErrorButton onClick={() => window.location.reload()}>
                        Tentar novamente
                      </ErrorButton>
                    </h3>
                  </ErrorAlert>
                </Block>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReturnReasonsItem;
