import { moneyMask } from "@utils/masks";
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { AppState } from "@store/index";
import { InputTextMoney } from "./style";
import {
  AlertPermission,
  AlertPermissionIco,
  AlertPermissionText,
} from "../../../refund-flow/components/return-reasons/style";
import iconAlert from "@assets/exclamation.svg";

const InputMoney: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({
  ...props
}) => {
  const totalItemValue = useSelector(
    (state: AppState) => state.returnItems.orderDetails.totalItemValue
  );
  const totalDiscounts = useSelector(
    (state: AppState) => state.returnItems.orderDetails.totalDiscounts
  );

  const totalValueRefund = totalItemValue - totalDiscounts;

  const [partialRefund, setValue] = useState("");
  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.maxLength = 14;
    let value = e.currentTarget.value;
    value = moneyMask(value);
    e.currentTarget.value = value;
    let valueRefund = e.currentTarget.value;
    valueRefund = valueRefund
      .replace(/\./g, "")
      .replace(/\,/g, ".")
      .replace(/\R\$/g, "")
      .replace(/\R\$/g, "");
    let valueRefundStorage = parseFloat(valueRefund).toFixed(2);
    setValue(value);
    localStorage.setItem("ValueRefund", valueRefundStorage);
  }, []);

  const partialItemRefundValue: any = localStorage.getItem("ValueRefund");

  return (
    <>
      <InputTextMoney
        {...props}
        onKeyUp={handleKeyUp}
        defaultValue={partialRefund}
      ></InputTextMoney>
      {partialItemRefundValue > totalValueRefund && (
        <AlertPermission>
          <AlertPermissionIco>
            <img src={iconAlert} alt="iconAlert" />
          </AlertPermissionIco>
          <AlertPermissionText>
            {`Você não pode realizar um estorno maior que ${new Intl.NumberFormat(
              "pt",
              {
                style: "currency",
                currency: "BRL",
              }
            ).format(totalItemValue - totalDiscounts)}`}
          </AlertPermissionText>
        </AlertPermission>
      )}

      {partialItemRefundValue === "0.00" && (
        <AlertPermission>
          <AlertPermissionIco>
            <img src={iconAlert} alt="iconAlert" />
          </AlertPermissionIco>
          <AlertPermissionText>
            {`Você não pode realizar um estorno no valor de ${new Intl.NumberFormat(
              "pt",
              { style: "currency", currency: "BRL" }
            ).format(partialItemRefundValue)}. `}
          </AlertPermissionText>
        </AlertPermission>
      )}

      {partialItemRefundValue === "NaN" && (
        <AlertPermission>
          <AlertPermissionIco>
            <img src={iconAlert} alt="iconAlert" />
          </AlertPermissionIco>
          <AlertPermissionText>{`Valor inválido `}</AlertPermissionText>
        </AlertPermission>
      )}
    </>
  );
};

export default InputMoney;
