import React from "react";

import { RetryModalContainer } from "./styles";
import { Column, Row } from "@components/utilities";

export const RetryModalContent: React.FC = () => {
  return (
    <RetryModalContainer>
      <Column>
        <Row>
          <div className="loader"></div>
        </Row>
        <Row className="title">Reenviando solicitação.</Row>
        <Row className="subtitle">Por favor, aguarde.</Row>
      </Column>
    </RetryModalContainer>
  );
};
