export interface AuthState {
  authorities: Array<string>;
}

export const AUTH_DATA = "AUTH_DATA";

export interface AuthActionsParams {
  type: string;
  payload: any;
}

type AuthActions = AuthActionsParams;

const initialState: AuthState = {
  authorities: [],
};

export function authoritiesReducer(
  state = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AUTH_DATA:
      return {
        ...state,
        authorities: action.payload,
      };
    default:
      return state;
  }
}
