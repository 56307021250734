import * as React from "react";

import { Title, Span } from "./styles";

export const SuccessContent = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        fill="none"
        viewBox="0 0 64 64"
      >
        <path
          fill="#33691E"
          fillRule="evenodd"
          d="M40.683 12.514a21.333 21.333 0 1012.651 19.498v-2.206a2.667 2.667 0 115.333 0v2.208a26.671 26.671 0 01-19.105 25.558 26.666 26.666 0 113.292-49.93 2.667 2.667 0 01-2.17 4.872z"
          clipRule="evenodd"
        />
        <path
          fill="#33691E"
          fillRule="evenodd"
          d="M54.999 11.25a2.667 2.667 0 01.418 3.748L34.084 41.692a2.667 2.667 0 01-3.972.217l-8-8.026a2.667 2.667 0 113.777-3.765l5.893 5.911L51.25 11.67a2.667 2.667 0 013.748-.419z"
          clipRule="evenodd"
        />
      </svg>

      <Title>Tudo certo!</Title>

      <Span>
        Sua solicitação de troca para o Marketplace foi enviada com sucesso!
      </Span>
    </>
  );
};
