import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import http from "@services/http";
import handleLogout from "@services/session";

import { AppState } from "src/store";
import { OrderState } from "@store/order-reducer";

import { Column, selectStyle } from "@components/utilities";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";
import { CancelState } from "@store/cancellation-reducer";
import { CancellationType } from "@pages/cancellation-flow/enums/cancellation-type";

type CancelReasonsProps = {
  cancelState: CancelState;
};

const CancelReasons = (props: CancelReasonsProps) => {
  const { cancelState } = props;
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const { orderDetail } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  async function getReasons() {
    const reasons = await http.get("/v1/orders/cancel/reasons");
    const _option: { value: string; label: string }[] = [];
    reasons.data.map((item: { reasonCode: string; description: string }) => {
      _option.push({ value: item.reasonCode, label: item.description });
    });

    setOptions(_option);
  }

  const handleChange = (selectedOption: {
    value: string;
    description: string;
  }) => {
    dispatch({ type: "CANCEL_REASON", payload: selectedOption.value });
  };

  useEffect(() => {
    if (orderDetail?.payments) {
      dispatch({
        type: "PAYMENT_TYPE",
        payload: orderDetail?.payments[0].paymentSystemName,
      });
    }
    getReasons();
  }, []);

  let subtitleText = "Por que você gostaria de cancelar essa entrega?";
  if (cancelState.cancellationType === CancellationType.TRANSPORT_OCURRENCE) {
    subtitleText = "Selecione um motivo para a ocorrência de transporte";
  } else if (
    cancelState.cancellationType === CancellationType.DELIVERY_FAILURE
  ) {
    subtitleText = "Selecione um motivo para a insucesso de entrega";
  }

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>1</Badge>Motivo
        </Title>
        <Subtitle>{subtitleText}</Subtitle>
      </ContainerTitle>
      <Column width="540px" margin="0 auto">
        <Select
          options={options}
          placeholder="Selecione um motivo..."
          onChange={handleChange}
          styles={selectStyle}
        />
      </Column>
    </>
  );
};

export default CancelReasons;
