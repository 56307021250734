import styled from "styled-components";

type TStyleItemsContainer = {
  width?: string;
};

export const ItemsContainer = styled.div<TStyleItemsContainer>`
  width: ${(props: TStyleItemsContainer) => props.width || "68%"};
`;

type TStyleLabel = {
  justify?: string;
};

export const Label = styled.span<TStyleLabel>`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: ${(props: TStyleLabel) => props.justify || "center"};
  text-transform: uppercase;
  color: #b0bec5;
`;

export const ItemHeader = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

export const ItemList = styled.div`
  border-bottom: 1px solid #eceff1;
`;

export const Item = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;
  align-items: flex-start;
`;

type TStyledP = {
  color?: string;
  margin?: string;
};

export const ItemName = styled.p<TStyledP>`
  max-width: 150px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: ${(props: TStyledP) => props.margin || "8px 0 0 0"};
  color: ${(props: TStyledP) => props.color || "#37474f"};
`;

export const TextDesc = styled.p<TStyledP>`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: ${(props: TStyledP) => props.margin || "8px 0 0 0"};
  color: ${(props: TStyledP) => props.color || "#37474f"};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
`;

type TStyledImg = {
  src?: string;
};

export const ImgDesc = styled.div<TStyledImg>`
  width: 56px;
  height: 56px;
  margin-right: 8px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  background-image: url(${(props: TStyledImg) => props.src || ""});
  background-size: cover;
  background-repeat: no-repeat;
`;

type TStyledSpan = {
  color?: string;
};

export const TextItem = styled.span<TStyledSpan>`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${(props: TStyledSpan) => props.color || "#546e7a"};
`;

export const Warranty = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  display: block;
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;

  img {
    margin-right: 20px;
  }

  strong {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #b0bec5;
  }

  p {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #37474f;
  }
`;

export const Reason = styled.div`
  background: #fcfdff;
  border-radius: 5px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  strong {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #607d8b;
    margin-right: 10px;
  }
  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #37474f;
  }
`;

export const ImgInfo = styled.img`
  position: absolute;
  margin: -5px;
`;
export const Button = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #fff;
  border: none;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: 0.2s all linear;
  transition: 0.2s all linear;
  margin: 0 10px 0 0;
  background-color: ${(props: TStyledSpan) => props.color || "#546e7a"};
`;
export const ButtonActive = styled.button`
  width: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 0 12px;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #40aa60;
  border: solid 1px #b0bec5;
  cursor: pointer;
  opacity: 1;
  -webkit-transition: 0.2s all linear;
  transition: 0.2s all linear;
  margin: 0 10px 0 0;
  background-color: #fff;
  span {
    color: green;
  }
  :hover {
    opacity: 0.7;
  }
`;
export const ButtonDisable = styled.button`
  width: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 0 12px;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #b0bec5;
  cursor: not-allowed;
  opacity: 0.8;
  -webkit-transition: 0.2s all linear;
  transition: 0.2s all linear;
  margin: 0 10px 0 0;
  color: #b0bec5;
  user-select: none;
`;
export const ModalBundleContainer = styled.div<{
  display?: string;
}>`
  width: 400px;
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #b0bec5;
  padding: 20px;
  overflow-y: auto;
  display: ${(props) => props.display || "none"};
  flex-direction: column;
  position: relative;
  right: 362px;
`;

export const RowBundle = styled.div`
  display: flex;
  border-bottom: solid 1px #eceff1;
  &:last-child {
    border-bottom: none;
    padding: 0px;
  }
  div {
    align-items: center;
  }
`;
export const TextHead = styled.p`
  font-size: 12px;
  color: #607d8b;
  margin: 10px 0 0 0;
`;
export const TextContent = styled.p`
  font-size: 12px;
  color: #4032;
`;

export const RedeemGiftError = styled.div`
  margin-bottom: 16px;
  position: relative;
  div {
    background: #ffeaed;
    border-radius: 8px;
    color: #5a646e;
    font-size: 12px;
    max-height: 112px;
    line-height: 18px;
    padding: 16px 48px;
  }
`;
export const CloseGiftError = styled.button`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
`;

export const GiftCardStatus = styled.p`
  margin-bottom: 0;
  span {
    color: #ffffff;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    padding: 5px 20px;
    white-space: nowrap;
  }
  span.Bloqueado {
    background: #ff7a14;
  }
  span.Desbloqueado {
    background: #40aa60;
  }
  span.Cancelado {
    background: #ff403f;
  }
`;
