import http from "./http";
import { BalanceAvaliableResponse } from "@pages/balance-available/BalanceAvaliableResponse";

export async function getGiftCards(
  email: string,
  offset: number = 0,
  limit: number
): Promise<BalanceAvaliableResponse> {
  try {
    const response = await http.get("/v1/customers/virtualGiftCards", {
      params: {
        email: email,
        limit: limit,
        offset: offset,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}
