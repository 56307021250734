import React from "react";
import { useHistory } from "react-router-dom";

import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import { exchangeMarketplaceIn } from "@services/exchangeMarketplaceIn";

import { CloseButton, Loader, ModalContent, ModalWrapper } from "./styles";
import { ErrorContent } from "./error";
import { SuccessContent } from "./success";

interface Props {
  consigment: Consignment;
  customerEmail?: string;
  close: () => void;
}

export const ModalExchangeMarketpalceInContent = ({
  consigment,
  customerEmail,
  close,
}: Props) => {
  const [isLoading, setLoading] = React.useState(true);
  const [hasError, setError] = React.useState<any>();

  const { go } = useHistory();

  const handleSendHttp = async () => {
    try {
      if (!consigment.links.consignmentExchange.href) {
        setError(true);
      }

      const response = await exchangeMarketplaceIn(
        consigment.links.consignmentExchange.href,
        consigment.items[0].orderLineId,
        customerEmail
      );

      if (response.status !== 200 && response.status !== 201) setError(true);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleSendHttp();
  }, []);

  const handleOnCloseModal = () => {
    if (!!hasError) {
      close();

      return;
    }

    go(0);
  };

  return (
    <ModalWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ModalContent>
            {!!hasError ? <ErrorContent /> : <SuccessContent />}

            <CloseButton onClick={handleOnCloseModal} aria-label="Fechar Modal">
              Fechar
            </CloseButton>
          </ModalContent>
        </>
      )}
    </ModalWrapper>
  );
};
