import http from "./http";

type OrderHistoryProp = {
  orderCode: string;
  consignmentCode: string;
};

export async function getOrderHistory(order: OrderHistoryProp) {
  try {
    const response = await http.get(
      `/v1/orders/${order.orderCode}/consignments/${order.consignmentCode}/history`
    );

    return response;
  } catch (error) {
    return error.response;
  }
}
