import styled from "styled-components";

import iconDownload from "@assets/download.svg";
import iconDownloadActive from "@assets/download-active.svg";
import iconInfo from "@assets/download-empty.svg";

export const AsideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Label = styled.label`
  width: 40px;
  height: 32px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: #37474f;
  margin: 10px 0;
`;
export const SubTitle = styled.h3`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: #37474f;
  margin: 10px 0;
`;

export const Select = styled.select`
  height: 40px;
  width: 311px;
  color: #666666;
  font-size: 14px;
  padding-left: 10px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 5px;
`;
export const Option = styled.option`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  color: #666666;
`;

export const Button = styled.button`
  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  background: #ffffff;
  background-image: url(${iconDownload});
  background-position: left 15px center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;
  padding: 12px 24px 12px 35px;
  color: #1e5bc6;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #1e5bc6;
    color: #ffffff;
    background-image: url(${iconDownloadActive});
    background-position: left 15px center;
    background-repeat: no-repeat;
  }
`;

export const ButtonModal = styled.button`
  width: 160px;
  padding: 10px 16px;

  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  background: #ffffff;
  background-position: left 15px center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;

  color: #1e5bc6;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #1e5bc6;
    color: #ffffff;
  }
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
`;

type TCardModal = {
  padding?: string;
};

export const CardModal = styled.div<TCardModal>`
  width: 328px;
  max-height: 85vh;
  min-height: 195px;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "24px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #353a40;
`;
export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleAlert = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #ff7a14;
`;

export const TextConfirmReport = styled.p`
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #263238;
`;

export const Info = styled.div`
  width: 64px;
  height: 64px;
  margin: 10px;
  background-image: url(${iconInfo});
  background-repeat: no-repeat;
`;
