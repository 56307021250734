import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Breadcrumbs,
  Text,
  Alert,
} from "carrefour-portal-backoffice-style-guide";

import { getOrderDetail } from "@services/orderDetails";
import { getTraking } from "@services/traking";
import { getCustomer } from "@services/customer";

import PageHeader from "@components/page-header";
import { Content, Container, Block, Row } from "@components/utilities";

import OrderSummary from "./components/order-summary";
import Details from "./components/details";
import ShippingContainer from "./components/shipping/container";
import Shipping from "./components/shipping";
import ReturnDelivery from "./components/return-delivery";

import { OrderDetailsResponse } from "./OrderDetailsResponse";
import { TrackingResponse } from "./TrackingResponse";

import ScrollToIcon from "@assets/scroll-to.svg";

import { ScrollTo, ScrollToButton } from "./styles";
import { CicleMarkTop, InfoReturn } from "./components/shipping/styles";
import { getContentCards } from "@services/getContentCards";

interface OrderDetailParms {
  orderId: string;
}

const OrderDetail = () => {
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState<OrderDetailsResponse>();
  const [customerData, setCustomerData] = useState();
  const [returnConsignment, setReturnConsignment] = useState(null);
  const [trackingData, setTrackingData] = useState<TrackingResponse>();
  const [errorMsg, setErrorMsg] = useState("");
  const [hasNext, setHasNext] = useState(true);
  const shippingRefs = React.useRef([]);
  const [giftCards, setGiftCards] = useState([]);

  function updateRefs(value: number) {
    for (let i = 0; i < value; i++) {
      shippingRefs.current[i] = shippingRefs.current[i] || React.createRef();
    }
    shippingRefs.current = shippingRefs.current.map(
      (item) => item || React.createRef()
    );
  }

  useEffect(() => {
    if (orderData?.consignments) {
      updateRefs(orderData?.consignments.length);
    }
  }, [orderData]);

  const { orderId } = useParams<OrderDetailParms>();

  async function loadCustomerDetails() {
    const { customerEmail } = orderData;
    const response = await getCustomer({
      searchTerm: customerEmail,
      searchParam: "email",
    });
    const [customer] = response.data;
    setCustomerData(customer);
  }

  async function loadOrderDetails() {
    const response = await getOrderDetail(orderId);
    if (response.status === 200) {
      setOrderData(response.data);
    }
    if (response.status === 500) {
      setErrorMsg(
        "Estamos com problemas para acessar os dados do pedido, tente novamente mais tarde."
      );
    }

    if (response.status === 404) {
      setErrorMsg("Não encontramos um pedido com esse número");
    }
  }

  async function loadTrackingDetails() {
    const response = await getTraking(orderId);
    setTrackingData(response.data);
  }

  useEffect(() => {
    if (orderData) {
      loadCustomerDetails();

      let tempReturnConsignment: string[] = [];
      orderData?.consignments.map((item) => {
        if (item?.links?.getReturnConsignment) {
          tempReturnConsignment.push(item?.links?.getReturnConsignment);
        }
      });
      setReturnConsignment(tempReturnConsignment);
    }
  }, [orderData]);

  useEffect(() => {
    if (orderData) {
      dispatch({ type: "ORDER_SELECTED", payload: orderId });
      dispatch({ type: "ORDER_DETAIL", payload: orderData });
      dispatch({
        type: "PAYMENT_TYPE",
        payload: orderData.payments[0]?.paymentSystemName,
      });
    }
  }, [dispatch, orderData, orderId]);

  const contentCardInfo = async (orderCode: string) => {
    const response = await getContentCards(orderCode);
    setGiftCards(response);
  };

  useEffect(() => {
    if (orderData?.orderCode) contentCardInfo(orderData?.orderCode);
  }, [orderData]);

  useEffect(() => {
    loadTrackingDetails();
    loadOrderDetails();
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const lastShippingComponent =
        shippingRefs.current[shippingRefs.current.length - 1];
      const pageHeight = document.documentElement.offsetHeight;
      const windowHeight = window.innerHeight;
      const scrollPosition = window.pageYOffset;
      const isFinalPage = pageHeight - windowHeight - 2;

      if (shippingRefs.current.length > 0 && lastShippingComponent.current) {
        const lastShippingPosition =
          lastShippingComponent.current.offsetTop - 110;

        if (window.pageYOffset + 2 < lastShippingPosition) {
          setHasNext(true);
        } else {
          setHasNext(false);
        }
        if (scrollPosition >= isFinalPage) {
          setHasNext(false);
        }
      }
    });
  }, [shippingRefs]);

  const executeScroll = () => {
    if (shippingRefs.current) {
      let nextShipping = 0;

      shippingRefs.current.map((ref) => {
        if (window.pageYOffset <= ref.current.offsetTop - 110) {
          if (window.pageYOffset + 2 >= nextShipping) {
            nextShipping = ref.current.offsetTop - 110;
          }
        }

        window.scrollTo(0, nextShipping);
      });
    }
  };

  const returnsOrder = orderData?.consignments.filter(
    (i) => i.consignmentState == "RETURNING"
  );
  const exchangesOrder = orderData?.consignments.filter((i) => i.exchangeOrder);

  const lengthReturns = returnsOrder?.length;

  const hasExchangeRequest = exchangesOrder?.length > 0;
  let exchangeOrderCode = "";
  if (hasExchangeRequest) {
    exchangeOrderCode = exchangesOrder[0]?.exchangeOrder?.orderCode;
  }
  const exchangeRequestInfos = { hasExchangeRequest, exchangeOrderCode };

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento", label: "Atendimento" },
          ]}
        />
        <PageHeader title="VISÃO GERAL" subtitle="Pedido" />

        {errorMsg.length === 0 ? (
          <>
            <Block>
              <OrderSummary customerData={customerData} data={orderData} />
            </Block>

            <Block>
              <Details data={orderData} trackingData={trackingData} />
            </Block>

            <Row justify="flex-start" borderBottom="1px solid #ECEFF1">
              <Text
                type="heading"
                size="medium"
                text="Entregas"
                margin="0 0 20px 0"
              />
              {lengthReturns > 0 &&
                (hasExchangeRequest ? (
                  <>
                    <InfoReturn>
                      <span>Troca pelo mesmo produto</span>
                      <CicleMarkTop>{lengthReturns}</CicleMarkTop>
                    </InfoReturn>
                    <InfoReturn>
                      <span>Devolução (referente à troca)</span>
                      <CicleMarkTop>{lengthReturns}</CicleMarkTop>
                    </InfoReturn>
                  </>
                ) : (
                  <InfoReturn>
                    <span>Devoluções solicitadas</span>
                    <CicleMarkTop>{lengthReturns}</CicleMarkTop>
                  </InfoReturn>
                ))}
            </Row>

            <Block>
              {shippingRefs.current &&
                orderData?.consignments.map((consignment, index) => {
                  return (
                    <ShippingContainer ref={shippingRefs.current[index]}>
                      <Shipping
                        orderData={orderData}
                        trackingData={trackingData}
                        consignment={consignment}
                        exchangeRequestInfos={exchangeRequestInfos}
                        index={index}
                        giftCards={giftCards}
                        customerData={customerData}
                      />
                    </ShippingContainer>
                  );
                })}
              {orderData?.consignments.length > 1 && hasNext && (
                <ScrollTo>
                  <ScrollToButton onClick={executeScroll}>
                    <img src={ScrollToIcon} alt="Scroll To Next" />
                  </ScrollToButton>
                </ScrollTo>
              )}
              {returnConsignment?.length > 0
                ? returnConsignment.map((item: any) => {
                    return <ReturnDelivery returnConsignment={item} />;
                  })
                : ""}
            </Block>
          </>
        ) : (
          <Alert onClick={() => {}} variation="danger">
            {errorMsg}
          </Alert>
        )}
      </Container>
    </Content>
  );
};

export default OrderDetail;
