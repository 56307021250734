import React from "react";

import { Consignment } from "./../../index";
import { Column } from "@components/utilities";
import { ContainerTitle, Badge, Title } from "@pages/styles";
import ReturnReasonItem from "./components/return-reason-item";

type Props = {
  consignment: Consignment;
};

const ReturnReasons = ({ consignment }: Props) => {
  const productOptions = consignment?.items?.map((i) => {
    return {
      label: {
        title: i.name,
        code: i.sellerSku,
        imageUrl: i.imageUrl,
      },
      value: i.id as string,
    };
  });

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>1</Badge>Selecione o item e motivo da devolução
        </Title>
      </ContainerTitle>
      <Column margin="0 auto">
        <ReturnReasonItem
          productOptions={productOptions}
          products={consignment?.items}
        />
      </Column>
    </>
  );
};

export default ReturnReasons;
