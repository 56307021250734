// @ts-nocheck
import http from "@services/http";
import { AppState } from "./index";
import moment from "moment";

const FETCH_ORDER = "FETCH_ORDER";
const FETCH_AVAILABILITY = "FETCH_AVAILABILITY";
const SET_RETURN_ITEM = "SET_RETURN_ITEM";
const SET_RETURN_ITEMS = "SET_RETURN_ITEMS";
const REMOVE_RETURN_ITEM = "REMOVE_RETURN_ITEM";
const SET_RETURN_METHOD = "SET_RETURN_METHOD";
const SET_REFUND_METHOD = "SET_REFUND_METHOD";
const SET_REFUND_INFO = "SET_REFUND_INFO";
const SET_BANK_INFO = "SET_BANK_INFO";
const REQUEST_ORDER_RETURN = "REQUEST_ORDER_RETURN";
const REQUEST_ORDER_RESEND = "REQUEST_ORDER_RESEND";
const CLEAR_STATE = "CLEAR_STATE";
const SET_HAS_RETURN_REASON = "SET_HAS_RETURN_REASON";
const UPDATE_RETURN_STATE = "UPDATE_RETURN_STATE";
const SET_FLOW_TYPE = "SET_FLOW_TYPE";
const SET_RESEND_ITEMS = "SET_RESEND_ITEMS";
const SET_CONSINGMENT = "SET_CONSINGMENT";
const SET_RESEND_STOCK = "SET_RESEND_STOCK";
const SET_RESEND_DATE = "SET_RESEND_DATE";

type CurrentProductType = {
  productValue?: string;
  quantity?: string;
  returnReason?: string;
  checkListOption?: string;
  returnNote?: string;
};

export type availabilityType = {
  items?: any;
  slas?: {
    deliveryChannel: string;
    id: string;
    name: string;
    freightValue: string | number;
    deliveryWindows: {
      endDate: string;
      startDate: string;
    }[];
  }[];
};

export type DeliveryModalityType =
  | "POSTAGE"
  | "COLLECT"
  | "COLLECT_IN_STORE"
  | "RESEND"
  | "REINTEGRATE_STOCK"
  | "NO_RETURN"
  | "TOTAL_REFUND"
  | "PARTIAL_REFUND"
  | "FREIGHT_REFUND";

type ActionType =
  | typeof FETCH_ORDER
  | typeof FETCH_AVAILABILITY
  | typeof SET_RETURN_ITEM
  | typeof SET_RETURN_ITEMS
  | typeof SET_RETURN_METHOD
  | typeof SET_REFUND_METHOD
  | typeof SET_RESEND_DATE
  | typeof SET_BANK_INFO
  | typeof SET_REFUND_INFO
  | typeof CLEAR_STATE
  | typeof SET_CONSINGMENT
  | typeof SET_FLOW_TYPE
  | typeof UPDATE_RETURN_STATE
  | typeof REMOVE_RETURN_ITEM
  | typeof SET_HAS_RETURN_REASON
  | typeof SET_CANCEL_REASON
  | typeof SET_RESEND_ITEMS
  | typeof SET_RESEND_STOCK
  | typeof REQUEST_ORDER_RETURN
  | typeof REQUEST_ORDER_RESEND;

type Action = {
  type: ActionType;
  payload: ReturnItemsState;
};

export interface ReturnItemsState {
  consignmentsItems?: string;
  returnItems?: CurrentProductType[];
  returnMethod?: DeliveryModalityType;
  orderDetails?: any;
  refundMethod?: any;
  refundInfo?: any;
  bankInfo?: any;
  requestReturnState: any;
  hasReturnReason: boolean;
  flowType: string;
  availabilityInfo: availabilityType;
  resendItems: any;
  resendDate: any;
  hasResendStock: boolean;
}

const initialState: ReturnItemsState = {
  consignmentCode: null,
  returnItems: [],
  returnMethod: null,
  refundMethod: null,
  refundInfo: null,
  bankInfo: null,
  requestReturnState: null,
  orderDetails: null,
  hasReturnReason: true,
  flowType: "RETURN",
  availabilityInfo: {},
  resendItems: [],
  resendDate: {},
  hasResendStock: true,
};

export function setHasReturnReason(payload: any) {
  return {
    type: SET_HAS_RETURN_REASON,
    payload,
  };
}

export function removeReturnItem(payload: string | Number) {
  return {
    type: REMOVE_RETURN_ITEM,
    payload,
  };
}

export function setBankInfo(payload: any) {
  return {
    type: SET_BANK_INFO,
    payload,
  };
}

export function setResendStock(payload: any) {
  return {
    type: SET_RESEND_STOCK,
    payload,
  };
}

export function setConsingmentCode(payload: any) {
  return {
    type: SET_CONSINGMENT,
    payload,
  };
}

export function setRefundMethod(payload: any) {
  return {
    type: SET_REFUND_METHOD,
    payload,
  };
}

export function setRefundInfo(payload: any) {
  return {
    type: SET_REFUND_INFO,
    payload,
  };
}

export function setFlowType(payload: any) {
  return {
    type: SET_FLOW_TYPE,
    payload,
  };
}

export function setReturnMethod(payload: DeliveryModalityType) {
  return {
    type: SET_RETURN_METHOD,
    payload,
  };
}

export function setReturnItem(payload: any) {
  return {
    type: SET_RETURN_ITEM,
    payload,
  };
}

export function setReturnItems(payload: any) {
  return {
    type: SET_RETURN_ITEMS,
    payload,
  };
}

export function setResendItems(payload: any) {
  return {
    type: SET_RESEND_ITEMS,
    payload,
  };
}

export function setResendDate(payload: any) {
  return {
    type: SET_RESEND_DATE,
    payload,
  };
}

function fetchAvailability(payload: any) {
  return {
    type: FETCH_AVAILABILITY,
    payload,
  };
}

export function fetchOrder(payload: any) {
  return {
    type: FETCH_ORDER,
    payload,
  };
}

function updateReturnState(payload: any) {
  return {
    type: UPDATE_RETURN_STATE,
    payload,
  };
}

export function clearState() {
  return {
    type: CLEAR_STATE,
    payload: {},
  };
}

export async function requestFetchOrderDetails(orderId: string) {
  return await http.get(`/v1/orders/${orderId}`, {
    params: {
      viewType: "PORTAL_BACKOFFICE",
    },
  });
}

async function requestPostOrderResend(data: any, orderCode, consignmentCode) {
  return await http.put(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/resend`,
    data,
    {
      params: {
        viewType: "PORTAL_BACKOFFICE",
      },
    }
  );
}

async function requestPostOrderReturn(
  data: any,
  orderCode,
  consignmentCode,
  customerEmail
) {
  return await http.put(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/return`,
    { ...data, attendanceChannel: 1, customerEmail: customerEmail }
  );
}

async function requestPostOrderRefund(data: any, orderCode, consignmentCode) {
  return await http.put(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/refund`,
    data,
    {
      params: {
        viewType: "PORTAL_BACKOFFICE",
      },
    }
  );
}

async function requestGetAvailability(data: any, orderCode, consignmentCode) {
  return await http.post(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/return/availability`,
    data
  );
}

export function fetchOrderDetails(orderId: string) {
  return async (dispatch: any) => {
    try {
      const { data } = await requestFetchOrderDetails(orderId);
      dispatch(fetchOrder(data));
    } catch (e) {
      console.error(e);
    }
  };
}

export function requestOrderReturn() {
  return async (dispatch: any, getState) => {
    dispatch(updateReturnState("LOADING"));
    try {
      const { returnItems } = getState();
      const {
        orderDetails,
        returnItems: returnItemsObj,
        refundMethod,
        bankInfo,
        returnMethod,
        consignmentCode,
        hasReturnReason,
      } = returnItems;
      const consignment = orderDetails.consignments.find(
        (c) => c.consignmentCode === consignmentCode
      );

      const returnItemVMList = returnItemsObj.map((item) => {
        const consigmentItem = consignment.items.find(
          (i) => i.id === item.productValue
        );
        const evaluation = {
          reason: item.returnReason,
          observation: item.returnNote,
        };

        if (item.checkListOption) {
          evaluation.checklist = [item.checkListOption];
        } else {
          evaluation.checklist = ["000"];
        }

        return {
          itemCode: consigmentItem.refId,
          quantity: item.quantity,
          evaluation,
        };
      });

      const requestData = {};

      if (hasReturnReason) {
        requestData.returnItems = returnItemVMList;
        requestData.returnModality = returnMethod;
        requestData.refund = { type: refundMethod ?? "REFUND" };

        if (returnMethod === "COLLECT_IN_STORE") {
          requestData.address = consignment.delivery.shippingAddress;
        }

        if (refundMethod === "ACCOUNT_DEPOSIT") {
          const {
            paymentAccount,
            bank,
            bankName,
            branch,
            account,
            branchDigit,
            digit,
          } = bankInfo;
          const accountType = {
            cc: "Conta Corrente",
            cp: "Conta Poupanca",
          };

          requestData.refund.bankAccount = {
            type: accountType[paymentAccount],
            account,
            branch,
            branchDigit,
            bank,
            bankName,
            digit,
          };
        }
      }

      if (!hasReturnReason) {
        requestData.type = refundMethod ?? "REFUND";

        if (refundMethod === "ACCOUNT_DEPOSIT") {
          const {
            paymentAccount,
            bank,
            bankName,
            branch,
            branchDigit,
            account,
            digit,
          } = bankInfo;
          const accountType = {
            cc: "Conta Corrente",
            cp: "Conta Poupanca",
          };

          requestData.bankAccount = {
            type: accountType[paymentAccount],
            account,
            branch,
            branchDigit,
            bank,
            bankName,
            digit,
          };
        }
      }

      if (hasReturnReason) {
        const { data } = await requestPostOrderReturn(
          requestData,
          orderDetails.orderCode,
          consignmentCode,
          orderDetails.customerEmail
        );
      } else {
        const { data } = await requestPostOrderRefund(
          requestData,
          orderDetails.orderCode,
          consignmentCode
        );
      }
      dispatch(updateReturnState("SUCCESS"));
    } catch (e) {
      console.error(e);
      dispatch(updateReturnState("ERROR"));
    }
  };
}

export function requestOrderResend() {
  return async (dispatch: any, getState) => {
    dispatch(updateReturnState("LOADING"));
    try {
      const { returnItems }: AppState = getState();
      const {
        orderDetails,
        returnItems: returnItemsObj,
        availabilityInfo,
        resendItems,
        resendDate,
        consignmentCode,
      } = returnItems;

      const hasDeliveryScheduler = availabilityInfo?.slas?.reduce(
        (acc, sla) => {
          return acc || !!sla.deliveryWindows.length;
        },
        false
      );

      const consignment = orderDetails.consignments.find(
        (c) => c.consignmentCode === consignmentCode
      );

      const returnItemVMList = returnItemsObj.map((item) => {
        const consigmentItem = consignment.items.find(
          (i) => i.id === item.productValue
        );
        const qtItem = resendItems.find(
          (i) => i.id === consigmentItem.vtexItemId
        );
        const availabilityItem = availabilityInfo?.items?.find(
          (i) => i.id === consigmentItem.vtexItemId
        );

        return {
          vtexItemId: consigmentItem.vtexItemId,
          quantity: qtItem?.quantity ?? item.quantity,
          price: consigmentItem.price,
          basePrice: availabilityItem?.basePrice,
          reason: item.returnReason,
          observation: item.returnNote,
          seller: orderDetails.sellerId,
        };
      });

      const shipping = {
        logistic: {
          selectedSla: resendDate.dayPeriod
            ? resendDate.dayPeriod
            : consignment.delivery.selectedSla,
          shippingEstimate: consignment.delivery.estimate,
        },
        address: {
          receiverName: consignment.delivery.receiverName,
          ...consignment.delivery.shippingAddress,
        },
      };

      if (hasDeliveryScheduler) {
        const deliveryStartDate = resendDate.dayHour;
        const dayPeriodArray = availabilityInfo.slas.find(
          (sla) => sla.id === resendDate.dayPeriod
        );
        const deliveryDate = dayPeriodArray.deliveryWindows.find((w) =>
          moment(w.startDate).isSame(deliveryStartDate)
        );

        shipping.logistic.deliveryChannel = dayPeriodArray.deliveryChannel;
        shipping.logistic.freightValue = dayPeriodArray?.freightValue;
        shipping.logistic.deliveryWindowEnd = deliveryDate.endDate;
        shipping.logistic.deliveryWindowStart = deliveryDate.startDate;
      } else {
        const [sla] = availabilityInfo.slas;

        shipping.logistic.deliveryChannel = sla.deliveryChannel;
        shipping.logistic.freightValue = sla.freightValue;
      }

      const customer = {
        firstName: orderDetails.customerFirstName,
        lastName: orderDetails.customerLastName,
        documentType: orderDetails.customerDocumentType,
        document: orderDetails.customerDocument,
        phone: orderDetails.customerPhone,
        email: orderDetails.customerEmail,
      };

      const requestData = {
        items: returnItemVMList,
        shipping,
        customer,
      };

      const { data } = await requestPostOrderResend(
        requestData,
        orderDetails.orderCode,
        consignmentCode
      );
      dispatch(updateReturnState("SUCCESS"));
    } catch (e) {
      console.error(e);
      dispatch(updateReturnState("ERROR"));
    }
  };
}

export function requestAvailability() {
  return async (dispatch: any, getState) => {
    try {
      const { returnItems } = getState();
      const {
        orderDetails,
        returnItems: returnItemsObj,
        consignmentCode,
      } = returnItems;
      const consignment = orderDetails.consignments.find(
        (c) => c.consignmentCode === consignmentCode
      );
      const returnItemsList = returnItemsObj.map((item) => {
        const consigmentItem = consignment.items.find(
          (i) => i.id === item.productValue
        );

        return {
          id: consigmentItem.vtexItemId,
          quantity: item.quantity,
        };
      });
      const requestData = { items: returnItemsList };

      const { data } = await requestGetAvailability(
        requestData,
        orderDetails.orderCode,
        consignmentCode
      );
      dispatch(fetchAvailability(data));
    } catch (e) {
      console.error(e);
      dispatch(setResendStock(false));
    }
  };
}

export function returnItemsReducer(
  state = initialState,
  action: Action
): ReturnItemsState {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case SET_RETURN_ITEM: {
      const returnItems = [...state.returnItems, action.payload];
      return {
        ...state,
        returnItems,
      };
    }
    case SET_FLOW_TYPE: {
      return {
        ...state,
        flowType: action.payload,
      };
    }
    case SET_RETURN_ITEMS: {
      return {
        ...state,
        returnItems: action.payload,
      };
    }
    case SET_HAS_RETURN_REASON: {
      return {
        ...state,
        hasReturnReason: action.payload,
      };
    }
    case REMOVE_RETURN_ITEM: {
      const returnItems = state.returnItems.filter(
        (i) => i.productValue != action.payload
      );
      return {
        ...state,
        returnItems,
      };
    }
    case FETCH_AVAILABILITY: {
      return {
        ...state,
        availabilityInfo: action.payload,
      };
    }
    case SET_RESEND_ITEMS: {
      return {
        ...state,
        resendItems: action.payload,
      };
    }
    case SET_RESEND_DATE: {
      return {
        ...state,
        resendDate: action.payload,
      };
    }
    case SET_RESEND_STOCK: {
      return {
        ...state,
        hasResendStock: action.payload,
      };
    }
    case SET_RETURN_METHOD: {
      return {
        ...state,
        returnMethod: action.payload,
      };
    }
    case SET_CONSINGMENT: {
      return {
        ...state,
        consignmentCode: action.payload,
      };
    }
    case SET_REFUND_METHOD: {
      return {
        ...state,
        refundMethod: action.payload,
      };
    }
    case UPDATE_RETURN_STATE: {
      return {
        ...state,
        requestReturnState: action.payload,
      };
    }
    case SET_REFUND_INFO: {
      return {
        ...state,
        refundInfo: action.payload,
      };
    }
    case SET_BANK_INFO: {
      return {
        ...state,
        bankInfo: action.payload,
      };
    }
    case REQUEST_ORDER_RETURN: {
      return initialState;
    }
    case CLEAR_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
