import React, { useState } from "react";
import Select, { ValueType } from "react-select";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "@store/index";
import { setResendItems, setRefundInfo } from "@store/return-items-reducer";
import { useParams } from "react-router-dom";
import { formatMoney } from "@utils/formatUtils";

import { Consignment } from "./../../index";

import { Button } from "carrefour-portal-backoffice-style-guide";
import { Column, selectStyle } from "@components/utilities";
import { ContainerTitle, Badge, Title } from "@pages/styles";
import ProductItem from "./components/product-item";
import {
  Td,
  Th,
  TableWrapper,
  Table,
  Heading,
  Subheading,
  TotalValueWrapper,
  TotalValueRow,
  TotalRow,
  TotalPriceLabel,
  TotalPriceSmall,
  TotalPrice,
  ActionsWrapper,
  DimButton,
} from "./style";

type ReturnProps = {
  consignment?: Consignment;
};

type ChangeOption = {
  label: string;
  value: string;
};

const ReturnItems = (props: ReturnProps) => {
  const { consignment } = props;
  const dispatch = useDispatch();
  const returnItems = useSelector(
    (state: AppState) => state.returnItems?.returnItems
  );
  const availabilityInfo = useSelector(
    (state: AppState) => state.returnItems?.availabilityInfo
  );
  const resendItems: { quantity: number; id: string }[] = useSelector(
    (state: AppState) => state.returnItems?.resendItems
  );
  const returnProducts = consignment?.items.filter((item) => {
    return returnItems.find((i) => i.productValue === item.id);
  });

  const handleChangeSelectChange = (option: ChangeOption, id: string) => {
    const filteredItems = resendItems.filter((r) => r.id !== id);
    const newItemsArray = [...filteredItems, { quantity: option.value, id }];
    dispatch(setResendItems(newItemsArray));
  };

  var totalSelectedValue = resendItems.reduce((acc, item) => {
    const product = returnProducts.find((p) => p.vtexItemId == item.id);
    return acc + product.price * item.quantity;
  }, 0);

  const totalReturnValue = returnItems.reduce((acc, item) => {
    const product = returnProducts.find((p) => p.id == item.productValue);
    return acc + product.price * parseInt(item.quantity);
  }, 0);

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>2</Badge>Selecione a quantidade a ser reenviada
        </Title>
      </ContainerTitle>
      <Column margin="0 auto">
        <TableWrapper>
          <Table>
            <thead>
              <tr>
                <Th>Descrição do produto</Th>
                <Th>Quantidade Selecionada</Th>
                <Th>Quantidade disponivel</Th>
                <Th>Quantidade reenvio</Th>
                <Th>Preço unitário</Th>
                <Th>Valor do frete</Th>
              </tr>
            </thead>
            <tbody>
              {returnProducts.map((product) => {
                const returnInfo = returnItems.find(
                  (p) => p.productValue === product.id
                );
                const { items } = availabilityInfo;
                const productStock = items?.find(
                  (i: any) => i.id === product.vtexItemId
                );
                const quantityArray = [
                  ...new Array(productStock?.quantity),
                ].map((_, i) => ({
                  label: `${i + 1}`,
                  value: `${i + 1}`,
                }));

                return (
                  <tr>
                    <Td>
                      <ProductItem
                        imageUrl={product.imageUrl}
                        productCode={product.sellerSku}
                        productTitle={product.name}
                      />
                    </Td>
                    <Td>
                      <Select
                        options={quantityArray}
                        placeholder="Qnt"
                        onChange={(o: ChangeOption) =>
                          handleChangeSelectChange(o, productStock.id)
                        }
                        styles={selectStyle}
                      />
                    </Td>
                    <Td>{productStock?.quantity}</Td>
                    <Td>{returnInfo.quantity}</Td>
                    <Td>{formatMoney(product.price)}</Td>
                    <Td>{formatMoney(product.freightPrice)}</Td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <TotalValueWrapper>
            <TotalValueRow>
              <TotalPriceLabel>Total de itens</TotalPriceLabel>
              <TotalPriceSmall>
                {formatMoney(totalSelectedValue)}
              </TotalPriceSmall>
            </TotalValueRow>
          </TotalValueWrapper>
        </TableWrapper>
      </Column>
    </>
  );
};

export default ReturnItems;
