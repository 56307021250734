import styled from "styled-components";

import imgHash from "../../../../assets/hashtag.svg";
import imgCalendar from "../../../../assets/calendar.svg";
import imgHeadphone from "../../../../assets/headphone.svg";
import imgArrowLeft from "../../../../assets/arrow-left.svg";
import { Column } from "@components/utilities";

type Title = {
  margin?: string;
  color?: string;
  size?: string;
  weight?: string;
};

export const TitleAlert = styled.h3<Title>`
  margin: ${(props: Title) => props.margin || "30px 0 0 0"};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props: Title) => props.size || "20px"};
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: Title) => props.color || "#333"};

  img {
    margin-right: 10px;
  }
`;
export const InfoReturn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: transparent;
  margin-left: 40px;
  margin-bottom: 20px;

  span {
    font-family: "Ubuntu";
    font-style: normal;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #173eb4;
    padding: 4px 8px;
    border: 1px solid #173eb4;
    border-radius: 4px;
  }
`;

export const CicleMarkTop = styled.span`
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background-color: #173eb4;
  border-radius: 100% !important;
  padding: 0 !important;
  align-self: auto;
`;

export const HowExchangeWorks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 0 0 32px 0;
  background-color: #173eb4;

  font-family: "Ubuntu";
  text-align: left;
  color: #fff;
  line-height: 24px;
  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
  }
  p {
    margin: 0;
    max-width: 815px;
    font-size: 16px;
    font-weight: 400;
    strong {
      font-weight: 700;
    }
  }
  a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
`;

type ModalityTitleProps = {
  color?: string;
  backgroundColor?: string;
};

export const ModalityTitle = styled.strong<ModalityTitleProps>`
  display: flex;
  background: ${(props: ModalityTitleProps) =>
    props.backgroundColor || "##1E5BC6"};
  padding: 0px 5px;
  border-radius: 4px;
  margin-right: 20px;

  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  align-items: flex-start;
  color: ${(props: ModalityTitleProps) => props.color || "#fff"};
  span {
    display: flex;
    justify-content: space-between;
    img {
      margin-left: 8px;
    }
  }
`;

export const TitleShipping = styled.h5<Title>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: ${(props: Title) => props.margin || "0 19px"};
`;

export const TitleSuccess = styled.h4`
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  color: #40aa60;
  margin: 0;
`;

export const DescAlert = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #263238;
`;

type TStyledP = {
  margin?: string;
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  textAlign?: string;
};

export const TextLight = styled.p<TStyledP>`
  font-weight: ${(props: TStyledP) => props.fontWeight || 300};
  font-size: ${(props: TStyledP) => props.fontSize || "14px"};
  line-height: 24px;
  color: ${(props: TStyledP) => props.color || "#546e7a"};
  margin: ${(props: TStyledP) => props.margin || "0px 0px 15px 0px"};
  text-align: ${(props: TStyledP) => props.textAlign || "initial"};
`;

export const Strong = styled.strong<Title>`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  color: ${(props: Title) => props.color || "#263238"};
  margin-right: 20px;
`;

export const Label = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #b0bec5;
  margin: 15px 0 0 0;
`;

type RowBetweenProps = {
  justifyContent?: string;
};

export const RowBetween = styled.div<RowBetweenProps>`
  display: flex;
  justify-content: ${(props: RowBetweenProps) =>
    props.justifyContent || "space-between"};
  width: 100%;
  align-items: center;
  * {
    background-position: right top;
  }
`;

type RowStartProps = {
  margin?: string;
};

export const RowStart = styled.div<RowStartProps>`
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: flex-start;
  margin-top: ${(props: RowStartProps) => props.margin || "35px"};
`;

export const SectionShipping = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

/*Modal*/
type TCardModal = {
  padding?: string;
  maxWidth?: string;
};

export const CardModal = styled.div<TCardModal>`
  width: auto;
  max-width: ${(props: TCardModal) => props.maxWidth || "none"};
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "20px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTotalContent = styled.div`
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 0 0 0;
  margin-top: 20px;
`;

export const CardTotals = styled.div<{ center?: boolean }>`
  width: 25%;
  height: 60px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.center ? "center" : "")};
`;

export const CardContentTotal = styled.div`
  background-image: url(${imgHash});
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardContentUp = styled.div`
  background-image: url(${imgCalendar});
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardContentDown = styled.div`
  background-image: url(${imgHeadphone});
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  margin: 0px;
  text-transform: uppercase;
  color: #90a4ae;
`;

export const CardText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #b0bec5;
`;

export const UserImg = styled.img`
  margin-right: 22px;
`;

export const UserName = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #546e7a;
  margin: 0px 0px 10px 0px;
`;

export const UserInfo = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #78909c;
  margin: 0px;
`;

export const UserValue = styled.h4<Title>`
  font-weight: ${(props: Title) => props.weight || "200"};
  font-size: 16px;
  line-height: 21px;
  color: ${(props: Title) => props.color || "#546e7a"};
  margin: 0;
  display: flex;
  align-items: center;
`;

export const ItemsOver = styled.div`
  max-height: 315px;
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

type ButtonNFProps = {
  margin?: string;
  padding?: string;
  imgMargin?: string;
};

export const ButtonNF = styled.a<ButtonNFProps>`
  font-weight: normal;
  font-size: 14px;
  padding: ${(props: ButtonNFProps) => props.padding || "8px 12px"};
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #1e5bc6;
  border-color: #1e5bc6;
  border: 1px solid;
  background: transparent;
  transition: 0.2s all linear;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
  margin: 0 10px 0 0;

  img {
    margin: ${(props: ButtonNFProps) => props.imgMargin || "0px 0px 0px 5px"};
  }
`;

export const ConsignmentOptionButton = styled.a`
  background: #ffffff;
  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 16px;
  color: #1e5bc6;
  font-size: 14px;
  line-height: 20px;

  :hover {
    cursor: pointer;
  }
`;

export const ConsignmenOptions = styled(Column)`
  align-items: flex-end;
  flex-direction: row;
  margin: 20px 0px 10px 25px;
`;

type ButtonProps = {
  margin?: string;
  color?: string;
};

export const ButtonData = styled.button<ButtonProps>`
  font-weight: normal;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${(props: ButtonProps) => props.color || "#ff7a14"};
  border: 1px solid;
  border-color: ${(props: ButtonProps) => props.color || "#ff7a14"};
  background: transparent;
  transition: 0.2s all linear;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
  margin: ${(props: ButtonProps) => props.margin || "30px 0px 10px 0px"};

  img {
    margin-left: 5px;
    padding-bottom: 1px;
  }
`;

export const ButtonAnchor = styled.a<ButtonProps>`
  font-weight: normal;
  font-size: 14px;
  padding: 8px 12px 8px 22px;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${(props: ButtonProps) => props.color || "#ff7a14"};
  border: 1px solid;
  border-color: ${(props: ButtonProps) => props.color || "#ff7a14"};
  background: transparent;
  background-image: url(${imgArrowLeft});
  transition: 0.2s all linear;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
  margin: ${(props: ButtonProps) => props.margin || "30px 0px 10px 0px"};
  background-repeat: no-repeat;
  background-position: 4px center;
`;

export const BorderCard = styled.div`
  border: 1px solid #eceff1;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const AlertDisruption = styled.div`
  background: rgba(255, 122, 20, 0.05);
  border: 1px solid #ff7a14;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0 0 30px 0;
  padding: 5px 14px;
  display: flex;
  justify-content: space-between;
  width: 68%;

  * {
    font-size: 12px;
    line-height: 16px;
    color: #ff7a14;
  }
`;

export const ButtonDetails = styled.button`
  background: unset;
  border: unset;
  color: #ff7a14;
  font-weight: 700;
  cursor: pointer;

  &:focus {
    outline: unset;
  }
`;

type Status = {
  active?: boolean;
};

export const ButtonStatus = styled.button<Status>`
  background: unset;
  border: unset;
  color: ${(props: Status) => (props.active ? "#1e5bc6" : "#cccccc")};
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid;
  border-color: ${(props: Status) => (props.active ? "#1e5bc6" : "#cccccc")};

  &:focus {
    outline: none;
  }

  span {
    background: ${(props: Status) => (props.active ? "#1e5bc6" : "#cccccc")};
    color: #ffffff;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    border-radius: 50%;
    margin-left: 8px;
    padding: 2px;
  }
`;

export const TrackingCodeWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #eaeef3;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 4px 0px;
  padding: 16px;
  width: fit-content;

  position: relative;
`;

export const TrackingCode = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
`;

export const TrackingCodeCopy = styled.button`
  all: unset;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  margin: 0px 8px;
  color: #00a3bc;
  cursor: pointer;

  :hover,
  :active {
    color: #1e5bc6;
  }
`;

export const TrackingCodeLink = styled.a`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #1e5bc6;
  margin-top: 4px;
`;

export const TrackingCodeInfo = styled.div`
  background: #fbf1e0;
  border-radius: 8px;
  margin: 0px 0px;
  margin-top: 12px;
  padding: 16px;

  position: relative;
  border: none;

  ::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #fbf1e0;

    position: absolute;
    left: 12px;
    top: -6px;
    transform: rotate(45deg);
  }
`;

export const TrackingCodeInfoText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #5a646e;
  margin: 0px 10px;
`;

export const CloseComponent = styled.button`
  all: unset;
  cursor: pointer;
`;

type ClipboardAlertProps = {
  active?: boolean;
};

export const ClipboardAlert = styled.div<ClipboardAlertProps>`
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;
  color: #353a40;
  padding: 8px 16.5px;

  background: #ffffff;
  border: 1px solid #8a99a8;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(90, 100, 110, 0.12);

  position: absolute;
  top: -5px;
  left: -5px;
  width: fit-content;
  transform: translateY(-50%) translateX(50%);

  z-index: ${(props: ClipboardAlertProps) => (props.active ? "0" : "-1")};
  opacity: ${(props: ClipboardAlertProps) => (props.active ? "1" : "0")};
  transition: ${(props: ClipboardAlertProps) =>
    props.active ? "" : "all 250ms linear 0.2s"};
`;

type HighlightShippingModality = {
  background?: string;
};

export const HighlightShippingModality = styled.div<HighlightShippingModality>`
  background: ${(props: HighlightShippingModality) =>
    props.background || "#333333"};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 25px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

type CardShippingProps = {
  width?: string;
  height?: string;
  background?: string;
  padding?: string;
  margin?: string;
  position?: string;
  children: any;
};

type ToolTipContainerProps = {
  maxWidth?: string;
  background?: string;
  margin?: string;
};

export const CardShipping = styled.div<CardShippingProps>`
  width: ${(props: CardShippingProps) => props.width || "100%"};
  height: ${(props: CardShippingProps) => props.height || "100%"};
  background: ${(props: CardShippingProps) => props.background || "#fff"};
  padding: ${(props: CardShippingProps) => props.padding || "30px 40px"};
  margin: ${(props: CardShippingProps) => props.margin || ""};
  position: ${(props: CardShippingProps) => props.position || "static"};
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(176, 190, 197, 0.3);
`;
export const DeliveryDetail = styled.small`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  color: #e89b28;
`;
export const CicleMark = styled.div`
  background-color: #e7f8eb;
  font-size: 20px;
  line-height: 32px;
  color: #007222;
  padding: 8px;
  border-radius: 50%;
  margin-left: 16px;
  span:first-child {
    font-weight: 700;
  }
`;

export const TooltipContainer = styled.div<ToolTipContainerProps>`
  background: #3c9457;
  margin: 35px 0 0 190px;
  max-width: 311px;
  border-radius: 8px;
  position: absolute;
  border: none;
  content: "";
  height: auto;
  ::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #3c9457;
    position: absolute;
    left: 12px;
    top: -6px;
    transform: rotate(45deg);
  }
`;

export const TooltipText = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #fff;
    margin: 0;
    padding: 16px 16px 16px 0;
  }

  img {
    padding: 16px;
  }
`;

export const TitleRedeemGift = styled.h4`
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  color: #1e5bc6;
  margin: ${(props: Title) => props.margin || "0"};
`;

export const NewTooltipContainer = styled.div<ToolTipContainerProps>`
  position: absolute;

  right: 0;
  left: inherit;
  bottom: -80px;

  min-width: 400px;
  background: #e2f1fe;
  padding: 16px 20px 16px 0;
  border-radius: 8px;
  border: none;
  content: "";
  height: auto;
  ::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #e2f1fe;
    position: absolute;
    left: 270px;
    top: -6px;
    transform: rotate(45deg);
  }
`;

export const NewTooltipText = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    display: initial;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    color: #353a40;
    margin: 0;

    button {
      color: #1e5bc6;
      background: transparent;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  img {
    padding: 8px;
  }
`;

type ModalActionButtonProps = {
  background?: string;
  color?: string;
  border?: string;
  padding?: string;
};
export const ModalActionButton = styled.button<ModalActionButtonProps>`
  width: 180px;
  height: 40px;

  border: none;
  background: ${(props: ModalActionButtonProps) =>
    props.background || "#FFFFFF"};
  color: ${(props: ModalActionButtonProps) => props.color || "#1E5BC6"};
  box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
  border-radius: 4px;

  font-weight: normal;
  font-size: 14px;
  padding: ${(props: ModalActionButtonProps) => props.padding || "16px 16px"};
  border-radius: 4px;
  line-height: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s all linear;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
  margin: 0 10px 0 0;
`;
