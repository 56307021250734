import styled from "styled-components";

export const ContainerBar = styled.div`
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 0px 8px rgba(176, 190, 197, 0.3);
`;

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

export const CustomerInfo = styled.div`
  h5 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #37474f;
    margin: 0;

    span {
      font-weight: 300;
    }
  }

  strong {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #90a4ae;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: #546e7a;

    span {
      margin-left: 5px;
      font-weight: 300;
      font-size: 10px;
      line-height: 16px;
      color: #b0bec5;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;
