import React from "react";
import styled from "styled-components";

type ProductProps = {
  title: string;
  cod: string;
  imgUrl: string;
};

const ProductInfoWrapper = styled.div`
  display: flex;
`;

const ProductTitle = styled.h1`
  font-size: 12px;
  line-height: 16px;
  color: #37474f;
  margin: 0;
`;
const ProductCode = styled.h2`
  font-size: 10px;
  line-height: 16px;
  color: #b0bec5;
  margin: 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: center;
`;

const ProductImage = styled.img`
  width: 56px;
  height: auto;
`;

export const ProductInfo = (props: ProductProps) => {
  return (
    <ProductInfoWrapper>
      <ProductImage src={props.imgUrl} alt={props.title} />
      <InfoWrapper>
        <ProductTitle>{props.title}</ProductTitle>
        <ProductCode>{props.cod}</ProductCode>
      </InfoWrapper>
    </ProductInfoWrapper>
  );
};
