import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cancel } from "@services/cancellation";
import { Column, Row } from "@components/utilities";
import { formatMoney } from "@utils/formatUtils";
import { Button } from "carrefour-portal-backoffice-style-guide";
import {
  TitleAlert,
  CardModal,
  ViewBorder,
  ViewGrey,
  TitleGrey,
  ValueTotal,
} from "../../styles";
import { OrderDetailsResponse } from "../../../../OrderDetailsResponse";
import { AppState } from "src/store";

type ModalConfirmProps = {
  close(): void;
  data: OrderDetailsResponse;
};

const ModalConfirm = ({ close, data }: ModalConfirmProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cancelReason = useSelector(
    (state: AppState) => state.cancel.cancelReason
  );

  const cancelOrder = async () => {
    const payload = {
      reason: cancelReason,
      observation: "",
      customerEmail: data.customerEmail,
      refund: {
        type: "NO_REFUND",
      },
    };

    if (data?.orderCode) {
      const response = await cancel(data.orderCode, payload);

      if (response === 204) {
        dispatch({
          type: "MODAL_TOTAL_CANCEL",
          payload: 4,
        });
        history.push(`/atendimento/pedido/detalhe/${data.orderCode}`);
      } else {
        dispatch({
          type: "MODAL_TOTAL_CANCEL",
          payload: 5,
        });
      }
    }
  };

  return (
    <>
      <CardModal data-testid="cancel-modal" padding="30px 50px 25px 50px">
        <Column margin="0 auto" textAlign="center">
          <Row>
            <TitleAlert color="#263238" margin="0px">
              Você tem certeza que deseja <br /> cancelar este pedido?
            </TitleAlert>
          </Row>
        </Column>

        <ViewBorder>
          <ViewGrey>
            <div>
              <TitleGrey>PEDIDO</TitleGrey>
              <ValueTotal>{data?.orderCode}</ValueTotal>
            </div>
            <div>
              <TitleGrey>QUANTIDADE DE ENTREGAS</TitleGrey>
              <ValueTotal>{`${data?.consignments.length} entrega${
                data?.consignments.length > 1 ? "s" : ""
              }`}</ValueTotal>
            </div>
            <div>
              <TitleGrey>VALOR TOTAL DO PEDIDO</TitleGrey>
              <ValueTotal color="#2E8CF6">
                {formatMoney(data?.totalValue)}
              </ValueTotal>
            </div>
          </ViewGrey>
        </ViewBorder>

        <Row margin="30px 0 0 0">
          <Column margin="0 20px 0 0">
            <Button
              text="Fechar"
              variation="primary"
              onClick={close}
              size="large"
              outline
            />
          </Column>
          <Button
            text="Confirmar cancelamento"
            variation="confirm"
            onClick={() => cancelOrder()}
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalConfirm;
