import React, { useEffect } from "react";
import { Column } from "@components/utilities";
import { TitleSuccess, CardModal } from "../../styles";
import iconCheck from "@assets/check.svg";

const ModalConfirmation = () => {
  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        <img src={iconCheck} alt="check" />
        <Column margin="30px 0 0 0" textAlign="center">
          <TitleSuccess>
            Sua solicitação de cancelamento <br />
            foi realizada com sucesso!
          </TitleSuccess>
        </Column>
      </CardModal>
    </>
  );
};

export default ModalConfirmation;
