import styled from "styled-components";

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;

  * {
    background-position: right top;
  }
`;

export const RowBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    display: flex;
    align-items: center;
  }
`;

export const ClientName = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #37474f;
  margin: 0px;
`;

export const ClientInfo = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #78909c;
  margin: 8px 0px;

  strong {
    color: #37474f;
    font-size: 14px;
  }

  b {
    color: #37474f;
    font-size: 10px;
  }
`;

export const SummaryText = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || "#607d8b"};
  border-bottom: 1px solid #eceff1;
  margin-top: 0px;
  padding-bottom: 10px;
  justify-content: space-between;

  &:last-child {
    font-size: 16px;
    color: #1e5bc6;
    border: none;
  }

  strong {
    font-weight: 500;
  }
`;

export const HeaderPayment = styled.div`
  padding: 15px 25px;
  background: #fcfdff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
`;

export const BodyPayment = styled.div`
  padding: 5px 15px;
`;

export const PaymentItem = styled.div`
  border-bottom: 1px solid #eceff1;
  padding: 15px 0px;

  img {
    margin-right: 5px;
  }
`;

export const StatusPayment = styled.h3`
  font-weight: 400;
  font-size: 20px;
  color: #40aa60;
  margin: 0px;
`;

type TTextPayment = {
  color?: string;
  weight?: string;
  margin?: string;
};

export const TextPayment = styled.span<TTextPayment>`
  font-size: 14px;
  line-height: 21px;
  font-weight: ${(props: TTextPayment) => props.weight || "400"};
  color: ${(props: TTextPayment) => props.color || "#353A40"};
  margin: ${(props: TTextPayment) => props.margin || "0px"};
`;

export const LinkUrl = styled.a`
  display: flex;
  color: #1e5bc6;
  font-size: 12px;
  padding-top: 12px;

  img {
    margin-left: 5px;
    width: 15px;
  }
`;

export const PaymentDetailName = styled.p`
  font-size: 18px;
  margin: 0px;
  color: #546e7a;
  padding: 10px 0;
`;
