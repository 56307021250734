import React, { useState, useEffect } from "react";

import { Column, Row } from "@components/utilities";
import { Text, Button } from "carrefour-portal-backoffice-style-guide";
import {
  TitleAlert,
  TitleShipping,
  RowBetween,
  CardModal,
  CardTotalContent,
  CardTotals,
  CardTitle,
  UserValue,
  ItemsOver,
  ButtonStatus,
} from "../../styles";

import iconExclamation from "@assets/exclamation.svg";
import iconArrowUp from "@assets/arrow-up.svg";
import iconArrowDown from "@assets/arrow-down.svg";

import Items from "./items";
import { formatDate } from "@utils/formatUtils";

interface TItems {
  items: TItem[];
  width?: string;
  consignmentCode?: string;
  totalFreight?: number;
  totalValue?: number;
  totalItemValue?: number;
  status?: string;
  close?: any;
}

interface TItem {
  ean?: string;
  name?: string;
  quantity?: number;
  price?: number;
  discount?: number;
  basePrice?: number;
  imageUrl?: string;
  warranty?: boolean;
  availableQuantity?: number;
  disruptionQuantity?: number;
}

const ModalStockBreak = ({
  items,
  consignmentCode,
  totalFreight,
  totalValue,
  totalItemValue,
  status,
  close,
}: TItems) => {
  const [totalQty, setTotalQty] = useState(0);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [valueCanceledItems, setValueCanceledItems] = useState(0);
  const [valueItemsSent, setValueItemsSent] = useState(0);
  const [totalDisruption, setTotalDisruption] = useState(0);
  const [available, setAvailable] = useState(true);

  useEffect(() => {
    if (items) {
      let qty = 0;
      let qtyAvailable = 0;
      let qtyDisruption = 0;
      items.map((item) => {
        qty = qty + item.quantity;
        qtyAvailable = qtyAvailable + item.availableQuantity;
        qtyDisruption = qtyDisruption + item.disruptionQuantity;
      });

      let valueSent = items.reduce((total, elemento) => {
        if (elemento.availableQuantity > 0)
          return (total += elemento.availableQuantity * elemento.price);
        else return total;
      }, 0);
      setValueItemsSent(valueSent);

      let valueCanceled = items.reduce((total, elemento) => {
        if (elemento.disruptionQuantity > 0)
          return (total += elemento.disruptionQuantity * elemento.price);
        else return total;
      }, 0);
      setValueCanceledItems(valueCanceled);

      setTotalQty(qty);
      setTotalAvailable(qtyAvailable);
      setTotalDisruption(qtyDisruption);
    }
  }, []);

  return (
    <>
      <CardModal data-testid="cancel-modal">
        <div>
          <Column margin="0 auto" textAlign="center">
            <TitleAlert color="#ff7a15" size="28px">
              <img src={iconExclamation} alt="exclamation" height="21px" />
              Ruptura de Estoque
            </TitleAlert>
            <br />
            {status === "PPA" ? (
              <Text
                type="body"
                size="medium"
                text="Alguns itens foram cancelados por indisponibilidade de estoque"
                margin="0"
              />
            ) : (
              <Text
                type="body"
                size="medium"
                text="Alguns itens desta entrega estão com indisponibilidade de estoque"
                margin="0"
              />
            )}
          </Column>
          <CardTotalContent>
            <TitleShipping>
              {`Entrega ${consignmentCode?.substr(
                consignmentCode?.length - 2
              )}`}
            </TitleShipping>
            {status === "PPA" ? (
              <RowBetween>
                <CardTotals center>
                  <CardTitle>ITENS ENVIADOS</CardTitle>
                  <UserValue color="#40AA60" weight="500">
                    <img
                      src={iconArrowUp}
                      alt="up"
                      height="16px"
                      style={{ marginRight: 8 }}
                    />
                    {totalAvailable}
                  </UserValue>
                </CardTotals>
                <CardTotals center>
                  <CardTitle>ITENS CANCELADOS</CardTitle>
                  <UserValue color="#FF403F" weight="500">
                    <img
                      src={iconArrowDown}
                      alt="down"
                      height="16px"
                      style={{ marginRight: 8 }}
                    />
                    {totalDisruption}
                  </UserValue>
                </CardTotals>
                <CardTotals center>
                  <CardTitle>VALOR DE ITENS ENVIADOS</CardTitle>
                  <UserValue color="#2E8CF6" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(valueItemsSent)}
                  </UserValue>
                </CardTotals>
                <CardTotals center>
                  <CardTitle>VALOR DE ITENS CANCELADOS</CardTitle>
                  <UserValue color="#2E8CF6" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(valueCanceledItems)}
                  </UserValue>
                </CardTotals>
              </RowBetween>
            ) : (
              <RowBetween>
                <CardTotals center>
                  <CardTitle>ITENS ADQUIRIDOS</CardTitle>
                  <UserValue color="#2E8CF6" weight="500">
                    {totalQty}
                  </UserValue>
                </CardTotals>
                <CardTotals>
                  <CardTitle>ITENS INDISPONÍVEIS</CardTitle>
                  <UserValue color="#FF403F" weight="500">
                    <img
                      src={iconArrowDown}
                      alt="down"
                      height="16px"
                      style={{ marginRight: 8 }}
                    />
                    {totalDisruption}
                  </UserValue>
                </CardTotals>
                <CardTotals center>
                  <CardTitle>VALOR DA ENTREGA</CardTitle>
                  <UserValue color="#2E8CF6" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(totalValue)}
                  </UserValue>
                </CardTotals>
              </RowBetween>
            )}
          </CardTotalContent>
          <Row justify="flex-start">
            {status === "RUP" && (
              <ButtonStatus active={true}>
                Itens indisponíveis <span>{totalDisruption}</span>
              </ButtonStatus>
            )}
            {status === "PPA" && (
              <>
                <ButtonStatus
                  onClick={() => setAvailable(true)}
                  active={available && true}
                >
                  Itens enviados <span>{totalAvailable}</span>
                </ButtonStatus>
                <ButtonStatus
                  onClick={() => setAvailable(false)}
                  active={!available && true}
                >
                  Itens cancelados <span>{totalDisruption}</span>
                </ButtonStatus>
              </>
            )}
          </Row>
          <ItemsOver>
            <Items
              items={items}
              width="100%"
              status={status}
              available={status === "RUP" ? false : available}
            />
          </ItemsOver>
        </div>
        <Row margin="30px 0 0 0" justify="flex-end">
          <Column margin="0 20px 0 0">
            <Button
              text="Fechar"
              variation="primary"
              onClick={() => close()}
              size="large"
              outline
            />
          </Column>
        </Row>
      </CardModal>
    </>
  );
};

export default ModalStockBreak;
