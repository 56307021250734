import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { AppState } from "src/store";
import { SearchState } from "../../store/search-reducer";

import { Content, Container, Block } from "@components/utilities";
import { Breadcrumbs } from "carrefour-portal-backoffice-style-guide";
import { getOrder } from "@services/order";
import PageHeader from "@components/page-header";
import OrderTable from "./components/order-table";
import Summary from "./components/summary";
import { ErrorAlert, ErrorButton } from "./styles";

type Order = {
  date: Date;
  order: string;
  value: number;
  status: string;
};

const Orders = () => {
  const { searchTermOrder } = useSelector<AppState, SearchState>(
    (state) => state.search
  );

  const [orderList, setOrderList] = useState([]);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  async function loadOrders(offset: number = 0) {
    setLoading(true);

    if (offset > 0) {
      offset = offset - 1;
    }

    try {
      const response = await getOrder(searchTermOrder, offset, ordersPerPage);
      const orderConverted = convertOrder(response.data);

      setTotalRecords(response.data.paging.totalRecords);
      setOrderList(orderConverted);
    } catch (error) {
      setRequestError(true);
    } finally {
      setLoading(false);
    }
  }

  const handlePerRowsChange = async (perPage: number) => {
    setOrdersPerPage(perPage);
  };

  function convertOrder(param: any) {
    let data: Order[] = [];

    if (param.objects.length > 0) {
      param.objects.map((item: any) => {
        const _item = {
          date: item.orderDate,
          order: item.orderCode,
          value: item.totalValue,
          status: item.consignments,
        };
        data.push(_item);
      });
    }

    return data;
  }

  useEffect(() => {
    loadOrders();
  }, [ordersPerPage]);

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento", label: "Atendimento" },
          ]}
        />
        <PageHeader title="RESUMO" subtitle="Pedidos" />
        <Block>
          <Summary />
        </Block>
        <Block data-testid="order-table">
          {requestError ? (
            <ErrorAlert>
              <h3>
                Ah não! Tivemos um problema em nosso sistema. <br />
                Por isso não conseguimos mostrar as informações solicitadas. Que
                tal tentar novamente?
                <ErrorButton onClick={() => window.location.reload()}>
                  Tentar novamente
                </ErrorButton>
              </h3>
            </ErrorAlert>
          ) : (
            <OrderTable
              loadOrders={loadOrders}
              handlePerRowsChange={handlePerRowsChange}
              data={orderList}
              loading={loading}
              totalRecords={totalRecords}
            />
          )}
        </Block>
      </Container>
    </Content>
  );
};

export default Orders;
