import styled from "styled-components";

export interface MessageModalContainerProps {
  type: string;
}

export const MessageModalContainer = styled.div<MessageModalContainerProps>`
  color: ${(props: MessageModalContainerProps) =>
    props.type === "success" ? "#40AA60" : "#455A64"};
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;

  width: 570px;
  background: #fff;
  border-radius: 8px;
  padding: 83px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    margin-bottom: 16px;
    width: 64px;
    height: 64px;
  }

  .buttons {
    margin-top: 16px;
  }
`;
