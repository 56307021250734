import styled from "styled-components";

/*Modal*/
type TCardModal = {
  padding?: string;
};

export const CardModal = styled.div<TCardModal>`
  z-index: 100;
  width: auto;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "20px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const TitleAlert = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #ff7a14;
`;

export const DescAlert = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #263238;
`;

export const Subtitle = styled.p`
  margin: 32px 10px;
  font-size: 15px;
  font-weight: 400;

  span {
    color: #1e5bc6;
  }
`;

export const SmallText = styled.small`
  color: #607d8b;
  margin-top: 12px;
  font-weight: 300;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 14px;
`;

export const InfoTextWrapper = styled.div`
  width: 600px;
  text-align: left;
  max-height: 300px;
  overflow-y: auto;
  font-size: 14px;
  line-height: 24px;
  padding-right: 24px;
`;
export const InfoSubhead = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;

export const InfoHead = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const InfoAddress = styled.div`
  text-align: left;
  color: #78909c;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
`;

export const SuccessText = styled.p`
  color: #40aa60;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  max-width: 451px;
  text-align: center;
`;
export const ErrorText = styled.p`
  color: #607d8b;
  max-width: 451px;
  text-align: center;
`;
export const PaymentInfo = styled.div`
  border: 1px solid #eceff1;
  border-radius: 8px;
  padding: 16px;
`;
export const PaymentInfoLabel = styled.h5`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #b0bec5;
  margin: 0;
`;

export const PaymentInfoValue = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #546e7a;
`;
export const Heading = styled.h1`
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #1e5bc6;
`;

export const SubtitleValue = styled(Subtitle)`
  margin: 16px 0 0 0;
`;

export const PaymentItem = styled.div`
  border-bottom: 1px solid #eceff1;
  padding: 15px 0;

  img {
    margin-right: 5px;
  }

  &:last-child {
    border-bottom: none;
    padding: 0px;
  }
`;

type TextPayment = {
  color?: string;
  weight?: string;
  margin?: string;
  fontSize?: string;
};

export const TextPayment = styled.span<TextPayment>`
  font-size: ${(props: TextPayment) => props.fontSize || "12px"};
  font-weight: ${(props: TextPayment) => props.weight || "300"};
  color: ${(props: TextPayment) => props.color || "#78909c"};
  margin: ${(props: TextPayment) => props.margin || "0px"};
`;

export const RowBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    display: flex;
    align-items: center;
  }
`;

export const CardItemPayment = styled.div`
  background: #fcfdff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 25px;
  width: 340px;
  margin: 15px auto 0px;

  p {
    display: none;
  }
`;

export const IconModal = styled.img`
  width: 128px;
  margin: 0 auto;
`;
