import styled from "styled-components";

export const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 0;
  max-width: 211px;
`;

export const TextProductWrapper = styled.div`
  margin-left: 6px;
  text-align: left;
`;

export const ProductTitle = styled.h5`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #37474f;
`;

export const ProductCode = styled.p`
  margin: 0;
  color: #d2d8db;
`;
