import React from "react";
import { formatDate } from "@utils/formatUtils";
import { useSelector } from "react-redux";
import {
  Row,
  RowBetween,
  CardContentTotal,
  CardContentUp,
  CardContentDown,
  CardContentLast,
  CardTitle,
  UserImg,
  UserName,
  UserInfo,
  UserValue,
} from "./styles";

import { AppState } from "src/store";
import { CustomerState } from "@store/customer-reducer";

import { Card } from "carrefour-portal-backoffice-style-guide";
import imgAvatar from "@assets/avatar.svg";

const Summary = () => {
  const customerData = useSelector<AppState, CustomerState>(
    (state) => state.customer
  );

  return (
    <RowBetween>
      <Row>
        <UserImg src={imgAvatar} alt="avatar" data-testid="client-img" />
        <div>
          <UserName data-testid="client-name">
            {customerData.firstName
              ? customerData.lastName
                ? `${customerData.firstName} ${customerData.lastName}`
                : `${customerData.firstName}`
              : "Nome não identificado"}
          </UserName>
          <UserInfo data-testid="client-email">
            E-mail: {customerData.email}
          </UserInfo>
          <UserInfo data-testid="client-cpf">
            CPF: {customerData.document}
          </UserInfo>
          <UserInfo>Telefone Principal: {customerData.homePhone}</UserInfo>
        </div>
      </Row>
      <Card
        margin="0px 0px 0px 30px"
        padding="20px"
        width="120px"
        height="auto"
      >
        <CardContentTotal>
          <CardTitle>
            QUANTIDADE <br />
            DE PEDIDOS
          </CardTitle>
          <UserValue>
            {customerData.ordersQuantity === 0
              ? "-"
              : `${customerData.ordersQuantity} pedidos`}
          </UserValue>
        </CardContentTotal>
      </Card>
      <Card
        margin="0px 0px 0px 30px"
        padding="20px"
        width="120px"
        height="auto"
      >
        <CardContentUp>
          <CardTitle>
            VALOR TOTAL <br />
            DOS PEDIDOS (R$)
          </CardTitle>
          <UserValue>
            {customerData.totalOrdersValue === 0
              ? "R$ -"
              : new Intl.NumberFormat("pt", {
                  style: "currency",
                  currency: "BRL",
                }).format(customerData.totalOrdersValue)}
          </UserValue>
        </CardContentUp>
      </Card>
      <Card
        margin="0px 0px 0px 30px"
        padding="20px"
        width="120px"
        height="auto"
      >
        <CardContentDown>
          <CardTitle>
            VALOR TOTAL <br />
            DEVOLVIDO (R$)
          </CardTitle>
          <UserValue>
            {customerData.totalOrdersValue === 0
              ? "R$ -"
              : new Intl.NumberFormat("pt", {
                  style: "currency",
                  currency: "BRL",
                }).format(customerData.returnedOrdersValue)}
          </UserValue>
        </CardContentDown>
      </Card>
      <Card
        margin="0px 0px 0px 30px"
        padding="20px"
        width="120px"
        height="auto"
      >
        <CardContentLast>
          <CardTitle>
            ÚLTIMA <br />
            ATUALIZAÇÃO
          </CardTitle>
          <UserValue>{formatDate(customerData.updatedIn)}</UserValue>
        </CardContentLast>
      </Card>
    </RowBetween>
  );
};

export default Summary;
