export interface ModalState {
  modalStatus: string;
}

export const MODAL_STATUS = "MODAL_STATUS";

export interface ModalActionsParams {
  type: string;
  payload: any;
}

type ModalActions = ModalActionsParams;

const initialState: ModalState = {
  modalStatus: "SHIPPING",
};

export function modalReducer(
  state = initialState,
  action: ModalActions
): ModalState {
  switch (action.type) {
    case MODAL_STATUS:
      return {
        ...state,
        modalStatus: action.payload,
      };
    default:
      return state;
  }
}
