import React, { useState, useEffect } from "react";
import useModal from "react-hooks-use-modal";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "carrefour-portal-backoffice-style-guide";

import { OrderDetailsResponse } from "@pages/order-detail/OrderDetailsResponse";
import { CancellationType } from "@pages/cancellation-flow/enums/cancellation-type";
import { Container } from "@components/utilities";
import { CancelState } from "@store/cancellation-reducer";
import { requestPartialCancellation, refund } from "@services/cancellation";
import { OrderState } from "@store/order-reducer";
import { AppState } from "@store/index";

import ModalAccountRefund from "../modals/modal-account-refund";
import DeliveryFailureModalContent from "../modals/delivery-failure";
import { CloseModal } from "../modals/styles";
import { ContainerBar, RowBetween, CustomerInfo, Buttons } from "./styles";

type ActionBarType = {
  data: OrderDetailsResponse;
  setScreen?: any;
  screen: string;
  cancelState: CancelState;
};

const ActionBar = ({ data, setScreen, cancelState, screen }: ActionBarType) => {
  const {
    consignmentCode,
    paymentType,
    cancelReason,
    refundMethod,
    bankAccount,
    totalValue,
    cancellationType,
  } = cancelState;
  const { selectedConsignment } = useSelector<AppState, OrderState>(
    (state) => state.order
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [finalStatus, setFinalStatus] = useState(null);
  const [orderCode, setOrderCode] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);

  let customerInfoText = "Cancelando entrega de: ";
  if (cancellationType === CancellationType.TRANSPORT_OCURRENCE) {
    customerInfoText = "Tratando ocorrência de transporte de: ";
  } else if (
    cancelState.cancellationType === CancellationType.DELIVERY_FAILURE
  ) {
    customerInfoText = "Tratando insucesso de entrega de: ";
  }

  const [Modal, open, close] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  const [DeliveryFailureModal, openFailureModal, closeFailureModal] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  function openModal() {
    open();
  }

  const handleMktOutCase = () => {
    dispatch({ type: "REFUND_METHOD", payload: "REFUND" });

    dispatch({
      type: "TOTAL_VALUE",
      payload: selectedConsignment.totalValue,
    });
  };

  function handleConfirmCancellation() {
    if (
      selectedConsignment.delivery.modality === "MARKETPLACE_OUT" &&
      screen === "cancelReason"
    ) {
      handleMktOutCase();
      openModal();
      return;
    }

    if (
      cancellationType === CancellationType.DELIVERY_CANCELLATION ||
      cancellationType === CancellationType.DELIVERY_FAILURE
    ) {
      if (paymentType === "Boleto Bancário") {
        if (screen === "cancelReason") {
          setScreen("refundBillet");
          dispatch({
            type: "CANCEL_SCREEN",
            payload: "refundBillet",
          });
          setDisableBtn(true);
        }
        if (screen === "refundBillet") {
          if (refundMethod === "GIFT_CARD") {
            openModal();
            return;
          }
          setScreen("refundAccount");
          dispatch({
            type: "CANCEL_SCREEN",
            payload: "refundAccount",
          });
          setDisableBtn(true);
        }
        if (screen === "refundAccount") {
          openModal();
        }
      } else {
        if (screen === "cancelReason") {
          setScreen("refundCard");
          dispatch({
            type: "CANCEL_SCREEN",
            payload: "refundCard",
          });
          setDisableBtn(true);
        }
        if (screen === "refundCard") {
          openModal();
        }
      }
    } else if (cancellationType === CancellationType.TRANSPORT_OCURRENCE) {
      openFailureModal();
    }
  }

  async function handleCancellation() {
    const payload = {
      attendanceChannel: "BACKOFFICE",
      orderCode: orderCode,
      consignmentCode: consignmentCode,
      observation: "",
      reason: cancelReason,
      customerEmail: data.customerEmail,
      refund: {
        type: refundMethod,
        bankAccount: {
          type: bankAccount.paymentAccount,
          bank: bankAccount.bank,
          bankName: bankAccount.bankName,
          branch: bankAccount.branch,
          branchDigit: bankAccount.branchDigit,
          account: bankAccount.account,
          digit: bankAccount.digit,
        },
      },
    };

    const response = await requestPartialCancellation(payload);
    setFinalStatus(response);
  }

  async function handleRefund() {
    const payload = {
      type: refundMethod,
      bankAccount:
        refundMethod === "ACCOUNT_DEPOSIT"
          ? {
              type: bankAccount.paymentAccount,
              bank: bankAccount.bank,
              bankName: bankAccount.bankName,
              branch: bankAccount.branch,
              branchDigit: bankAccount.branchDigit,
              account: bankAccount.account,
              digit: bankAccount.digit,
            }
          : null,
    };

    const response = await refund(orderCode, consignmentCode, payload);

    setFinalStatus(response);
  }

  function onProceedRefundClick() {
    if (cancellationType === CancellationType.DELIVERY_FAILURE) {
      handleRefund();
    } else {
      handleCancellation();
    }
  }

  useEffect(() => {
    if (screen === "cancelReason" && cancelReason.length) {
      setDisableBtn(false);
    }
  }, [cancelReason]);

  useEffect(() => {
    if (screen === "refundBillet") {
      if (refundMethod.length > 0) {
        setDisableBtn(false);
      }
    }
    if (screen === "refundAccount") {
      if (refundMethod === "ACCOUNT_DEPOSIT") {
        setDisableBtn(true);
        if (bankAccount?.paymentAccount?.length > 0) {
          if (!Object.values(bankAccount).some((value) => value === "")) {
            setDisableBtn(false);
          }
        }
      }
      if (refundMethod === "PAYMENT_ORDER") {
        setDisableBtn(false);
      }
    }
    if (screen === "refundCard") {
      if (refundMethod.length > 0) {
        setDisableBtn(false);
      }
    }
  }, [cancelState]);

  useEffect(() => {
    if (data) {
      const name = `${data?.customerFirstName} ${data?.customerLastName}`;
      const dataBank = {
        customer: name,
        document: data?.customerDocument,
      };
      dispatch({ type: "BANK_ACCOUNT", payload: dataBank });
      setOrderCode(data?.orderCode);
    }
  }, [data]);

  return (
    <>
      <ContainerBar>
        <Container>
          <RowBetween>
            <CustomerInfo>
              <h5>
                {customerInfoText}
                <span>{`${data?.customerFirstName} ${data?.customerLastName}`}</span>
              </h5>
              <strong>PEDIDO</strong>
              <p>
                {data?.orderCode}
                <span>Pedido VTEX</span>
              </p>
            </CustomerInfo>
            <Buttons>
              <Button
                data-testid="button-close"
                text="Fechar"
                onClick={() =>
                  history.push(`/atendimento/pedido/detalhe/${orderCode}`)
                }
                outline
                margin="0 15px 0 0"
              />
              <Button
                data-testid="button-confirm"
                text={"Prosseguir"}
                onClick={() => handleConfirmCancellation()}
                variation="confirm"
                disabled={disableBtn}
              />
            </Buttons>
          </RowBetween>
        </Container>
      </ContainerBar>

      <Modal preventScroll={true}>
        <CloseModal onClick={close}>X</CloseModal>

        <ModalAccountRefund
          totalValue={totalValue}
          refundMethod={refundMethod}
          handleCancellation={onProceedRefundClick}
          finalStatus={finalStatus}
          close={close}
        />
      </Modal>

      <DeliveryFailureModal preventScroll>
        <CloseModal onClick={closeFailureModal}>X</CloseModal>
        <DeliveryFailureModalContent
          consigment={selectedConsignment}
          close={closeFailureModal}
          next={() => alert("next step")}
        />
      </DeliveryFailureModal>
    </>
  );
};

export default ActionBar;
