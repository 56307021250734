import React from "react";

const Success = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.6833 12.5135C36.4812 10.6411 31.7864 10.1773 27.2992 11.1911C22.8119 12.2049 18.7726 14.6421 15.7836 18.1392C12.7947 21.6363 11.0163 26.0059 10.7136 30.5963C10.4109 35.1867 11.6002 39.7519 14.104 43.6112C16.6079 47.4705 20.2922 50.417 24.6075 52.0112C28.9228 53.6055 33.6378 53.7621 38.0494 52.4578C42.461 51.1534 46.3327 48.4579 49.0872 44.7733C51.8417 41.0888 53.3314 36.6125 53.334 32.0121V29.8057C53.334 28.3329 54.5279 27.139 56.0006 27.139C57.4734 27.139 58.6673 28.3329 58.6673 29.8057V32.0136C58.664 37.7641 56.802 43.361 53.3589 47.9667C49.9157 52.5724 45.0761 55.9418 39.5616 57.5722C34.0471 59.2027 28.1533 59.0069 22.7592 57.014C17.3651 55.0212 12.7597 51.3381 9.62988 46.514C6.50003 41.6899 5.01343 35.9833 5.39179 30.2453C5.77015 24.5073 7.9932 19.0454 11.7294 14.674C15.4656 10.3027 20.5147 7.2562 26.1238 5.9889C31.7329 4.72161 37.6014 5.30141 42.854 7.64185C44.1992 8.24126 44.8039 9.81773 44.2045 11.163C43.605 12.5083 42.0286 13.1129 40.6833 12.5135Z"
        fill="#33691E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M54.9988 11.2504C56.1493 12.1699 56.3366 13.8479 55.4171 14.9984L34.0838 41.6916C33.6096 42.2849 32.9049 42.6478 32.1466 42.6894C31.3882 42.731 30.6481 42.4472 30.1119 41.9092L22.1119 33.8825C21.0722 32.8394 21.075 31.151 22.1182 30.1113C23.1613 29.0716 24.8497 29.0745 25.8894 30.1176L31.7816 36.0294L51.2509 11.6687C52.1703 10.5182 53.8483 10.3309 54.9988 11.2504Z"
        fill="#33691E"
      />
    </svg>
  );
};

export default Success;
