import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  FieldGroup,
  FieldGroupItemContainer,
  ForceInstanceDetailContentContainer,
  ConsignmentOptionButton,
  ConsignmentOptions,
} from "./styles";
import { Column, Row } from "@components/utilities";
import moment from "moment";
import {
  Refund,
  PaymentMethod,
  PaymentRefunds,
} from "../../../../../force-instance-flow/components/modal-confirm-refund";

import { AppState } from "@store/index";
import { OrderState } from "@store/order-reducer";

import {
  Item,
  ReturnRequest,
  ReturnConsignmentItem,
} from "@pages/order-detail/OrderDetailsResponse";
import {
  LoadingPaymentData,
  RefundInfoContainer,
} from "@pages/force-instance-flow/components/modal-confirm-refund/styles";
import { getRefundTypeName } from "@pages/force-instance-flow/components/modal-confirm-refund";
import { formatMoney } from "@utils/formatUtils";
import { ForceInstanceType } from "@pages/force-instance-flow";
import { getByTitle } from "@testing-library/react";

export interface ForceInstanceDetailRefundInfo {
  paymentMethod: string;
  paymentSystemName: string;
  paymentValue: string;
  lastDigits?: string;
}

export interface ForceInstanceDetail {
  consignmentCode: string;
  saleConsignmentCode: string;
  ticket: string;
  reason: string;
  attendanceOperatorEmail: string;
  requestDate: string;
  returnToWarehouse: boolean;
  observation: string;
  type: string;
  refundType: string;
  refundName: string;
  forceRefundInfoVMS?: ForceInstanceDetailRefundInfo[];
  resendOrderCode?: string;
}

export interface ForceInstanceDetailContentProps {
  forceInstance: ForceInstanceDetail;
}

export const ForceInstanceDetailContent: React.FC<ForceInstanceDetailContentProps> = (
  props: ForceInstanceDetailContentProps
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { forceInstance } = props;
  const [paymentRefund, setPaymentRefund] = useState<Refund | null>(null);
  const [isMarketplaceOut, setIsMarketplaceOut] = useState<boolean>(false);
  const [returnConsignment, setReturnCosignment] = useState<
    ReturnConsignmentItem
  >();
  const [isLoadingData, setIsLoadingData] = useState(true);

  const { selectedConsignment } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  const { returnConsignments } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  const formatDate = (date: string) => {
    const adaptedDate = date?.replace("[Etc/UTC]", "");
    return moment(adaptedDate)
      .tz("America/Sao_Paulo")
      .format("DD/MM/YYYY HH:mm");
  };

  useEffect(() => {
    setIsLoadingData(true);
    if (selectedConsignment.type === "MARKETPLACE_OUT") {
      setIsMarketplaceOut(true);
      setReturnCosignment(getReturnConsignment());
      setIsLoadingData(false);
    } else {
      let paymentMethods: PaymentMethod[] = [];
      let totalValue: number = 0;

      if (isRefund(forceInstance.type)) {
        forceInstance.forceRefundInfoVMS.map((forceRefundInfo) => {
          let refunValue: number = Number(forceRefundInfo.paymentValue);
          totalValue = totalValue + refunValue;

          paymentMethods.push({
            paymentMethod: forceRefundInfo.paymentMethod,
            paymentSystemName: forceRefundInfo.paymentSystemName,
            refundValue: refunValue,
            lastDigits: forceRefundInfo.lastDigits || null,
          });
        });

        setPaymentRefund({
          name: forceInstance.refundName,
          type: forceInstance.refundType,
          totalValue: totalValue,
          paymentMethods: paymentMethods,
        });
      }
      setIsLoadingData(false);
    }
  }, []);

  const getReturnConsignment = (): ReturnConsignmentItem => {
    let resultData: ReturnConsignmentItem;
    returnConsignments.forEach((returnConsignment) => {
      returnConsignment.returnConsignments.forEach((returnConsignmentItem) => {
        if (
          returnConsignmentItem.consignmentCode ===
          forceInstance.consignmentCode
        ) {
          resultData = returnConsignmentItem;
        }
      });
    });
    return resultData;
  };

  const isRefund = (forceInstanceType: string): boolean => {
    return (
      forceInstanceType === ForceInstanceType.FORCE_DELIVERY_FAILURE_REFUND ||
      forceInstanceType === ForceInstanceType.FORCE_DEVOLUTION_REFUND
    );
  };

  const getTitle = () => {
    return isRefund(forceInstance.type)
      ? "Instância forçada | Restituição"
      : "Instância forçada | Reenvio Liberado";
  };

  const goToDetail = () => {
    dispatch({ type: "CLEAR_RETURN_CONSIGNMENTS", payload: null });
    dispatch({
      type: "ORDER_SELECTED",
      payload: forceInstance.resendOrderCode,
    });
    history.go(0);
  };

  return (
    <ForceInstanceDetailContentContainer forceInstanceType={forceInstance.type}>
      <Row className="title">
        <div>{getTitle()}</div>
        {!isRefund(forceInstance.type) && (
          <ConsignmentOptions>
            <ConsignmentOptionButton
              data-testid="get-force-instance-button"
              onClick={() => goToDetail()}
            >
              <div className="button">
                Novo pedido {forceInstance.resendOrderCode}
              </div>
            </ConsignmentOptionButton>
          </ConsignmentOptions>
        )}
      </Row>
      <Row className="content">
        <Column className="content-left">
          <FieldGroup>
            <FieldGroupItem
              label="Operador de atendimento"
              value={forceInstance.attendanceOperatorEmail}
              width="45%"
            />
            <FieldGroupItem
              label="Data/Hora da solicitação"
              value={formatDate(forceInstance.requestDate)}
              width="30%"
            />
            <FieldGroupItem
              label="Número do ticket"
              value={forceInstance.ticket}
              width="25%"
            />
            <FieldGroupItem
              label="Motivo da exceção"
              value={forceInstance.reason}
              width="45%"
            />
            <FieldGroupItem
              label="Número da entrega"
              value={forceInstance.consignmentCode}
              width="30%"
            />
            <FieldGroupItem
              label="Retorno do produto"
              value={forceInstance.returnToWarehouse ? "Sim" : "Não"}
              width="25%"
            />
          </FieldGroup>
          <FieldGroup>
            <FieldGroupItem
              label="Comentário"
              value={forceInstance.observation}
              width="100%"
            />
          </FieldGroup>
        </Column>

        {isRefund(forceInstance.type) && (
          <Column className="content-right">
            <Row className="label">
              <div>Método de Restituição</div>
            </Row>
            <Row>
              {isLoadingData ? (
                <LoadingPaymentData>
                  Carregando informações de reembolso.
                </LoadingPaymentData>
              ) : isMarketplaceOut ? (
                <div className="payment-group">
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <Column
                      className="payment-group-title"
                      style={{ padding: "0" }}
                    >
                      {getRefundTypeName(
                        returnConsignment.returnRequest.refund.refundType
                      )}
                    </Column>
                  </Row>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Column
                      className="payment-group-subtotal-label"
                      style={{ width: "55%" }}
                    >
                      Total do reembolso
                    </Column>
                    <Column
                      className="payment-group-subtotal"
                      style={{
                        color: "#1E5BC6",
                        fontSize: "14px",
                        fontWeight: "bold",
                        width: "45%",
                        textAlign: "right",
                      }}
                    >
                      {formatMoney(returnConsignment.totalValue)}
                    </Column>
                  </Row>
                </div>
              ) : (
                <PaymentRefunds data={paymentRefund} />
              )}
            </Row>
          </Column>
        )}
      </Row>
    </ForceInstanceDetailContentContainer>
  );
};

interface FieldGroupItemProps {
  label: string;
  value: string;
  width?: string;
}

const FieldGroupItem: React.FC<FieldGroupItemProps> = (
  props: FieldGroupItemProps
) => {
  const { label, value, width } = props;
  return (
    <FieldGroupItemContainer width={width}>
      <div className="field-group-item-column-label">{label}</div>
      <div className="field-group-item-column-value">{value}</div>
    </FieldGroupItemContainer>
  );
};
