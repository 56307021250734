import React from "react";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { Container } from "@components/utilities";
import { ContainerBar, RowBetween, CustomerInfo, Buttons } from "./styles";

interface ActionBarProps {
  actionTitle: string;
  customerName: string;
  consignmentCode: string;
  isValidToContinue: boolean;
  onClose: Function;
  onContinue: Function;
}

export const ActionBar: React.FC<ActionBarProps> = (props: ActionBarProps) => {
  return (
    <>
      <ContainerBar>
        <Container>
          <RowBetween>
            <CustomerInfo>
              <h5>
                {props.actionTitle} <span> {props.customerName}</span>
              </h5>
              <strong>ENTREGA</strong>
              <p>{props.consignmentCode}</p>
            </CustomerInfo>
            <Buttons>
              <Button
                data-testid="button-close"
                text="Fechar"
                onClick={() => props.onClose()}
                outline
                margin="0 15px 0 0"
              />
              <Button
                data-testid="button-confirm"
                text={"Prosseguir"}
                onClick={() => props.onContinue()}
                variation="confirm"
                disabled={!props.isValidToContinue}
              />
            </Buttons>
          </RowBetween>
        </Container>
      </ContainerBar>
    </>
  );
};
