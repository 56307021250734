import React from "react";

const Warning = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.9999 10.6668C20.2178 10.6668 10.6666 20.2181 10.6666 32.0002C10.6666 43.7822 20.2178 53.3335 31.9999 53.3335C43.782 53.3335 53.3333 43.7822 53.3333 32.0002C53.3333 20.2181 43.782 10.6668 31.9999 10.6668ZM5.33325 32.0002C5.33325 17.2726 17.2723 5.3335 31.9999 5.3335C46.7275 5.3335 58.6666 17.2726 58.6666 32.0002C58.6666 46.7278 46.7275 58.6668 31.9999 58.6668C17.2723 58.6668 5.33325 46.7278 5.33325 32.0002Z"
        fill="#CC4010"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.9999 18.6665C33.4727 18.6665 34.6666 19.8604 34.6666 21.3332V31.9998C34.6666 33.4726 33.4727 34.6665 31.9999 34.6665C30.5272 34.6665 29.3333 33.4726 29.3333 31.9998V21.3332C29.3333 19.8604 30.5272 18.6665 31.9999 18.6665Z"
        fill="#CC4010"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.3333 42.6667C29.3333 41.1939 30.5272 40 31.9999 40H32.0266C33.4993 40 34.6933 41.1939 34.6933 42.6667C34.6933 44.1394 33.4993 45.3333 32.0266 45.3333H31.9999C30.5272 45.3333 29.3333 44.1394 29.3333 42.6667Z"
        fill="#CC4010"
      />
    </svg>
  );
};

export default Warning;
