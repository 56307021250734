import firebase from "./firebase";

async function handleLogout() {
  localStorage.removeItem("group");
  localStorage.removeItem("access");
  localStorage.removeItem("firebaseui::rememberedAccounts");
  await firebase.auth().signOut();
  window.location.href = "/";
}

export default handleLogout;
