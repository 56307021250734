import React from "react";
import { Column, Row } from "@components/utilities";
import { TitleAlert, CardModal, DescAlert } from "../styles";
import iconForbidden from "@assets/forbidden.svg";

type Props = {
  errorMessage: string;
};

const ModalForbidden = (props: Props) => {
  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        <img src={iconForbidden} alt="exclamation" />
        <Column margin="0 auto" textAlign="center">
          <DescAlert>{props.errorMessage}</DescAlert>
        </Column>
      </CardModal>
    </>
  );
};

export default ModalForbidden;
