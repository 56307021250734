import { LinkItem } from "@pages/order-detail/OrderDetailsResponse";
import { Method } from "axios";
import http from "./http";

export const getForceInstaceReasons = async () => {
  try {
    const response = await http.get("/v1/orders/force/reasons");
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const executeLink = async (linkItem: LinkItem, data?: any) => {
  try {
    return http.request({
      method: linkItem.type.toLocaleLowerCase() as Method,
      url: linkItem.href,
      data: data ? data : null,
    });
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};

export const getRefundInfo = async (
  orderCode: string,
  consignmentCode: string
) => {
  try {
    const response = await http.get(
      "/v1/orders/" +
        orderCode +
        "/consignments/" +
        consignmentCode +
        "/refund-info"
    );
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};
