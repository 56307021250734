import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  Row,
  Column,
  RowBetween,
  CardContentTotal,
  CardContentUp,
  CardContentLast,
  CardTitle,
  CardText,
  UserImg,
  UserName,
  UserInfo,
  UserValue,
} from "./styles";

import { Card } from "carrefour-portal-backoffice-style-guide";
import { formatMoney, formatDate } from "@utils/formatUtils";

import imgAvatar from "@assets/avatar.svg";
import { OrderDetailsResponse } from "../../../order-detail/OrderDetailsResponse";

type CancelSummary = {
  data: OrderDetailsResponse;
};

const CancelSummary = ({ data }: CancelSummary) => {
  return (
    <RowBetween>
      <Row>
        <UserImg src={imgAvatar} alt="avatar" data-testid="client-img" />
        {data?.customerEmail ? (
          <div>
            <UserName data-testid="client-name">{`${data?.customerFirstName} ${data?.customerLastName}`}</UserName>
            <UserInfo data-testid="client-email">
              E-mail: {data?.customerEmail}
            </UserInfo>
            <UserInfo data-testid="client-cpf">
              CPF: {data?.customerDocument}
            </UserInfo>
            <UserInfo>Telefone Principal: {data?.customerPhone}</UserInfo>
          </div>
        ) : (
          <Skeleton width={240} height={96} />
        )}
      </Row>
      <Column>
        <Row>
          <Card
            margin="20px 0px 0px 30px"
            padding="20px"
            width="120px"
            height="auto"
          >
            <CardContentTotal>
              <CardTitle>PEDIDO</CardTitle>
              <div>
                <UserValue>
                  {data?.orderCode ? (
                    data?.orderCode
                  ) : (
                    <Skeleton width={80} height={16} />
                  )}
                </UserValue>
                <CardText>Pedido VTEX</CardText>
              </div>
            </CardContentTotal>
          </Card>
          <Card
            margin="20px 0px 0px 30px"
            padding="20px"
            width="120px"
            height="auto"
          >
            <CardContentUp>
              <CardTitle>
                CRIAÇÃO DO <br />
                PEDIDO
              </CardTitle>
              <UserValue>
                {data?.creationDate ? (
                  formatDate(data?.creationDate)
                ) : (
                  <Skeleton width={80} height={16} />
                )}
              </UserValue>
            </CardContentUp>
          </Card>
          <Card
            margin="20px 0px 0px 30px"
            padding="20px"
            width="120px"
            height="auto"
          >
            <CardContentLast>
              <CardTitle>
                VALOR TOTAL <br />
                DA ENTREGA
              </CardTitle>
              <UserValue>
                {data?.totalValue ? (
                  formatMoney(data?.totalValue)
                ) : (
                  <Skeleton width={80} height={16} />
                )}
              </UserValue>
            </CardContentLast>
          </Card>
        </Row>
      </Column>
    </RowBetween>
  );
};

export default CancelSummary;
