import React from "react";
import Skeleton from "react-loading-skeleton";
import { RowBetween } from "@components/utilities";
import {
  Row,
  CardContentTotal,
  CardTitle,
  UserImg,
  UserName,
  UserInfo,
  UserValue,
} from "./styles";

import { formatDateOnly } from "@utils/formatUtils";
import { Customer } from "../../BalanceAvaliableResponse";

import { Card } from "carrefour-portal-backoffice-style-guide";
import imgAvatar from "@assets/avatar.svg";

type SummaryType = {
  customerData: Customer;
  totalRecords: number;
  isLoading?: boolean;
};

const Summary = ({ customerData, totalRecords, isLoading }: SummaryType) => {
  return (
    <RowBetween margin="0 0 50px 0">
      <Row>
        <UserImg src={imgAvatar} alt="avatar" data-testid="client-img" />
        <div>
          <UserName data-testid="client-name">
            {isLoading ? (
              <Skeleton width={210} height={32} />
            ) : (
              `${customerData?.firstName} ${customerData?.lastName}`
            )}
          </UserName>
          <UserInfo>
            Data de Nascimento:{"   "}
            {isLoading ? (
              <Skeleton width={80} height={22} />
            ) : (
              formatDateOnly(customerData?.birthDate)
            )}
          </UserInfo>
          <UserInfo data-testid="client-email">
            E-mail:{"   "}
            {isLoading ? (
              <Skeleton width={80} height={22} />
            ) : (
              customerData?.email
            )}
          </UserInfo>
          <UserInfo data-testid="client-cpf">
            CPF:{" "}
            {isLoading ? (
              <Skeleton width={80} height={22} />
            ) : (
              customerData?.document
            )}
          </UserInfo>
          <UserInfo>
            Telefone Principal:{" "}
            {isLoading ? (
              <Skeleton width={80} height={22} />
            ) : (
              customerData?.homePhone
            )}
          </UserInfo>
        </div>
      </Row>
      <Card
        margin="0px 0px 0px 30px"
        padding="20px"
        width="120px"
        height="auto"
      >
        <CardContentTotal>
          <CardTitle>
            TOTAL <br />
            DE VALES
          </CardTitle>
          <UserValue>{totalRecords === 0 ? "-" : `${totalRecords}`}</UserValue>
        </CardContentTotal>
      </Card>
    </RowBetween>
  );
};

export default Summary;
