import styled from "styled-components";

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;

  * {
    background-position: right top;
  }
`;

export const RowBetweenTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 30px 0;
  padding-bottom: 30px;
  align-items: center;
  border-bottom: 1px solid #eceff1;
`;

export const RowBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    display: flex;
    align-items: center;
  }
`;

export const ClientName = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #37474f;
  margin: 0px;
`;

export const ClientInfo = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #78909c;
  margin: 8px 0px;

  strong {
    color: #37474f;
    font-size: 14px;
  }

  b {
    color: #37474f;
    font-size: 10px;
  }
`;

export const SummaryText = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${(props) => props.color || "#607d8b"};
  border-bottom: 1px solid #eceff1;
  margin-top: 0px;
  padding-bottom: 10px;
  justify-content: space-between;

  &:last-child {
    font-size: 16px;
    color: #1e5bc6;
    border: none;
  }

  strong {
    font-weight: 500;
  }
`;

export const HeaderPayment = styled.div<StatusPaymentProps>`
  padding: 15px 25px;
  background: ${(props: StatusPaymentProps) =>
    props.status === "Aguardando Pagamento" ? "#FFF5EE" : "#fcfdff"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
`;

export const BodyPayment = styled.div`
  padding: 0 25px 15px 25px;
`;

export const PaymentItem = styled.div`
  border-bottom: 1px solid #eceff1;
  padding: 15px 0px;

  img {
    margin-right: 5px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

type StatusPaymentProps = {
  status?: string;
};

export const StatusPayment = styled.h3<StatusPaymentProps>`
  font-weight: 400;
  font-size: 20px;
  color: ${(props: StatusPaymentProps) =>
    props.status === "Aguardando Pagamento" ? "#FF7A14" : "#40aa60"};
  margin: 0px;
`;

type TTextPayment = {
  color?: string;
  weight?: string;
  margin?: string;
};

export const TextPayment = styled.span<TTextPayment>`
  font-size: 12px;
  line-height: 16px;
  font-weight: ${(props: TTextPayment) => props.weight || "300"};
  color: ${(props: TTextPayment) => props.color || "#78909c"};
  margin: ${(props: TTextPayment) => props.margin || "0px"};
`;

export const LinkUrl = styled.a`
  display: flex;
  color: #1e5bc6;
  font-size: 12px;
  padding-top: 12px;

  img {
    margin-left: 5px;
    width: 15px;
  }
`;

export const ValueText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #263238;
`;

export const ValueLabel = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #263238;
`;

export const PaymentValueInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

export const ButtonCancelTotal = styled.button`
  color: #fff;
  background: #e81e26;
  border-radius: 4px;
  padding: 13px 19px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: 0.2s all linear;

  &:hover {
    background: #d2151c;
  }
`;

type Title = {
  margin?: string;
  color?: string;
  size?: string;
  weight?: string;
};

export const TitleAlert = styled.h3<Title>`
  margin: ${(props: Title) => props.margin || "30px 0 0 0"};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props: Title) => props.size || "20px"};
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props: ButtonProps) => props.color || "#ff7a14"};

  img {
    margin-right: 10px;
  }
`;

export const TitleSuccess = styled.h4`
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  color: #40aa60;
  margin: 0;
`;

export const DescAlert = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #263238;
`;

type TStyledP = {
  margin?: string;
  fontSize?: string;
  fontWeight?: number;
  color?: string;
};

/*Modal*/
type TCardModal = {
  padding?: string;
};

export const CardModal = styled.div<TCardModal>`
  width: auto;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "20px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTotalContent = styled.div`
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 0 0 0;
  margin-top: 20px;
`;

export const CardTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  margin: 0px;
  text-transform: uppercase;
  color: #90a4ae;
`;

export const CardText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #b0bec5;
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
`;

type ButtonProps = {
  margin?: string;
  color?: string;
};

export const ButtonData = styled.button<ButtonProps>`
  font-weight: normal;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${(props: ButtonProps) => props.color || "#ff7a14"};
  border: 1px solid;
  border-color: ${(props: ButtonProps) => props.color || "#ff7a14"};
  background: transparent;
  transition: 0.2s all linear;
  cursor: pointer;
  text-decoration: none;
  opacity: 1;
  margin: ${(props: ButtonProps) => props.margin || "30px 0px 10px 0px"};

  img {
    margin-left: 5px;
    padding-bottom: 1px;
  }
`;

export const BorderCard = styled.div`
  border: 1px solid #eceff1;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const AlertDisruption = styled.div`
  background: rgba(255, 122, 20, 0.05);
  border: 1px solid #ff7a14;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 30px;
  padding: 5px 14px;
  display: flex;
  justify-content: space-between;
  width: 68%;

  * {
    font-size: 12px;
    line-height: 16px;
    color: #ff7a14;
  }
`;

export const ViewBorder = styled.div`
  border: 1px solid #f7f9fc;
  padding: 15px;
  border-radius: 8px;
  margin: 35px 0;
  -webkit-font-smoothing: antialiased;
`;

export const ViewGrey = styled.div`
  background: #f7f9fc;
  padding: 15px 45px;
  border-radius: 8px;
  display: flex;

  div {
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-right: 65px;
  }
`;

export const TitleGrey = styled.span`
  color: #b0bec5;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 3px;
`;

type ValueTotalProps = {
  color?: string;
};

export const ValueTotal = styled.span<ValueTotalProps>`
  color: ${(props: ButtonProps) => props.color || "#607d8b"};
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
`;

type ButtonTabProps = {
  active?: boolean;
};

export const ButtonTab = styled.button<ButtonTabProps>`
  width: 185px;
  font-size: 16px;
  padding: 5px;
  background: unset;
  border: unset;
  border-bottom: 2px solid;
  display: flex;
  justify-content: center;
  border-color: ${(props: ButtonTabProps) =>
    props.active ? "#1E5BC6" : "#b0bec5"};
  color: ${(props: ButtonTabProps) => (props.active ? "#1E5BC6" : "#b0bec5")};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Badge = styled.span<ButtonTabProps>`
  font-size: 10px;
  width: 16px;
  height: 16px;
  color: #ffffff;
  border-radius: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  display: inline-flex;
  background: ${(props: ButtonTabProps) =>
    props.active ? "#1E5BC6" : "#b0bec5"};
`;

export const Box = styled.div`
  background: #f7f9fc;
  padding: 15px 40px;
  margin-top: 20px;
  margin-bottom: 15px;
`;
