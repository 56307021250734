import React from "react";
import { Row, RowBetween, Column, Button } from "./styles";
import { useHistory } from "react-router-dom";
import { Text } from "carrefour-portal-backoffice-style-guide";

interface PageHeaderProps {
  title: string;
  subtitle: string;
  button?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  button = true,
}) => {
  const history = useHistory();

  return (
    <RowBetween>
      <Column>
        <Text type="heading" size="sub" text={title} margin="0px" />
        <Text type="heading" size="medium" text={subtitle} margin="0px" />
      </Column>
      <Row>
        {button ? (
          <Button
            onClick={() => {
              history.push("/atendimento");
            }}
          >
            Nova busca
          </Button>
        ) : (
          ""
        )}
      </Row>
    </RowBetween>
  );
};

export default PageHeader;
