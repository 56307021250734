import styled from "styled-components";

export const RetryModalContainer = styled.div`
  width: 570px;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: 83px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #3498db;
    width: 59px;
    height: 59px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .title,
  .subtitle {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
  }

  .title {
    margin-top: 16px;
    color: #455a64;
  }

  .subtitle {
    color: #009adc;
  }
`;
