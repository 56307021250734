import http from "./http";
import handleLogout from "./session";

type RefundRequest = {
  orderCode: string;
  consignmentCode: string;
};

export type Refund = {
  type: string;
  name: string;
  totalValue: number;
  paymentMethods: PaymentMethod[];
};

type PaymentMethod = {
  paymentMethod: string;
  refundValue: number;
  lastDigits: string;
  paymentSystemName: string;
};

export async function deliveryRefundMethods(params: RefundRequest) {
  try {
    const response = await http.get(
      `/v1/orders/${params.orderCode}/consignments/${params.consignmentCode}/refund/list/types`
    );

    const { data } = response;
    return data;
  } catch (error) {
    return error.response;
  }
}

interface ItemParam {
  id: string | number;
  quantity: number;
  totalValue: number | string;
}

interface RefundTypesByItemParam {
  items: ItemParam[];
}

export const refundTypesByItem = async (
  orderCode: string,
  items: RefundTypesByItemParam
) => {
  try {
    const response = await http.post(
      `/v1/orders/${orderCode}/refund/list/types`,
      items
    );

    return response;
  } catch (error) {
    return error.response;
  }
};
