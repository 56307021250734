import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Column, Row } from "@components/utilities";
import { formatMoney, formatDate } from "@utils/formatUtils";
import { Button } from "carrefour-portal-backoffice-style-guide";
import Items from "@components/items";
import {
  TitleAlert,
  CardModal,
  DescAlert,
  ViewBorder,
  ViewGrey,
  TitleGrey,
  ValueTotal,
  ButtonTab,
  Badge,
} from "../../styles";
import iconExclamation from "@assets/exclamation.svg";
import { OrderDetailsResponse } from "../../../../OrderDetailsResponse";

type ModalItemsProps = {
  close(): void;
  data: OrderDetailsResponse;
};

const ModalItems = ({ close, data }: ModalItemsProps) => {
  const [activeShipping, setActiveShipping] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <CardModal data-testid="cancel-modal" padding="30px 50px 25px 50px">
        <Column margin="0 auto" textAlign="center">
          <Row>
            <img src={iconExclamation} alt="exclamation" height="22px" />
            <TitleAlert margin="0 0 0 10px">Atenção</TitleAlert>
          </Row>
          <DescAlert>
            Vamos interromper o processo de entrega do pedido após sua
            solicitação.
          </DescAlert>
        </Column>

        <ViewBorder>
          <ViewGrey>
            <div>
              <TitleGrey>PEDIDO</TitleGrey>
              <ValueTotal>{data?.orderCode}</ValueTotal>
            </div>
            <div>
              <TitleGrey>DATA DA CRIAÇÃO DO PEDIDO</TitleGrey>
              <ValueTotal>{formatDate(data?.creationDate)}</ValueTotal>
            </div>
            <div>
              <TitleGrey>VALOR TOTAL DO PEDIDO</TitleGrey>
              <ValueTotal color="#2E8CF6">
                {formatMoney(data?.totalValue)}
              </ValueTotal>
            </div>
          </ViewGrey>
        </ViewBorder>

        <Row justify="flex-start" margin="0 0 12px 0">
          {data?.consignments.map((u, index) => {
            return (
              <ButtonTab
                onClick={() => setActiveShipping(index + 1)}
                active={activeShipping === index + 1}
              >
                Entrega {index + 1}
                <Badge active={activeShipping === index + 1}>
                  {u.items.length}
                </Badge>
              </ButtonTab>
            );
          })}
        </Row>

        {data?.consignments.map((u, index) => {
          return (
            <>
              {activeShipping === index + 1 && (
                <Items items={u.items} width="100%" />
              )}
            </>
          );
        })}

        <Row margin="30px 0 0 0">
          <Column margin="0 20px 0 0">
            <Button
              text="Fechar"
              variation="primary"
              onClick={close}
              size="large"
              outline
            />
          </Column>
          <Button
            text="Cancelar Pedido"
            variation="confirm"
            onClick={() =>
              dispatch({
                type: "MODAL_TOTAL_CANCEL",
                payload: 3,
              })
            }
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalItems;
