import styled from "styled-components";

/*Modal*/
type TCardModal = {
  padding?: string;
};

export const Title = styled.h2`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: #ff7a14;
  margin: 0 28px;
`;

export const SubTitle = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-size: 14px;
  text-align: center;
  color: #263238;
  margin: 16px 28px;
`;

export const CardModal = styled.div<TCardModal>`
  width: auto;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "20px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 12px;
    font-size: 14px;
  }
`;
