import firebase from "firebase";

let config;

if (process.env.PARAM === "development") {
  config = {
    apiKey: "AIzaSyCQvKIuBH7kuQDPdxm4ZZDCkPjQ1TdEDXc",
    authDomain: "br-digitalcomm-dev.firebaseapp.com",
    projectId: "br-digitalcomm-dev",
    appId: "1:1041356878392:web:674c87e879a219395b6ccd",
  };
}
if (process.env.PARAM === "qa") {
  config = {
    apiKey: "AIzaSyAJ-srxS7yaq4k_l10oPuzEjv5Zt4P11V8",
    authDomain: "br-digitalcomm-qa.firebaseapp.com",
    projectId: "br-digitalcomm-qa",
    appId: "1:305401786312:web:173249ab890f805f499afd",
  };
}
if (process.env.PARAM === "production") {
  config = {
    apiKey: "AIzaSyCK96kYbLMLcqgBYLkvw86Alr6p2hSFXno",
    authDomain: "br-digitalcomm-prod.firebaseapp.com",
    projectId: "br-digitalcomm-prod",
    appId: "1:771447935056:web:85f8fcab0a345c180b0155",
  };
}

firebase.initializeApp(config);

export default firebase;
