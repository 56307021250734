import React from "react";
import useModal from "react-hooks-use-modal";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "carrefour-portal-backoffice-style-guide";
import Skeleton from "react-loading-skeleton";

import ModalRefund from "../modals/modal-refund";
import ModalClickStatus from "../modals/modal-click-status";
import ModalForbidden from "../modals/modal-forbidden";
import ModalSuccess from "../modals/modal-success";
import ModalPaymentData from "../modals/modal-payment-data";
import ModalVoucherRefund from "../modals/modal-voucher-refund";
import ModalCreditCardRefund from "../modals/modal-credit-card-refund";
import ModalLoading from "../modals/modal-loading";
import ModalResendForbidden from "../modals/modal-resend-forbidden";

import { setReturnMethod } from "@store/refund-items-reducer";

import { Container } from "@components/utilities";
import { CloseModal } from "../modals/styles";
import { ContainerBar, RowBetween, CustomerInfo, Buttons } from "./styles";
import { AppState } from "@store/index";

type Status =
  | "bankAccountRefund"
  | "returnSummary"
  | "clickStatus"
  | "refundMktOutConfirm"
  | "returnRequestFailed"
  | "forbiddenRequest"
  | "loadingRequest"
  | "voucherRefund"
  | "creditCardRefund"
  | "successRequest"
  | "paymentData"
  | "resendRequestFailed"
  | "resendConfirm"
  | "refundConfirm"
  | "refundOrder";

const ActionBar = (props: any) => {
  const {
    nextPage,
    modal,
    data,
    canGoNext,
    closeModal,
    setHasConfirmation,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const paymentOption = useSelector(
    (state: AppState) => state.returnItems.refundMethod
  );
  const refundInfo = useSelector(
    (state: AppState) => state.returnItems.refundInfo
  );
  const orderDetails = useSelector(
    (state: AppState) => state.returnItems.orderDetails
  );
  const flowType = useSelector((state: AppState) => state.returnItems.flowType);

  const [Modal, open, close, isOpen] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  type RenderScreenProps = {
    status: Status;
    action: (...args: any[]) => any;
    close: (...args: any[]) => any;
  };

  function RenderScreen({ status, action, close }: RenderScreenProps) {
    const restituitionValue = refundInfo?.find(
      (r: any) => r.type === paymentOption
    );

    switch (status) {
      case "refundOrder":
        return <ModalRefund close={close} action={action} />;
      case "resendConfirm":
        return <ModalRefund close={close} action={action} />;
      case "refundConfirm":
        return <ModalRefund close={close} action={action} />;
      case "refundMktOutConfirm":
        return (
          <ModalRefund
            close={() => {
              setHasConfirmation(false);
              close();
            }}
            action={action}
          />
        );
      case "bankAccountRefund":
        return <ModalRefund close={close} action={action} />;
      case "clickStatus":
        return <ModalClickStatus />;
      case "forbiddenRequest":
        return (
          <ModalForbidden errorMessage="Você não pode solicitar devolução fora do prazo" />
        );
      case "paymentData":
        return <ModalPaymentData />;
      case "voucherRefund":
        return <ModalRefund close={close} action={action} />;
      case "creditCardRefund":
        return <ModalRefund close={close} action={action} />;
      case "resendRequestFailed":
        return (
          <ModalResendForbidden
            action={() => {
              dispatch(setReturnMethod("NO_RETURN"));
              close();
            }}
            close={close}
            errorMessage="Ops, o estoque desse produto está indisponivel, ofereça reembolso."
          />
        );
      case "returnRequestFailed":
        return (
          <ModalForbidden
            errorMessage={`Ops, não foi possível finalizar a solicitação do
        pedido #${orderDetails.orderCode}, tente novamente`}
          />
        );
      case "loadingRequest":
        return <ModalLoading loadingMessage="Carregando solicitação..." />;
      case "successRequest":
        return (
          <ModalSuccess
            successMessage={`Sua solicitação foi realizada com sucesso!`}
          />
        );
      default:
        return null;
    }
  }

  function action(modalStatus: Status) {
    if (modalStatus && isOpen) {
      nextPage();
      if (closeModal) {
        close();
      }
      return;
    }

    if (modalStatus) {
      open();
      return;
    }

    nextPage();
  }

  function closeButton() {
    if (
      modal === "successRequest" ||
      modal === "forbiddenRequest" ||
      modal === "returnRequestFailed"
    ) {
      history.push(`/atendimento/pedido/detalhe/${orderDetails.orderCode}`);
      return;
    }

    close();
  }

  const flowText = () => {
    switch (flowType) {
      case "DeliveryOccurrence":
        return "Tratando ocorrência de transporte";
      case "TransportOccurrence":
        return "Tratando insucesso de entrega";
      default:
        return "Estorno do pedido";
    }
  };

  return (
    <>
      <ContainerBar>
        <Container>
          <RowBetween>
            <CustomerInfo>
              <h5>
                {flowText()} de:{" "}
                {data?.customerFirstName ? (
                  <span>{`${data?.customerFirstName} ${data?.customerLastName}`}</span>
                ) : (
                  <Skeleton width={80} height={22} />
                )}
              </h5>
              <strong>PEDIDO</strong>
              <p>
                {data?.orderCode ? (
                  data?.orderCode
                ) : (
                  <Skeleton width={80} height={16} />
                )}
                <span>Pedido VTEX</span>
              </p>
            </CustomerInfo>
            <Buttons>
              <Button
                data-testid="button-close"
                text="Fechar"
                onClick={() =>
                  history.push(
                    `/atendimento/pedido/detalhe/${orderDetails.orderCode}`
                  )
                }
                outline
                margin="0 15px 0 0"
              />
              <Button
                data-testid="button-confirm"
                text="Prosseguir"
                onClick={() => action(modal)}
                variation="confirm"
                disabled={!canGoNext}
              />
            </Buttons>
          </RowBetween>
        </Container>
      </ContainerBar>

      <Modal preventScroll>
        <CloseModal onClick={() => closeButton()}>X</CloseModal>
        <RenderScreen action={action} close={close} status={modal} />
      </Modal>
    </>
  );
};

export default ActionBar;
