import { FlowType, Status, Payments } from "./types";
import {
  availabilityType,
  DeliveryModalityType,
} from "@store/refund-items-reducer";

type deliverySelectorNonFoodParams = {
  deliveryOption?: DeliveryModalityType;
  hasStock?: boolean;
  consignmentType?: FlowType;
  hasDeliveryScheduler?: boolean;
};

export const deliverySelectorNonFood = ({
  deliveryOption,
  hasStock,
  consignmentType,
  hasDeliveryScheduler,
}: deliverySelectorNonFoodParams) => {
  if (
    deliveryOption === "TOTAL_REFUND" ||
    deliveryOption === "PARTIAL_REFUND" ||
    deliveryOption === "FREIGHT_REFUND" ||
    !hasStock
  ) {
    if (consignmentType === "MARKETPLACE_OUT") return null;
    return "Refund";
  }

  if (deliveryOption === "RESEND" && hasDeliveryScheduler) {
    return "DeliveryScheduler";
  }
};

export function nextScreenModal(
  currentFlow: FlowType,
  currentScreen: Status,
  {
    deliveryOption,
    paymentOption,
    requestReturnStatus,
    hasStock,
    availabilityInfo,
    consignmentType,
    hasConfirmation,
  }: {
    deliveryOption?: DeliveryModalityType;
    paymentOption?: Payments;
    availabilityInfo?: availabilityType;
    requestReturnStatus?: string;
    hasStock?: boolean;
    consignmentType?: FlowType;
    hasConfirmation?: boolean;
  }
) {
  const hasDeliveryScheduler = availabilityInfo?.slas?.reduce((acc, sla) => {
    return acc || !!sla.deliveryWindows.length;
  }, false);

  switch (currentFlow) {
    default: {
      if (currentScreen === "Refund" && !requestReturnStatus) {
        if (paymentOption === "GIFT_CARD") {
          return "voucherRefund";
        }
        if (paymentOption === "CREDIT_CARD") {
          return "creditCardRefund";
        }
        if (paymentOption === "REFUND") {
          return "creditCardRefund";
        }
      }
      if (currentScreen === "AccountRefund" && !requestReturnStatus) {
        if (paymentOption === "ACCOUNT_DEPOSIT") {
          return "bankAccountRefund";
        }
        if (paymentOption === "PAYMENT_ORDER") {
          return "refundOrder";
        }
      }
      if (
        (currentScreen === "Refund" && requestReturnStatus) ||
        (currentScreen === "AccountRefund" && requestReturnStatus)
      ) {
        if (requestReturnStatus === "LOADING") {
          return "loadingRequest";
        }
        if (requestReturnStatus === "SUCCESS") {
          return "successRequest";
        }
        if (requestReturnStatus === "ERROR") {
          return "returnRequestFailed";
        }
      }
      return null;
    }
  }
}
