import styled from "styled-components";
import imgSearch from "../../../../assets/search.svg";

interface DropGroupProps {
  error: string;
}

interface InputProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: any;
}

export const Card = styled.div`
  justify-items: center;
  background: #e4f1ff;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 45px 40px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const Margin = styled.div`
  margin-top: 30px;
`;

export const DropContainer = styled.div`
  position: relative;
  width: 75%;
  display: flex;
`;

export const DropGroup = styled.div<DropGroupProps>`
  box-shadow: 0px 0px 8px rgba(176, 190, 197, 0.3);
  margin-right: 10px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  border: ${(props) =>
    props.error.length === 0 ? "none" : "1px solid #E81E26"};
`;

export const DropButton = styled.div`
  min-width: 105px;
  background-color: #fff;
  padding: 12px;
  color: #1e5bc6;
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #eceff1;
  cursor: pointer;
  background: #fff;
  background-repeat: no-repeat;
  background-position: right 10px center;
`;
export const DropUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 45px;
  left: 0px;
  width: 130px;
  background-color: white;
  position: absolute;
  border-radius: 4px;
  margin-top: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;
export const DropLi = styled.li`
  padding: 10px 12px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
`;
export const DropClick = styled.a`
  color: #1e5bc6;
  text-decoration: none;
  display: block;
`;

export const Input = styled.input<InputProps>`
  height: 40px;
  border: none;
  width: 100%;
  font-size: 14px;
  padding: 2px 20px 2px 40px;
  border-radius: 0 4px 4px 0;
  background: #fff url(${imgSearch});
  background-repeat: no-repeat;
  background-position: left 15px center;

  &:focus {
    outline: none;
  }
`;
