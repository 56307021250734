import http from "./http";
import handleLogout from "./session";

export async function getTraking(orderCode: string) {
  const response = await http.get(`/v1/orders/${orderCode}/tracking`, {
    params: {
      viewType: "PORTAL_BACKOFFICE",
    },
  });

  return response;
}
