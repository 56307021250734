import React from "react";
import {
  BackButton,
  BackButtonLabel,
  Header,
  Separator,
  Step,
  StepTitle,
  Steps,
} from "./styles";
import ArrowLeft from "@pages/exchange-flow/icons/ArrowLeft";

interface StepHeaderProps {
  length: number;
  activeStep: number;
  title: string;
  backButtonOnClick: () => void;
}

const StepHeader = ({
  length,
  activeStep,
  title,
  backButtonOnClick,
}: StepHeaderProps) => {
  const arrayWithSteps = Array.from(Array(length).keys()).map(
    (item) => item + 1
  );

  return (
    <div>
      <Header>
        <BackButton onClick={backButtonOnClick}>
          <ArrowLeft />

          <BackButtonLabel>Voltar</BackButtonLabel>
        </BackButton>
      </Header>

      <Steps>
        {arrayWithSteps.map((step) => {
          return (
            <>
              <Step active={step === activeStep}>{step}</Step>
              {arrayWithSteps.length !== step && <Separator />}
            </>
          );
        })}
      </Steps>

      <StepTitle>{title}</StepTitle>
    </div>
  );
};

export default StepHeader;
