import styled from "styled-components";

/*Modal*/
type TCardModal = {
  padding?: string;
};

export const CardModal = styled.div<TCardModal>`
  width: auto;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "20px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    margin: 0 auto;
  }
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
`;

export const Title = styled.h1`
  font-family: Ubuntu;
  color: #1e5bc6;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;
export const TitleAlert = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #ff7a14;
`;

export const DescAlert = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #263238;
`;

export const Subtitle = styled.p`
  margin: 32px 10px;
  font-size: 15px;
  font-weight: 400;

  span {
    color: #1e5bc6;
  }
`;

export const SmallText = styled.small`
  color: #607d8b;
  margin-top: 12px;
  font-weight: 300;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 14px;
`;

export const InfoTextWrapper = styled.div`
  width: 600px;
  text-align: left;
  max-height: 300px;
  overflow-y: auto;
  font-size: 14px;
  line-height: 24px;
  padding-right: 24px;
`;
export const InfoSubhead = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
`;

export const InfoHead = styled.h2`
  width: 100%;
  text-align: center;
  color: #1e5bc6;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
`;

export const InfoAddress = styled.div`
  text-align: left;
  color: #78909c;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
`;

export const SuccessText = styled.p`
  color: #40aa60;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  max-width: 451px;
  text-align: center;
`;

export const PaymentInfo = styled.div`
  border: 1px solid #eceff1;
  border-radius: 8px;
  padding: 16px;
`;
export const PaymentInfoLabel = styled.h5`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #b0bec5;
  margin: 0;
`;

export const PaymentInfoValue = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #546e7a;
`;
export const Heading = styled.h1`
  margin: 20px 0 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #1e5bc6;
`;

export const SubtitleValue = styled(Subtitle)`
  margin: 16px 0 0 0;
`;

export const PaymentItem = styled.div`
  border-bottom: 1px solid #eceff1;
  padding: 15px 0;

  img {
    margin-right: 5px;
  }

  &:last-child {
    border-bottom: none;
    padding: 0px;
  }
`;

type TextPayment = {
  color?: string;
  weight?: string;
  margin?: string;
  fontSize?: string;
};

export const TextPayment = styled.span<TextPayment>`
  font-size: ${(props: TextPayment) => props.fontSize || "12px"};
  font-weight: ${(props: TextPayment) => props.weight || "300"};
  color: ${(props: TextPayment) => props.color || "#78909c"};
  margin: ${(props: TextPayment) => props.margin || "0px"};
`;

export const RowBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    display: flex;
    align-items: center;
  }
`;

export const CardItemPayment = styled.div`
  background: #fcfdff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 25px;
  730px
  margin: 15px auto 0px;

  p {
    display: none;
  }
`;
export const ContainerPayment = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SmallTextBold = styled.span`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
`;

export const RowSimple = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  padding-bottom: 32px;
  width: 600px;
`;

type RowWithBorder = {
  width?: string;
  border?: string;
  margin?: string;
  padding?: string;
};
export const RowWithBorder = styled.div<RowWithBorder>`
  display: flex;
  justify-content: space-around;
  width: ${(props: RowWithBorder) => props.width || "100%"};
  border: ${(props: RowWithBorder) => props.border || "1px solid #90A4AE;"};
  margin: ${(props: RowWithBorder) => props.margin || "0"};
  padding: ${(props: RowWithBorder) => props.padding || "0"};
  border-radius: 16px;
`;

export const Text = styled.span`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #607d8b;
`;
export const TextHighlighBold = styled.span`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #1e5bc6;
`;
export const Pipeline = styled.div`
  width: 1px;
  height: 80px;
  background-color: #90a4ae;
`;
export const TextHighlighNormal = styled.span`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #1e5bc6;
`;
