import * as React from "react";
import { Span, Title } from "./styles";

export const ErrorContent = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        fill="none"
        viewBox="0 0 64 64"
      >
        <path
          fill="#AA0D11"
          fillRule="evenodd"
          d="M32 53.333c11.783 0 21.334-9.551 21.334-21.333 0-11.782-9.551-21.333-21.333-21.333-11.782 0-21.334 9.55-21.334 21.333 0 11.782 9.552 21.333 21.334 21.333zM58.668 32c0 14.727-11.939 26.667-26.666 26.667-14.728 0-26.667-11.94-26.667-26.667 0-14.728 11.94-26.667 26.667-26.667 14.727 0 26.666 11.94 26.666 26.667z"
          clipRule="evenodd"
        />
        <path
          fill="#AA0D11"
          fillRule="evenodd"
          d="M41.886 22.114a2.667 2.667 0 010 3.771l-16 16a2.667 2.667 0 01-3.771-3.77l16-16a2.667 2.667 0 013.771 0z"
          clipRule="evenodd"
        />
        <path
          fill="#AA0D11"
          fillRule="evenodd"
          d="M22.115 22.114a2.667 2.667 0 013.771 0l16 16a2.667 2.667 0 11-3.771 3.772l-16-16a2.667 2.667 0 010-3.772z"
          clipRule="evenodd"
        />
      </svg>

      <Title>Erro na troca!</Title>

      <Span>
        Desculpe, houve um problema ao enviar sua solicitação. Por favor, tente
        novamente mais tarde.
      </Span>
    </>
  );
};
