import React from "react";
import { Button } from "carrefour-portal-backoffice-style-guide";

import { Column, Row } from "@components/utilities";
import {
  CardModal,
  DescAlert,
  PaymentInfoValue,
  SubtitleValue,
  Heading,
} from "../styles";
import { formatMoney } from "@utils/formatUtils";

type Props = {
  value: number;
  returnTitle?: string;
  returnSubtitle?: string;
  action?: (...args: any[]) => any;
  close?: (...args: any[]) => any;
};

const ModalVoucherRefund = (props: Props) => {
  return (
    <CardModal padding="50px 150px">
      <Column margin="0 auto" textAlign="center">
        <DescAlert>Você tem certeza que deseja estornar esse pedido?</DescAlert>
        <PaymentInfoValue>{props.returnSubtitle}</PaymentInfoValue>
        <Heading>{props.returnTitle}</Heading>
        <SubtitleValue>
          Valor total de: <span>{formatMoney(props.value)}</span>
        </SubtitleValue>
      </Column>

      <Row margin="30px 0 0 0">
        <Column margin="0 20px 0 0">
          <Button
            text="Cancelar"
            variation="primary"
            onClick={props.close}
            size="large"
            outline
          />
        </Column>
        <Button
          text="Prosseguir"
          variation="confirm"
          onClick={props.action}
          size="large"
        />
      </Row>
    </CardModal>
  );
};

export default ModalVoucherRefund;
