import styled from "styled-components";
import { Row } from "@components/utilities";

export const ButtonRow = styled(Row)`
  margin: 6px 0;

  button {
    display: block !important;
    width: 100% !important;
    box-sizing: border-box;
    margin: 0 9px;
  }
`;

export const BorderContainer = styled.div`
  padding: 25px;
  width: 30%;
  max-width: 350px;
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
`;

type InputProps = {
  width?: string;
};

export const InputGroup = styled.div<InputProps>`
  flex: ${(props: InputProps) => props.width || "50%"};
  margin: 10px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

export const DeliveryRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #eceff1;
  border-bottom: 1px solid #eceff1;
  margin: 16px 8px;
  padding: 16px 0;
`;

export const DeliveryInputWrapper = styled.div`
  margin: 8px 0px;

  &:first-child {
    margin-top: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const DeliveryInput = styled.input`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #607d8b;

  &:checked + label {
    color: #1e5bc6;
    font-weight: 500;
  }
`;

export const Label = styled.label`
  color: #263238;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
  text-align: left;
`;

export const ContainerTitle = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
