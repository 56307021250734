import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "@store/index";
import { OrderState } from "@store/order-reducer";

import { Button } from "carrefour-portal-backoffice-style-guide";
import { Column, Row } from "@components/utilities";
import { getRefundInfo, executeLink } from "@services/forceInstance";

import { Item } from "@pages/order-detail/OrderDetailsResponse";

import {
  CardModal,
  Title,
  SubTititle,
  PaymentGroupContainer,
  PaymentDetailName,
  BodyPayment,
  PaymentItem,
  RowBetweenCenter,
  TextPayment,
  LoadingPaymentData,
  Buttons,
  RefundInfoContainer,
  InfoAccountBank,
} from "./styles";
import { RenderPaymentType } from "@pages/cancellation-flow/components/payments";
import { formatMoney } from "@utils/formatUtils";

interface ConfirmRefundModalContentProps {
  onClose: () => void;
  onConfirm: () => void;
  orderCode: string;
  consignmentCode: string;
  items: Item[];
}
export interface PaymentMethod {
  paymentMethod: string;
  paymentSystemName: string;
  refundValue: number;
  lastDigits?: string;
}

export interface Refund {
  name?: string;
  type?: string;
  totalValue?: number;
  paymentMethods: PaymentMethod[];
}

type Payments =
  | "BANK_SLIP"
  | "ALELO_CARD"
  | "CARREFOUR_CARD"
  | "GIFT_CARD"
  | "VIRTUAL_GIFT_CARD"
  | "PIX"
  | "CREDIT_CARD";

type PaymentGroup = { [P in Payments]?: PaymentMethod[] };

interface PaymentDetailItem {
  paymentMethod?: string;
  paymentSystemName?: string;
  refundValue?: number;
  lastDigits?: string;
}

interface PaymentDetailGroup {
  method?: string;
  subtotal?: number;
  items?: PaymentDetailItem[];
}

interface RefundInfoPayment {
  paymentMethod: string;
  paymentSystemName: string;
  paymentValue: string;
  lastDigits?: string;
}
interface RefundInfo {
  refundType: string;
  refundName: string;
  forceRefundInfoVMS: RefundInfoPayment[];
}

export const getRefundTypeName = (refundType: string): string => {
  switch (refundType) {
    case "GIFT_CARD":
      return "Vale Troca";
    case "REFUND":
      return "Estorno";
    case "PAYMENT_ORDER":
      return "Ordem de Pagamento";
    case "ACCOUNT_DEPOSIT":
      return "Depósito em conta";
    default:
      return "Sem Estorno";
  }
};

export const PaymentRefunds: React.FC<{ data: Refund }> = ({ data }) => {
  const [payments, setPayments] = useState<PaymentGroup>();
  const [groups, setGroups] = useState<PaymentDetailGroup[]>([]);
  const [bankAccountInfos, setBankAccountInfos] = useState<any[]>([]);

  useEffect(() => {
    if (data.type !== "REFUND") {
      let payment: PaymentMethod = {
        paymentMethod: data.type,
        refundValue: data.totalValue,
        paymentSystemName: data.name,
      };
      data.paymentMethods = [payment];
    }

    const paymentGroup: any = data.paymentMethods.reduce((group, key) => {
      group[key.paymentMethod] = group[key.paymentMethod] || [];
      group[key.paymentMethod].push(key);
      return group;
    }, Object.create(null));
    setPayments(paymentGroup);
  }, []);

  const createGroups = () => {
    if (payments) {
      const currentGroups: PaymentDetailGroup[] = [];
      Object.keys(payments).map((key: Payments) => {
        let currentSubtotal = 0;
        let currentItems: PaymentDetailItem[] = payments[key].map((payment) => {
          currentSubtotal = currentSubtotal + payment.refundValue;
          return {
            paymentMethod: payment.paymentMethod,
            paymentSystemName: payment.paymentSystemName,
            refundValue: payment.refundValue,
            lastDigits: payment.lastDigits,
          };
        });

        let currentGroup: PaymentDetailGroup = {
          method: key as string,
          subtotal: currentSubtotal,
          items: currentItems,
        };

        currentGroups.push(currentGroup);
      });

      setGroups(currentGroups);
    }
  };

  const getPaymentName = (paymentName: string): string => {
    switch (paymentName) {
      case "BANK_SLIP":
        return "Boleto bancário";
      case "ALELO_CARD":
        return "Cartão Alelo";
      case "CARREFOUR_CARD":
        return "Cartão Carrefour";
      case "GIFT_CARD":
        return "Vale Troca";
      case "VIRTUAL_GIFT_CARD":
        return "Vale Troca";
      case "CREDIT_CARD":
        return "Cartão de Crédito";
      case "PAYMENT_ORDER":
        return "Ordem de Pagamento";
      case "ACCOUNT_DEPOSIT":
        return "Depósito em conta";
      case "NO_PAYMENT":
        return "Estorno";
      case "PIX":
        return "Pix";
      default:
        return paymentName;
    }
  };

  const { orderDetail } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  function getBankAccount() {
    let arrOrder = [];
    arrOrder = orderDetail.consignments;
    arrOrder.map((item) => {
      let forceLink = item.links.getForceInstance;
      executeLink(forceLink, data)
        .then((response) => {
          setBankAccountInfos([response.data?.bankAccount]);
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  useEffect(() => {
    createGroups();
  }, [payments]);

  useEffect(() => {
    getBankAccount();
  }, []);

  return (
    <div id="payments">
      {groups && (
        <div>
          {groups.map((group, index) => (
            <>
              <PaymentGroupContainer className="payment-group">
                <PaymentDetailName
                  className="payment-group-title"
                  key={`${group.method}-${index}`}
                >
                  {getPaymentName(group.method)}
                </PaymentDetailName>
                <BodyPayment className="payment-group-body">
                  {group.items.map((item, index) => (
                    <PaymentItem className="payment-group-item" key={index}>
                      <RowBetweenCenter>
                        <div className="payment-group-item-description">
                          <RenderPaymentType
                            paymentType={item.paymentSystemName}
                            lastDigits={item.lastDigits}
                          />
                        </div>
                        <TextPayment className="payment-group-item-value">
                          {formatMoney(item.refundValue)}
                        </TextPayment>
                      </RowBetweenCenter>
                    </PaymentItem>
                  ))}
                  <PaymentItem className="payment-group-subtotal">
                    <RowBetweenCenter>
                      <TextPayment className="payment-group-subtotal-label">
                        Subtotal
                      </TextPayment>
                      <TextPayment
                        color="#353A40"
                        className="payment-group-subtotal-value"
                        weight="700"
                        margin="6px 0"
                      >
                        {formatMoney(group.subtotal)}
                      </TextPayment>
                    </RowBetweenCenter>
                  </PaymentItem>
                </BodyPayment>
              </PaymentGroupContainer>
              <div className="payment-group-total">
                <PaymentItem>
                  <RowBetweenCenter>
                    <TextPayment color="#353A40" weight="400">
                      Total reembolso
                    </TextPayment>
                    <TextPayment color="#1E5BC6" weight="700" fontSize="16px">
                      {formatMoney(data.totalValue)}
                    </TextPayment>
                  </RowBetweenCenter>
                </PaymentItem>
              </div>
              {group.method === "ACCOUNT_DEPOSIT" && (
                <>
                  <InfoAccountBank>
                    <>
                      {bankAccountInfos.map((item) => (
                        <>
                          <p className="info-bank_text">Dados bancários</p>
                          <div className="info-bank_line">
                            <span className="info-bank_line--item">
                              Tipo de conta
                            </span>
                            <span className="info-bank_line--item">
                              {item.type}
                            </span>
                          </div>
                          <div className="info-bank_line">
                            <span className="info-bank_line--item">Banco</span>
                            <span className="info-bank_line--item">
                              {item?.bank === undefined || !item?.bank
                                ? ""
                                : item?.bank}
                              {item?.bankName === undefined || !item?.bankName
                                ? ""
                                : ` - ${item?.bankName}`}
                            </span>
                          </div>
                          <div className="info-bank_line">
                            <span className="info-bank_line--item">
                              Agência
                            </span>
                            <span className="info-bank_line--item">
                              {item?.branch === undefined || !item?.branch
                                ? ""
                                : item?.branch}
                              {item?.branchDigit === undefined ||
                              !item?.branchDigit
                                ? ""
                                : ` - ${item?.branchDigit}`}
                            </span>
                          </div>
                          <div className="info-bank_line">
                            <span className="info-bank_line--item">Conta</span>
                            <span className="info-bank_line--item">
                              {item?.account === undefined || !item?.account
                                ? ""
                                : item?.account}
                              {item?.digit === undefined || !item?.digit
                                ? ""
                                : ` - ${item?.digit}`}
                            </span>
                          </div>
                        </>
                      ))}
                    </>
                  </InfoAccountBank>
                </>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export const ConfirmRefundModalContent: React.FC<ConfirmRefundModalContentProps> = (
  props: ConfirmRefundModalContentProps
) => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [refund, setRefund] = useState<Refund>(null);
  const [isMarketplaceOut, setIsMarketplaceOut] = useState<boolean>(false);
  const [refundInfo, setRefundInfo] = useState<RefundInfo | null>(null);

  const { selectedConsignment } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  /**
   * On start the component load the refund info
   */
  useEffect(() => {
    setIsMarketplaceOut(selectedConsignment.type === "MARKETPLACE_OUT");
    setIsLoadingData(true);
    loadRefundInfo();
  }, []);

  /**
   * When refund info was loaded starts to mount the refund object
   */
  useEffect(() => {
    if (refundInfo) {
      mountRefund();
    }
  }, [refundInfo]);

  /**
   * When the refund object was loaded sets the loading flag to false
   */
  useEffect(() => {
    if (refund) {
      setIsLoadingData(false);
    }
  }, [refund]);

  /**
   * Get the Refund Information by order and consignment
   */
  const loadRefundInfo = async () => {
    const result = await getRefundInfo(
      props.orderCode,
      props.orderCode + "0001"
    );
    // const result = await getRefundInfo(props.orderCode, props.consignmentCode);
    /* correct the 404 error by accessing the api https://customer-services.cloud.carrefour.com.br/v1/orders/{order_code}/consignments/{consignments_code}/refund-info */
    setRefundInfo(result.data);
  };

  /**
   * Mount the Refund object to use on PaymentRefunds component
   */
  const mountRefund = async () => {
    let paymentMethods: PaymentMethod[] = [];
    let totalValue: number = 0;

    refundInfo.forceRefundInfoVMS.map((forceRefundInfo) => {
      let refunValue: number = Number(forceRefundInfo.paymentValue);
      totalValue = totalValue + refunValue;
      paymentMethods.push({
        paymentMethod: forceRefundInfo.paymentMethod,
        paymentSystemName: forceRefundInfo.paymentSystemName,
        refundValue: refunValue,
        lastDigits: forceRefundInfo.lastDigits || null,
      });
    });

    setRefund({
      name: refundInfo.refundName,
      type: refundInfo.refundType,
      totalValue: totalValue,
      paymentMethods: paymentMethods,
    });
  };

  return (
    <CardModal padding="20px ">
      <Column>
        <Title>Restituição de valor</Title>
        <SubTititle>
          <p>
            Ao prosseguir, o reembolso/estorno será iniciado para atender o
            cliente.
          </p>
          <p>Para confirmar, clique em prosseguir</p>
        </SubTititle>
        <Row>
          {isLoadingData ? (
            <LoadingPaymentData>
              Carregando informações de reembolso.
            </LoadingPaymentData>
          ) : isMarketplaceOut ? (
            <RefundInfoContainer>
              <Row>
                <Column className="title">
                  {getRefundTypeName(refundInfo.refundType)}
                </Column>
              </Row>
              <Row>
                <Column className="label">Total do reembolso</Column>
                <Column className="value">
                  {formatMoney(refund.totalValue)}
                </Column>
              </Row>
            </RefundInfoContainer>
          ) : (
            <Row>
              <PaymentRefunds data={refund} />
            </Row>
          )}
        </Row>
        <Row margin="16px 0 0 0">
          <Buttons>
            <Button
              text="Cancelar"
              variation="primary"
              margin="0 10px 0 0"
              size="medium"
              onClick={props.onClose}
              outline
            />
            <Button
              text={"Prosseguir"}
              variation="confirm"
              margin="0 10px 0 0"
              size="medium"
              onClick={props.onConfirm}
              disabled={isLoadingData}
            />
          </Buttons>
        </Row>
      </Column>
    </CardModal>
  );
};
