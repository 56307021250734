import styled from "styled-components";

export const Content = styled.div`
  display: flow-root;
`;

export const Container = styled.div`
  max-width: 1112px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
`;

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 25px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type ScrollToProps = {
  bottom?: string;
  right?: string;
};

export const ScrollTo = styled.div<ScrollToProps>`
  position: fixed;
  bottom: ${(props: ScrollToProps) => props.bottom || "40px"};
  right: ${(props: ScrollToProps) => props.right || "40px"};
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const ScrollToButton = styled.button`
  all: unset;
  cursor: pointer;
`;
