import React from "react";

import { StyledButton, LeftIcon, RightIcon } from "./styles";
import { Size, Variation } from "./types";

type ButtonProps = {
  text: string;
  variation: Variation;
  size: Size;
  margin: string;
  leftIcon?: any;
  rightIcon?: any;
  outline?: boolean;
  onClick: () => void;
};

function Button(props: ButtonProps) {
  const {
    text,
    variation,
    size,
    margin,
    leftIcon,
    rightIcon,
    outline,
    onClick,
  } = props;
  return (
    <StyledButton
      variation={variation}
      size={size}
      margin={margin}
      onClick={onClick}
      outline={outline}
    >
      {leftIcon ? <LeftIcon src={leftIcon} /> : null}
      {text}
      {rightIcon ? <RightIcon src={rightIcon} /> : null}
    </StyledButton>
  );
}

export default Button;
