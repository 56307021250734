// @ts-nocheck
import Skeleton from "react-loading-skeleton";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import _uniqBy from "lodash/uniqBy";
import { setRefundMethod, setRefundInfo } from "@store/return-items-reducer";
import iconBarcode from "@assets/barcode.svg";
import http from "@services/http";
import { formatMoney } from "@utils/formatUtils";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";
import { RenderPaymentType } from "@pages/cancellation-flow/components/payments";
import {
  RowBetweenCenter,
  PaymentMethods,
  PaymentMethod,
  PaymentMethodBtn,
  PaymentMethodLabel,
  PaymentDescription,
  PaymentDetails,
  PaymentDetailName,
  BodyPayment,
  PaymentItem,
  LoadingWrapper,
  TextPayment,
  Loading,
} from "./styles";

type PaymentMethodType =
  | "BANK_SLIP"
  | "CREDIT_CARD"
  | "DEBIT_CARD"
  | "GIFT_CARD"
  | "VIRTUAL_GIFT_CARD"
  | "ALELO_CARD"
  | "CARREFOUR_CARD"
  | "PIX";

type Payment = {
  paymentMethod: PaymentMethodType;
  lastDigits: string;
  paymentSystemName: string;
  authorizationCode: string;
  refundValue: number;
};

const formatPayment = (
  payments: Payment[]
): { method: PaymentMethodType; payments: Payment[] }[] => {
  return _map(
    _groupBy(payments, "paymentMethod"),
    (payments: Payment, method: string) => ({ method, payments })
  );
};

const getPaymentName = (paymentName: PaymentMethodType): string => {
  switch (paymentName) {
    case "BANK_SLIP":
      return "boleto bancário";
    case "ALELO_CARD":
      return "cartão Alelo";
    case "CARREFOUR_CARD":
      return "cartão Carrefour";
    case "PROMISSORY":
      return "cartão Carrefour Virtual";
    case "GIFT_CARD":
      return "cartão presente";
    case "VIRTUAL_GIFT_CARD":
      return "cartão presente";
    case "CREDIT_CARD":
      return "cartão de crédito";
    case "DEBIT_CARD":
      return "cartão de débito";
    case "PIX":
      return "Pix";
  }
};

const paymentDescription = (payments) => {
  const paymentUnique = _uniqBy(payments, (p) => p.paymentMethod);
  const paymentNameArray = paymentUnique.map((payment) =>
    getPaymentName(payment.paymentMethod)
  );

  if (paymentNameArray.length === 1) {
    return paymentNameArray.toString();
  }
  if (paymentNameArray.length === 2) {
    return paymentNameArray.join(" e ");
  }

  const lastPaymentItem = paymentNameArray.pop();
  return `${paymentNameArray.join(", ")} e ${lastPaymentItem}`;
};

const CardRefund = (props: any) => {
  const { orderDetails } = props;
  const dispatch = useDispatch();
  const returnProducts = useSelector<AppState>(
    (state) => state.returnItems?.returnItems
  );
  const paymentsString = paymentDescription(orderDetails.payments);
  const [optionsValues, setOptionsValues] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const filteredPaymentMethods =
    optionsValues?.filter((op) => op.type != "PAYMENT_ORDER") ?? null;

  async function getOptionsValues() {
    const { orderCode } = orderDetails;
    const returnItemList = returnProducts?.map((item) => ({
      id: item.productValue,
      quantity: item.quantity,
    }));

    const { data }: { data: any } = await http.post(
      `/v1/orders/${orderCode}/refund/list/types`,
      {
        items: returnItemList,
      }
    );

    setOptionsValues(data);
  }

  useEffect(() => {
    getOptionsValues();
  }, []);

  useEffect(() => {
    dispatch(setRefundInfo(optionsValues));
  }, [optionsValues]);

  const handlePaymentChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setRefundMethod(value));
  };

  const rules = useSelector<AppState, AuthState>((state) => state.rules);

  const getAuthorizedPartialRefund = rules.authorities.find(
    (element: any) => element === "cs-order.refund-partial"
  );

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>{`3`}</Badge>
          {`Escolha a forma de reembolso`}
        </Title>
        <Subtitle>
          {`A sua compra foi paga com`} <strong>{paymentsString}</strong>.
          {` Escolha a forma de restituição abaixo:`}
        </Subtitle>
        {filteredPaymentMethods ? (
          <PaymentMethods onChange={handlePaymentChange}>
            {filteredPaymentMethods?.map(
              (option: {
                type?: any;
                name?: any;
                totalValue?: any;
                paymentMethods?: any;
              }) => {
                const { paymentMethods } = option;
                const formattedPayments = formatPayment(paymentMethods);
                const getOptionType = option.type;
                return (
                  <>
                    {(!getAuthorizedPartialRefund &&
                      getOptionType != "GIFT_CARD") ||
                    getOptionType === "ACCOUNT_DEPOSIT" ? (
                      ``
                    ) : (
                      <PaymentMethod
                        inactive={
                          paymentMethod !== option.type && paymentMethod !== ""
                        }
                      >
                        <PaymentMethodBtn
                          type="radio"
                          value={option.type}
                          name="payment"
                          id={option.type}
                          onClick={() => setPaymentMethod(option.type)}
                        />
                        <PaymentMethodLabel htmlFor={option.type}>
                          {option.name}
                        </PaymentMethodLabel>
                        <PaymentDescription
                          active={paymentMethod === option.type}
                        >
                          {`Será feito um estorno de acordo com a forma de pagamento utilizada.`}
                        </PaymentDescription>
                        <PaymentDetails active={paymentMethod === option.type}>
                          {formattedPayments.map((payment, index) => (
                            <>
                              <PaymentDetailName
                                active={paymentMethod === option.type}
                                key={`${payment.method}-${index}`}
                              >
                                {getPaymentName(payment.method)}
                              </PaymentDetailName>
                              <BodyPayment>
                                {payment.payments.map((paymentItem, index) => (
                                  <PaymentItem key={index}>
                                    <RowBetweenCenter>
                                      <div>
                                        <RenderPaymentType
                                          paymentType={
                                            paymentItem.paymentSystemName
                                          }
                                          lastDigits={paymentItem.lastDigits}
                                        />
                                      </div>
                                      <TextPayment color="#37474F" weight="500">
                                        {formatMoney(paymentItem.refundValue)}
                                      </TextPayment>
                                    </RowBetweenCenter>
                                  </PaymentItem>
                                ))}
                                <PaymentItem>
                                  <RowBetweenCenter>
                                    <TextPayment
                                      margin="15px 0"
                                      color="#1E5BC6"
                                      fontSize="16px"
                                      weight="500"
                                    >{`Total do estorno`}</TextPayment>
                                    <TextPayment
                                      margin="15px 0"
                                      color="#1E5BC6"
                                      fontSize="16px"
                                      weight="500"
                                    >
                                      {formatMoney(
                                        localStorage.getItem("ValueRefund")
                                      )}
                                    </TextPayment>
                                  </RowBetweenCenter>
                                </PaymentItem>
                              </BodyPayment>
                            </>
                          ))}
                          {option.type == "ACCOUNT_DEPOSIT" && (
                            <>
                              <BodyPayment>
                                <PaymentItem>
                                  <RowBetweenCenter>
                                    <div>
                                      <img src={iconBarcode} alt="Boleto" />
                                      <TextPayment>{`Pagamento por boleto`}</TextPayment>
                                    </div>
                                    <TextPayment color="#37474F" weight="500">
                                      {formatMoney(option.totalValue)}
                                    </TextPayment>
                                  </RowBetweenCenter>
                                </PaymentItem>
                                <PaymentItem>
                                  <RowBetweenCenter>
                                    <TextPayment
                                      margin="15px 0"
                                      color="#1E5BC6"
                                      fontSize="16px"
                                      weight="500"
                                    >{`Total do reembolso`}</TextPayment>
                                    <TextPayment
                                      margin="15px 0"
                                      color="#1E5BC6"
                                      fontSize="16px"
                                      weight="500"
                                    >
                                      {formatMoney(
                                        localStorage.getItem("ValueRefund")
                                      )}
                                    </TextPayment>
                                  </RowBetweenCenter>
                                </PaymentItem>
                              </BodyPayment>
                            </>
                          )}
                          {!paymentMethods.length &&
                            option.type != "ACCOUNT_DEPOSIT" && (
                              <BodyPayment>
                                <PaymentItem>
                                  <RowBetweenCenter>
                                    <TextPayment
                                      margin="15px 0"
                                      color="#1E5BC6"
                                      fontSize="16px"
                                      weight="500"
                                    >{`Valor`}</TextPayment>
                                    <TextPayment
                                      margin="15px 0"
                                      color="#1E5BC6"
                                      fontSize="16px"
                                      weight="500"
                                    >
                                      {formatMoney(
                                        localStorage.getItem("ValueRefund")
                                      )}
                                    </TextPayment>
                                  </RowBetweenCenter>
                                </PaymentItem>
                              </BodyPayment>
                            )}
                        </PaymentDetails>
                      </PaymentMethod>
                    )}
                  </>
                );
              }
            )}
          </PaymentMethods>
        ) : (
          <LoadingWrapper>
            <Skeleton width="30%" height={400} />
            <Loading width="30%" height={400} />
          </LoadingWrapper>
        )}
      </ContainerTitle>
    </>
  );
};

export default CardRefund;
