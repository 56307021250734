import styled from "styled-components";

export const SelectedItemWrapper = styled.div<{ canEdit: boolean }>`
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e5e5;

  &:last-child {
    border-bottom: ${(props) => (props.canEdit ? "1px solid #e5e5e5" : "none")};
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const Th = styled.th`
  font-family: "Ubuntu";
  font-size: 12px;
  font-weight: 400;
  text-align: center;

  color: #575757;

  &:first-child,
  &:last-child {
    text-align: left;
  }

  &:last-child {
    padding-left: 26px;
  }
`;

export const Td = styled.td<{ discount?: boolean }>`
  position: relative;
  padding: 24px 0;

  font-size: 14px;
  text-align: center;
  vertical-align: top;

  color: ${(props) => (props.discount ? "#E81E26" : "#454545")};

  &:first-child,
  &:last-child {
    text-align: left;
  }

  &:last-child {
    padding-left: 26px;
    min-width: 200px;
  }
`;

export const Image = styled.img`
  width: 56px;
  height: 56px;
`;

export const ProductInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductInfo = styled.div`
  margin-left: 24px;
`;

export const ProductName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #454545;

  margin: 0;

  max-width: 120px;
`;

export const ProductRef = styled.div`
  font-size: 12px;
  font-weight: 400;

  color: #575757;

  margin: 8px 0;
`;

export const ProductRemoveButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;

  cursor: pointer;
  background: transparent;
  border: none;

  margin-left: 36px;
  column-gap: 8px;

  font-family: "Ubuntu";
  font-size: 12px;
  font-weight: 400;

  color: #aa0d11;
`;

export const AvailabilityError = styled.p`
  position: absolute;
  left: 0;
  width: 280px;

  margin-top: 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  color: #aa0d11;
`;

export const ChecklistContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.h2`
  margin: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin: 16px 0;
`;

export const ChecklistWrapper = styled.div`
  display: block;
`;

export const CheckListColumn = styled.div`
  flex: 60%;
  column-count: 2;
`;

export const CheckListResumeText = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin-right: 16px;
  color: #607d8b;
`;

export const CheckListResumeNote = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #90a4ae;
`;

export const CheckListResumeLabel = styled.span`
  color: #78909c;
  background: #eceff1;
  border-radius: 4px;
  padding: 7px 13px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

export const CheckListOption = styled.div`
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }
`;

export const CheckListLabel = styled.label`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #607d8b;
`;

export const Observation = styled.textarea`
  margin-top: 40px;
  padding: 16px;

  border-radius: 4px;
  border: 1px solid #d9d9d9;

  min-width: 480px;
  max-width: 480px;
  min-height: 200px;
  max-height: 280px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: #575757;
`;

export const ProductQuantity = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  color: #454545;
`;

export const ProductReason = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: #173eb4;
`;

export const ProductObservation = styled.span`
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  color: #575757;
`;
