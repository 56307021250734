import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { validateEmail } from "@utils/masks";
import { AppState } from "src/store";
import { SearchState } from "src/store/search-reducer";
import ModalForbidden from "@pages/return-flow/components/modals/modal-forbidden";

import {
  DropContainer,
  DropGroup,
  DropButton,
  Input,
  Row,
  Card,
  Margin,
} from "./styles";

import { Button, Alert } from "carrefour-portal-backoffice-style-guide";

const DropDown: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [term, setTerm] = useState("");
  const [msgError, setMsgError] = useState("");
  const { searchGiftError } =
    useSelector<AppState, SearchState>((state) => state.search) || {};

  function goToBalanceAvailable() {
    dispatch({ type: "SEARCH_GIFT", payload: term });
    history.push(`/atendimento/saldo-disponivel`);
  }

  function returnErrorModal() {
    setMsgError("Não conseguimos encontrar o e-mail " + searchGiftError);
    dispatch({ type: "SEARCH_GIFT_ERROR", payload: "reset" });
  }

  function validateInput(event: Event) {
    event.preventDefault();
    if (validateEmail(term)) {
      goToBalanceAvailable();
    } else {
      setMsgError("Digite um e-mail válido");
    }
  }

  useEffect(() => {
    if (searchGiftError && searchGiftError !== "reset") {
      returnErrorModal();
    }
  }, [searchGiftError]);

  return (
    <>
      {msgError ? (
        <Margin>
          <Alert variation="danger" actionLabel="Action" onClick={() => {}}>
            {msgError}
          </Alert>
        </Margin>
      ) : (
        ""
      )}

      <Card>
        <form onSubmit={() => validateInput(event)}>
          <Row>
            <DropContainer>
              <DropGroup error={msgError}>
                <DropButton data-testid="button-type">E-mail</DropButton>
                <Input
                  type="text"
                  aria-label="search-input"
                  placeholder="Digite sua busca aqui..."
                  value={term}
                  onChange={(e: { target: { value: string } }) =>
                    setTerm(e.target.value)
                  }
                />
              </DropGroup>
              <Button
                text="Buscar"
                onClick={(event: Event) => validateInput(event)}
                size="large"
                variation="primary"
              />
            </DropContainer>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default DropDown;
