export interface GiftCardState {
  giftCards: Array<IGiftRedeem>;
}

interface IGiftRedeem {
  error: string;
  hasError: boolean;
  item: string;
}

const initialState: GiftCardState = {
  giftCards: [],
};

export interface GiftCardActionsParams {
  type: string;
  payload: any;
}

type GiftCardActions = GiftCardActionsParams;

export function giftcardReducer(
  state = initialState,
  action: GiftCardActions
): GiftCardState {
  switch (action.type) {
    case "REDEEM_GIFT":
      return {
        ...state,
        giftCards: action.payload,
      };
    default:
      return state;
  }
}
