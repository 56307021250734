// Service to get the gift cards information
import contentCard from "./contentCard";

export async function getContentCards(orderCode: string) {
  try {
    const response = await contentCard.get(
      `/v1/firebase/authorization/contentCards/orderCode/${orderCode}`
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}
