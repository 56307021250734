import styled from "styled-components";

export const Input = styled.input`
  &:focus + label {
    color: #1e5bc6;
  }
`;

export const LabelText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 16px;
  margin-left: 8px;
  color: #263238;
  display: inline-block;
`;

export const InfoLabel = styled.div`
  border: 1px solid #eceff1;
  padding: 16px 24px;
  color: #78909c;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  background: #ffffff;
  display: block;
  max-width: 350px;
`;

export const InfoTitle = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
  margin-bottom: 8px;
  color: #37474f;
`;

export const AddressSelectorsWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddressSelectorContent = styled.div`
  margin: 0px 8px;
`;
