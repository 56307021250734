import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AppState } from "src/store";
import { OrderState } from "@store/order-reducer";
import { formatMoney, formatDate } from "@utils/formatUtils";

import { Column, Row } from "@components/utilities";
import { Button, Text } from "carrefour-portal-backoffice-style-guide";
import {
  CardModal,
  CardTitle,
  CardTotalContent,
  CardTotals,
  ItemsOver,
  RowBetween,
  TitleAlert,
  TitleShipping,
  UserValue,
} from "../../styles";

import iconExclamation from "@assets/exclamation.svg";

import Items from "@components/items";
import { ForceInstanceType } from "@pages/force-instance-flow";

interface TItems {
  items: TItem[];
  width?: string;
  consignmentCode?: string;
  totalFreight?: number;
  totalValue?: number;
  totalItemValue?: number;
  close?: any;
  isReturn?: any;
  orderData?: any;
  flowType?: string;
}

interface TItem {
  ean?: string;
  name?: string;
  quantity?: number;
  price?: number;
  discount?: number;
  basePrice?: number;
  imageUrl?: string;
  warranty?: boolean;
}

const ModalForceInstance = ({
  items,
  consignmentCode,
  totalFreight,
  totalValue,
  close,
  orderData,
}: TItems) => {
  useDispatch();
  const history = useHistory();
  function handleForceInstance() {
    history.push("/atendimento/forcar-instancia");
  }

  const { forceInstance } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  const getDescription = () => {
    if (
      forceInstance.forceInstanceType ===
      ForceInstanceType.FORCE_DELIVERY_FAILURE_RESEND
    ) {
      return "Vamos liberar o processo de expedição do pedido após a sua solicitação.";
    }
    return "Revise as informações da devolução antes de liberar a restituição.";
  };

  const getTotalValueLabel = () => {
    if (
      forceInstance.forceInstanceType ===
      ForceInstanceType.FORCE_DELIVERY_FAILURE_RESEND
    ) {
      return "TOTAL DA ENTREGA";
    }
    return "TOTAL DA RESTITUIÇÃO";
  };

  const getItemTitleLabel = () => {
    if (
      forceInstance.forceInstanceType ===
      ForceInstanceType.FORCE_DELIVERY_FAILURE_RESEND
    ) {
      return "Itens do pedido";
    }
    return "Itens em devolução";
  };

  const getFreightPrice = () => {
    if (totalFreight === null || totalFreight === 0) {
      return "GRÁTIS";
    }
    return formatMoney(totalFreight);
  };

  return (
    <>
      <CardModal data-testid="cancel-modal">
        <div>
          <Column margin="0 auto" textAlign="center">
            <TitleAlert color="#ff7a15" size="28px">
              <img src={iconExclamation} alt="exclamation" height="21px" />
              Atenção
            </TitleAlert>
            <br />
            <Text
              type="body"
              size="medium"
              text={getDescription()}
              margin="0"
            />
          </Column>
          <CardTotalContent>
            <TitleShipping>{`Entrega ${consignmentCode.substr(
              consignmentCode.length - 2
            )}`}</TitleShipping>
            <RowBetween justifyContent="flex-start">
              <CardTotals>
                <CardTitle>ENTREGA</CardTitle>
                <UserValue>{consignmentCode}</UserValue>
              </CardTotals>
              <CardTotals>
                <CardTitle>CRIAÇÃO DO PEDIDO</CardTitle>
                <UserValue>{formatDate(orderData.creationDate)}</UserValue>
              </CardTotals>
              <CardTotals>
                <CardTitle>{getTotalValueLabel()}</CardTitle>
                <UserValue weight="500" color="#2E8CF6">
                  {formatMoney(totalValue)}
                </UserValue>
              </CardTotals>
              <CardTotals>
                <CardTitle>TOTAL DO FRETE</CardTitle>
                <UserValue weight="500" color="#2E8CF6">
                  {getFreightPrice()}
                </UserValue>
              </CardTotals>
            </RowBetween>
          </CardTotalContent>
          <Row justify="flex-start">
            <TitleShipping margin="20px 0px 0px">
              {getItemTitleLabel()}
            </TitleShipping>
          </Row>
          <ItemsOver>
            <Items items={items} width="100%" />
          </ItemsOver>
        </div>
        <Row margin="30px 0 0 0" justify="flex-end">
          <Column margin="0 20px 0 0">
            <Button
              text="Cancelar"
              variation="primary"
              onClick={() => close()}
              size="large"
              outline
            />
          </Column>
          <Button
            text="Prosseguir"
            variation="confirm"
            onClick={() => handleForceInstance()}
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalForceInstance;
