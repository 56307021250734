import React from "react";
import { Column } from "@components/utilities";
import {
  ItemsContainer,
  Label,
  ItemHeader,
  Item,
  TextDesc,
  TextItem,
  ItemList,
  ImgDesc,
  Reason,
  Description,
} from "./styles";

interface TItems {
  items: TItem[];
  width?: string;
  status?: string;
  available?: boolean;
}

interface TItem {
  ean?: string;
  name?: string;
  quantity?: number;
  price?: number;
  discount?: number;
  basePrice?: number;
  imageUrl?: string;
  sellerSku?: string;
  warranty?: boolean;
  reason?: Reason;
  disruptionQuantity?: number;
  availableQuantity?: number;
}

interface Reason {
  description?: string;
}

const Items = ({ items, width, status, available }: TItems) => {
  return (
    <>
      <ItemsContainer width={width}>
        <ItemHeader>
          <Column width="230px" margin="5px 25px 5px 0px">
            <Label justify="flex-start">DESCRIÇÃO DO PRODUTO</Label>
          </Column>

          {status === "RUP" && (
            <Column width="130px" margin="5px 4px">
              <Label>QUANTIDADE INDISPONÍVEL</Label>
            </Column>
          )}

          {!available && status === "PPA" && (
            <Column width="130px" margin="5px 4px">
              <Label>TOTAL CANCELADO</Label>
            </Column>
          )}

          {available && status === "PPA" && (
            <Column width="130px" margin="5px 4px">
              <Label>TOTAL ENVIADO</Label>
            </Column>
          )}

          <Column width="100px" margin="5px 4px">
            <Label>PREÇO UNITÁRIO</Label>
          </Column>
          <Column width="100px" margin="5px 4px">
            <Label>DESCONTO</Label>
          </Column>
          <Column width="130px" margin="5px 4px">
            <Label>SUBTOTAL</Label>
          </Column>
        </ItemHeader>
        {items?.length > 0 ? (
          items.map((item, index) => {
            return (
              <>
                {available && item.availableQuantity > 0 ? (
                  <ItemList key={index} data-testid="order-item">
                    <Item>
                      <Column width="230px" margin="5px 25px 5px 0px">
                        <Description>
                          <ImgDesc src={item.imageUrl} />
                          <div>
                            <TextDesc>{item.name}</TextDesc>
                            <TextDesc color="#B0BEC5">
                              Cód: {item.sellerSku}
                            </TextDesc>
                          </div>
                        </Description>
                      </Column>
                      {((!available && status === "PPA") ||
                        status === "RUP") && (
                        <Column width="130px" margin="5px 4px">
                          <TextItem>{item.disruptionQuantity}</TextItem>
                        </Column>
                      )}

                      {available && status === "PPA" && (
                        <Column width="130px" margin="5px 4px">
                          <TextItem>{item.availableQuantity}</TextItem>
                        </Column>
                      )}
                      <Column width="100px" margin="5px 4px">
                        <TextItem>
                          {new Intl.NumberFormat("pt", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.basePrice)}
                        </TextItem>
                      </Column>
                      <Column width="100px" margin="5px 4px">
                        <TextItem color="#FF403F">
                          {new Intl.NumberFormat("pt", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.quantity * item.discount)}
                        </TextItem>
                      </Column>
                      <Column width="130px" margin="5px 4px">
                        <TextItem>
                          {new Intl.NumberFormat("pt", {
                            style: "currency",
                            currency: "BRL",
                          }).format(
                            available
                              ? item.availableQuantity * item.price
                              : item.disruptionQuantity * item.price
                          )}
                        </TextItem>
                      </Column>
                    </Item>
                    {item?.reason ? (
                      <Reason>
                        <strong>MOTIVO</strong>
                        <span>{item?.reason?.description}</span>
                      </Reason>
                    ) : (
                      ""
                    )}
                  </ItemList>
                ) : !available && item.disruptionQuantity > 0 ? (
                  <ItemList key={index} data-testid="order-item-disruption">
                    <Item>
                      <Column width="230px" margin="5px 25px 5px 0px">
                        <Description>
                          <ImgDesc src={item.imageUrl} />
                          <div>
                            <TextDesc>{item.name}</TextDesc>
                            <TextDesc color="#B0BEC5">
                              Cód: {item.sellerSku}
                            </TextDesc>
                          </div>
                        </Description>
                      </Column>
                      {((!available && status === "PPA") ||
                        status === "RUP") && (
                        <Column width="130px" margin="5px 4px">
                          <TextItem>{item.disruptionQuantity}</TextItem>
                        </Column>
                      )}

                      {available && status === "PPA" && (
                        <Column width="130px" margin="5px 4px">
                          <TextItem>{item.availableQuantity}</TextItem>
                        </Column>
                      )}
                      <Column width="100px" margin="5px 4px">
                        <TextItem>
                          {new Intl.NumberFormat("pt", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.basePrice)}
                        </TextItem>
                      </Column>
                      <Column width="100px" margin="5px 4px">
                        <TextItem color="#FF403F">
                          {new Intl.NumberFormat("pt", {
                            style: "currency",
                            currency: "BRL",
                          }).format(item.quantity * item.discount)}
                        </TextItem>
                      </Column>
                      <Column width="130px" margin="5px 4px">
                        <TextItem>
                          {new Intl.NumberFormat("pt", {
                            style: "currency",
                            currency: "BRL",
                          }).format(
                            available
                              ? item.availableQuantity * item.price
                              : item.disruptionQuantity * item.price
                          )}
                        </TextItem>
                      </Column>
                    </Item>
                    {item?.reason ? (
                      <Reason>
                        <strong>MOTIVO</strong>
                        <span>{item?.reason?.description}</span>
                      </Reason>
                    ) : (
                      ""
                    )}
                  </ItemList>
                ) : (
                  ""
                )}
              </>
            );
          })
        ) : (
          <ItemList data-testid="order-item">
            <Item>Items not found</Item>
          </ItemList>
        )}
      </ItemsContainer>
    </>
  );
};

export default Items;
