import React from "react";
import DropDown from "./components/drop-down";
import { Breadcrumbs } from "carrefour-portal-backoffice-style-guide";
import PageHeader from "@components/page-header";

import { Content, Container, Column } from "@components/utilities";

const Voucher = () => {
  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento/vale-troca", label: "Consultar vale-troca" },
          ]}
        />
        <Column>
          <PageHeader
            title="VALE-TROCA"
            subtitle="Faça sua busca"
            button={false}
          />
          <DropDown />
        </Column>
      </Container>
    </Content>
  );
};

export default Voucher;
