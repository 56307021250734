import React, { Dispatch, useEffect, useState } from "react";
import StepHeader from "../step-header";
import { CurrentPage } from "../../index";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import SelectedItem from "../common/selected-item";
import { SelectOtherProductsButton } from "./styles";
import { Consignment } from "../../../order-detail/OrderDetailsResponse";
import http from "../../../../services/http";
import { optionType } from "../../../return-flow/components/return-reasons/components/return-reason-item";

interface SelectQuantityAndReasonProps {
  setCurrentPage: Dispatch<React.SetStateAction<CurrentPage>>;
  selectedConsignment: Consignment;
}

export interface Reason {
  value: string;
  label: string;
}

const SelectQuantityAndReason = ({
  selectedConsignment,
  setCurrentPage,
}: SelectQuantityAndReasonProps) => {
  const selectedItems = useSelector(
    (state: AppState) => state.exchange.selectedItems
  );
  const [reasonOptions, setReasonOptions] = useState<Reason[]>([]);

  async function getReasons() {
    const response = await http.get("/v1/orders/return/reasons");
    const productsIDs = selectedItems.map((item) => item.id).join(",");

    const { data }: { data: optionType[] } = response;

    const reasons = data.map((item) => ({
      value: item.reasonCode,
      label: item.description,
    }));

    setReasonOptions(reasons);
  }

  if (selectedItems.length === 0) {
    setCurrentPage("SELECT_ITEMS");
  }

  const allProductsAreSelected =
    selectedConsignment?.items.length === selectedItems.length;

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <div>
      <StepHeader
        length={4}
        activeStep={2}
        title="Selecione a quantidade e o motivo da troca"
        backButtonOnClick={() => {
          setCurrentPage("SELECT_ITEMS");
        }}
      />

      {selectedItems.map((selectedItem) => (
        <SelectedItem
          canEdit
          item={selectedItem}
          reasonOptions={reasonOptions}
          consignmentCode={selectedConsignment.consignmentCode}
        />
      ))}

      {!allProductsAreSelected && (
        <SelectOtherProductsButton
          onClick={() => setCurrentPage("SELECT_ITEMS")}
        >
          Adicionar outros produtos
        </SelectOtherProductsButton>
      )}
    </div>
  );
};

export default SelectQuantityAndReason;
