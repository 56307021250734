import axios from "axios";
import handleLogout from "@services/session";
function getEnvironment() {
  let url = "";
  switch (process.env.PARAM) {
    case "development":
      url = "https://apihub-qa.carrefour.com.br/digitalcomm-contentcard";
      break;
    case "qa":
      url = "https://apihub-qa.carrefour.com.br/digitalcomm-contentcard";
      break;
    default:
      url = "https://apihub.carrefour.com.br/digitalcomm-contentcard";
      break;
  }
  if (location.host === "localhost:9000") {
    url = "https://apihub-qa.carrefour.com.br/digitalcomm-contentcard";
  }
  return url;
}
function getAuthorizationToken() {
  const firebaseToken = localStorage.getItem("token");

  const headersQa = {
    "Content-Type": "application/json",
    "x-api-key": "rUDxTuaFf1icszZIa4idcQanWUUjZr9s",
    "X-Authorization-Firebase": firebaseToken,
  };
  const headersProd = {
    "Content-Type": "application/json",
    "x-api-key": "tp1cgzMw3dLIfxu2fLq2ql5V87BEdaiD",
    "X-Authorization-Firebase": firebaseToken,
  };

  if (location.host === "localhost:9000") return headersQa;

  switch (process.env.PARAM) {
    case "development":
      return headersQa;
    case "qa":
      return headersQa;
    default:
      return headersProd;
  }
}

const contentCard = axios.create({
  baseURL: getEnvironment(),
  headers: getAuthorizationToken(),
});

contentCard.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.response);
    if (error.response === 333) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);
export default contentCard;
