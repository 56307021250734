import http from "./http";

export async function totalRefund(formData: FormData) {
  try {
    const response = await http.post(`/v1/upload-excel-refund`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.status as number;
  } catch (error) {
    if (error.response) {
      return error.response.status as number;
    }
  }
}
