import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { Breadcrumbs, Card } from "carrefour-portal-backoffice-style-guide";

import { Content, Container } from "@components/utilities";
import PageHeader from "@components/page-header";
import { AppState } from "@store/index";

import CancelSummary from "./components/cancel-summary";
import ReturnReasons from "./components/return-reasons";
import DeliverySelector from "./components/delivery-selector";
import AddressSelector from "./components/address-selector";
import ReturnItems from "./components/return-items";
import AccountRefund from "./components/account-refund";
import Refund from "./components/card-refund";
import DeliveryScheduler from "./components/delivery-scheduler";
import ActionBar from "./components/action-bar";

import { deliverySelectorNonFood, nextScreenModal } from "./utils";
import { FlowType, Status } from "./types";

import {
  Address,
  Delivery,
  Invoice,
  Item,
  Links,
} from "@pages/order-detail/OrderDetailsResponse";
import {
  fetchOrderDetails,
  requestOrderReturn,
  requestOrderResend,
  setConsingmentCode,
  availabilityType,
  clearState,
} from "@store/return-items-reducer";

type FlowObjectType = {
  [key in Status]?: Status;
};

export interface Consignment {
  consignmentCode: string;
  consignmentState: string;
  cubageWeight: number;
  delivery: Delivery;
  invoiceAddress: Address;
  invoices: Invoice[];
  items: Item[];
  links?: Links;
  status?: any;
  realWeight: number;
  totalDiscounts: number;
  totalFreight: number;
  totalItemValue: number;
  totalValue: number;
  type: FlowType;
}

type RenderScreenProps = {
  status: Status;
  consignment: Consignment;
  orderDetails: any;
};

interface PageParams {
  orderCode: string;
  consignmentsCode: string;
}

function RenderScreen({
  status,
  consignment,
  orderDetails,
}: RenderScreenProps) {
  switch (status) {
    case "ReturnReasons":
      return <ReturnReasons consignment={consignment} />;
    case "DeliverySelector":
      return <DeliverySelector consignment={consignment} />;
    case "AddressSelector":
      return (
        <AddressSelector
          orderDetails={orderDetails}
          consignment={consignment}
        />
      );
    case "ReturnItems":
      return <ReturnItems consignment={consignment} />;
    case "AccountRefund":
      return (
        <AccountRefund orderDetails={orderDetails} consignment={consignment} />
      );
    case "DeliveryScheduler":
      return <DeliveryScheduler />;
    case "Refund":
      return <Refund orderDetails={orderDetails} consignment={consignment} />;
    default:
      return null;
  }
}

function nextScreen(
  currentFlow: FlowType,
  currentScreen: Status,
  {
    deliveryOption,
    paymentOption,
    hasStock,
    availabilityInfo,
    isReturn,
    consignmentType,
  }: {
    deliveryOption?: any;
    paymentOption?: any;
    hasStock?: any;
    availabilityInfo?: availabilityType;
    consignmentType: any;
    isReturn?: any;
  }
): Status {
  const hasDeliveryScheduler = availabilityInfo?.slas?.reduce((acc, sla) => {
    return acc || !!sla.deliveryWindows.length;
  }, false);

  switch (currentFlow) {
    case "FOOD": {
      const food: FlowObjectType = {
        ReturnReasons: "DeliverySelector",
        DeliverySelector:
          deliveryOption === "NO_RETURN" ||
          !hasStock ||
          deliveryOption === "COLLECT_IN_STORE"
            ? deliveryOption === "COLLECT_IN_STORE"
              ? "AddressSelector"
              : "Refund"
            : !isReturn && hasDeliveryScheduler
            ? "DeliveryScheduler"
            : !isReturn && !hasDeliveryScheduler
            ? null
            : "ReturnItems",
        AddressSelector: "Refund",
        ReturnItems: hasDeliveryScheduler ? "DeliveryScheduler" : null,
        Refund: paymentOption === "ACCOUNT_DEPOSIT" ? "AccountRefund" : null,
      };

      return food[currentScreen];
    }
    default: {
      const ePrincipal: FlowObjectType = {
        ReturnReasons: "DeliverySelector",
        DeliverySelector: deliverySelectorNonFood({
          deliveryOption,
          hasStock,
          consignmentType,
          hasDeliveryScheduler,
        }),
        AddressSelector:
          consignmentType !== "MARKETPLACE_OUT" ? "Refund" : null,
        Refund: paymentOption === "ACCOUNT_DEPOSIT" ? "AccountRefund" : null,
      };
      return ePrincipal[currentScreen];
    }
  }
}

const ReturnFlow = () => {
  const hasReturnReasons = useSelector(
    (state: AppState) => state.returnItems.hasReturnReason
  );
  const firstPage = hasReturnReasons ? "ReturnReasons" : "DeliverySelector";
  const [currentPage, setCurrentPage] = useState<Status>(firstPage);
  const [currentModal, setCurrentModal] = useState(null);
  const [shouldCloseModal, setCloseModal] = useState(true);
  const [hasConfirmation, setHasConfirmation] = useState(false);
  const { orderCode, consignmentsCode } = useParams<PageParams>();
  const dispatch = useDispatch();
  const consignments: Consignment[] = useSelector(
    (state: AppState) => state.returnItems.orderDetails?.consignments
  );
  const orderDetails = useSelector(
    (state: AppState) => state.returnItems.orderDetails
  );
  const deliveryOption = useSelector(
    (state: AppState) => state.returnItems.returnMethod
  );
  const paymentOption = useSelector(
    (state: AppState) => state.returnItems.refundMethod
  );
  const requestReturnStatus = useSelector(
    (state: AppState) => state.returnItems.requestReturnState
  );
  const returnItems = useSelector((state: AppState) => state.returnItems);
  const flowType = useSelector((state: AppState) => state.returnItems.flowType);
  const hasStock = useSelector(
    (state: AppState) => state.returnItems.hasResendStock
  );
  const isReturn = useSelector(
    (state: AppState) => state.returnItems.hasReturnReason
  );
  const availabilityInfo = useSelector(
    (state: AppState) => state.returnItems.availabilityInfo
  );

  const consignment = consignments?.find(
    (c) => c.consignmentCode === consignmentsCode
  );

  useEffect(() => {
    if (!orderDetails) {
      dispatch(fetchOrderDetails(orderCode));
    }
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    dispatch(setConsingmentCode(consignmentsCode));
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consignmentsCode]);

  useEffect(() => {
    const nextModalString = nextScreenModal(consignment?.type, currentPage, {
      deliveryOption,
      paymentOption,
      requestReturnStatus,
      hasStock,
      availabilityInfo,
      consignmentType: consignment?.type,
      hasConfirmation,
    });

    if (
      nextModalString === "bankAccountRefund" ||
      nextModalString === "creditCardRefund" ||
      nextModalString === "voucherRefund" ||
      nextModalString === "refundOrder" ||
      nextModalString === "refundConfirm" ||
      nextModalString === "resendConfirm"
    ) {
      setCloseModal(false);
    }

    setCurrentModal(nextModalString);
  }, [
    hasConfirmation,
    deliveryOption,
    currentPage,
    paymentOption,
    requestReturnStatus,
    hasStock,
    availabilityInfo,
  ]);

  const nextPageAction = () => {
    if (deliveryOption === "RESEND") {
      dispatch(requestOrderResend());
    } else {
      dispatch(requestOrderReturn());
    }
  };

  const nextPage = () => {
    const nextPageString = nextScreen(consignment?.type, currentPage, {
      deliveryOption,
      paymentOption,
      hasStock,
      availabilityInfo,
      isReturn,
      consignmentType: consignment?.type,
    });

    if (nextPageString) {
      setCurrentPage(nextPageString);
      return;
    }

    nextPageAction();
  };

  const hasValidOption = () => {
    switch (currentPage) {
      case "ReturnReasons": {
        if (
          consignment?.delivery?.modality == "DRIVE" &&
          consignment?.status?.status == "FPR"
        )
          return returnItems.returnItems.length === consignment?.items?.length;
        return returnItems.returnItems.length;
      }
      case "DeliverySelector": {
        if (returnItems.returnMethod === "NO_RETURN" || !hasStock) {
          return returnItems.returnMethod;
        }

        if (
          (consignment.type == "FOOD" &&
            consignment?.status?.status !== "FPR") ||
          !hasReturnReasons
        )
          return (
            returnItems.availabilityInfo?.items?.length &&
            returnItems.returnMethod
          );

        return returnItems.returnMethod;
      }
      case "Refund": {
        return returnItems.refundMethod;
      }
      case "ReturnItems": {
        return returnItems.resendItems;
      }
      case "AccountRefund": {
        return returnItems.bankInfo;
      }
      case "DeliveryScheduler": {
        return (
          returnItems.resendDate.dayPeriod &&
          returnItems.resendDate.currentDay &&
          returnItems.resendDate.dayHour
        );
      }
      default:
        return true;
    }
  };

  const breadcrumbText = () => {
    switch (flowType) {
      case "DeliveryOccurrence":
        return "Ocorrência de transporte";
      case "TransportOccurrence":
        return "Insucesso de entrega";
      default:
        return "Devolução da entrega";
    }
  };

  const flowText = () => {
    switch (flowType) {
      case "DeliveryOccurrence":
        return "Ocorrência de transporte";
      case "TransportOccurrence":
        return "Insucesso de entrega";
      default:
        return "Devolução";
    }
  };

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: `/atendimento/pedido/detalhe/${orderCode}`, label: "Pedido" },
            {
              to: "#",
              label: breadcrumbText(),
            },
          ]}
        />
        <PageHeader title="PEDIDO" subtitle={flowText()} button={false} />
        <CancelSummary data={orderDetails} />
        <Card
          width="auto"
          margin="50px 0 150px 0"
          padding="35px 40px 100px 40px"
        >
          <RenderScreen
            status={currentPage}
            orderDetails={orderDetails}
            consignment={consignment}
          />
        </Card>
      </Container>
      <ActionBar
        nextPage={nextPage}
        data={orderDetails}
        closeModal={shouldCloseModal}
        canGoNext={hasValidOption()}
        consignmentType={consignment?.type}
        setHasConfirmation={setHasConfirmation}
        setCloseModal={setCloseModal}
        modal={currentModal}
      />
    </Content>
  );
};

export default ReturnFlow;
