import React from "react";
import { Column, Row } from "@components/utilities";
import { Button } from "carrefour-portal-backoffice-style-guide";
import {
  TitleAlert,
  CardModal,
  InfoTextWrapper,
  TitleWrapper,
  TitleIcon,
  Subtitle,
  InfoSubhead,
} from "../styles";
import iconExclamation from "@assets/exclamation.svg";

const ModalInfoPost = ({ close, action }: any) => {
  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 50px">
        <TitleWrapper>
          <TitleIcon src={iconExclamation} alt="exclamation" />
          <TitleAlert>Atenção</TitleAlert>
        </TitleWrapper>
        <Column margin="0 auto" textAlign="center">
          <Subtitle>Entenda o prazo de postagem!</Subtitle>
          <InfoSubhead>Informativo para postagem</InfoSubhead>
          <InfoTextWrapper>
            Para efetuarmos a sua troca ou devolução, passamos por algumas
            etapas.
            <p>
              1. Da sua casa a uma agência dos Correios Enviaremos um código de
              postagem para o seu e-mail que expira em 5 dias. Dentro desse
              prazo, você deve encaminhar o produto a uma agência dos Correios.
            </p>
            <p>
              2. Dos Correios ao nosso armazém Depois que você entrega o produto
              nos Correios, acompanharemos a devolução do seu pedido até a
              chegada em nosso armazém o que pode levar o mesmo prazo da
              primeira entrega.
            </p>
            <p>
              3. Análise do produto Após recebermos o seu pedido em nosso
              armazém, o produto passa por uma breve análise, podendo levar até
              3 dias, para verificarmos se está na condição informada do
              checklist que acabamos de preencher.
            </p>
            <p>
              ***Prazo válido apenas se a postagem ocorrer em uma agência dos
              Correios localizada no mesmo município em que o produto foi
              entregue.
            </p>
          </InfoTextWrapper>
        </Column>
        <Row margin="30px 0 0 0" justify="flex-end">
          <Column margin="0 20px 0 0">
            <Button
              text="Cancelar"
              variation="primary"
              onClick={() => close()}
              size="large"
              outline
            />
          </Column>
          <Button
            text="Prosseguir"
            variation="confirm"
            onClick={action}
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalInfoPost;
