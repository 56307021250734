import React from "react";
import { Row } from "@components/utilities";
import {
  CardModal,
  TitleAlert,
  TextLight,
  ModalActionButton,
} from "../../../styles";

interface TItems {
  close?: any;
}

const ShipFromStoreLearnMore = ({ close }: TItems) => {
  return (
    <>
      <CardModal maxWidth="650px" padding="35px">
        <div>
          <TitleAlert color="#1E5BC6" size="26px">
            Saiba mais sobre a reversa no Ship From Store
          </TitleAlert>
          <TextLight
            fontSize="16px"
            fontWeight={400}
            color="#5A646E"
            margin="30px 0px 15px 0px"
            textAlign="center"
          >
            O fluxo de Reversa para a modalidade Ship From Store é realizado
            pela Loja e não pelo Portal Backoffice, por esse motivo, o botão de
            cancelar/devolver está disponível
            <b style={{ marginLeft: "3px" }}>
              apenas para registrar, no Portal, que o processo foi iniciado
              junto à loja.
            </b>
          </TextLight>
        </div>
        <Row margin="30px 0 0 0" justify="center">
          <ModalActionButton onClick={() => close()}>Fechar</ModalActionButton>
        </Row>
      </CardModal>
    </>
  );
};

export default ShipFromStoreLearnMore;
