import React from "react";
import { Column } from "@components/utilities";
import { CardModal, TitleWrapper } from "../styles";
import Spinner from "react-spinner-material";

type Props = {
  loadingMessage: string;
};

const ModalLoading = (props: Props) => {
  return (
    <>
      <CardModal data-testid="load-modal" padding="50px 150px">
        <TitleWrapper>
          <Spinner radius={60} color={"#1E5BC6"} stroke={2} visible={true} />
        </TitleWrapper>
        <Column margin="0 auto" textAlign="center">
          <p>{props.loadingMessage}</p>
        </Column>
      </CardModal>
    </>
  );
};

export default ModalLoading;
