import styled from "styled-components";

export const ModalIcon = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
`;

export const ModalTitle = styled.h2`
  font-family: Ubuntu;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;

  color: #454545;

  margin: 16px 0;
`;

export const ModalDescription = styled.p`
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  max-width: 352px;
  margin: 0;
`;

export const RefundOptions = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const RefundOption = styled.div`
  display: flex;
  align-items: center;

  background-color: transparent;
  border: none;
  cursor: pointer;

  &:first-child {
    margin-bottom: 16px;
  }
`;

export const RefundCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 24px;
`;

export const RefundCheckboxInner = styled.div`
  width: 16px;
  height: 16px;

  border-radius: 16px;
  background-color: #0970e6;
`;

export const RefundLabel = styled.p`
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #454545;

  margin: 0;
  margin-left: 8px;
`;

export const RefundActions = styled.div`
  display: flex;
  gap: 8px;
`;

export const GoBackButton = styled.button`
  flex: 1;
  height: 40px;

  border-radius: 4px;
  border: 1px solid #0970e6;
  background-color: transparent;
  cursor: pointer;

  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  color: #0970e6;
`;

export const GoNext = styled.button`
  flex: 1;
  height: 40px;

  border-radius: 4px;
  border: none;
  background-color: #0970e6;
  cursor: pointer;

  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  color: #fff;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
