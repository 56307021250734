import React, { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DateTimeComponent from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Row, selectStyle } from "@components/utilities";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { Badge, Title, Subtitle } from "@pages/styles";
import { AppState } from "@store/index";
import { setResendDate } from "@store/return-items-reducer";

import {
  BorderContainer,
  InputGroup,
  ContainerTitle,
  Label,
  DeliveryRow,
  DeliveryInput,
  ButtonRow,
  DeliveryInputWrapper,
} from "./styles";

const StyledDateTime = ({ className, inputProps, ...props }: any) => {
  return (
    <DateTimeComponent inputProps={{ className, ...inputProps }} {...props} />
  );
};

const DateTime = styled(StyledDateTime)`
  width: 100%;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  font-family: Ubuntu, sans-serif;
  border-radius: 4px;
  padding: 14px;
  height: 40px;
`;

const DeliveryScheduler = () => {
  const [dayPeriod, setDayPeriod] = useState("");
  const [currentDay, setCurrentDay] = useState(null);
  const dispatch = useDispatch();
  const availabilityInfo = useSelector(
    (state: AppState) => state.returnItems?.availabilityInfo
  );
  const resendDate = useSelector(
    (state: AppState) => state.returnItems?.resendDate
  );
  const supportedDates = availabilityInfo.slas.find(
    (sla) => sla.id === dayPeriod
  );

  const handleChange = (currentDay: moment.Moment) => {
    setCurrentDay(currentDay);
  };

  const handleDayTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDayPeriod(value);
  };

  const saveCurrentDayTimeDate = () => {
    const sendDate = { dayPeriod, currentDay };
    dispatch(setResendDate(sendDate));
  };

  const validateDate = (currentDate: moment.Moment) => {
    const hasSupportedDate = supportedDates?.deliveryWindows.reduce(
      (acc, delivery) => {
        const startDate = moment(delivery.startDate);
        const endDate = moment(delivery.endDate);
        return (
          acc ||
          (currentDate.isSame(startDate, "day") &&
            currentDate.isSame(endDate, "day"))
        );
      },
      false
    );

    return hasSupportedDate;
  };

  const supportedHours = supportedDates?.deliveryWindows.filter((window) => {
    const startDate = moment(window.startDate);
    const endDate = moment(window.endDate);

    return (
      currentDay?.isSame(startDate, "day") && currentDay?.isSame(endDate, "day")
    );
  });

  const hourOptions = supportedHours?.map((hour) => {
    const startHour = moment(hour.startDate).utc().format("HH:mm");
    const endHour = moment(hour.endDate).utc().format("HH:mm");
    return {
      label: `Das ${startHour} às ${endHour}`,
      value: moment(hour.startDate).toISOString(),
    };
  });

  const saveAllInfo = (option: { label: string; value: string }) => {
    const sendDate = { dayPeriod, currentDay, dayHour: option.value };
    dispatch(setResendDate(sendDate));
  };

  const hourCurrentOption = hourOptions?.find(
    (op) => op.value === resendDate.dayHour
  );

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>3</Badge>
          Selecione o dia e horário para o reenvio
        </Title>
        <Subtitle>
          Os horários, preços e tipos de entrega poderão sofrer alterações até o
          fechamento do pedido.
        </Subtitle>
        <BorderContainer>
          <Row>
            <Label>Forma de Entrega</Label>
          </Row>
          <DeliveryRow onChange={handleDayTimeChange}>
            {availabilityInfo.slas.map((sla) => (
              <DeliveryInputWrapper>
                <DeliveryInput
                  type="radio"
                  id={sla.id}
                  value={sla.id}
                  name="sla-windows"
                />
                <label htmlFor={sla.id}>{sla.name}</label>
              </DeliveryInputWrapper>
            ))}
          </DeliveryRow>
          <Row>
            <InputGroup data-testid="inputName">
              <Label htmlFor="date">Selecione o dia</Label>
              <DateTime
                utc
                locale="pt-BR"
                timeFormat={false}
                onChange={handleChange}
                isValidDate={validateDate}
                inputProps={{
                  placeholder: "Selecione uma data...",
                  disabled: !dayPeriod,
                }}
              />
            </InputGroup>
          </Row>
          {!(resendDate.dayPeriod && resendDate.currentDay) && (
            <ButtonRow>
              <Button text="Salvar" onClick={saveCurrentDayTimeDate} />
            </ButtonRow>
          )}
          {resendDate.dayPeriod && resendDate.currentDay && (
            <Row>
              <InputGroup data-testid="inputBank">
                <Label htmlFor="daytime">Selecione o horário</Label>
                <Select
                  name="daytime"
                  options={hourOptions}
                  onChange={saveAllInfo}
                  value={hourCurrentOption}
                  placeholder="Escolha um período..."
                  styles={selectStyle}
                />
              </InputGroup>
            </Row>
          )}
        </BorderContainer>
      </ContainerTitle>
    </>
  );
};

export default DeliveryScheduler;
