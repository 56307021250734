import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Breadcrumbs, Card } from "carrefour-portal-backoffice-style-guide";

import { Content, Container } from "@components/utilities";
import PageHeader from "@components/page-header";
import {
  setClearStateExchange,
  setConsingmentToExchange,
  fetchExchangeOrderDetails,
  requestExchangeItems,
  requestFetchItemsAvailability,
} from "@store/exchange-reducer";
import { AppState } from "../../store";
import SelectItems from "./components/select-items";
import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import ActionBar from "./components/action-bar";
import ExchangeSummary from "../return-flow/components/cancel-summary";
import SelectQuantityAndReason from "./components/select-quantity-and-reason";
import ModalWrapper from "../return-flow/components/modals/modal-wrapper";
import ExtendedWarrantyWarning from "./components/modals/extended-warranty-warning";
import SelectPostageOrCollect from "./components/select-postage-or-collect";
import ExchangeReview from "./components/exchange-review";
import ExchangeFeedback from "./components/modals/exchange-feedback";
import ExchangeLoading from "./components/modals/exchange-loading";

interface RenderScreenProps {
  currentPage: CurrentPage;
  selectedConsignment?: Consignment;
  setCurrentPage: Dispatch<React.SetStateAction<CurrentPage>>;
  setCurrentModal: Dispatch<React.SetStateAction<CurrentModal>>;
}

interface RenderModalProps {
  currentModal: CurrentModal;
  setCurrentModal: Dispatch<React.SetStateAction<CurrentModal>>;
  setCurrentPage: Dispatch<React.SetStateAction<CurrentPage>>;
  handleCloseModal: () => void;
}

export type CurrentPage =
  | "SELECT_ITEMS"
  | "SELECT_QUANTITY_AND_REASON"
  | "SELECT_COLLECT_OR_POSTAGE"
  | "EXCHANGE_REVIEW";

export type CurrentModal =
  | "EXTENDED_WARRANTY_WARNING"
  | "EXCHANGE_LOADING"
  | "EXCHANGE_SUCCESS"
  | "EXCHANGE_ERROR";

interface PageParams {
  orderCode: string;
  consignmentCode: string;
}

function RenderScreen({
  currentPage,
  setCurrentPage,
  setCurrentModal,
  selectedConsignment,
}: RenderScreenProps) {
  switch (currentPage) {
    case "SELECT_ITEMS":
      return (
        <SelectItems
          setCurrentModal={setCurrentModal}
          selectedConsignment={selectedConsignment}
        />
      );

    case "SELECT_QUANTITY_AND_REASON":
      return (
        <SelectQuantityAndReason
          setCurrentPage={setCurrentPage}
          selectedConsignment={selectedConsignment}
        />
      );

    case "SELECT_COLLECT_OR_POSTAGE":
      return <SelectPostageOrCollect setCurrentPage={setCurrentPage} />;

    case "EXCHANGE_REVIEW":
      return (
        <ExchangeReview
          setCurrentPage={setCurrentPage}
          selectedConsignment={selectedConsignment}
        />
      );

    default:
      return null;
  }
}

function RenderModal({
  currentModal,
  setCurrentModal,
  setCurrentPage,
  handleCloseModal,
}: RenderModalProps) {
  const extendedWarrantyWarningConfirm = () => {
    setCurrentModal(null);

    setCurrentPage("SELECT_QUANTITY_AND_REASON");
  };

  switch (currentModal) {
    case "EXTENDED_WARRANTY_WARNING": {
      return (
        <ExtendedWarrantyWarning
          closeModal={handleCloseModal}
          goNext={extendedWarrantyWarningConfirm}
        />
      );
    }

    case "EXCHANGE_LOADING": {
      return <ExchangeLoading />;
    }

    case "EXCHANGE_SUCCESS": {
      return <ExchangeFeedback type="SUCCESS" closeModal={handleCloseModal} />;
    }

    case "EXCHANGE_ERROR": {
      return <ExchangeFeedback type="ERROR" closeModal={handleCloseModal} />;
    }

    default:
      return null;
  }
}

const ExchangeFlow = () => {
  const [currentPage, setCurrentPage] = useState<CurrentPage>("SELECT_ITEMS");
  const [currentModal, setCurrentModal] = useState<CurrentModal | null>(null);

  const history = useHistory();
  const { orderCode, consignmentCode } = useParams<PageParams>();
  const dispatch = useDispatch();
  const orderToExchange = useSelector(
    (state: AppState) => state.exchange.orderToExchange
  );
  const selectedItems = useSelector(
    (state: AppState) => state.exchange.selectedItems
  );
  const returnMethod = useSelector(
    (state: AppState) => state.exchange.returnMethod
  );

  const selectedConsignment = orderToExchange?.consignments?.find(
    (consignment: Consignment) =>
      consignment.consignmentCode === consignmentCode
  );

  const nextPageOrAction = async () => {
    switch (currentPage) {
      case "SELECT_ITEMS": {
        return setCurrentModal("EXTENDED_WARRANTY_WARNING");
      }

      case "SELECT_QUANTITY_AND_REASON": {
        return setCurrentPage("SELECT_COLLECT_OR_POSTAGE");
      }

      case "SELECT_COLLECT_OR_POSTAGE": {
        return setCurrentPage("EXCHANGE_REVIEW");
      }

      case "EXCHANGE_REVIEW": {
        setCurrentModal("EXCHANGE_LOADING");

        if (!selectedConsignment.links.consignmentExchange) {
          return setCurrentModal("EXCHANGE_ERROR");
        }

        const delivery = selectedConsignment.delivery;
        const address = selectedConsignment.delivery.shippingAddress;

        try {
          const items = selectedItems.map((item) => {
            return {
              vtexItemId: item.vtexItemId,
              quantity: item.exchange.quantity.value,
              price: `${item.price}`,
              basePrice: `${item.basePrice}`,
              seller: item.sellerName,
              reason: item.exchange.reason.value,
              observation: item.exchange.observation,
            };
          });

          const itemsToAvailabilityRequest = selectedItems.map((item) => {
            return {
              id: item.vtexItemId,
              quantity: item.exchange.quantity.value,
            };
          });

          const availabilityResponse = await requestFetchItemsAvailability(
            itemsToAvailabilityRequest,
            orderToExchange.orderCode,
            consignmentCode
          );

          const [sla] = availabilityResponse.data.slas;

          await requestExchangeItems(
            selectedConsignment.links.consignmentExchange.href,
            {
              resendOrderVM: {
                items: items,
                shipping: {
                  logistic: {
                    selectedSla: sla.id,
                    shippingEstimate: sla.name,
                    deliveryChannel: sla.deliveryChannel,
                    freightValue: sla.freightValue,
                  },
                  address: {
                    receiverName: delivery.receiverName,
                    addressType: address.addressType,
                    postalCode: address.postalCode,
                    state: address.state,
                    neighborhood: address.neighborhood,
                    number: address.number,
                    country: address.country,
                    street: address.street,
                    city: address.city,
                    externalAddressId: address.externalAddressId,
                  },
                },
                customer: {
                  firstName: orderToExchange.customerFirstName,
                  lastName: orderToExchange.customerLastName,
                  documentType: orderToExchange.customerDocumentType,
                  document: orderToExchange.customerDocument,
                  email: orderToExchange.customerEmail,
                  phone: orderToExchange.customerPhone,
                },
              },
              returnRequestVM: {
                returnItems: selectedItems.map((selectedItem) => {
                  const returnItem = {
                    itemCode: selectedItem.refId,
                    quantity: selectedItem.exchange.quantity.value,
                    evaluation: {
                      reason: selectedItem.exchange.reason.value,
                      checklist: selectedItem.exchange.checklist,
                      observation:
                        selectedItem.exchange.observation === ""
                          ? "."
                          : selectedItem.exchange.observation,
                    },
                  };

                  return returnItem;
                }),
                refund: {
                  type: "GIFT_CARD",
                },
                returnModality: returnMethod,
                attendanceChannel: 1,
                customerEmail: orderToExchange.customerEmail,
              },
            }
          );

          setCurrentModal("EXCHANGE_SUCCESS");
        } catch (error) {
          setCurrentModal("EXCHANGE_ERROR");
        }
      }

      default:
        return;
    }
  };

  const getIfCanGoNext = () => {
    switch (currentPage) {
      case "SELECT_ITEMS": {
        return !!selectedItems.length;
      }

      case "SELECT_QUANTITY_AND_REASON": {
        return selectedItems.every((selectedItem) => {
          const allRequiredFieldsAreFilled =
            selectedItem.exchange.quantity.value &&
            selectedItem.exchange.reason.value;

          const isAvailable =
            !selectedItem.exchange.isAvailabilityLoading &&
            selectedItem.exchange.isAvailable &&
            selectedItem.exchange.checklist.length > 0;

          return allRequiredFieldsAreFilled && isAvailable;
        });
      }

      case "SELECT_COLLECT_OR_POSTAGE": {
        return !!returnMethod;
      }

      default:
        return true;
    }
  };

  const handleCloseModal = () => {
    switch (currentPage) {
      case "EXCHANGE_REVIEW": {
        setCurrentModal(null);

        return history.push(`/atendimento/pedido/detalhe/${orderCode}`);
      }

      default: {
        return setCurrentModal(null);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchExchangeOrderDetails(orderCode));

    return () => {
      dispatch(setClearStateExchange());
    };
  }, [dispatch, orderCode]);

  useEffect(() => {
    dispatch(setConsingmentToExchange(consignmentCode));
  }, [consignmentCode, dispatch]);

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: `/atendimento/pedido/detalhe/${orderCode}`, label: "Pedido" },
            {
              to: "#",
              label: "Troca pelo mesmo produto",
            },
          ]}
        />
        <PageHeader
          title="PEDIDO"
          subtitle="Troca pelo mesmo produto"
          button={false}
        />

        <ExchangeSummary data={orderToExchange} />

        <Card
          width="auto"
          margin="50px 0 150px 0"
          padding="35px 40px 100px 40px"
        >
          <RenderScreen
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setCurrentModal={setCurrentModal}
            selectedConsignment={selectedConsignment}
          />
        </Card>
      </Container>

      <ActionBar
        nextPageOrAction={nextPageOrAction}
        canGoNext={getIfCanGoNext()}
        inLastScreen={currentPage === "EXCHANGE_REVIEW"}
      />

      <ModalWrapper show={!!currentModal} onClose={handleCloseModal}>
        <RenderModal
          currentModal={currentModal}
          setCurrentModal={setCurrentModal}
          setCurrentPage={setCurrentPage}
          handleCloseModal={handleCloseModal}
        />
      </ModalWrapper>
    </Content>
  );
};

export default ExchangeFlow;
