import styled from "styled-components";

export const ExchangeLoadingWrapper = styled.div`
  width: 400px;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;

  text-align: center;
  box-sizing: border-box;
`;

export const ExchangeLoadingIcon = styled.div`
  margin: 0 auto;
  width: 48px;
`;

export const ExchangeLoadingTitle = styled.div`
  width: 72px;

  margin-top: 36px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
`;

export const ExchangeLoadingDescription = styled.div`
  width: 90%;

  margin-bottom: 4px;
  margin-left: auto;
  margin-right: auto;
`;

export const ExchangeLoadingButton = styled.div`
  margin-top: 20px;
`;
