import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import firebase from "@services/firebase";

import {
  ModalContent,
  ModalIcon,
  ModalTitle,
  ModalDescription,
  RefundOptions,
  RefundOption,
  RefundCheckbox,
  RefundCheckboxInner,
  RefundLabel,
  RefundActions,
  GoBackButton,
  GoNext,
} from "./styles";
import ReturnIcon from "@assets/return.svg";

interface ModalReturnOrExchangeProps {
  orderCode: string;
  consigment: Consignment;
  close: () => void;
  openModalExchangeMarketpalceIn: () => void;
}

type RefundType = "return" | "exchange";

interface RefundOption {
  type: RefundType;
  label: string;
}

const refundOptions: RefundOption[] = [
  {
    type: "return",
    label: "Devolução (reembolso)",
  },
  {
    type: "exchange",
    label: "Troca pelo mesmo produto",
  },
];

const ModalReturnOrExchange = ({
  orderCode,
  consigment,
  close,
  openModalExchangeMarketpalceIn,
}: ModalReturnOrExchangeProps) => {
  const history = useHistory();

  const [exchangeCase, setExchangeCase] = useState<boolean>(false);

  const [
    refundTypeSelected,
    setRefundTypeSelected,
  ] = useState<RefundType | null>(null);

  const handleRedirectToReturnOrExchange = () => {
    if (refundTypeSelected === "return") {
      history.push(
        `/atendimento/devolucao/${orderCode}/${consigment.consignmentCode}`
      );
    }

    if (refundTypeSelected === "exchange") {
      if (consigment.type === "MARKETPLACE_IN") {
        close();
        openModalExchangeMarketpalceIn();
      } else {
        history.push(
          `/atendimento/troca/${orderCode}/${consigment.consignmentCode}`
        );
      }
    }
  };

  const verifyIfHasLink = (refundType: RefundType) => {
    switch (refundType) {
      case "exchange":
        return exchangeCase;

      case "return":
        return Boolean(consigment.links.returnConsignment);

      default:
        return false;
    }
  };

  const checkExchangeOption = async () => {
    const remoteConfig = firebase.remoteConfig();

    remoteConfig.settings = {
      fetchTimeoutMillis: 60000,
      minimumFetchIntervalMillis: 3600000,
    };

    try {
      await remoteConfig.fetchAndActivate();

      const allSettings = remoteConfig.getAll();

      const activated = allSettings.activate_button_request_exchange.asBoolean();

      if (activated) {
        setExchangeCase(Boolean(consigment.links.consignmentExchange));
      }
    } catch (error) {
      console.error("Error when remote settings:", error);
    }
  };

  useEffect(() => {
    checkExchangeOption();
  }, []);

  return (
    <ModalContent>
      <ModalIcon>
        <img src={ReturnIcon} alt="" />
      </ModalIcon>

      <ModalTitle>Como deseja atender o cliente?</ModalTitle>

      <ModalDescription>
        Selecione entre devolução (reembolso) ou troca pelo mesmo produto.
      </ModalDescription>

      <RefundOptions>
        {refundOptions.map((refundOption) => {
          if (!verifyIfHasLink(refundOption.type)) {
            return null;
          }

          return (
            <RefundOption
              onClick={() => setRefundTypeSelected(refundOption.type)}
            >
              <RefundCheckbox>
                {refundTypeSelected === refundOption.type && (
                  <RefundCheckboxInner />
                )}
              </RefundCheckbox>

              <RefundLabel>{refundOption.label}</RefundLabel>
            </RefundOption>
          );
        })}
      </RefundOptions>

      <RefundActions>
        <GoBackButton onClick={() => close()}>Voltar</GoBackButton>

        <GoNext
          disabled={!refundTypeSelected}
          onClick={handleRedirectToReturnOrExchange}
        >
          Continuar
        </GoNext>
      </RefundActions>
    </ModalContent>
  );
};

export default ModalReturnOrExchange;
