import styled from "styled-components";

import { ForceInstanceType } from "@pages/force-instance-flow";

import { Column } from "@components/utilities";

type ForceInstanceDetailContentContainerProps = {
  forceInstanceType: string;
};

export const ForceInstanceDetailContentContainer = styled.div<
  ForceInstanceDetailContentContainerProps
>`
  width: ${({ forceInstanceType }: ForceInstanceDetailContentContainerProps) =>
    forceInstanceType === ForceInstanceType.FORCE_DELIVERY_FAILURE_RESEND
      ? "660px"
      : "990px"};
  max-height: 430px;
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .title div {
    font-size: 20px;
    line-height: 23px;
    color: #ff7a14;
    font-weight: 500;
    margin-bottom: auto;
    margin-top: auto;
  }

  .title .button {
    font-family: Ubuntu;
    font-size: 10px;
    font-style: normal;
    font-weight: bold;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: #1e5bc6 !important;
    margin-top: auto;
    margin-bottom: auto;
  }

  .content {
    padding: 0;
    maring: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &-left {
      padding-right: 25px;
      width: 640px;
    }

    &-right {
      width: 300px;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 0 0 25px;
      border-left: 1px solid #f2f2f2;

      .label {
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        color: #353a40;
        padding: 4px 0;
        justify-content: flex-start;
      }
    }
  }

  .payment-group {
    width: 300px;

    &-item {
      padding: 6px 0;
    }

    &-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-decoration-line: underline;
      color: #353a40;
      padding: 4px 0 6px 0;
      text-align: left;
    }

    &-body {
      padding: 0;
    }

    &-subtotal {
      &-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #353a40;
        padding: 5px 0;
      }
    }

    &-total {
      padding: 5px 0;
    }
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface FieldGroupItemContainerProp {
  width?: string;
}

export const FieldGroupItemContainer = styled.div<FieldGroupItemContainerProp>`
  flex-grow: 1;
  width: ${(props: FieldGroupItemContainerProp) => props.width || "33%"};
  margin-bottom: 20px;

  .field-group-item-column-label {
    color: #353a40;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    padding: 4px 0;
  }

  .field-group-item-column-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #353a40;
    padding: 4px 0 8px 0;
  }
`;

export const ConsignmentOptionButton = styled.a`
  background: #ffffff;
  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  color: #1e5bc6;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  :hover {
    cursor: pointer;
  }
`;

export const ConsignmentOptions = styled(Column)`
  align-items: flex-end;
  flex-direction: row;
  margin-left: 20px;
`;
