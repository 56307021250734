import styled from "styled-components";

type TStyleItemsContainer = {
  width?: string;
};

export const ItemsContainer = styled.div<TStyleItemsContainer>`
  width: ${(props: TStyleItemsContainer) => props.width || "68%"};
`;

type TStyleLabel = {
  justify?: string;
};

export const Label = styled.span<TStyleLabel>`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: ${(props: TStyleLabel) => props.justify || "center"};
  text-transform: uppercase;
  color: #b0bec5;
`;

export const ItemHeader = styled.div`
  display: flex;
  margin: 20px 0 0 0;
  align-items: center;
`;

export const ItemList = styled.div`
  border-bottom: 1px solid #eceff1;
`;

export const Item = styled.div`
  display: flex;

  padding: 10px 0;
  align-items: center;
`;

type TStyledP = {
  color?: string;
  margin?: string;
};

export const TextDesc = styled.p<TStyledP>`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: ${(props: TStyledP) => props.margin || "8px 0 0 0"};
  color: ${(props: TStyledP) => props.color || "#37474f"};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
`;

type TStyledImg = {
  src?: string;
};

export const ImgDesc = styled.div<TStyledImg>`
  width: 56px;
  height: 56px;
  margin-right: 8px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  background-image: url(${(props: TStyledImg) => props.src || ""});
  background-size: cover;
  background-repeat: no-repeat;
`;

type TStyledSpan = {
  color?: string;
};

export const TextItem = styled.span<TStyledSpan>`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${(props: TStyledSpan) => props.color || "#546e7a"};
`;

export const Reason = styled.div`
  background: #fcfdff;
  border-radius: 5px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  strong {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #607d8b;
    margin-right: 10px;
  }
  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #37474f;
  }
`;
