// Service to redeem gift card

import contentCard from "./contentCard";

type redeemGiftPayload = {
  orderId: string;
  orderCode: string;
  sequence: string;
  userId: string;
  numId: string;
  giftcardSKU: string;
  emailTo: string;
  productName: string;
  userName: string;
  price: string;
  origem: string;
};

export async function redeemGiftCard(payload: redeemGiftPayload) {
  try {
    const response = await contentCard.post(
      `/v1/firebase/authorization/generateContentCard`,
      payload
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response;
    }

    throw new Error(error);
  }
}
