import React from "react";
import { Label, MultiplasEntregas } from "./styles";

type StatusItem = {
  status: string;
  creationTime: string;
  description: string;
  color: string;
};

const Status = ({ status }: any) => {
  const renderStatus = () => {
    if (status?.length === 1) {
      const item: StatusItem = status[0].status;
      return <Label color={item.color}>{item.description}</Label>;
    } else
      return (
        <MultiplasEntregas>
          Pedido com mutiplas entregas, clique em <br />
          <b> visualizar </b> para ver detalhes
        </MultiplasEntregas>
      );
  };

  return <div>{renderStatus()}</div>;
};

export default Status;
