import React, { useState, useCallback, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "@store/index";
import MoneyIcon from "./icons/money";
import InputMoney from "./inputMoney";

import {
  Br,
  OptionButton,
  OptionsWrapper,
  OptionsWrapperSelected,
  OptionsText,
  OptionRadio,
  OptionContainer,
  OptionsMoney,
  AlertPermission,
  AlertPermissionIco,
  AlertPermissionText,
} from "./style";
import iconAlert from "@assets/exclamation.svg";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";
import { Column } from "@components/utilities";
import {
  setReturnMethod,
  DeliveryModalityType,
} from "@store/refund-items-reducer";
import { AuthState } from "@store/authorities-reducer";

const DeliverySelector = (props: any) => {
  const { consignment } = props;

  const returnMethod = useSelector(
    (state: AppState) => state.returnItems.returnMethod
  );

  const dispatch = useDispatch();

  const isFoodSubdued =
    (consignment?.status?.status == "FPR" &&
      consignment?.delivery?.modality == "DRIVE") ||
    (consignment?.delivery?.modality == "DRIVE" &&
      consignment.consignmentStatus == "FPR");
  const isClickSubdued =
    (consignment?.status?.status == "FPR" &&
      consignment?.delivery?.modality == "CLICK_RETIRE_CD") ||
    (consignment?.delivery?.modality == "CLICK_RETIRE_CD" &&
      consignment.consignmentStatus == "FPR");

  const isSubdued = isFoodSubdued || isClickSubdued;

  const handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReturnMethod(e.target.value as DeliveryModalityType));
    let getRefundMethod = e.target.value;
    let valueTotalRefund = "0";

    if (getRefundMethod === "FREIGHT_REFUND") {
      valueTotalRefund = consignment?.totalFreight;
    } else {
      valueTotalRefund = consignment?.totalValue;
    }
    localStorage.setItem("ValueRefund", valueTotalRefund);
  };

  const TextInforefundMethod =
    "Você pode escolher se prefere o ESTORNO TOTAL, ESTORNO PARCIAL ou ESTORNO DE FRETE. Para o estorno total, o valor a ser restituído é o valor total dos itens selecionados na etapa anterior. Para valor parcial você precisa selecionar o tipo de estorno parcial e digitar o valor em R$.";
  const TextInfoNotAuthorization = `Não é possivel realizar o estorno de frete do pedido n°#${consignment?.consignmentCode}`;
  const saveReturnMethod = returnMethod;
  localStorage.setItem("RefundMethod", saveReturnMethod);

  interface ValorEstorno {
    valorEstorno: string;
  }

  const [valorEstorno, setValorEstorno] = useState<ValorEstorno>(
    {} as ValorEstorno
  );

  const handleChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setValorEstorno({
        ...valorEstorno,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    },
    [valorEstorno]
  );

  const rules = useSelector<AppState, AuthState>((state) => state.rules);

  const getAuthorizedPartialRefund = rules.authorities.find(
    (element: any) => element === "cs-order.refund-partial"
  );

  return (
    <form>
      <ContainerTitle>
        <Title>
          <Badge>2</Badge>Selecione o tipo de estorno
        </Title>
        <Subtitle>
          {(consignment?.totalFreight === 0 || !consignment?.totalFreight) &&
          !getAuthorizedPartialRefund ? (
            <AlertPermission>
              <AlertPermissionIco>
                <img src={iconAlert} alt="iconAlert" />
              </AlertPermissionIco>
              <AlertPermissionText>
                {TextInfoNotAuthorization}
              </AlertPermissionText>
            </AlertPermission>
          ) : (
            <>{TextInforefundMethod}</>
          )}
        </Subtitle>
      </ContainerTitle>
      <Column margin="0 auto">
        <OptionsWrapper onChange={handleDeliveryChange}>
          {getAuthorizedPartialRefund && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`refund-total`}
                name="checklist"
                value={"TOTAL_REFUND"}
                checked={returnMethod === "TOTAL_REFUND"}
              />
              <OptionButton htmlFor={`refund-total`}>
                <MoneyIcon />
                <OptionsText>Estorno total</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}
          {getAuthorizedPartialRefund && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`refund-partial`}
                name="checklist"
                value={"PARTIAL_REFUND"}
                checked={returnMethod === "PARTIAL_REFUND"}
              />

              <OptionButton htmlFor={`refund-partial`}>
                <MoneyIcon />
                <OptionsText>Estorno parcial</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}

          {consignment?.totalFreight > 0 && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`freight-partial`}
                name="checklist"
                value={"FREIGHT_REFUND"}
                checked={returnMethod === "FREIGHT_REFUND"}
              />
              <OptionButton htmlFor={`freight-partial`}>
                <MoneyIcon />
                <OptionsText>Estorno de frete</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}
        </OptionsWrapper>

        {returnMethod === "TOTAL_REFUND" && (
          <OptionsWrapperSelected>
            <OptionsText>
              Você selecionou o estorno total. Esse valor não pode ser alterado.
            </OptionsText>
            <OptionsMoney
              id="refundValue"
              type="string"
              value={new Intl.NumberFormat("pt", {
                style: "currency",
                currency: "BRL",
              }).format(consignment?.totalValue)}
              disabled
            ></OptionsMoney>
          </OptionsWrapperSelected>
        )}

        {returnMethod === "PARTIAL_REFUND" && (
          <OptionsWrapperSelected>
            <OptionsText>
              Você selecionou o estorno parcial. Digite o valor do reembolso.
              <Br />
            </OptionsText>
            <InputMoney
              placeholder="R$ 0,00"
              name="valorEstorno"
              onChange={handleChange}
            ></InputMoney>
          </OptionsWrapperSelected>
        )}

        {returnMethod === "FREIGHT_REFUND" && (
          <OptionsWrapperSelected>
            <OptionsText>
              Você selecionou o estorno de frete. Esse valor não pode ser
              alterado.
            </OptionsText>
            <OptionsMoney
              id="refundValue"
              type="string"
              value={new Intl.NumberFormat("pt", {
                style: "currency",
                currency: "BRL",
              }).format(consignment?.totalFreight)}
              disabled
            ></OptionsMoney>
          </OptionsWrapperSelected>
        )}
      </Column>
    </form>
  );
};

export default DeliverySelector;
