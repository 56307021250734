import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import validator from "cpf-cnpj-validator";
const Joi = require("@hapi/joi").extend(validator);
import {
  cpfMask,
  cnpjMask,
  validateEmail,
  clearDocument,
  validateOrderCode,
} from "@utils/masks";

import {
  DropContainer,
  DropGroup,
  DropButton,
  DropClick,
  DropUl,
  DropLi,
  Input,
  Row,
  Card,
  Margin,
} from "./styles";
import { Button, Alert } from "carrefour-portal-backoffice-style-guide";

const DropDown: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDisplayMenu, setShowDisplayMenu] = useState(false);
  const [searchParam, setSearchParam] = useState("document");
  const [type, setType] = useState("text");
  const [term, setTerm] = useState("");
  const [msgError, setMsgError] = useState("");

  const goToOrder = () => {
    dispatch({ type: "ORDER_SELECTED", payload: term });
    history.push(`/atendimento/pedido/detalhe/${term}`);
  };

  function goToClient() {
    if (searchParam === "document") {
      let termSearch = clearDocument(term);
      dispatch({ type: "SEARCH_TERM", payload: termSearch });
    } else {
      dispatch({ type: "SEARCH_TERM", payload: term });
    }
    history.replace(`atendimento/clientes`);
  }

  function changeInput(value: string) {
    dispatch({ type: "SEARCH_PARAM", payload: searchParam });
    switch (searchParam) {
      case "principalCode":
        setType("text");
        setTerm(value);
        break;
      case "email":
        setType("text");
        setTerm(value);
        break;
      default:
        if (value.length > 14) {
          setTerm(cnpjMask(value));
        } else {
          setTerm(cpfMask(value));
        }
        break;
    }
  }

  function validateInput(event: Event) {
    event.preventDefault();
    switch (searchParam) {
      case "principalCode":
        if (term.length > 0) {
          if (validateOrderCode(term)) {
            goToOrder();
          } else {
            setMsgError("Código do pedido errado");
          }
        } else {
          setMsgError("Número do pedido é obrigatório");
        }
        break;
      case "email":
        if (validateEmail(term)) {
          goToClient();
        } else {
          setMsgError("Digite um e-mail válido");
        }
        break;
      default:
        if (term.length > 14) {
          const cnpjSchema = Joi.document().cnpj();
          const cnpjValid = cnpjSchema.validate(term);
          if (cnpjValid.error) {
            setMsgError("Digite um CNPJ válido");
          } else {
            goToClient();
          }
        } else {
          const cpfSchema = Joi.document().cpf();
          const cpfValid = cpfSchema.validate(term);

          if (cpfValid.error) {
            setMsgError("Digite um CPF válido");
          } else {
            goToClient();
          }
        }
        break;
    }
  }

  useEffect(() => {
    setTerm("");
    setMsgError("");
    switch (searchParam) {
      case "principalCode":
        setType("text");
        break;
      case "email":
        setType("text");
        break;
      default:
        setType("text");
        break;
    }
  }, [searchParam]);

  const renderSwitch = (searchParam: string) => {
    switch (searchParam) {
      case "email":
        return "E-mail";
      case "principalCode":
        return "Pedido";
      default:
        return "CPF";
    }
  };

  return (
    <>
      {msgError ? (
        <Margin>
          <Alert variation="danger" actionLabel="Action" onClick={() => {}}>
            {msgError}
          </Alert>
        </Margin>
      ) : (
        ""
      )}

      <Card>
        <form onSubmit={() => validateInput(event)}>
          <Row>
            <DropContainer>
              <DropGroup error={msgError}>
                <DropButton
                  onClick={() => setShowDisplayMenu(!showDisplayMenu)}
                  data-testid="button-type"
                >
                  {renderSwitch(searchParam)}
                </DropButton>
                {showDisplayMenu ? (
                  <DropUl data-testid="list-type">
                    <DropLi>
                      <DropClick
                        onClick={() => {
                          setSearchParam("document");
                          setShowDisplayMenu(false);
                        }}
                        data-testid="list-type-cpf"
                      >
                        CPF
                      </DropClick>
                    </DropLi>
                    <DropLi>
                      <DropClick
                        onClick={() => {
                          setSearchParam("principalCode");
                          setShowDisplayMenu(false);
                        }}
                        data-testid="list-type-order"
                      >
                        Pedido
                      </DropClick>
                    </DropLi>
                    <DropLi>
                      <DropClick
                        onClick={() => {
                          setSearchParam("email");
                          setShowDisplayMenu(false);
                        }}
                        data-testid="list-type-email"
                      >
                        E-mail
                      </DropClick>
                    </DropLi>
                  </DropUl>
                ) : (
                  ""
                )}
                <Input
                  type={type}
                  aria-label="search-input"
                  placeholder="Digite sua busca aqui..."
                  value={term}
                  onFocus={() => setShowDisplayMenu(false)}
                  onChange={(e: { target: { value: string } }) =>
                    changeInput(e.target.value)
                  }
                />
              </DropGroup>
              <Button
                text="Buscar"
                onClick={(event: Event) => validateInput(event)}
                size="large"
                variation="primary"
              />
            </DropContainer>
          </Row>
        </form>
      </Card>
    </>
  );
};

export default DropDown;
