import React from "react";

import { Column, Row } from "@components/utilities";
import Button from "@components/button";
import { Consignment } from "@pages/order-detail/OrderDetailsResponse";

import { Title, Address } from "./styles";
import { CardModal } from "../styles";

type DeliveryFailureModalProps = {
  consigment: Consignment;
  close: () => void;
  next: () => void;
};

function DeliveryFailureModal(props: DeliveryFailureModalProps) {
  const {
    delivery: {
      shippingAddress: {
        postalCode,
        street,
        number,
        complement,
        neighborhood,
        city,
        state,
      },
    },
  } = props.consigment;
  return (
    <CardModal>
      <Column width={"730px"} margin="0 auto">
        <Title>
          O pedido será reenviado para o endereço da entrega original.
        </Title>
        <Address>
          CEP {postalCode}
          <br />
          {street}, {number} <br />
          {complement}
          <br /> {neighborhood} - {city}-{state}
        </Address>
        <Row>
          <Button
            text="Cancelar"
            variation="primary"
            margin="0 10px 0 0"
            size="medium"
            onClick={props.close}
            outline
          />
          <Button
            text="Prosseguir"
            variation="confirm"
            margin="0 10px 0 0"
            size="medium"
            onClick={props.next}
          />
        </Row>
      </Column>
    </CardModal>
  );
}

export default DeliveryFailureModal;
