import React, { useState, useEffect } from "react";
import Select from "react-select";

import http from "@services/http";

import { Column, selectStyle } from "@components/utilities";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";

const CancelReasons = () => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  async function getReasons() {
    const reasons = await http.get("/v1/orders/cancel/reasons");

    const _option: { value: string; label: string }[] = [];

    reasons.data.map((item: { reasonCode: string; description: string }) => {
      _option.push({ value: item.reasonCode, label: item.description });
    });

    setOptions(_option);
  }

  const handleChange = (selectedOption: {
    value: string;
    description: string;
  }) => {
    setSelectedValue(selectedOption.value);
  };

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>1</Badge>Motivo
        </Title>
        <Subtitle>Selecione um motivo para a ocorrência de transporte</Subtitle>
      </ContainerTitle>
      <Column width="540px" margin="0 auto">
        <Select
          options={options}
          placeholder="Selecione um motivo..."
          onChange={handleChange}
          styles={selectStyle}
        />
      </Column>
    </>
  );
};

export default CancelReasons;
