import React from "react";

const Error = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.0007 53.3333C43.7827 53.3333 53.334 43.782 53.334 31.9999C53.334 20.2178 43.7827 10.6666 32.0007 10.6666C20.2186 10.6666 10.6673 20.2178 10.6673 31.9999C10.6673 43.782 20.2186 53.3333 32.0007 53.3333ZM58.6673 31.9999C58.6673 46.7275 46.7282 58.6666 32.0007 58.6666C17.2731 58.6666 5.33398 46.7275 5.33398 31.9999C5.33398 17.2723 17.2731 5.33325 32.0007 5.33325C46.7282 5.33325 58.6673 17.2723 58.6673 31.9999Z"
        fill="#AA0D11"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.8863 22.1143C42.9277 23.1557 42.9277 24.8441 41.8863 25.8855L25.8863 41.8855C24.8449 42.9269 23.1564 42.9269 22.115 41.8855C21.0736 40.8441 21.0736 39.1557 22.115 38.1143L38.115 22.1143C39.1564 21.0729 40.8449 21.0729 41.8863 22.1143Z"
        fill="#AA0D11"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.115 22.1143C23.1564 21.0729 24.8449 21.0729 25.8863 22.1143L41.8863 38.1143C42.9277 39.1557 42.9277 40.8441 41.8863 41.8855C40.8449 42.9269 39.1564 42.9269 38.115 41.8855L22.115 25.8855C21.0736 24.8441 21.0736 23.1557 22.115 22.1143Z"
        fill="#AA0D11"
      />
    </svg>
  );
};

export default Error;
