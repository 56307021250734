import React from "react";

import { Button } from "carrefour-portal-backoffice-style-guide";
import { Column, Row } from "@components/utilities";

import { CardModal, Title, SubTitle, Buttons } from "./styles";

interface ConfirmResendModalContentProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmResendModalContent: React.FC<ConfirmResendModalContentProps> = (
  props: ConfirmResendModalContentProps
) => {
  return (
    <CardModal>
      <Column width={"700px"} margin="0 auto">
        <Title>Reenvio do Pedido</Title>
        <SubTitle>
          Ao confirmar, a entrega será liberada para que o pedido seja preparado
          e expedido ao cliente. Tem certeza que deseja continuar?{" "}
        </SubTitle>
        <Row>
          <Buttons>
            <Button
              text="Cancelar"
              variation="primary"
              margin="0 10px 0 0"
              size="medium"
              onClick={props.onClose}
              outline
            />

            <Button
              text={"Prosseguir"}
              variation="confirm"
              margin="0 10px 0 0"
              size="medium"
              onClick={props.onConfirm}
            />
          </Buttons>
        </Row>
      </Column>
    </CardModal>
  );
};
