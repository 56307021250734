import React, { Dispatch, useState } from "react";

import StepHeader from "../step-header";
import { CurrentPage } from "../..";
import {
  ReturnMethodWrapper,
  SelectPostageOrCollectDescription,
} from "./styles";
import ReturnMethod from "./return-method";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store";
import { setReturnMethodExchange } from "../../../../store/exchange-reducer";

interface SelectPostageOrCollectProps {
  setCurrentPage: Dispatch<React.SetStateAction<CurrentPage>>;
}

const SelectPostageOrCollect = ({
  setCurrentPage,
}: SelectPostageOrCollectProps) => {
  const selectedReturnMethod = useSelector(
    (appState: AppState) => appState.exchange.returnMethod
  );
  const selectedItems = useSelector(
    (state: AppState) => state.exchange.selectedItems
  );
  const hasHeavyItem = selectedItems.some((item) => item.weight > 30000);
  const totalPrice = selectedItems.reduce(
    (partial, item) => partial + item.price,
    0
  );
  const disableColectMethod = totalPrice < 149;

  const dispatch = useDispatch();

  return (
    <div>
      <StepHeader
        length={4}
        activeStep={3}
        title="Selecione o método de devolução"
        backButtonOnClick={() => {
          setCurrentPage("SELECT_QUANTITY_AND_REASON");
        }}
      />

      <SelectPostageOrCollectDescription>
        Você pode escolher se prefere postar gratuitamente o produto nos
        Correios ou esperar a nossa transportadora retirar o produto.
      </SelectPostageOrCollectDescription>

      <ReturnMethodWrapper>
        {!hasHeavyItem && (
          <ReturnMethod
            returnMethod="POSTAGE"
            title="Postagem"
            description="O cliente é responsável por levar o produto a ser devolvido até a agência dos Correios."
            selectedReturnMethod={selectedReturnMethod}
            onClick={(returnMethod) =>
              dispatch(setReturnMethodExchange(returnMethod))
            }
          />
        )}

        {(!disableColectMethod || hasHeavyItem) && (
          <ReturnMethod
            returnMethod="COLLECT"
            title="Coleta no cliente"
            description="O transportador irá coletar o produto a ser devolvido diretamente no endereço do cliente."
            selectedReturnMethod={selectedReturnMethod}
            onClick={(returnMethod) =>
              dispatch(setReturnMethodExchange(returnMethod))
            }
          />
        )}
      </ReturnMethodWrapper>
    </div>
  );
};

export default SelectPostageOrCollect;
