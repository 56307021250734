import styled from "styled-components";

import imgHash from "@assets/hashtag.svg";
import imgCalendar from "@assets/calendar.svg";
import imgPrice from "@assets/price.svg";

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    background-position: right top;
  }
`;

export const CardContentTotal = styled.div`
  background-image: url(${imgHash});
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardContentUp = styled.div`
  background-image: url(${imgCalendar});
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardContentLast = styled.div`
  background-image: url(${imgPrice});
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  margin: 0px;
  text-transform: uppercase;
  color: #90a4ae;
`;

export const CardText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #b0bec5;
`;

export const UserImg = styled.img`
  margin-right: 22px;
`;

export const UserName = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #546e7a;
  margin: 0px 0px 10px 0px;
`;

export const UserInfo = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #78909c;
  margin: 0px;
`;

export const UserValue = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #546e7a;
  margin: 10px 0 0 0;
`;
