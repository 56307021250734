import React from "react";
import { formatDate, formatDateOnly } from "@utils/formatUtils";
import Skeleton from "react-loading-skeleton";

import { Row } from "@components/utilities";
import {
  Column,
  RowBetween,
  CardContentTotal,
  CardContentUp,
  CardContentDown,
  CardContentLast,
  CardContentHome,
  CardTitle,
  CardText,
  UserImg,
  UserName,
  UserInfo,
  UserValue,
} from "./styles";

import { Card } from "carrefour-portal-backoffice-style-guide";

import { OrderDetailsResponse } from "../../OrderDetailsResponse";
import imgAvatar from "@assets/avatar.svg";

type OrderSummary = {
  data: OrderDetailsResponse;
  customerData: any;
};

const OrderSummary = ({ data, customerData }: OrderSummary) => {
  return (
    <RowBetween>
      {data ? (
        <>
          <Row>
            <UserImg src={imgAvatar} alt="avatar" data-testid="client-img" />
            <div>
              <UserName data-testid="client-name">
                {`${data?.customerFirstName} ${data?.customerLastName}`}
              </UserName>
              <UserInfo data-testid="client-date">
                Data de nascimento:{" "}
                {customerData ? (
                  formatDateOnly(customerData?.birthDate)
                ) : (
                  <Skeleton width={80} height={17} />
                )}
              </UserInfo>
              <UserInfo data-testid="client-email">
                E-mail: {data?.customerEmail}
              </UserInfo>
              <UserInfo data-testid="client-cpf">
                CPF: {data?.customerDocument}
              </UserInfo>
              <br />
              <UserInfo>Telefone Principal: {data?.customerPhone}</UserInfo>
            </div>
          </Row>
          <Column>
            <Row>
              <Card
                margin="20px 0px 0px 10px"
                padding="20px"
                width="140px"
                height="auto"
              >
                <CardContentTotal>
                  <CardTitle>PEDIDO</CardTitle>
                  <div>
                    <CardText>Pedido {data?.originApplication}</CardText>
                    <UserValue>{data?.orderCode}</UserValue>
                  </div>
                </CardContentTotal>
              </Card>
              <Card
                margin="20px 0px 0px 10px"
                padding="20px"
                width="140px"
                height="auto"
              >
                <CardContentUp>
                  <CardTitle>
                    CRIAÇÃO DO <br />
                    PEDIDO
                  </CardTitle>
                  <UserValue>{formatDate(data?.creationDate)}</UserValue>
                </CardContentUp>
              </Card>
              <Card
                margin="20px 0px 0px 10px"
                padding="20px"
                width="140px"
                height="auto"
              >
                <CardContentDown>
                  <CardTitle>
                    CANAL DE <br />
                    VENDAS
                  </CardTitle>
                  <CardText>{data?.callCenterOperatorUserName}</CardText>
                  <UserValue>{data?.salesChannel}</UserValue>
                </CardContentDown>
              </Card>
              <Card
                margin="20px 0px 0px 10px"
                padding="20px"
                width="140px"
                height="auto"
              >
                <CardContentLast>
                  <CardTitle>
                    QUANTIDADE DE <br />
                    ENTREGAS
                  </CardTitle>
                  <UserValue>
                    {data?.consignments.length > 1
                      ? `${data?.consignments.length} entregas`
                      : `${data?.consignments.length} entrega`}
                  </UserValue>
                </CardContentLast>
              </Card>
            </Row>
            <Row justify="flex-start">
              <Card
                margin="10px 0px 0px 10px"
                padding="20px"
                width="200px"
                height="auto"
              >
                <CardContentHome>
                  <CardTitle>ENDEREÇO DE FATURA</CardTitle>
                  <CardText color="#546E7A">
                    CEP: {data?.consignments[0].invoiceAddress.postalCode}
                  </CardText>
                  <CardText color="#546E7A">
                    {data?.consignments[0].invoiceAddress.street}{" "}
                    {data?.consignments[0].invoiceAddress.number}
                  </CardText>
                  <CardText color="#546E7A">
                    {data?.consignments[0].invoiceAddress.neighborhood}{" "}
                    {data?.consignments[0].invoiceAddress.city}
                  </CardText>
                </CardContentHome>
              </Card>
            </Row>
          </Column>
        </>
      ) : (
        <>
          <Row>
            <Skeleton circle={true} height={75} width={75} />
            <div>
              <div style={{ marginTop: 20, marginLeft: 30 }}>
                <Skeleton width={240} height={112} />
              </div>
            </div>
          </Row>
          <Column>
            <Row>
              <div style={{ marginTop: 20, marginLeft: 30 }}>
                <Skeleton width={160} height={112} />
              </div>
              <div style={{ marginTop: 20, marginLeft: 30 }}>
                <Skeleton width={160} height={112} />
              </div>
              <div style={{ marginTop: 20, marginLeft: 30 }}>
                <Skeleton width={160} height={112} />
              </div>
              <div style={{ marginTop: 20, marginLeft: 30 }}>
                <Skeleton width={160} height={112} />
              </div>
            </Row>
            <Row justify="flex-start">
              <div style={{ marginTop: 20, marginLeft: 30 }}>
                <Skeleton width={240} height={112} />
              </div>
            </Row>
          </Column>
        </>
      )}
    </RowBetween>
  );
};

export default OrderSummary;
