// ADD SUFIX "EXCHANGE" TO SEPARATE OTHERS REDUCERS

import { Reason } from "../pages/exchange-flow/components/select-quantity-and-reason";
import {
  Item,
  OrderDetailsResponse,
} from "../pages/order-detail/OrderDetailsResponse";
import http from "../services/http";
import { requestFetchOrderDetails } from "./return-items-reducer";

const FETCH_ORDER_EXCHANGE = "FETCH_ORDER_EXCHANGE";
const SET_CONSINGMENT_TO_EXCHANGE = "SET_CONSINGMENT_TO_EXCHANGE";
const CLEAR_STATE_EXCHANGE = "CLEAR_STATE_EXCHANGE";
const ADD_ITEM_TO_EXCHANGE = "ADD_ITEM_TO_EXCHANGE";
const REMOVE_ITEM_TO_EXCHANGE = "REMOVE_ITEM_TO_EXCHANGE";
const CHANGE_ITEM_REASON_EXCHANGE = "CHANGE_ITEM_REASON_EXCHANGE";
const CHANGE_ITEM_QUANTITY_EXCHANGE = "CHANGE_ITEM_QUANTITY_EXCHANGE";
const CHANGE_ITEM_CHECKLIST_EXCHANGE = "CHANGE_ITEM_CHECKLIST_EXCHANGE";
const CHANGE_ITEM_AVAILABILITY_EXCHANGE = "CHANGE_ITEM_AVAILABILITY_EXCHANGE";
const CHANGE_ITEM_AVAILABILITY_LOADING_EXCHANGE =
  "CHANGE_ITEM_AVAILABILITY_LOADING_EXCHANGE";
const CHANGE_ITEM_OBSERVATION_EXCHANGE = "CHANGE_ITEM_OBSERVATION_EXCHANGE";
const CHANGE_RETURN_METHOD_EXCHANGE = "CHANGE_RETURN_METHOD_EXCHANGE";

// TODO: type payload
type Action = {
  type: ActionType;
  payload: any;
};

type ActionType =
  | typeof FETCH_ORDER_EXCHANGE
  | typeof SET_CONSINGMENT_TO_EXCHANGE
  | typeof CLEAR_STATE_EXCHANGE
  | typeof ADD_ITEM_TO_EXCHANGE
  | typeof REMOVE_ITEM_TO_EXCHANGE
  | typeof CHANGE_ITEM_REASON_EXCHANGE
  | typeof CHANGE_ITEM_QUANTITY_EXCHANGE
  | typeof CHANGE_ITEM_CHECKLIST_EXCHANGE
  | typeof CHANGE_ITEM_AVAILABILITY_EXCHANGE
  | typeof CHANGE_ITEM_AVAILABILITY_LOADING_EXCHANGE
  | typeof CHANGE_ITEM_OBSERVATION_EXCHANGE
  | typeof CHANGE_RETURN_METHOD_EXCHANGE;

export interface SelectedItem extends Item {
  exchange?: SelectedItemExchange;
}

interface SelectedItemExchange {
  quantity: SelectedItemQuantity;
  reason: Reason;
  checklist: string[];
  isAvailable: boolean;
  isAvailabilityLoading: boolean;
  observation: string;
}

interface SelectedItemQuantity {
  label: string;
  value: number;
}

interface ExchangeState {
  selectedConsignmentToExchange: string;
  orderToExchange: OrderDetailsResponse;
  selectedItems: SelectedItem[];
  returnMethod: ReturnMethod | null;
}

export type ReturnMethod = "POSTAGE" | "COLLECT";

// INITIAL STATE

const initialState: ExchangeState = {
  selectedConsignmentToExchange: "",
  orderToExchange: null,
  selectedItems: [],
  returnMethod: null,
};

// SET FUNCTIONS

export function setConsingmentToExchange(payload: any) {
  return {
    type: SET_CONSINGMENT_TO_EXCHANGE,
    payload,
  };
}

export function setClearStateExchange() {
  return {
    type: CLEAR_STATE_EXCHANGE,
    payload: {},
  };
}

export function setFetchOrder(orderDetails: OrderDetailsResponse) {
  return {
    type: FETCH_ORDER_EXCHANGE,
    payload: orderDetails,
  };
}

export function setAddItemToExchange(selectedItem: Item) {
  return {
    type: ADD_ITEM_TO_EXCHANGE,
    payload: selectedItem,
  };
}

export function setRemoveItemToExchange(vtexItemId: string) {
  return {
    type: REMOVE_ITEM_TO_EXCHANGE,
    payload: vtexItemId,
  };
}

export function setChangeItemReasonExchange(
  vtexItemId: string,
  reason: Reason
) {
  return {
    type: CHANGE_ITEM_REASON_EXCHANGE,
    payload: {
      vtexItemId,
      reason,
    },
  };
}

export function setChangeItemChecklistExchange(
  vtexItemId: string,
  checklist: string[]
) {
  return {
    type: CHANGE_ITEM_CHECKLIST_EXCHANGE,
    payload: {
      vtexItemId,
      checklist,
    },
  };
}

export function setChangeItemQuantityExchange(
  vtexItemId: string,
  quantity: SelectedItemQuantity
) {
  return {
    type: CHANGE_ITEM_QUANTITY_EXCHANGE,
    payload: {
      vtexItemId,
      quantity,
    },
  };
}

export function setChangeItemAvailabilityLoadingExchange(
  vtexItemId: string,
  isAvailabilityLoading: boolean
) {
  return {
    type: CHANGE_ITEM_AVAILABILITY_LOADING_EXCHANGE,
    payload: {
      vtexItemId,
      isAvailabilityLoading,
    },
  };
}

export function setChangeItemAvailabilityToExchange(
  vtexItemId: string,
  isAvailable: boolean
) {
  return {
    type: CHANGE_ITEM_AVAILABILITY_EXCHANGE,
    payload: {
      vtexItemId,
      isAvailable,
    },
  };
}

export function setChangeItemObservationExchange(
  vtexItemId: string,
  observation: string
) {
  return {
    type: CHANGE_ITEM_OBSERVATION_EXCHANGE,
    payload: {
      vtexItemId,
      observation,
    },
  };
}

export function setReturnMethodExchange(returnMethod: ReturnMethod) {
  return {
    type: CHANGE_RETURN_METHOD_EXCHANGE,
    payload: {
      returnMethod,
    },
  };
}

// HELPERS

interface ItemAvailability {
  id: string;
  quantity: number;
}

interface RequestExchangeData {
  resendOrderVM: ResendOrderVMInput;
  returnRequestVM: ReturnRequestVMInput;
}

export interface ResendOrderVMInput {
  items: ResendItemsInput[];
  shipping: ResendShippingInput;
  customer: ResendCustomerInput;
}

export interface ResendItemsInput {
  vtexItemId: string;
  quantity: number;
  price: string;
  basePrice: string;
  seller: string;
  reason: string;
  observation: string;
}
export interface ResendShippingInput {
  logistic: ResendLogisticInput;
  address: Address;
}
export interface ResendLogisticInput {
  selectedSla: string;
  shippingEstimate: string;
  deliveryChannel: string;
  freightValue: number;
}

export interface ResendCustomerInput {
  firstName: string;
  lastName: string;
  documentType: string;
  document: string;
  phone: string;
  email: string;
}

export interface ReturnRequestVMInput {
  returnItems: ReturnItem[];
  refund: ReturnRefundVMInput;
  returnModality: string;
  attendanceChannel: number;
  customerEmail: string;
}

export interface ReturnItem {
  itemCode: string;
  quantity: number;
  evaluation: {
    reason: string;
    checklist: string[];
    observation: string;
  };
}

export interface Address {
  addressType: string;
  postalCode: string;
  state: string;
  neighborhood: string;
  number?: string;
  country: string;
  street: string;
  city: string;
  receiverName?: string;
  externalAddressId?: string;
}

export interface ReturnRefundVMInput {
  type: String;
}

export function fetchExchangeOrderDetails(orderCode: string) {
  return async (dispatch: any) => {
    try {
      const { data } = await requestFetchOrderDetails(orderCode);

      dispatch(setFetchOrder(data));
    } catch (e) {
      console.error(e);
    }
  };
}

export async function requestFetchItemsAvailability(
  items: ItemAvailability[],
  orderCode: string,
  consignmentCode: string
) {
  return await http.post(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/exchange/availability`,
    {
      items,
    }
  );
}

export async function requestExchangeItems(
  url: string,
  data: RequestExchangeData
) {
  return await http.put(url, data, {
    params: {
      viewType: "PORTAL_BACKOFFICE",
    },
  });
}

function updateSelectedItemField(
  state: ExchangeState,
  action: Action,
  fieldToUpdate: string
) {
  const { vtexItemId } = action.payload;

  const updatedSelectedItems = state.selectedItems.map((selectedItem) => {
    if (selectedItem.vtexItemId === vtexItemId) {
      return {
        ...selectedItem,
        exchange: {
          ...selectedItem.exchange,
          [fieldToUpdate]: action.payload[fieldToUpdate],
        },
      };
    }
    return selectedItem;
  });

  return {
    ...state,
    selectedItems: updatedSelectedItems,
  };
}

// REDUCER

export function exchangeReducer(
  state = initialState,
  action: Action
): ExchangeState {
  switch (action.type) {
    case SET_CONSINGMENT_TO_EXCHANGE: {
      return {
        ...state,
        selectedConsignmentToExchange: action.payload,
      };
    }

    case FETCH_ORDER_EXCHANGE: {
      return {
        ...state,
        orderToExchange: action.payload,
      };
    }

    case ADD_ITEM_TO_EXCHANGE: {
      return {
        ...state,
        selectedItems: [
          ...state.selectedItems,
          {
            ...action.payload,
            exchange: {
              reason: "",
              quantity: 0,
              checklist: [],
              isAvailable: true,
              isAvailabilityLoading: false,
              observation: "",
            },
          },
        ],
      };
    }

    case REMOVE_ITEM_TO_EXCHANGE: {
      return {
        ...state,
        selectedItems: state.selectedItems.filter(
          (selectedItem) => selectedItem.vtexItemId !== action.payload
        ),
      };
    }

    case CHANGE_ITEM_REASON_EXCHANGE: {
      return updateSelectedItemField(state, action, "reason");
    }

    case CHANGE_ITEM_QUANTITY_EXCHANGE: {
      return updateSelectedItemField(state, action, "quantity");
    }

    case CHANGE_ITEM_AVAILABILITY_EXCHANGE: {
      return updateSelectedItemField(state, action, "isAvailable");
    }

    case CHANGE_ITEM_AVAILABILITY_LOADING_EXCHANGE: {
      return updateSelectedItemField(state, action, "isAvailabilityLoading");
    }

    case CHANGE_ITEM_CHECKLIST_EXCHANGE: {
      console.log(state);
      return updateSelectedItemField(state, action, "checklist");
    }

    case CHANGE_ITEM_OBSERVATION_EXCHANGE: {
      return updateSelectedItemField(state, action, "observation");
    }

    case CHANGE_RETURN_METHOD_EXCHANGE: {
      return {
        ...state,
        returnMethod: action.payload.returnMethod,
      };
    }

    case CLEAR_STATE_EXCHANGE: {
      return initialState;
    }

    default:
      return state;
  }
}
