import React from "react";

const TruckIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="truck-icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.334 2.5H0.833984V13.3333H13.334V2.5Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.334 6.66669H16.6673L19.1673 9.16669V13.3334H13.334V6.66669Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.58333 17.5C5.73393 17.5 6.66667 16.5672 6.66667 15.4166C6.66667 14.2661 5.73393 13.3333 4.58333 13.3333C3.43274 13.3333 2.5 14.2661 2.5 15.4166C2.5 16.5672 3.43274 17.5 4.58333 17.5Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4173 17.5C16.5679 17.5 17.5007 16.5672 17.5007 15.4166C17.5007 14.2661 16.5679 13.3333 15.4173 13.3333C14.2667 13.3333 13.334 14.2661 13.334 15.4166C13.334 16.5672 14.2667 17.5 15.4173 17.5Z"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TruckIcon;
