// @ts-nocheck
import http from "@services/http";
import { AppState } from "./index";
import moment from "moment";

const FETCH_ORDER = "FETCH_ORDER";
const FETCH_AVAILABILITY = "FETCH_AVAILABILITY";
const SET_RETURN_ITEM = "SET_RETURN_ITEM";
const SET_RETURN_ITEMS = "SET_RETURN_ITEMS";
const REMOVE_RETURN_ITEM = "REMOVE_RETURN_ITEM";
const SET_RETURN_METHOD = "SET_RETURN_METHOD";
const SET_REFUND_METHOD = "SET_REFUND_METHOD";
const SET_REFUND_INFO = "SET_REFUND_INFO";
const SET_BANK_INFO = "SET_BANK_INFO";
const REQUEST_ORDER_RETURN = "REQUEST_ORDER_RETURN";
const REQUEST_ORDER_RESEND = "REQUEST_ORDER_RESEND";
const CLEAR_STATE = "CLEAR_STATE";
const SET_HAS_RETURN_REASON = "SET_HAS_RETURN_REASON";
const UPDATE_RETURN_STATE = "UPDATE_RETURN_STATE";
const SET_FLOW_TYPE = "SET_FLOW_TYPE";
const SET_RESEND_ITEMS = "SET_RESEND_ITEMS";
const SET_CONSINGMENT = "SET_CONSINGMENT";
const SET_RESEND_STOCK = "SET_RESEND_STOCK";
const SET_RESEND_DATE = "SET_RESEND_DATE";

type CurrentProductType = {
  productValue?: string;
  quantity?: string;
  returnReason?: string;
  checkListOption?: string;
  returnNote?: string;
};

export type availabilityType = {
  items?: any;
  slas?: {
    deliveryChannel: string;
    id: string;
    name: string;
    freightValue: string | number;
    deliveryWindows: {
      endDate: string;
      startDate: string;
    }[];
  }[];
};

export type DeliveryModalityType =
  | "POSTAGE"
  | "COLLECT"
  | "COLLECT_IN_STORE"
  | "RESEND"
  | "REINTEGRATE_STOCK"
  | "NO_RETURN"
  | "TOTAL_REFUND"
  | "PARTIAL_REFUND"
  | "FREIGHT_REFUND";

type ActionType =
  | typeof FETCH_ORDER
  | typeof FETCH_AVAILABILITY
  | typeof SET_RETURN_ITEM
  | typeof SET_RETURN_ITEMS
  | typeof SET_RETURN_METHOD
  | typeof SET_REFUND_METHOD
  | typeof SET_RESEND_DATE
  | typeof SET_BANK_INFO
  | typeof SET_REFUND_INFO
  | typeof CLEAR_STATE
  | typeof SET_CONSINGMENT
  | typeof SET_FLOW_TYPE
  | typeof UPDATE_RETURN_STATE
  | typeof REMOVE_RETURN_ITEM
  | typeof SET_HAS_RETURN_REASON
  | typeof SET_CANCEL_REASON
  | typeof SET_RESEND_ITEMS
  | typeof SET_RESEND_STOCK
  | typeof REQUEST_ORDER_RETURN
  | typeof REQUEST_ORDER_RESEND;

type Action = {
  type: ActionType;
  payload: ReturnItemsState;
};

export interface ReturnItemsState {
  consignmentsItems?: string;
  returnItems?: CurrentProductType;
  returnMethod?: DeliveryModalityType;
  orderDetails?: any;
  refundMethod?: any;
  refundInfo?: any;
  bankInfo?: any;
  requestReturnState: any;
  hasReturnReason: boolean;
  flowType: string;
  availabilityInfo: availabilityType;
  resendItems: any;
  resendDate: any;
  hasResendStock: boolean;
}

const initialState: ReturnItemsState = {
  consignmentCode: null,
  returnItems: null,
  returnMethod: null,
  refundMethod: null,
  refundInfo: null,
  bankInfo: null,
  requestReturnState: null,
  orderDetails: null,
  hasReturnReason: false,
  flowType: "PARTIAL_REFUND" || "TOTAL_REFUND" || "FREIGHT_REFUND",
  availabilityInfo: {},
  resendItems: [],
  resendDate: {},
  hasResendStock: false,
};

export function setHasReturnReason(payload: any) {
  return {
    type: SET_HAS_RETURN_REASON,
    payload,
  };
}

export function removeReturnItem(payload: string | Number) {
  return {
    type: REMOVE_RETURN_ITEM,
    payload,
  };
}

export function setBankInfo(payload: any) {
  return {
    type: SET_BANK_INFO,
    payload,
  };
}

export function setResendStock(payload: any) {
  return {
    type: SET_RESEND_STOCK,
    payload,
  };
}

export function setConsingmentCode(payload: any) {
  return {
    type: SET_CONSINGMENT,
    payload,
  };
}

export function setRefundMethod(payload: any) {
  return {
    type: SET_REFUND_METHOD,
    payload,
  };
}

export function setRefundInfo(payload: any) {
  return {
    type: SET_REFUND_INFO,
    payload,
  };
}

export function setFlowType(payload: any) {
  return {
    type: SET_FLOW_TYPE,
    payload,
  };
}

export function setReturnMethod(payload: DeliveryModalityType) {
  return {
    type: SET_RETURN_METHOD,
    payload,
  };
}

export function setReturnItem(payload: any) {
  return {
    type: SET_RETURN_ITEM,
    payload,
  };
}

export function setReturnItems(payload: any) {
  return {
    type: SET_RETURN_ITEMS,
    payload,
  };
}

export function setResendItems(payload: any) {
  return {
    type: SET_RESEND_ITEMS,
    payload,
  };
}

export function setResendDate(payload: any) {
  return {
    type: SET_RESEND_DATE,
    payload,
  };
}

function fetchAvailability(payload: any) {
  return {
    type: FETCH_AVAILABILITY,
    payload,
  };
}

export function fetchOrder(payload: any) {
  return {
    type: FETCH_ORDER,
    payload,
  };
}

function updateReturnState(payload: any) {
  return {
    type: UPDATE_RETURN_STATE,
    payload,
  };
}

export function clearState() {
  return {
    type: CLEAR_STATE,
    payload: {},
  };
}

async function requestFetchOrderDetails(orderId: string) {
  return await http.get(`/v1/orders/${orderId}`, {
    params: {
      viewType: "PORTAL_BACKOFFICE",
    },
  });
}

async function requestPostOrderResend(data: any, orderCode, consignmentCode) {
  return await http.put(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/resend`,
    data,
    {
      params: {
        viewType: "PORTAL_BACKOFFICE",
      },
    }
  );
}

async function requestPostOrderReturn(data: any, orderCode, consignmentCode) {
  return await http.put(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/partial-refund`,
    data
  );
}

async function requestPostOrderRefund(data: any, orderCode, consignmentCode) {
  return await http.put(
    `/v1/orders/${orderCode}/consignments/${consignmentCode}/refund`,
    data,
    {
      params: {
        viewType: "PORTAL_BACKOFFICE",
      },
    }
  );
}

export function fetchOrderDetails(orderId: string) {
  return async (dispatch: any) => {
    try {
      const { data } = await requestFetchOrderDetails(orderId);
      dispatch(fetchOrder(data));
    } catch (e) {
      console.error(e);
    }
  };
}

export function requestOrderReturn() {
  return async (dispatch: any, getState) => {
    dispatch(updateReturnState("LOADING"));
    try {
      const { returnItems } = getState();
      const {
        orderDetails,
        returnItems: returnItemsObj,
        refundMethod,
        bankInfo,
        returnMethod,
        consignmentCode,
        hasReturnReason,
      } = returnItems;

      const consignment = orderDetails.consignments.find(
        (c) => c.consignmentCode === consignmentCode
      );

      const returnItemVMList = returnItemsObj.map((item) => {
        let getDiagnosticName = localStorage.getItem("diagnosticName");
        getDiagnosticName = getDiagnosticName.replace(/[\\"]/g, "");
        getDiagnosticName = getDiagnosticName.replace(/[\""]/g, "");

        if (item.checkListOption) {
          evaluation.checklist = [item.checkListOption];
        }

        if (bankInfo) {
          return {
            bankAccount: {
              account: bankInfo.account,
              bank: bankInfo.bank,
              branch: bankInfo.branch,
              digit: bankInfo.digit,
              type: bankInfo.paymentAccount,
            },
            checkList: getDiagnosticName,
            observation: item.returnNote,
            totalValue: localStorage.getItem("ValueRefund"),
            type: refundMethod,
          };
        } else {
          return {
            bankAccount: {
              account: "",
              bank: "",
              branch: "",
              digit: "",
              type: "",
            },
            checkList: getDiagnosticName,
            observation: item.returnNote,
            totalValue: localStorage.getItem("ValueRefund"),
            type: refundMethod,
          };
        }
      });

      if (hasReturnReason) {
        const { data } = await requestPostOrderReturn(
          returnItemVMList[0],
          orderDetails.orderCode,
          consignmentCode
        );
      } else {
        const { data } = await requestPostOrderRefund(
          returnItemVMList[0],
          orderDetails.orderCode,
          consignmentCode
        );
      }
      // localStorage.setItem("RefundMethod", "");
      localStorage.setItem("diagnosticName", "");
      localStorage.setItem("localStorageRefundInfo", "");
      localStorage.setItem("observation", "");

      dispatch(updateReturnState("SUCCESS"));
    } catch (e) {
      console.error(e);
      dispatch(updateReturnState("ERROR"));
    }
  };
}

export function returnItemsReducer(
  state = initialState,
  action: Action
): ReturnItemsState {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case SET_RETURN_ITEM: {
      const returnItems = [...state.returnItems, action.payload];
      return {
        ...state,
        returnItems,
      };
    }
    case SET_FLOW_TYPE: {
      return {
        ...state,
        flowType: action.payload,
      };
    }
    case SET_RETURN_ITEMS: {
      return {
        ...state,
        returnItems: action.payload,
      };
    }
    case SET_HAS_RETURN_REASON: {
      return {
        ...state,
        hasReturnReason: action.payload,
      };
    }
    case REMOVE_RETURN_ITEM: {
      const returnItems = state.returnItems.filter(
        (i) => i.productValue != action.payload
      );
      return {
        ...state,
        returnItems,
      };
    }
    case FETCH_AVAILABILITY: {
      return {
        ...state,
        availabilityInfo: action.payload,
      };
    }
    case SET_RESEND_ITEMS: {
      return {
        ...state,
        resendItems: action.payload,
      };
    }
    case SET_RESEND_DATE: {
      return {
        ...state,
        resendDate: action.payload,
      };
    }
    case SET_RESEND_STOCK: {
      return {
        ...state,
        hasResendStock: action.payload,
      };
    }
    case SET_RETURN_METHOD: {
      return {
        ...state,
        returnMethod: action.payload,
      };
    }
    case SET_CONSINGMENT: {
      return {
        ...state,
        consignmentCode: action.payload,
      };
    }
    case SET_REFUND_METHOD: {
      return {
        ...state,
        refundMethod: action.payload,
      };
    }
    case UPDATE_RETURN_STATE: {
      return {
        ...state,
        requestReturnState: action.payload,
      };
    }
    case SET_REFUND_INFO: {
      return {
        ...state,
        refundInfo: action.payload,
      };
    }
    case SET_BANK_INFO: {
      return {
        ...state,
        bankInfo: action.payload,
      };
    }
    case REQUEST_ORDER_RETURN: {
      return initialState;
    }
    case CLEAR_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}
