export const formatMoney = (money: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(money);
};

import moment from "moment-timezone";

// eslint-disable-next-line
moment.tz.add(
  "America/Sao_Paulo|LMT -03 -02|36.s 30 20|01212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2glwR.w HdKR.w 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 pTd0 PX0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 1EN0 Lz0 1C10 IL0 1HB0 Db0 1HB0 On0 1zd0 On0 1zd0 Lz0 1zd0 Rb0 1wN0 Wn0 1tB0 Rb0 1tB0 WL0 1tB0 Rb0 1zd0 On0 1HB0 FX0 1C10 Lz0 1Ip0 HX0 1zd0 On0 1HB0 IL0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1zd0 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1HB0 FX0|20e6"
);

export const formatDate = (date: string) => {
  const adaptedDate = date?.replace("[UTC]", "");
  return moment(adaptedDate).tz("America/Sao_Paulo").format("DD/MM/YYYY HH:mm");
};

export const estimateTime = (estimate: string) => {
  if (estimate.includes("h")) {
    return `${estimate.replace("h", "")} horas`;
  }

  return `${estimate.replace("bd", "")} dias úteis`;
};

export const modalityFormat = (date: string, modality: string) => {
  const adaptedDate = date?.replace("[UTC]", "");
  switch (modality) {
    case "CLICK_RETIRE_CD":
      return moment(adaptedDate).tz("America/Sao_Paulo").format("DD/MM/YYYY");
    case "HOME_DELIVERY":
      return moment(adaptedDate).tz("America/Sao_Paulo").format("DD/MM/YYYY");
    case "MARKETPLACE":
      return moment(adaptedDate).tz("America/Sao_Paulo").format("DD/MM/YYYY");
    case "COLLECT":
      return moment(adaptedDate).tz("America/Sao_Paulo").format("DD/MM/YYYY");
    case "CLICK_RETIRE_LOJA":
      return moment(adaptedDate).tz("America/Sao_Paulo").format("DD/MM/YYYY");
    default:
      return "-";
  }
};

const formatHour = (date: string) => {
  const adaptedDate = date?.replace("[UTC]", "");
  return moment(adaptedDate).tz("America/Sao_Paulo").format("HH:mm");
};

export const formatDateOnly = (date: string) => {
  const adaptedDate = date?.replace("[UTC]", "");
  return moment(adaptedDate).tz("America/Sao_Paulo").format("DD/MM/YYYY");
};

export const rangeFormat = (startDate: string, endDate: string) => {
  const adaptStart = startDate?.replace("[UTC]", "");
  const adaptEnd = endDate?.replace("[UTC]", "");
  return `${moment(adaptStart)
    .tz("America/Sao_Paulo")
    .format("DD/MM/YYYY")} - Entre ${formatHour(adaptStart)} e ${formatHour(
    adaptEnd
  )}`;
};

export const addDaysFormat = (startDate: string, quantity: number) => {
  const start = moment(startDate?.replace("[UTC]", ""));

  const date = start
    .add(quantity, "days")
    .tz("America/Sao_Paulo")
    .format("DD/MM/YYYY HH:mm");

  return date;
};
