import styled from "styled-components";

export const Content = styled.div`
  display: flow-root;
`;

export const Container = styled.div`
  max-width: 1112px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
`;

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 50px;
`;

export const Column = styled.div<{
  width?: string;
  margin?: string;
  textAlign?: string;
  justify?: string;
  align?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.margin || "0px"};
  width: ${(props) => props.width || "auto"};
  text-align: ${(props) => props.textAlign || "auto"};
  justify-content: ${(props) => props.justify || ""};
  align-items: ${(props) => props.align || ""};
`;

export const Row = styled.div<{
  width?: string;
  height?: string;
  justify?: string;
  margin?: string;
  borderBottom?: string;
  align?: string;
}>`
  flex-direction: row;
  @media only screen and (max-width: 760px) {
    flex-direction: column;
    align-items: center;
  }
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  display: flex;
  justify-content: ${(props) => props.justify || "center"};
  margin: ${(props) => props.margin || "0px"};
  border-bottom: ${(props) => props.borderBottom || ""};
  align-items: ${(props) => props.align || ""};
`;

export const TitleReport = styled.h3`
  width: 100%;
  padding: 13px 0;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #37474f;
  border-bottom: 1px solid #eceff1;
  margin: 0;
`;
export const RowBetween = styled.div<{
  padding?: string;
  margin?: string;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: ${(props) => props.padding || ""};
  margin: ${(props) => props.margin || ""};

  * {
    background-position: right top;
  }
`;

export const selectStyle = {
  control: (provided: any) => ({
    ...provided,
    cursor: "pointer",
  }),
  option: (provided: any) => ({
    ...provided,
    cursor: "pointer",
  }),
};
