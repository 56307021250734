import React from "react";
import {
  ProductWrapper,
  TextProductWrapper,
  ProductTitle,
  ProductCode,
} from "./style";

type Props = {
  productTitle: string;
  productCode: string;
  imageUrl: string;
};

const ProductItem = (props: Props) => {
  return (
    <ProductWrapper>
      <img src={props.imageUrl} alt="img" />
      <TextProductWrapper>
        <ProductTitle>{props.productTitle}</ProductTitle>
        <ProductCode>{props.productCode}</ProductCode>
      </TextProductWrapper>
    </ProductWrapper>
  );
};

export default ProductItem;
