import React, { ReactNode } from "react";
import {
  ModalWrapper,
  ModalWrapperOverlay,
  ModalWrapperContent,
} from "./styles";

interface Props {
  children?: ReactNode;
  show: boolean;
  onClose: () => void;
}

const CustomModalWrapper = ({ children, onClose, show }: Props) => {
  if (!show) {
    return null;
  }

  return (
    <ModalWrapper>
      <ModalWrapperOverlay onClick={onClose} />

      <ModalWrapperContent>{children}</ModalWrapperContent>
    </ModalWrapper>
  );
};

export default CustomModalWrapper;
