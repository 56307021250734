import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store";
import { CancelState } from "@store/cancellation-reducer";
import { OrderState } from "@store/order-reducer";

import Payments from "../../payments";
import {
  SmallText,
  TitleAlert,
  ContainerPaymentMethod,
  InfosPaymentMethod,
  Subtitle,
  InfoTotalRefund,
} from "../styles";

type ModalContentProps = {
  refundMethod: string;
  totalValue: number;
};

const ModalContent = ({ refundMethod, totalValue }: ModalContentProps) => {
  const { paymentMethods } = useSelector<AppState, CancelState>(
    (state) => state.cancel
  );

  const { selectedConsignment } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  switch (refundMethod) {
    case "ACCOUNT_DEPOSIT":
      return (
        <>
          <TitleAlert>Reembolso em conta</TitleAlert>
          <Subtitle>
            Total do reembolso:{" "}
            <span>
              {new Intl.NumberFormat("pt", {
                style: "currency",
                currency: "BRL",
              }).format(totalValue)}
            </span>
          </Subtitle>
        </>
      );
    case "GIFT_CARD":
      return (
        <>
          <TitleAlert>Vale-troca</TitleAlert>
          <SmallText>
            Um crédito será liberado para utilização em novas compras no site
            Carrefour
          </SmallText>
          <Subtitle>
            Vale-troca no valor de:{" "}
            <span>
              {new Intl.NumberFormat("pt", {
                style: "currency",
                currency: "BRL",
              }).format(totalValue)}
            </span>
          </Subtitle>
        </>
      );
    case "PAYMENT_ORDER":
      return (
        <>
          <TitleAlert>Ordem de pagamento</TitleAlert>
          <Subtitle>
            Total do reembolso:{" "}
            <span>
              {new Intl.NumberFormat("pt", {
                style: "currency",
                currency: "BRL",
              }).format(totalValue)}
            </span>
          </Subtitle>
        </>
      );
    case "REFUND":
      return (
        <>
          {selectedConsignment.delivery.modality === "MARKETPLACE_OUT" ? (
            <>
              <TitleAlert>Estorno</TitleAlert>
              <SmallText>
                Após a confirmação, o cliente poderá verificar o método e o
                prazo do estorno na <br /> plataforma (marketplace), onde ele
                realizou a compra.
              </SmallText>
              <ContainerPaymentMethod>
                <InfosPaymentMethod>
                  <InfoTotalRefund>
                    <span>Total do estorno</span>
                    <span className="text-bold">
                      {new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(totalValue)}
                    </span>
                  </InfoTotalRefund>
                </InfosPaymentMethod>
              </ContainerPaymentMethod>
            </>
          ) : (
            <>
              <TitleAlert>Estorno</TitleAlert>
              <SmallText>
                Após a confirmação do estorno, o crédito poderá ser visualizado
                de uma a duas faturas subsequentes, <br /> dependendo da
                administradora do cartão e fechamento da fatura.
              </SmallText>
              <ContainerPaymentMethod>
                <InfosPaymentMethod>
                  <Payments item={paymentMethods} />
                  <InfoTotalRefund>
                    <span>Total do estorno</span>
                    <span className="text-bold">
                      {new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(totalValue)}
                    </span>
                  </InfoTotalRefund>
                </InfosPaymentMethod>
              </ContainerPaymentMethod>
            </>
          )}
        </>
      );
    default:
      return null;
  }
};

export default ModalContent;
