import styled from "styled-components";

export const BorderContainer = styled.div`
  padding: 25px;
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
`;

type InputProps = {
  width?: string;
};

export const InputGroup = styled.div<InputProps>`
  flex: ${(props: InputProps) => props.width || "50%"};
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px;
  height: 40px;
  line-height: 24px;
`;

export const Label = styled.label`
  color: #263238;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
  text-align: left;
  line-height: 24px;
  span {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
`;

export const PaymentMethods = styled.ul`
  margin: 0 0 0 10px;
  padding: 0;
`;

type PaymentActive = {
  inactive?: boolean;
  active?: boolean;
};

export const PaymentMethod = styled.li<PaymentActive>`
  position: relative;
  list-style: none;
  max-width: 340px;
  opacity: ${(props: PaymentActive) => (props.inactive ? 0.3 : 1)};

  label::before {
    border: 0.1rem solid #b0bec5;
    background: #fff;
    box-shadow: 0px 0px 0px 2px #fff;
  }
`;

export const PaymentMethodBtn = styled.input`
  opacity: 0;
  visibility: hidden;

  &:checked + label {
    color: #1e5bc6;
  }

  &:checked + label::before {
    margin-right: 10px;
    border: 0.1rem solid #fff;
    background: #1e5bc6;
    box-shadow: 0px 0px 0px 2px #1e5bc6;
  }
`;

export const PaymentMethodLabel = styled.label`
  display: flex;
  align-items: center;
  color: #263238;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.25s linear;

  &::before {
    display: inline-block;
    content: "";
    height: 8px;
    width: 8px;
    margin-right: 10px;
    border-radius: 50%;
    transition: all 0.25s linear;
    border: 0.1rem solid #fff;
    background: #1e5bc6;
    box-shadow: 0px 0px 0px 2px #1e5bc6;
  }
`;

export const SwitchForm = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
`;

type ButtonProps = {
  active?: boolean;
};

export const ButtonSwitch = styled.button<ButtonProps>`
  background: transparent;
  cursor: pointer;
  border: none;
  border-bottom: ${(props) => (props.active ? "1px solid #1e5bc6" : "none")};
  color: ${(props) => (props.active ? "#1e5bc6" : "#607d8b")};
  padding: 10px 30px;
  outline: none;
  font-family: Ubuntu, sans-serif;
  font-weight: ${(props) => (props.active ? "500" : "300")};
`;

type TextError = {
  active?: boolean;
};

export const ErrorMessage = styled.span<TextError>`
  color: red;
  font-size: 10px;
  padding: 10px 0;
  display: ${(props) => (props.active ? "flex" : "none")};
`;

export const TooltipContainer = styled.div`
  background: #fbf1e0;
  border-radius: 8px;
  margin: 0px 0px;
  margin-top: 80px;
  padding: 10px;

  position: absolute;
  border: none;
  content: "";
  max-width: 215px;
  height: auto;
  background: #fbf1e0;
  ::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #fbf1e0;
    position: absolute;
    left: 12px;
    top: -6px;
    transform: rotate(45deg);
  }
`;

export const TooltipText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #5a646e;
  margin: 0;
`;
