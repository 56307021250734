import http from "./http";

export async function exchangeMarketplaceIn(
  link: string,
  orderLineId: string,
  customerEmail?: string
) {
  try {
    const attendant = JSON.parse(
      localStorage.getItem("firebaseui::rememberedAccounts")
    );

    const response = await http.put(link, {
      orderLineId,
      attendanceChannel: "PORTAL_BACKOFFICE",
      customerEmail: customerEmail ?? attendant[0].email,
    });

    return response;
  } catch (error) {
    return error.response;
  }
}
