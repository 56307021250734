import http from "./http";

export async function getBanks() {
  try {
    const response = await http.get("/v1/banks");

    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}
