import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "src/store";
import { CancelState } from "@store/cancellation-reducer";
import Skeleton from "react-loading-skeleton";
import useModal from "react-hooks-use-modal";
import { formatDate } from "@utils/formatUtils";
import { Column } from "@components/utilities";
import { Text, Card } from "carrefour-portal-backoffice-style-guide";
import Payments from "./components/payments";
import iconCarrefour from "@assets/carrefour-icon.svg";
import iconHome from "@assets/home-light.svg";
import ModalAlert from "./components/modal-alert";
import ModalItems from "./components/modal-items";
import ModalConfirm from "./components/modal-confirm";
import ModalConfirmation from "./components/modal-confirmation";
import ModalError from "./components/modal-error";

import {
  ClientName,
  ClientInfo,
  SummaryText,
  RowBetween,
  RowBetweenTitle,
  HeaderPayment,
  BodyPayment,
  StatusPayment,
  TextPayment,
  ButtonCancelTotal,
  CloseModal,
} from "./styles";

import { OrderDetailsResponse } from "../../OrderDetailsResponse";
import { TrackingResponse } from "@pages/order-detail/TrackingResponse";

type Details = {
  data: OrderDetailsResponse;
  trackingData: TrackingResponse;
};

const Details = ({ data, trackingData }: Details) => {
  const dispatch = useDispatch();
  const [showButtonCancelOrder, setShowButtonCancelOrder] = useState(false);

  const addressType =
    data?.consignments[0].delivery?.shippingAddress?.addressType;
  const modality = data?.consignments[0].delivery?.modality;

  const isClickRetire =
    modality === "CLICK_RETIRE_CD" ||
    (modality === "CLICK_RETIRE_LOJA" && addressType === "pickup");

  const [Modal, open, close, isOpen] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  const { modalTotalCancel } = useSelector<AppState, CancelState>(
    (state) => state.cancel
  );

  const openModalCancel = () => {
    dispatch({
      type: "MODAL_TOTAL_CANCEL",
      payload: 1,
    });
    open();
  };

  const validateConsignmentsModalityForInvoiceCancel = () => {
    data?.consignments.forEach((consignment) => {
      let modality = consignment.delivery?.modality;
      if (
        modality === "CLICK_RETIRE_LOJA" ||
        modality === "CLICK_RETIRE_CD" ||
        modality === "HOME_DELIVERY" ||
        modality === "MARKETPLACE"
      ) {
        return true;
      }
    });
    return false;
  };

  /// Hide  cancel order button
  useEffect(() => {
    trackingData?.consignments.map((item) => {
      item.status.forEach((i) => {
        if (
          (i.status === "PED" &&
            validateConsignmentsModalityForInvoiceCancel) ||
          i.status === "CVR" ||
          i.status === "CCR" ||
          i.status === "NFM" ||
          i.status === "PNA" ||
          i.status === "CAN" ||
          i.status === "FCP" ||
          i.status === "AAP"
        ) {
          setShowButtonCancelOrder(true);
        }
      });
    });
  }, [trackingData]);

  /// Show  cancel order button
  useEffect(() => {
    trackingData?.consignments.map((item) => {
      item.status.find((i) => {
        if (
          !validateConsignmentsModalityForInvoiceCancel ||
          i.status === "CNP" ||
          i.status === "CPR" ||
          i.status === "CAR" ||
          i.status === "FTR" ||
          i.status === "PAP" ||
          i.status === "PPM" ||
          i.status === "PCP" ||
          i.status === "CCW"
        ) {
          setShowButtonCancelOrder(false);
        }
      });
    });
  }, [trackingData]);

  const renderModal = () => {
    switch (modalTotalCancel) {
      case 1:
        return <ModalAlert close={close} />;
      case 2:
        return <ModalItems close={close} data={data} />;
      case 3:
        return <ModalConfirm close={close} data={data} />;
      case 4:
        return <ModalConfirmation />;
      case 5:
        return <ModalError />;
      default:
        return null;
    }
  };

  return (
    <>
      <RowBetweenTitle>
        <Text
          type="heading"
          size="medium"
          text="Detalhes do pedido"
          margin="0"
        />
        {showButtonCancelOrder && modality !== "SERVICE" && (
          <ButtonCancelTotal onClick={() => openModalCancel()}>
            Cancelar Pedido
          </ButtonCancelTotal>
        )}
      </RowBetweenTitle>

      {data ? (
        <RowBetween>
          {!isClickRetire ? (
            <Column data-testid="shipping-address-card">
              <Text type="heading" size="small" text="Endereço de entrega" />
              <Card
                width="300px"
                padding="25px"
                height="206px"
                margin="0 10px 0 0"
              >
                <RowBetween>
                  <ClientName>{`${data?.customerFirstName} ${data?.customerLastName}`}</ClientName>
                  <img src={iconHome} alt="home" />
                </RowBetween>

                <ClientInfo>
                  CEP:{" "}
                  {data?.consignments[0].delivery?.shippingAddress.postalCode}
                </ClientInfo>

                <ClientInfo>{`${data?.consignments[0].delivery?.shippingAddress.street}, ${data?.consignments[0].delivery?.shippingAddress.number}, ${data?.consignments[0].delivery?.shippingAddress.complement}`}</ClientInfo>
                <ClientInfo>
                  {`${data?.consignments[0].delivery?.shippingAddress.neighborhood}, 
              ${data?.consignments[0].delivery?.shippingAddress.city}/
              ${data?.consignments[0].delivery?.shippingAddress.state}`}
                </ClientInfo>
              </Card>
            </Column>
          ) : (
            <Column data-testid="shipping-address-card">
              <Text type="heading" size="small" text="Endereço de entrega" />
              <Card
                width="300px"
                padding="25px"
                height="auto"
                margin="0 10px 0 0"
              >
                <RowBetween>
                  <ClientName>Clique & Retire</ClientName>
                  <img src={iconCarrefour} alt="carrefour" />
                </RowBetween>

                <ClientInfo>
                  <strong>
                    {data?.consignments[0].delivery?.pickupStoreFriendlyName}
                  </strong>
                </ClientInfo>
                <ClientInfo>
                  {data?.consignments[0].delivery?.shippingAddress.postalCode}
                </ClientInfo>
                <ClientInfo>{`${data?.consignments[0].delivery?.shippingAddress.street}, ${data?.consignments[0].delivery?.shippingAddress.number}`}</ClientInfo>
                <ClientInfo>
                  {`${data?.consignments[0].delivery?.shippingAddress.neighborhood}, 
            ${data?.consignments[0].delivery?.shippingAddress.city}/
            ${data?.consignments[0].delivery?.shippingAddress.state}`}
                </ClientInfo>
                <ClientInfo>
                  <b>A RETIRADA SERÁ FEITA POR:</b>
                </ClientInfo>
                <ClientInfo>
                  {data?.consignments[0].delivery?.receiverName}
                </ClientInfo>
                <ClientInfo>
                  {data?.consignments[0].delivery?.receiverDocument}
                </ClientInfo>
              </Card>
            </Column>
          )}
          <Column data-testid="summary-card">
            <Text type="heading" size="small" text="Resumo" />
            <Card
              width="205px"
              padding="25px"
              height="206px"
              margin="0 10px 0 0"
            >
              <SummaryText>
                Subtotal{" "}
                <strong>
                  {data?.totalItemValue === 0
                    ? "R$ 0,00"
                    : new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(data?.totalItemValue)}
                </strong>
              </SummaryText>
              <SummaryText color="#FF403F">
                Descontos
                <strong>
                  {data?.totalDiscounts === 0
                    ? "R$ 0,00"
                    : new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(data?.totalDiscounts)}
                </strong>
              </SummaryText>
              <SummaryText>
                Frete
                <strong>
                  {data?.totalFreight === 0
                    ? "R$ 0,00"
                    : new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(data?.totalFreight)}
                </strong>
              </SummaryText>
              <SummaryText>
                <strong>Total</strong>
                <strong>
                  {data?.totalValue === 0
                    ? "R$ 0,00"
                    : new Intl.NumberFormat("pt", {
                        style: "currency",
                        currency: "BRL",
                      }).format(data?.totalValue)}
                </strong>
              </SummaryText>
            </Card>
          </Column>
          <Column data-testid="payment-methods-card">
            <Text type="heading" size="small" text="Formas de pagamento" />
            <Card width="395px" padding="0" height="auto" margin="0 10px 0 0">
              <HeaderPayment status={data?.payments[0]?.paymentStatus}>
                <StatusPayment status={data?.payments[0]?.paymentStatus}>
                  {data?.payments[0]?.paymentStatus ||
                    (data?.payments[0]?.transactionId ===
                      "PAYMENT-FROM-AFFILIATE" &&
                      "Pagamento não existente")}
                </StatusPayment>
                <TextPayment>
                  {formatDate(data?.payments[0]?.paymentStatusUpdateDate)}
                </TextPayment>
              </HeaderPayment>

              <BodyPayment>
                {data?.payments
                  ? data?.payments.map((item, index) => (
                      <Payments item={item} index={index} key={index} />
                    ))
                  : ""}
              </BodyPayment>
            </Card>
          </Column>
        </RowBetween>
      ) : (
        <RowBetween>
          <Skeleton width={360} height={296} />
          <Skeleton width={265} height={296} />
          <Skeleton width={405} height={200} />
        </RowBetween>
      )}

      <Modal preventScroll={true}>
        <CloseModal onClick={close}>✕</CloseModal>
        {renderModal()}
      </Modal>
    </>
  );
};

export default Details;
