import styled from "styled-components";

export const Td = styled.td`
  padding: 16px 0;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #eceff1;
`;

export const Th = styled.th`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: "#607D8B";
  text-align: left;
  text-transform: uppercase;
`;

export const TableWrapper = styled.div``;

export const Table = styled.table`
  margin-top: 60px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 8px 0px;
`;

export const Heading = styled.h2`
  margin: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin: 16px 0;
`;

export const Subheading = styled.h3`
  margin: 0px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`;

export const TotalValueWrapper = styled.div`
  padding: 8px 16px;
  background-color: #fcfdff;
  margin-top: 16px;
`;

export const TotalValueRow = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid #eceff1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TotalRow = styled(TotalValueRow)`
  justify-content: flex-end;
  border-bottom: none;
`;

export const TotalPriceLabel = styled.h4`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  font-weight: 500;
`;

export const TotalPriceSmall = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #263238;
  margin: 0;
  padding-right: 25px;
`;

export const TotalPrice = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1e5bc6;
  margin: 0;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const DimButton = styled.button`
  border: 1px solid #b0bec5;
  background: none;
  margin-right: 20px;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 14px;
  color: #263238;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background: #efefef;
    transition: 200ms;
  }
`;
