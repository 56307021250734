import styled from "styled-components";

export const Td = styled.td<{ negative?: boolean }>`
  padding: 16px 0;
  font-size: 12px;
  line-height: 16px;
  color: ${({ negative }) => (negative ? "#FF403F" : "#263238")};
`;

export const Th = styled.th<{ active?: boolean }>`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${({ active }) => (active ? "#607D8B" : "#d2d8db")};
  text-align: left;
  text-transform: uppercase;
`;

export const TableWrapper = styled.div`
  border-bottom: 1px solid #eceff1;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 8px 0px;
`;

export const CheckListWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CheckListColumn = styled.div`
  flex: 60%;
  column-count: 2;
`;

export const NoteColumn = styled.div`
  flex: 40%;
`;

export const NoteItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Textarea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d2dde6;
  color: #90a4ae;
  padding: 19px 16px;
  border-radius: 4px;
  resize: none;
  min-height: 168px;
`;

export const TextareaInfo = styled.span`
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  color: #90a4ae;
  align-self: flex-end;
  margin-top: 4px;
`;

export const Heading = styled.h2`
  margin: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin: 16px 0;
`;

export const Subheading = styled.h3`
  margin: 0px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`;

export const CheckListLabel = styled.label`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #607d8b;
`;

export const CheckListOption = styled.div`
  margin: 16px 0;

  &:first-child {
    margin-top: 0;
  }
`;

export const CheckListResumeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fcfdff;
  border-radius: 8px;
  padding: 10px 13px;
  margin-bottom: 16px;
`;

export const CheckListResumeText = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin-right: 16px;
  color: #607d8b;
`;

export const CheckListResumeNote = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #90a4ae;
`;

export const CheckListResumeLabel = styled.span`
  color: #78909c;
  background: #eceff1;
  border-radius: 4px;
  padding: 7px 13px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

export const ChecklistResumeColumn = styled.div`
  flex: 60%;
  display: flex;
  align-items: center;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DimButton = styled.button`
  border: 1px solid #b0bec5;
  background: none;
  margin-right: 20px;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 14px;
  color: #263238;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background: #efefef;
    transition: 200ms;
  }
`;

export const RemoveTdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 50px;
`;

export const ErrorButton = styled.button`
  border: none;
  margin: 24px 10px 0 10px;
  display: block;
  width: 240px;
  height: 40px;
  max-width: 100%;

  background-color: #eb4141;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: none;
  color: #fff;

  padding: 0.625rem 0;
  border-radius: 0.5rem;
`;
export const NextButton = styled.button`
  border: none;
  margin: 24px 10px 0 10px;
  display: block;
  width: 240px;
  height: 40px;
  max-width: 100%;

  background-color: green;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: none;
  color: #fff;

  padding: 0.625rem 0;
  border-radius: 0.5rem;
`;

export const ErrorAlert = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
`;
