import React, { useMemo, useEffect } from "react";
import { formatDate } from "@utils/formatUtils";
import DataTable from "react-data-table-component";
import { customStyles } from "@utils/customStyles";
import Status from "../status";
import { LinkOrder, GoOrderDetail } from "./styles";

type DataOrder = {
  loadOrders?: any;
  handlePerRowsChange?: any;
  data?: DataOrderFields[];
  loading: boolean;
  totalRecords?: number;
};

type DataOrderFields = {
  date?: string;
  order?: string;
  value?: string;
  status?: StatusType;
};

type StatusType = {
  status: string;
  creationTime: string;
  description: string;
  color: string;
};

const OrderTable = ({
  data,
  totalRecords,
  loading,
  loadOrders,
  handlePerRowsChange,
}: DataOrder) => {
  const columns = useMemo(
    () => [
      {
        name: "PEDIDO REALIZADO",
        cell: (row: { date: string }) => (
          <div style={{ fontWeight: 300 }} data-testid="row-data-date">
            {formatDate(row.date)}
          </div>
        ),
        sortable: false,
        certer: true,
      },
      {
        name: "NÚMERO DO PEDIDO",
        cell: (row: { order: number }) => (
          <LinkOrder href={`/atendimento/pedido/detalhe/${row.order}`}>
            {row.order}
          </LinkOrder>
        ),
        sortable: false,
      },
      {
        name: "VALOR TOTAL",
        cell: (row: { value: number }) => (
          <div style={{ fontWeight: 300 }}>
            {new Intl.NumberFormat("pt", {
              style: "currency",
              currency: "BRL",
            }).format(row.value)}
          </div>
        ),
        sortable: false,
      },
      {
        name: "STATUS",
        cell: (row: { status: any }) => <Status status={row.status} />,
        sortable: false,
        allowOverflow: false,
        grow: 2,
      },
      {
        cell: (row: any) => (
          <GoOrderDetail href={`/atendimento/pedido/detalhe/${row.order}`}>
            Visualizar
          </GoOrderDetail>
        ),
        ignoreRowClick: false,
        allowOverflow: false,
        button: false,
        name: "",
        right: true,
      },
    ],
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const paginationOptions = {
    rowsPerPageText: "Pedidos por página",
    rangeSeparatorText: "de",
  };

  return (
    <>
      {data ? (
        <DataTable
          noHeader={true}
          columns={columns}
          progressPending={loading}
          progressComponent={<h3>Carregando</h3>}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={loadOrders}
          paginationComponentOptions={paginationOptions}
          paginationTotalRows={totalRecords}
          pagination
          paginationServer
          noDataComponent={
            <h3>
              Ops... parece que este cliente ainda <br /> não fez nenhuma
              compra!
            </h3>
          }
          data={data}
          style={{
            borderRadius: "8px",
            boxShadow: "0px 0px 8px rgba(176, 190, 197, 0.3)",
          }}
          customStyles={customStyles}
        />
      ) : (
        "Carregando"
      )}
    </>
  );
};

export default OrderTable;
