import React, { Dispatch } from "react";
import Trash from "../../../icons/Trash";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../store";
import { Item } from "../../../../order-detail/OrderDetailsResponse";
import { setAddItemToExchange } from "../../../../../store/exchange-reducer";
import {
  ProductCard,
  ProductCardCode,
  ProductCardInfo,
  ProductCardName,
} from "./styles";
import { CurrentModal } from "../../..";

interface ItemOptionProps {
  item: Item;
  setCurrentModal: Dispatch<React.SetStateAction<CurrentModal>>;
}

const ItemOption = ({ item, setCurrentModal }: ItemOptionProps) => {
  const dispatch = useDispatch();
  const selectedItems = useSelector(
    (state: AppState) => state.exchange.selectedItems
  );

  const isSelected = selectedItems?.some((i) => i.id === item.id);

  const handleClick = () => {
    if (!isSelected) {
      dispatch(setAddItemToExchange(item));

      setCurrentModal("EXTENDED_WARRANTY_WARNING");
    }
  };

  return (
    <ProductCard isSelected={isSelected} onClick={handleClick}>
      <img src={item.imageUrl} width={56} height={56} alt="Imagem do Produto" />

      <ProductCardInfo>
        <ProductCardName>{item.name}</ProductCardName>

        <ProductCardCode>Cód.{item.refId}</ProductCardCode>
      </ProductCardInfo>
    </ProductCard>
  );
};

export default ItemOption;
