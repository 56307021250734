export const checklist = [
  {
    diagnosticName: "COM TODOS OS ACESSÓRIOS",
    diagnosticCode: 122,
  },
  {
    diagnosticName: "MANUAL DE INSTRUÇÕES",
    diagnosticCode: 148,
  },
  {
    diagnosticName: "Produto amassado",
    diagnosticCode: 164,
  },
  {
    diagnosticName: "Produto não funciona",
    diagnosticCode: 182,
  },
  {
    diagnosticName: "Produto quebrado",
    diagnosticCode: 189,
  },
  {
    diagnosticName: "Produto rasgado",
    diagnosticCode: 190,
  },
  {
    diagnosticName: "Produto riscado",
    diagnosticCode: 191,
  },
  {
    diagnosticName: "Produto sujo",
    diagnosticCode: 193,
  },
  {
    diagnosticName: "Produto trincado",
    diagnosticCode: 195,
  },
  {
    diagnosticName: "EM - Embalagem Aberta",
    diagnosticCode: 287,
  },
  {
    diagnosticName: "EM - Embalagem Improvisada",
    diagnosticCode: 288,
  },
  {
    diagnosticName: "EM - Embalagem Lacrada de Fábrica",
    diagnosticCode: 289,
  },
  {
    diagnosticName: "EM - Falta Embalagem",
    diagnosticCode: 292,
  },
  {
    diagnosticName: "EM - FALTOU O PRODUTO",
    diagnosticCode: 1218,
  },
  {
    diagnosticName: "AC - FALTOU ACESSÓRIO",
    diagnosticCode: 1219,
  },
  {
    diagnosticName: "IN - INVERSÃO",
    diagnosticCode: 1221,
  },
  {
    diagnosticName: "Produto com tela trincada",
    diagnosticCode: 1227,
  },
  {
    diagnosticName: "Produto com defeito intermitente",
    diagnosticCode: 1228,
  },
  {
    diagnosticName: "Produto com defeito",
    diagnosticCode: 1229,
  },
  {
    diagnosticName: "Produto sem sinais de uso",
    diagnosticCode: 1230,
  },
  {
    diagnosticName: "Produto com sinais de uso",
    diagnosticCode: 1231,
  },
];
