import { ForceInstanceType } from "@pages/force-instance-flow";
import {
  OrderDetailsResponse,
  Consignment,
  ReturnConsignment,
} from "@pages/order-detail/OrderDetailsResponse";

export interface OrderState {
  orderSelected: string;
  orderDetail: OrderDetailsResponse;
  selectedConsignment: Consignment;
  returnConsignments: ReturnConsignment[];
  forceInstance: {
    forceInstanceType: ForceInstanceType;
  };
}

export const ORDER_SELECTED = "ORDER_SELECTED";
export const ORDER_DETAIL = "ORDER_DETAIL";
export const SELECTED_CONSIGNMENT = "SELECTED_CONSIGNMENT";
export const RETURN_CONSIGNMENTS = "RETURN_CONSIGNMENTS";
export const CLEAR_RETURN_CONSIGNMENTS = "CLEAR_RETURN_CONSIGNMENTS";
export const SET_FORCE_INSTANCE = "SET_FORCE_INSTANCE";

export interface OrderActionsParams {
  type: string;
  payload: any;
}

type OrderActions = OrderActionsParams;

const initialState: OrderState = {
  orderSelected: "",
  orderDetail: {
    affiliatedId: "vtex",
    callCenterOperatorUserName: "",
    callCenterOperatorEmail: "",
    consignments: [],
    creationDate: null,
    customerDocument: "",
    customerDocumentType: "",
    customerEmail: "",
    customerExternalEmail: "",
    customerFirstName: "",
    customerLastName: "",
    customerPhone: "",
    links: null,
    marketplaceOrderId: "",
    orderCode: "",
    orderGroup: "",
    orderOrigin: "",
    payments: [],
    principalCode: "",
    sellerId: "",
    sellerName: "",
    sellerOrderId: "",
  },
  selectedConsignment: {
    consignmentCode: "",
    consignmentState: "",
    cubageWeight: 0,
    delivery: null,
    invoiceAddress: null,
    invoices: [],
    items: [],
    links: null,
    status: null,
    realWeight: 0,
    totalDiscounts: 0,
    totalFreight: 0,
    totalItemValue: 0,
    totalValue: 0,
    type: "",
    isCarrefourEnvios: false,
  },
  returnConsignments: [],
  forceInstance: {
    forceInstanceType: ForceInstanceType.FORCE_DEVOLUTION_REFUND,
  },
};

export function orderReducer(
  state = initialState,
  action: OrderActions
): OrderState {
  switch (action.type) {
    case ORDER_SELECTED:
      return {
        ...state,
        orderSelected: action.payload,
      };
    case ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.payload,
      };
    case SELECTED_CONSIGNMENT:
      return {
        ...state,
        selectedConsignment: action.payload,
      };
    case CLEAR_RETURN_CONSIGNMENTS:
      return {
        ...state,
        returnConsignments: [],
      };
    case RETURN_CONSIGNMENTS:
      let newReturnConsignments: ReturnConsignment[] = state.returnConsignments
        ? state.returnConsignments
        : [];

      let hasInList: boolean = false;
      newReturnConsignments.forEach((returnConsignment) => {
        if (
          returnConsignment.salesConsignment.consignmentCode ===
          action.payload.salesConsignment.consignmentCode
        ) {
          hasInList = true;
        }
      });

      if (!hasInList) {
        newReturnConsignments.push(action.payload);
      }
      return {
        ...state,
        returnConsignments: newReturnConsignments,
      };
    case SET_FORCE_INSTANCE:
      return {
        ...state,
        forceInstance: action.payload,
      };
    default:
      return state;
  }
}
