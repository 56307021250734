import React from "react";

const Trash = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 6C2.75 5.58579 3.08579 5.25 3.5 5.25H21.5C21.9142 5.25 22.25 5.58579 22.25 6C22.25 6.41421 21.9142 6.75 21.5 6.75H3.5C3.08579 6.75 2.75 6.41421 2.75 6Z"
        fill="#AA0D11"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 2.75C10.1685 2.75 9.85054 2.8817 9.61612 3.11612C9.3817 3.35054 9.25 3.66848 9.25 4V5.25H15.75V4C15.75 3.66848 15.6183 3.35054 15.3839 3.11612C15.1495 2.8817 14.8315 2.75 14.5 2.75H10.5ZM17.25 5.25V4C17.25 3.27065 16.9603 2.57118 16.4445 2.05546C15.9288 1.53973 15.2293 1.25 14.5 1.25H10.5C9.77065 1.25 9.07118 1.53973 8.55546 2.05546C8.03973 2.57118 7.75 3.27065 7.75 4V5.25H5.5C5.08579 5.25 4.75 5.58579 4.75 6V20C4.75 20.7293 5.03973 21.4288 5.55546 21.9445C6.07118 22.4603 6.77065 22.75 7.5 22.75H17.5C18.2293 22.75 18.9288 22.4603 19.4445 21.9445C19.9603 21.4288 20.25 20.7293 20.25 20V6C20.25 5.58579 19.9142 5.25 19.5 5.25H17.25ZM6.25 6.75V20C6.25 20.3315 6.3817 20.6495 6.61612 20.8839C6.85054 21.1183 7.16848 21.25 7.5 21.25H17.5C17.8315 21.25 18.1495 21.1183 18.3839 20.8839C18.6183 20.6495 18.75 20.3315 18.75 20V6.75H6.25Z"
        fill="#AA0D11"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 10.25C10.9142 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 10.9142 17.75 10.5 17.75C10.0858 17.75 9.75 17.4142 9.75 17V11C9.75 10.5858 10.0858 10.25 10.5 10.25Z"
        fill="#AA0D11"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5 10.25C14.9142 10.25 15.25 10.5858 15.25 11V17C15.25 17.4142 14.9142 17.75 14.5 17.75C14.0858 17.75 13.75 17.4142 13.75 17V11C13.75 10.5858 14.0858 10.25 14.5 10.25Z"
        fill="#AA0D11"
      />
    </svg>
  );
};

export default Trash;
