import styled from "styled-components";

export const InformativeStatement = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: #575757;

  margin-top: 0;
  margin-bottom: 16px;
`;

export const CollectAddressTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  color: #454545;

  margin: 0;
`;

export const CollectReceiverName = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  color: #575757;

  margin: 16px 0;
`;

export const CollectAddressStatement = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;

  color: #575757;

  margin-top: 0;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 16px;
  }
`;
