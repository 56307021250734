import styled from "styled-components";

export const Title = styled.h2`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: #1e5bc6;
  margin: 0 28px;
`;

export const Address = styled.p`
  font-family: Ubuntu;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin: 40px auto;
  width: 201px;
  color: #78909c;
`;
