import styled from "styled-components";

export const TrackingContainer = styled.div`
  width: 30%;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
`;

type TLink = {
  active?: boolean;
};

export const TabLink = styled.button<TLink>`
  border: none;
  border-bottom: 1px solid;
  border-color: ${(props: TLink) => (props.active ? "#2E8CF6" : "#eceff1")};
  background: transparent;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: ${(props: TLink) => (props.active ? "#2E8CF6" : "#B0BEC5")};
  flex: 1 auto;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
  padding-top: 45px;
`;

export const TrackUl = styled.ul`
  margin: 0px;
  padding: 0px;
`;

export const TrackLi = styled.li`
  margin: 0px;
  padding: 0px;
  list-style: none;

  &:last-child {
    * {
      border-left: none;
    }
  }
`;

type TDot = {
  background: string;
};

export const Dot = styled.div`
  width: 20px;
  height: 55px;
  margin-left: 15px;
  border-left: 1px solid #eceff1;

  div {
    height: 16px;
    width: 16px;
    background: ${(props: TDot) => props.background};
    border-radius: 50%;
    margin-left: -9px;
  }
`;

export const TrackDesc = styled.div`
  strong {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #37474f;
    margin-right: 5px;
  }
  span {
    font-size: 12px;
    line-height: 16px;
    color: #78909c;
  }
  small {
    display: block;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    color: #b0bec5;
  }
`;
