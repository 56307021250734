import styled from "styled-components";

import { Size, Variation } from "./types";
import {
  handleSize,
  handleVariationOutline,
  handleVariationOutlineHover,
  handleVariation,
} from "./utils";

type StyledButtonProps = {
  variation: Variation;
  size: Size;
  margin: string;
  outline?: boolean;
};

export const StyledButton = styled.button<StyledButtonProps>`
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #1e5bc6;
  outline: none;
  border-color: #1e5bc6;
  border: 1px solid;
  background: transparent;
  transition: 0.2s all linear;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  ${({ size }) => handleSize(size)};
  ${({ variation, outline }) =>
    outline ? handleVariationOutline(variation) : handleVariation(variation)};
  :hover {
    ${({ variation, outline }) =>
      outline
        ? handleVariationOutlineHover(variation)
        : "filter: brightness(0.9);"};
  }
`;

export const LeftIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

export const RightIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 10px;
`;
