export const customStyles = {
  headRow: {
    style: {
      minHeight: "40px",
      padding: "0 20px",
      background: "#55A4FE",
      wrap: false,
    },
  },
  rows: {
    style: {
      minHeight: "72px",
      padding: "0 20px",
      wrap: false,
    },
  },
  headCells: {
    style: {
      height: "40px",
      color: "#fff",
      wrap: false,
      allowOverflow: true,
    },
  },
  cells: {
    style: {
      color: "#546E7A",
      fontSize: "14px",
      fontWeight: 300,
      wrap: false,
      allowOverflow: false,
    },
  },
};
