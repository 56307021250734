import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Breadcrumbs, Card } from "carrefour-portal-backoffice-style-guide";

import { AppState } from "@store/index";
import PageHeader from "@components/page-header";
import { Content, Container } from "@components/utilities";
import CancelSummary from "@components/cancel-summary";
import { OrderState } from "@store/order-reducer";
import { CancelState } from "@store/cancellation-reducer";

import CancelReasons from "./components/cancel-reasons";
import BilletRefund from "./components/billet-refund";
import CardRefund from "./components/card-refund";
import ActionBar from "./components/action-bar";
import AccountRefund from "./components/account-refund";
import { CancellationType } from "./enums/cancellation-type";

type RenderScreenProps = {
  screen: string;
};

const CancellationFlow = () => {
  const cancelState = useSelector<AppState, CancelState>(
    (state) => state.cancel
  );

  const { orderSelected, orderDetail } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  function getInitialScreen() {
    if (orderDetail && orderDetail.payments[0]) {
      const payment = orderDetail.payments[0];
      if (payment.paymentSystemName === "Boleto Bancário") {
        return "refundBillet";
      } else {
        return "refundCard";
      }
    }
    return "";
  }

  const [screen, setScreen] = useState(
    cancelState.cancellationType === CancellationType.DELIVERY_FAILURE
      ? getInitialScreen()
      : "cancelReason"
  );

  let title = "Cancelamento de entrega";
  if (cancelState.cancellationType === CancellationType.TRANSPORT_OCURRENCE) {
    title = "Ocorrência de transporte";
  } else if (
    cancelState.cancellationType === CancellationType.DELIVERY_FAILURE
  ) {
    title = "Insucesso de entrega";
  }

  function renderScreen({ screen }: RenderScreenProps) {
    switch (screen) {
      case "cancelReason":
        return <CancelReasons cancelState={cancelState} />;
      case "refundBillet":
        return <BilletRefund />;
      case "refundCard":
        return <CardRefund />;
      case "refundAccount":
        return <AccountRefund />;
      default:
        return null;
    }
  }

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            {
              to: `/atendimento/pedido/detalhe/${orderSelected}`,
              label: "Pedido",
            },
            {
              to: "/atendimento/cancelamento",
              label: "Cancelamento de pedido",
            },
          ]}
        />
        <PageHeader title="PEDIDO" subtitle={title} button={false} />
        <CancelSummary data={orderDetail} />
        <Card
          width="auto"
          margin="50px 0 150px 0"
          padding="35px 150px 100px 150px"
        >
          {renderScreen({ screen })}
        </Card>
      </Container>

      <ActionBar
        data={orderDetail}
        cancelState={cancelState}
        setScreen={setScreen}
        screen={screen}
      />
    </Content>
  );
};

export default CancellationFlow;
