import React from "react";

import { MessageModalContainer } from "./styles";
import iconSuccess from "@assets/success.svg";
import iconError from "@assets/forbidden.svg";

export type ModalType = "success" | "resendSuccess" | "error";

export const MessageModalContent: React.FC<{
  modalType: ModalType;
  iconAlt: string;
}> = ({ modalType, iconAlt, children }) => {
  let icon: string;

  switch (modalType) {
    case "success":
    case "resendSuccess":
      icon = iconSuccess;
      break;
    case "error":
      icon = iconError;
      break;
  }

  return (
    <MessageModalContainer type={modalType}>
      <div>
        <img src={icon} alt={iconAlt} />
      </div>
      {children}
    </MessageModalContainer>
  );
};
