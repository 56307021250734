import React from "react";
import {
  ModalWarrantyWarning,
  ModalWarrantyWarningActions,
  ModalWarrantyWarningBack,
  ModalWarrantyWarningDescription,
  ModalWarrantyWarningNext,
  ModalWarrantyWarningTitle,
} from "./styles";
import Warning from "../../../icons/Warning";

interface ExtendedWarrantyWarningProps {
  closeModal: () => void;
  goNext: () => void;
}

const ExtendedWarrantyWarning = ({
  closeModal,
  goNext,
}: ExtendedWarrantyWarningProps) => {
  return (
    <ModalWarrantyWarning>
      <Warning />

      <ModalWarrantyWarningTitle>Atenção</ModalWarrantyWarningTitle>

      <ModalWarrantyWarningDescription>
        Verifique se este item possui algum serviço contratado, como Garantia
        Estendida, ou outros. Caso positivo, prossiga com a{" "}
        <strong>devolução e recompra.</strong>
      </ModalWarrantyWarningDescription>

      <ModalWarrantyWarningDescription>
        Se o item não possuir nenhum serviço contratado, clique no botão
        “continuar”.
      </ModalWarrantyWarningDescription>

      <ModalWarrantyWarningActions>
        <ModalWarrantyWarningBack onClick={closeModal}>
          Voltar
        </ModalWarrantyWarningBack>

        <ModalWarrantyWarningNext onClick={goNext}>
          Continuar
        </ModalWarrantyWarningNext>
      </ModalWarrantyWarningActions>
    </ModalWarrantyWarning>
  );
};

export default ExtendedWarrantyWarning;
