import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Column, Row, selectStyle } from "@components/utilities";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { TitleAlert, CardModal, DescAlert, Box } from "../../styles";
import iconExclamation from "@assets/exclamation.svg";
import Select from "react-select";

import http from "@services/http";

type ModalAlertProps = {
  close(): void;
};

const ModalAlert = ({ close }: ModalAlertProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [options, setOptions] = useState([]);

  async function getReasons() {
    const reasons = await http.get("/v1/orders/cancel/reasons");
    const _option: { value: string; label: string }[] = [];
    reasons.data.map((item: { reasonCode: string; description: string }) => {
      _option.push({ value: item.reasonCode, label: item.description });
    });

    setOptions(_option);
  }

  const handleChange = (selectedOption: {
    value: string;
    description: string;
  }) => {
    dispatch({ type: "CANCEL_REASON", payload: selectedOption.value });
  };

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        {/* <img src={iconExclamation} alt="exclamation" /> */}
        <Column margin="0 auto" textAlign="center">
          {/* <TitleAlert margin="0 0 0 10px">
            O pedido ainda não teve confirmação do pagamento
          </TitleAlert> */}
          <DescAlert>
            Ao prosseguir, o cancelamento do pedido será realizado
            <strong>imediatamente</strong> e a <br />
            pré-autorização no cartão será desfeita,
            <strong>não haverá restituição ao cliente.</strong>
          </DescAlert>
        </Column>
        <Column>
          <Box>
            <p>Por que você gostaria de cancelar esta entrega?</p>
            <Select
              options={options}
              placeholder="Selecione um motivo..."
              onChange={handleChange}
              styles={selectStyle}
            />
          </Box>
        </Column>

        <Row margin="30px 0 0 0">
          <Column margin="0 20px 0 0">
            <Button
              text="Voltar"
              variation="primary"
              onClick={close}
              size="large"
              outline
            />
          </Column>
          <Button
            text="Prosseguir"
            variation="confirm"
            onClick={() =>
              dispatch({
                type: "MODAL_TOTAL_CANCEL",
                payload: 2,
              })
            }
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalAlert;
