import styled from "styled-components";

export const BottomBarStyle = styled.div`
  background: #fff;
  padding: 20px 0px;
  margin-top: 50px;
`;

export const CustomerName = styled.h2`
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  margin: 0px;
  display: flex;
  align-items: center;
  color: #37474f;

  strong {
    font-weight: 500;
    margin-right: 5px;
  }
`;

export const TitleValue = styled.p`
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  color: #90a4ae;
  margin: 0px;
`;

export const Value = styled.p`
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  color: #546e7a;
`;
