import React from "react";
import { useSelector } from "react-redux";
import { formatDate } from "@utils/formatUtils";
import { AppState } from "src/store";
import { OrderState } from "@store/order-reducer";

import {
  Row,
  Column,
  RowBetween,
  CardContentTotal,
  CardContentUp,
  CardContentLast,
  CardTitle,
  CardText,
  UserImg,
  UserName,
  UserInfo,
  UserValue,
} from "./styles";

import { Card } from "carrefour-portal-backoffice-style-guide";

import { OrderDetailsResponse } from "@pages/order-detail/OrderDetailsResponse";
import imgAvatar from "@assets/avatar.svg";

type CancelSummary = {
  data: OrderDetailsResponse;
};

const CancelSummary = ({ data }: CancelSummary) => {
  const { selectedConsignment } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  return (
    <RowBetween>
      <Row>
        <UserImg src={imgAvatar} alt="avatar" data-testid="client-img" />
        <div>
          <UserName data-testid="client-name">{`${data?.customerFirstName} ${data?.customerLastName}`}</UserName>
          <UserInfo data-testid="client-email">
            E-mail: {data?.customerEmail}
          </UserInfo>
          <UserInfo data-testid="client-cpf">
            CPF: {data?.customerDocument}
          </UserInfo>
          <UserInfo>Telefone Principal: {data?.customerPhone}</UserInfo>
        </div>
      </Row>
      <Column>
        <Row>
          <Card
            margin="20px 0px 0px 30px"
            padding="20px"
            width="120px"
            height="auto"
          >
            <CardContentTotal>
              <CardTitle>PEDIDO</CardTitle>
              <div>
                <UserValue>{data?.orderCode}</UserValue>
                <CardText>Pedido VTEX</CardText>
              </div>
            </CardContentTotal>
          </Card>
          <Card
            margin="20px 0px 0px 30px"
            padding="20px"
            width="120px"
            height="auto"
          >
            <CardContentUp>
              <CardTitle>
                CRIAÇÃO DO <br />
                PEDIDO
              </CardTitle>
              <UserValue>
                {data?.creationDate ? formatDate(data?.creationDate) : ""}
              </UserValue>
            </CardContentUp>
          </Card>
          <Card
            margin="20px 0px 0px 30px"
            padding="20px"
            width="120px"
            height="auto"
          >
            <CardContentLast>
              <CardTitle>
                VALOR TOTAL <br />
                DA ENTREGA
              </CardTitle>
              <UserValue>
                {new Intl.NumberFormat("pt", {
                  style: "currency",
                  currency: "BRL",
                }).format(selectedConsignment?.totalItemValue)}
              </UserValue>
            </CardContentLast>
          </Card>
        </Row>
      </Column>
    </RowBetween>
  );
};

export default CancelSummary;
