import React from "react";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { Column, Row } from "@components/utilities";
import {
  CardModal,
  InfoHead,
  Heading,
  PaymentItem,
  SmallText,
  RowBetweenCenter,
  TextPayment,
  CardItemPayment,
} from "../styles";
import { formatMoney } from "@utils/formatUtils";
import { RenderPaymentType } from "@pages/cancellation-flow/components/payments";

type Props = {
  value: number;
  returnTitle: string;
  returnSubtitle: string;
  payments?: any;
  action?: (...args: any[]) => any;
  close?: (...args: any[]) => any;
};

const ModalCreditCardRefund = (props: Props) => {
  return (
    <CardModal padding="50px 50px">
      <Column margin="0 auto" width="650px" textAlign="center">
        <InfoHead>{`Você tem certeza que deseja devolver esta entrega?`}</InfoHead>
        <SmallText>{props.returnSubtitle}</SmallText>
        <Heading>{props.returnTitle}</Heading>
        <CardItemPayment>
          {props?.payments?.map((payment: any) => (
            <PaymentItem>
              <RowBetweenCenter>
                <div>
                  <RenderPaymentType
                    paymentType={payment.paymentSystemName}
                    lastDigits={payment.lastDigits}
                  />
                </div>
                <TextPayment color="#37474F" weight="500">
                  {formatMoney(payment.refundValue)}
                </TextPayment>
              </RowBetweenCenter>
            </PaymentItem>
          ))}
          <PaymentItem>
            <RowBetweenCenter>
              <TextPayment
                margin="15px 0 0"
                color="#546E7A"
                weight="500"
              >{`Total do estorno`}</TextPayment>
              <TextPayment
                margin="15px 0 0"
                color="#1E5BC6"
                weight="500"
                fontSize="16px"
              >
                {formatMoney(props.value)}
              </TextPayment>
            </RowBetweenCenter>
          </PaymentItem>
        </CardItemPayment>
      </Column>
      <Row margin="30px 0 0 0">
        <Column margin="0 20px 0 0">
          <Button
            text="Cancelar"
            variation="primary"
            onClick={props.close}
            size="large"
            outline
          />
        </Column>
        <Button
          text="Prosseguir"
          variation="confirm"
          onClick={props.action}
          size="large"
        />
      </Row>
    </CardModal>
  );
};

export default ModalCreditCardRefund;
