import React from "react";

import { ReturnMethod as ReturnMethodType } from "../../../../../store/exchange-reducer";

import {
  ReturnMethodCard,
  ReturnMethodCheckbox,
  ReturnMethodCheckboxInner,
  ReturnMethodDescription,
  ReturnMethodInfos,
  ReturnMethodTitle,
  ReturnMethodTitleWrapper,
} from "./styles";

interface ReturnMethodProps {
  returnMethod: ReturnMethodType;
  title: string;
  description: string;
  onClick: (returnMethod: ReturnMethodType) => void;
  selectedReturnMethod: ReturnMethodType;
}

const ReturnMethod = ({
  onClick,
  returnMethod,
  title,
  description,
  selectedReturnMethod,
}: ReturnMethodProps) => {
  const isSelected = selectedReturnMethod === returnMethod;

  return (
    <ReturnMethodCard onClick={() => onClick(returnMethod)}>
      <ReturnMethodCheckbox>
        {isSelected && <ReturnMethodCheckboxInner />}
      </ReturnMethodCheckbox>

      <ReturnMethodInfos>
        <ReturnMethodTitleWrapper>
          <ReturnMethodTitle>{title}</ReturnMethodTitle>
        </ReturnMethodTitleWrapper>

        <ReturnMethodDescription>{description}</ReturnMethodDescription>
      </ReturnMethodInfos>
    </ReturnMethodCard>
  );
};

export default ReturnMethod;
