import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useModal from "react-hooks-use-modal";
import { Card, Text } from "carrefour-portal-backoffice-style-guide";
import { Column, Row } from "@components/utilities";
import {
  TitleAlert,
  TextLight,
  Strong,
  Label,
  RowBetween,
  RowStart,
  ButtonAnchor,
  ButtonData,
  CloseModal,
  BorderCard,
  CicleMark,
} from "../shipping/styles";

import http from "@services/http";
import { formatMoney, formatDate } from "@utils/formatUtils";
import { cpfMask } from "@utils/masks";
import Items from "@components/items";
import Tracking from "../tracking";
import { RETURN_CONSIGNMENTS } from "@store/order-reducer";
declare global {
  var returnValue: number;
}
const ReturnDelivery = (returnConsignment: any) => {
  const dispatch = useDispatch();

  const [orderData, setOrderData] = useState([]);

  const [Modal, open, close, isOpen] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  async function loadReturnConsignment() {
    const response = await http.get(returnConsignment.returnConsignment.href, {
      params: {
        viewType: "PORTAL_BACKOFFICE",
      },
    });
    setOrderData(response.data.returnConsignments);
    dispatch({
      type: RETURN_CONSIGNMENTS,
      payload: response.data,
    });
    globalThis.returnValue = response.data.returnConsignments[0].totalValue;
  }

  useEffect(() => {
    if (returnConsignment.returnConsignment.href) {
      loadReturnConsignment();
    }
  }, []);

  const totalQuantityReturns = orderData.length;

  return (
    <>
      {orderData.length > 0 &&
        orderData.map((item: any, index: number) => (
          <>
            <Card padding="50px" width="100%" margin="50px 0 0 0">
              <RowBetween>
                <Row justify="flex-start">
                  <TitleAlert margin="0px" color="green">
                    {item?.consignmentState}
                  </TitleAlert>
                  <CicleMark>
                    <span>{`${index + 1}`}</span>/
                    <span>{`${totalQuantityReturns}`}</span>
                  </CicleMark>
                </Row>
                <Row justify="flex-end">
                  <ButtonAnchor href={`#${item?.consignmentCode}`}>
                    {`Voltar para entrega ${item?.consignmentCode.substr(
                      item?.consignmentCode.length - 2
                    )}`}
                  </ButtonAnchor>
                </Row>
              </RowBetween>
              <RowStart>
                <Column data-testid="shipping-address">
                  <Strong>{`Entrega original:`}</Strong>
                  <TextLight>{item?.consignmentCode}</TextLight>
                  <Strong>{`Solicitado por:`}</Strong>
                  <TextLight>{item?.returnRequest?.userName}</TextLight>
                  <Strong>{`Dia e hora da solicitação:`}</Strong>
                  <TextLight>
                    {formatDate(item?.returnRequest?.requestDate)}
                  </TextLight>
                </Column>
                <Card
                  background="#FCFDFF"
                  width="200px"
                  padding="25px"
                  height="190px"
                  margin="0px 25px 0 0"
                >
                  <Column data-testid="invoice-card">
                    <Strong>{`Restituição`}</Strong>
                    <Column margin="0 25px 0 0">
                      <Label>{`MÉTODO ESCOLHIDO`}</Label>
                      <Strong color="#607D8B">
                        {item?.type === "FOOD"
                          ? "-"
                          : item?.returnRequest?.refund?.refundName}
                      </Strong>
                    </Column>
                    <Column margin="0 25px 0 0">
                      <Label>{`TOTAL RESTITUÍDO`}</Label>
                      <Strong color="#1E5BC6">
                        {item?.type === "FOOD"
                          ? "-"
                          : formatMoney(item?.totalValue)}
                      </Strong>
                    </Column>
                    <Column>
                      {item?.returnRequest?.refund.refundType === "REFUND" ||
                        (item?.returnRequest?.refund.refundType ===
                          "ACCOUNT_DEPOSIT" && (
                          <ButtonData
                            onClick={() => open()}
                          >{`Visualizar dados bancários`}</ButtonData>
                        ))}
                    </Column>
                  </Column>
                </Card>
                <Card
                  background="#FCFDFF"
                  width="200px"
                  padding="25px"
                  height="190px"
                >
                  <Column data-testid="shipping-company">
                    <Strong>
                      {item?.type === "FOOD" ? "Reenvio" : "Devolução"}
                    </Strong>
                    <Row justify="normal">
                      <Column margin="0 25px 0 0">
                        <Label>
                          {item?.type === "FOOD"
                            ? "NÚMERO DO NOVO PEDIDO GERADO"
                            : "MÉTODO ESCOLHIDO"}
                        </Label>
                        <Strong color="#607D8B">
                          {" "}
                          {item?.type === "FOOD"
                            ? "Número do pedido"
                            : item?.returnRequest?.returnModality}
                        </Strong>
                      </Column>
                    </Row>
                  </Column>
                </Card>
              </RowStart>
              <Row justify="flex-start">
                <Text
                  type="display"
                  size="20"
                  text="Itens do pedido devolvido"
                  margin="50px 0 0 0"
                />
              </Row>
              <Row>
                <Items items={item?.items} />
                <Tracking status={item?.statuses} reverse={true} />
              </Row>
            </Card>
            <Modal preventScroll={true}>
              <Card width="400px">
                <CloseModal onClick={close}>X</CloseModal>
                <Row margin="20px 0" justify="flex-start">
                  <TitleAlert
                    color="orange"
                    margin="0"
                  >{`Dados do cliente`}</TitleAlert>
                </Row>
                <BorderCard>
                  <RowBetween>
                    <Column>
                      <TextLight
                        margin="0"
                        color="#B0BEC5"
                        fontWeight={500}
                        fontSize="10px"
                      >{`NOME`}</TextLight>
                      <TextLight margin="0">
                        {item?.delivery?.receiverName}
                      </TextLight>
                      <TextLight
                        margin="0"
                        color="#B0BEC5"
                        fontWeight={500}
                        fontSize="10px"
                      >{`CPF`}</TextLight>
                      <TextLight margin="0">
                        {item?.delivery?.receiverDocument.length > 0 &&
                          cpfMask(item?.delivery?.receiverDocument)}
                      </TextLight>
                    </Column>
                    <Column>
                      <TextLight
                        margin="0"
                        color="#B0BEC5"
                        fontWeight={500}
                        fontSize="10px"
                      >{`DADOS BANCÁRIOS`}</TextLight>
                      <TextLight margin="0">
                        {`Conta:`}
                        {`${item?.returnRequest?.bankAccount?.account}-${item?.returnRequest?.bankAccount?.digit}`}
                      </TextLight>
                      <TextLight margin="0">
                        {`Agência:`} {item?.returnRequest?.bankAccount?.branch}
                      </TextLight>
                      <TextLight margin="0">
                        {`Banco:`} {item?.returnRequest?.bankAccount?.bank}
                      </TextLight>
                    </Column>
                  </RowBetween>
                </BorderCard>
                <Row>
                  <ButtonData color="#546E7A" onClick={() => close()}>
                    {`Fechar`}
                  </ButtonData>
                </Row>
              </Card>
            </Modal>
          </>
        ))}
    </>
  );
};

export default ReturnDelivery;
