import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "carrefour-portal-backoffice-style-guide";

import http from "@services/http";
import { Item } from "@pages/order-detail/OrderDetailsResponse";
import { setReturnItem, removeReturnItem } from "@store/refund-items-reducer";
import { AppState } from "@store/index";
import { useParams } from "react-router-dom";

import ProductSelect from "../product-select";
import { ProductInfo } from "./components/product-info";
import {
  Td,
  Th,
  TableWrapper,
  Table,
  CheckListWrapper,
  CheckListColumn,
  NoteColumn,
  NoteItems,
  Textarea,
  TextareaInfo,
  Heading,
  Subheading,
  CheckListResumeWrapper,
  CheckListResumeText,
  ChecklistResumeColumn,
  CheckListResumeLabel,
  CheckListResumeNote,
  CheckListLabel,
  CheckListOption,
  ActionsWrapper,
  RemoveTdWrapper,
  AlertPermission,
  AlertPermissionIco,
  AlertPermissionText,
} from "../../style";

import iconAlert from "@assets/exclamation.svg";

import { mockChecklist } from "@utils/mockCheckList";
import { AuthState } from "@store/authorities-reducer";
import { selectStyle } from "@components/utilities";

type optionType = {
  description: string;
  observationMandatory: boolean;
  reasonCode: string;
};

type currentProductType = {
  productValue?: string;
  quantity?: string;
  returnReason?: string;
  checkListOption?: string;
  returnNote?: string;
};

type Props = {
  products: Item[];
  productOptions: {
    label: {
      title: string;
      code: string;
      imageUrl: string;
    };
    value: string;
  }[];
};

type ChecklistData = {
  id: string;
  checklist: {
    diagnosticCode: number;
    diagnosticName: string;
  }[];
}[];

type ChecklistOptionsType = {
  [key: string]: {
    diagnosticCode: number;
    diagnosticName: string;
  }[];
};

type OrderProps = {
  orderCode: string;
  consignmentsCode: string;
};

const ReturnReasonsItem = (props: Props) => {
  const { productOptions, products } = props;
  const { orderCode, consignmentsCode } = useParams<OrderProps>();
  const consignments = useSelector(
    (state: AppState) => state.returnItems.orderDetails?.consignments
  );

  const consignment = consignments?.find(
    (c: any) => c.consignmentCode === consignmentsCode
  );
  const dispatch = useDispatch();
  const returnItems = useSelector(
    (state: AppState) => state.returnItems?.returnItems
  );
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [checkListOptions, setCheckListOptions] = useState<
    ChecklistOptionsType
  >(null);
  const [currentProduct, setCurrentProduct] = useState<currentProductType>(
    null
  );

  async function getProductsChecklist() {
    if (consignment.type !== "FOOD" && consignment.type !== "MARKETPLACE_IN") {
      const productsIds = products?.map((item) => item.id);
      const { data }: { data: ChecklistData } = await http.get(
        `/v1/orders/${orderCode}/items/checklist`,
        {
          params: {
            orderItemIds: productsIds.join(","),
          },
        }
      );
      const checklists = data.reduce((acc, curr) => {
        return { ...acc, [curr.id]: curr.checklist };
      }, {});

      setCheckListOptions(checklists);
    }

    setLoadingOptions(false);
  }

  async function getReasons() {
    const response = await http.get("/v1/orders/return/reasons");
    const { data }: { data: optionType[] } = response;
    const reasons = data.map((item) => ({
      value: item.reasonCode,
      label: item.description,
    }));

    setOptions(reasons);
  }

  useEffect(() => {
    if (products) {
      getProductsChecklist();
    }
  }, [products]);

  useEffect(() => {
    getReasons();
  }, []);

  const handleProductChange = (selectedOption: {
    value: any;
    label: string;
  }) => {
    const newCurrentProduct = {
      ...currentProduct,
      productValue: selectedOption.value,
    };

    if (consignment.delivery.modality == "DRIVE") {
      const product = products.find((p) => p.id == selectedOption.value);

      newCurrentProduct.quantity = `${product.availableQuantity}`;
    }

    setCurrentProduct(newCurrentProduct);
  };

  const handleQuantityChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setCurrentProduct({ ...currentProduct, quantity: selectedOption.value });
  };

  const handleReturnReason = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setCurrentProduct({
      ...currentProduct,
      returnReason: selectedOption.value,
    });
  };

  const handleNoteChange = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLTextAreaElement>) => {
    setCurrentProduct({ ...currentProduct, returnNote: value });
  };

  const saveItem = () => {
    dispatch(setReturnItem(currentProduct));
    setCurrentProduct(null);
  };

  const removeItem = (id: string | Number) => {
    dispatch(removeReturnItem(id));
  };

  const currentEditProduct = products?.find(
    (p) => p.id === currentProduct?.productValue
  );

  const quantityArray = [
    ...new Array(currentEditProduct?.availableQuantity),
  ].map((_, i) => ({
    label: `${i + 1}`,
    value: `${i + 1}`,
  }));
  const remainProducts = products?.filter(
    (i) => !returnItems.find((r) => r.productValue == i.id)
  );
  const checkList = mockChecklist.checkList;

  const [checkPermission, setCheckPermission] = useState(false);

  const [selectedItem, setSelectedItem] = useState("");
  const handleItemSelected = (e: any) => {
    const itemSelected = e.target.name;
    setSelectedItem(itemSelected);
  };
  const getCheckPermission = (e: any) => {
    const handleItemDisabled = e.target.name;
    handleItemDisabled ? setCheckPermission(false) : setCheckPermission(true);
    setSelectedItem(handleItemDisabled);
  };

  const rules = useSelector<AppState, AuthState>((state) => state.rules);

  const getAuthorizedPartialRefund = rules.authorities.find(
    (element: any) => element === "cs-order.refund-partial"
  );

  const [checkListOptionSelected, setCheckListOptionSelected] = useState(null);

  useEffect(() => {
    setCheckListOptionSelected(selectedItem);
  }, [checkPermission]);

  return (
    <>
      {returnItems.map((item: any) => {
        const product = products?.find((p) => p.id === item.productValue);
        const checkListValue = checkListOptions?.[item.productValue]?.find(
          (c) => c.diagnosticCode == item.checkListOption
        );
        const activeOption = options.find((i) => i.value === item.returnReason);

        const handleLocalStorage = (Event: any) => {
          let reasonLabel = activeOption?.label;
          let reasonValue = activeOption?.value;
          let observationValue = document.querySelectorAll(
            "#observationValue"
          )[0].innerHTML;
          let diagnosticName = document.querySelectorAll("#checklistValue")[0]
            .innerHTML;

          localStorage.setItem(
            "diagnosticName",
            JSON.stringify(diagnosticName)
          );
          localStorage.setItem("observation", JSON.stringify(observationValue));
        };

        return (
          <>
            <div onMouseMove={handleLocalStorage}>
              <Heading>Selecione o item</Heading>
              <TableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <Th active>Descrição do produto</Th>
                      <Th active>Quantidade</Th>
                      <Th active>Preço unitário</Th>
                      <Th active>Desconto</Th>
                      <Th active>Subtotal</Th>
                      <Th active>
                        <RemoveTdWrapper>
                          <span>Selecione um motivo</span>
                          <Button
                            text="Remover"
                            variation="delete"
                            onClick={() => removeItem(product.id)}
                          />
                        </RemoveTdWrapper>
                      </Th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <Td>
                        <ProductInfo
                          title={product.name}
                          cod={product.sellerSku}
                          imgUrl={product.imageUrl}
                        />
                      </Td>
                      <Td>{item.quantity}</Td>
                      <Td>
                        {product?.basePrice?.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Td>
                      <Td>
                        {(item.quantity * product?.discount)?.toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                      </Td>
                      <Td>
                        {(item.quantity * product?.price)?.toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                      </Td>
                      <Td>
                        <Select
                          options={options}
                          placeholder="Selecione um motivo..."
                          menuIsOpen={false}
                          value={activeOption}
                          styles={selectStyle}
                        />
                      </Td>
                    </tr>
                  </tbody>
                </Table>
                <CheckListResumeWrapper>
                  <ChecklistResumeColumn>
                    <CheckListResumeText>Checklist</CheckListResumeText>
                    <CheckListResumeLabel id="checklistValue">
                      {`${selectedItem}`}
                    </CheckListResumeLabel>
                  </ChecklistResumeColumn>
                  <ChecklistResumeColumn>
                    <CheckListResumeText>Observação</CheckListResumeText>
                    <CheckListResumeNote id="observationValue">
                      {item?.returnNote}
                    </CheckListResumeNote>
                  </ChecklistResumeColumn>
                </CheckListResumeWrapper>
              </TableWrapper>
            </div>
          </>
        );
      })}
      {!!(remainProducts?.length || !returnItems.length) && products?.length && (
        <>
          <Heading>Selecione o item</Heading>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <Th active>Descrição do produto</Th>
                  <Th active={!!currentProduct?.productValue}>Quantidade</Th>
                  <Th active={!!currentProduct?.productValue}>
                    Preço unitário
                  </Th>
                  <Th active={!!currentProduct?.productValue}>Desconto</Th>
                  <Th active={!!currentProduct?.productValue}>Subtotal</Th>
                  <Th active={!!currentProduct?.productValue}>
                    Selecione um motivo
                  </Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>
                    {
                      <ProductSelect
                        isDisabled={loadingOptions}
                        options={productOptions}
                        placeholder="Selecione um produto..."
                        onChange={handleProductChange}
                      />
                    }
                  </Td>
                  <Td>
                    {currentProduct?.productValue ? (
                      consignment?.delivery?.modality == "DRIVE" &&
                      consignment?.status?.status == "FPR" ? (
                        currentProduct.quantity
                      ) : (
                        <Select
                          options={quantityArray}
                          placeholder="Qtd"
                          onChange={handleQuantityChange}
                          styles={selectStyle}
                        />
                      )
                    ) : null}
                  </Td>
                  <Td>
                    {currentEditProduct?.basePrice?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Td>
                  <Td>
                    {currentProduct?.quantity &&
                      (
                        parseInt(currentProduct?.quantity) *
                        currentEditProduct?.discount
                      )?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                  </Td>
                  <Td>
                    {(
                      currentProduct?.quantity &&
                      parseInt(currentProduct?.quantity) *
                        currentEditProduct?.price
                    )?.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Td>
                  <Td>
                    {currentProduct?.productValue ? (
                      <Select
                        options={options}
                        placeholder="Selecione um motivo..."
                        onChange={handleReturnReason}
                        styles={selectStyle}
                      />
                    ) : null}
                  </Td>
                </tr>
              </tbody>
            </Table>
          </TableWrapper>
          {currentProduct?.returnReason && currentProduct?.quantity ? (
            <>
              <Heading>Checklist</Heading>
              <CheckListWrapper>
                {getAuthorizedPartialRefund ? (
                  <CheckListColumn>
                    {checkList.map((c) => (
                      <CheckListOption
                        id="checklist"
                        onClick={() =>
                          setCheckListOptionSelected(c.diagnosticName)
                        }
                      >
                        <input
                          type="radio"
                          className="checkListValue"
                          id={c.diagnosticCode}
                          name={c.diagnosticName}
                          value={c.diagnosticName}
                          onClick={handleItemSelected}
                          checked={selectedItem === c.diagnosticName}
                        />
                        <CheckListLabel htmlFor={c.diagnosticCode}>
                          {c.diagnosticName}
                        </CheckListLabel>
                      </CheckListOption>
                    ))}
                  </CheckListColumn>
                ) : (
                  <CheckListColumn>
                    {checkList.map((c) => (
                      <CheckListOption
                        id="checklist"
                        onClick={getCheckPermission}
                      >
                        <input
                          type="radio"
                          className="checkListValue"
                          id={c.diagnosticCode}
                          name={c.diagnosticName}
                          value={c.diagnosticName}
                          checked={selectedItem === c.diagnosticName}
                          onClick={handleItemSelected}
                          disabled={c.permission === "N2" ? true : false}
                        />
                        <CheckListLabel htmlFor={c.diagnosticCode}>
                          {c.diagnosticName}
                        </CheckListLabel>
                      </CheckListOption>
                    ))}
                  </CheckListColumn>
                )}

                <NoteColumn>
                  <NoteItems>
                    <Subheading>Observação</Subheading>
                    <Textarea onChange={handleNoteChange} maxLength={150} />
                    <TextareaInfo>Máximo de 150 caracteres</TextareaInfo>
                  </NoteItems>

                  {checkPermission && (
                    <AlertPermission>
                      <AlertPermissionIco>
                        <img src={iconAlert} alt="iconAlert" />
                      </AlertPermissionIco>
                      <AlertPermissionText>
                        {`Você não tem permissão para selecionar essa opção. Entre em contato com o seu gestor`}
                      </AlertPermissionText>
                    </AlertPermission>
                  )}
                </NoteColumn>
              </CheckListWrapper>

              <ActionsWrapper>
                <Button
                  text="Salvar"
                  variation="confirm"
                  disabled={
                    !currentProduct.returnNote ||
                    checkPermission ||
                    !checkListOptionSelected
                  }
                  onClick={saveItem}
                />
              </ActionsWrapper>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default ReturnReasonsItem;
