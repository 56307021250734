import styled from "styled-components";

export const ExchangeFeedbackWrapper = styled.div`
  width: 400px;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;

  text-align: center;
  box-sizing: border-box;
`;

export const ExchangeFeedbackTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;

  color: #454545;

  margin-top: 24px;
  margin-bottom: 16px;
`;

export const ExchangeFeedbackDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  color: #575757;

  margin-top: 0;
  margin-bottom: 24px;
`;

export const ExchangeFeedbackButton = styled.button`
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: #0970e6;

  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 700;

  color: #fff;
`;
