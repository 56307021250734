import React, { useCallback } from "react";
import { formatDate, formatMoney } from "@utils/formatUtils";
import DataTable from "react-data-table-component";
import { customStyles } from "@utils/customStyles";

type DataGift = {
  data?: any;
  loadCustomer?: any;
  handlePerRowsChange?: any;
  loading: boolean;
  LoadingComponent: React.ReactElement;
  totalRecords?: number;
};

const Table = ({
  data = [],
  loadCustomer,
  handlePerRowsChange,
  loading,
  totalRecords,
  LoadingComponent,
}: DataGift) => {
  const columns: any = [
    {
      name: "CÓDIGO DO VALE",
      cell: (row: { number: string }) => (
        <div style={{ fontWeight: 300 }}>{row.number}</div>
      ),
    },
    {
      name: "DATA CRIAÇÃO",
      cell: (row: { creationDate: string }) => (
        <div style={{ fontWeight: 300 }} data-testid="row-data-date">
          {formatDate(row.creationDate)}
        </div>
      ),
    },
    {
      name: "PEDIDO ORIGINAL",
      cell: (row: { principalCode: string }) => (
        <div style={{ fontWeight: 300 }}>{row.principalCode}</div>
      ),
      grow: 1.5,
    },
    {
      name: "VALOR ORIGINAL",
      cell: (row: { originalAmount: number }) => (
        <div style={{ fontWeight: 300 }}>{formatMoney(row.originalAmount)}</div>
      ),
      sortable: false,
    },
    {
      name: "VALIDADE",
      cell: (row: { expirationDate: string }) => (
        <div style={{ fontWeight: 300 }}>{formatDate(row.expirationDate)}</div>
      ),

      allowOverflow: false,
    },
    {
      name: "SALDO ATUAL",
      cell: (row: { balance: number }) => (
        <div style={{ fontWeight: 300 }}>{formatMoney(row.balance)}</div>
      ),

      allowOverflow: false,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Pedidos por página",
    rangeSeparatorText: "de",
  };

  return (
    <DataTable
      noHeader={true}
      columns={columns}
      progressPending={loading}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={loadCustomer}
      paginationComponentOptions={paginationOptions}
      paginationTotalRows={totalRecords}
      pagination
      paginationServer
      progressComponent={LoadingComponent}
      noDataComponent={
        <h3>Cliente não contém vale-troca vinculado a este e-mail</h3>
      }
      data={data}
      style={{
        borderRadius: "8px",
        boxShadow: "0px 0px 8px rgba(176, 190, 197, 0.3)",
      }}
      customStyles={customStyles}
    />
  );
};

export default Table;
