import React from "react";

import {
  CardModal,
  Container,
  IconDownloadEmpy,
  CloseModalButton,
  TextEmpyReport,
} from "../styles";
import { useHistory } from "react-router-dom";

type Props = {
  successMessage?: string;
};

const ModalEmpyReport: React.FC = () => {
  const history = useHistory();
  function closeModalError() {
    history.push(`/home`);
  }
  return (
    <CardModal>
      <Container>
        <IconDownloadEmpy onClick={closeModalError} />
        <TextEmpyReport>
          Não existem dados nesse momento, tente mais tarde
        </TextEmpyReport>
        <CloseModalButton onClick={closeModalError}>Fechar</CloseModalButton>
      </Container>
    </CardModal>
  );
};

export default ModalEmpyReport;
