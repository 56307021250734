import styled from "styled-components";

export const OptionButton = styled.label`
  border: 1px solid #eceff1;
  padding: 20px 16px 20px 36px;
  margin: 0px 12px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 8px;
  line-height: 24px;
  background: #ffffff;
  display: flex;
  align-items: center;

  .home-icon path,
  .package-icon path,
  .truck-icon path {
    stroke: #263238;
  }

  .money-icon path {
    fill: #263238;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 80px;
  padding-top: 50px;
`;

export const OptionsText = styled.span`
  margin-left: 12px;
`;

export const OptionRadio = styled.input`
  position: absolute;
  left: 26px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;

  &:checked + label {
    border: 1px solid #1e5bc6;
    color: #1e5bc6;
    box-shadow: 0px 0px 8px rgba(176, 190, 197, 0.3);

    .home-icon path,
    .package-icon path,
    .truck-icon path {
      stroke: #1e5bc6;
    }

    .money-icon path {
      fill: #1e5bc6;
    }
  }
`;

export const OptionContainer = styled.div`
  position: relative;
`;
