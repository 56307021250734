import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectStyle } from "@components/utilities";

import {
  SelectedItem as ISelectedItem,
  requestFetchItemsAvailability,
  setChangeItemAvailabilityLoadingExchange,
  setChangeItemAvailabilityToExchange,
  setChangeItemObservationExchange,
  setChangeItemQuantityExchange,
  setChangeItemChecklistExchange,
  setChangeItemReasonExchange,
  setRemoveItemToExchange,
} from "../../../../../store/exchange-reducer";
import {
  SelectedItemWrapper,
  Table,
  Th,
  Image,
  ProductInfo,
  ProductInfoWrapper,
  Td,
  ProductName,
  ProductRef,
  ProductRemoveButton,
  AvailabilityError,
  ChecklistContainer,
  CheckListColumn,
  ChecklistWrapper,
  Heading,
  CheckListOption,
  CheckListLabel,
  CheckListResumeLabel,
  CheckListResumeText,
  Observation,
  ProductQuantity,
  ProductReason,
  ProductObservation,
} from "./styles";
import Trash from "../../../icons/Trash";
import { formatMoney } from "../../../../../utils/formatUtils";
import { Reason } from "../../select-quantity-and-reason/index";
import { AppState } from "../../../../../store";
import { checklist } from "../../../../../mocks/checklist";

interface SelectedItemProps {
  canEdit?: boolean;
  item: ISelectedItem;
  reasonOptions?: Reason[];
  consignmentCode?: string;
}

interface Quantity {
  label: string;
  value: number;
}

const SelectedItem = ({
  canEdit,
  item,
  reasonOptions,
  consignmentCode,
}: SelectedItemProps) => {
  const orderToExchange = useSelector(
    (state: AppState) => state.exchange.orderToExchange
  );
  const [checklists, setChecklists] = useState<string[]>(
    item.exchange.checklist
  );
  const dispatch = useDispatch();

  const handleRemoveItem = () => {
    dispatch(setRemoveItemToExchange(item.vtexItemId));
  };

  const handleChangeChecklist = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    let updatedList = [...checklists];

    if (event.currentTarget.checked) {
      updatedList = [...checklists, value];
    } else {
      updatedList.splice(checklists.indexOf(value), 1);
    }

    setChecklists(updatedList);
    dispatch(setChangeItemChecklistExchange(item.vtexItemId, updatedList));
  };

  const handleChangeReason = (reason: Reason) => {
    dispatch(setChangeItemReasonExchange(item.vtexItemId, reason));
  };

  const handleChangeQuantity = async (quantity: Quantity) => {
    dispatch(setChangeItemQuantityExchange(item.vtexItemId, quantity));

    dispatch(setChangeItemAvailabilityLoadingExchange(item.vtexItemId, true));

    try {
      const response = await requestFetchItemsAvailability(
        [
          {
            id: item.vtexItemId,
            quantity: quantity.value,
          },
        ],
        orderToExchange.orderCode,
        consignmentCode
      );

      const [currentItemAvailability] = response.data.items;

      const isItemAvailable = currentItemAvailability.quantity !== 0;

      dispatch(
        setChangeItemAvailabilityToExchange(item.vtexItemId, isItemAvailable)
      );
    } catch {}

    dispatch(setChangeItemAvailabilityLoadingExchange(item.vtexItemId, false));
  };

  const handleChangeObservation = (value: string) => {
    dispatch(setChangeItemObservationExchange(item.vtexItemId, value));
  };

  const quantityArray = [...new Array(item.quantity)].map((_, i) => ({
    label: `${i + 1}`,
    value: i + 1,
  }));

  return (
    <SelectedItemWrapper canEdit={canEdit}>
      <Table>
        <thead>
          <Th>Descrição do produto</Th>

          <Th>Quantidade</Th>

          <Th>Preço Unitário</Th>

          <Th>Desconto</Th>

          <Th>Subtotal</Th>

          <Th>Selecione um motivo</Th>
        </thead>

        <tbody>
          <tr>
            <Td>
              <ProductInfoWrapper>
                <Image src={item.imageUrl} alt="Imagem do Produto" />

                <ProductInfo>
                  <ProductName>{item.name}</ProductName>
                  <ProductRef>Cód.{item.refId} </ProductRef>

                  {canEdit && (
                    <ProductRemoveButton onClick={handleRemoveItem}>
                      <Trash />
                      Remover
                    </ProductRemoveButton>
                  )}
                </ProductInfo>
              </ProductInfoWrapper>
            </Td>

            <Td>
              {canEdit ? (
                <Select
                  value={item.exchange.quantity}
                  options={quantityArray}
                  placeholder="Qtde"
                  onChange={handleChangeQuantity}
                  styles={selectStyle}
                />
              ) : (
                <ProductQuantity>
                  {item.exchange.quantity.value}
                </ProductQuantity>
              )}

              {!item.exchange.isAvailable &&
                !item.exchange.isAvailabilityLoading && (
                  <AvailabilityError>
                    Produto indisponível no estoque para a quantidade
                    selecionada. Por favor, escolha uma quantidade menor ou
                    prossiga com o reembolso.
                  </AvailabilityError>
                )}
            </Td>

            <Td>{formatMoney(item.basePrice)}</Td>

            {/* Adicionar sinal de negativo */}
            <Td discount>
              {item.exchange.quantity.value
                ? formatMoney(item.exchange.quantity.value * item.discount)
                : "-"}
            </Td>
            <Td>
              {item.exchange.quantity.value
                ? formatMoney(item.exchange.quantity.value * item.price)
                : "-"}
            </Td>
            <Td>
              {canEdit ? (
                <Select
                  value={item.exchange.reason}
                  options={reasonOptions}
                  placeholder="Selecione um motivo..."
                  onChange={handleChangeReason}
                  styles={selectStyle}
                />
              ) : (
                <ProductReason>{item.exchange.reason.label}</ProductReason>
              )}
            </Td>
          </tr>
        </tbody>
      </Table>

      <ChecklistContainer>
        {canEdit ? (
          <ChecklistWrapper>
            <Heading>Checklist</Heading>
            <CheckListColumn>
              {checklist.map((option, index) => (
                <CheckListOption>
                  <input
                    type="checkbox"
                    id={`${option.diagnosticCode}-${index}`}
                    name="checklist"
                    value={option.diagnosticCode}
                    checked={item.exchange.checklist.includes(
                      option.diagnosticCode.toString()
                    )}
                    readOnly={!canEdit}
                    onChange={handleChangeChecklist}
                  />
                  <CheckListLabel htmlFor={`${option.diagnosticCode}-${index}`}>
                    {option.diagnosticName}
                  </CheckListLabel>
                </CheckListOption>
              ))}
            </CheckListColumn>
          </ChecklistWrapper>
        ) : (
          <ChecklistWrapper>
            <CheckListResumeText>Checklist</CheckListResumeText>
            <CheckListResumeLabel id="checklistValue">
              {`${item.exchange.checklist.join(" - ")}`}
            </CheckListResumeLabel>
          </ChecklistWrapper>
        )}

        {canEdit ? (
          <Observation
            onChange={(e) => handleChangeObservation(e.target.value)}
            placeholder="Observações (opcional)"
          />
        ) : (
          <ProductObservation>
            Obs.: {item.exchange.observation}
          </ProductObservation>
        )}
      </ChecklistContainer>
    </SelectedItemWrapper>
  );
};

export default SelectedItem;
