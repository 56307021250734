import styled from "styled-components";

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .form-field-label {
    text-align: left;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .form-field-info {
    text-align: left;
    font-size: 10px;
    line-height: 12px;
    margin: 4px 0 24px 0;
  }

  .new-order-title {
    font-family: Ubuntu;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;
    color: #40aa60;
    margin-bottom: 20px;
  }

  .new-order-label {
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    color: #b0bec5;
  }

  .new-order-info {
    font-family: Ubuntu;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    margin-bottom: 12px;
  }

  .bottom-info-label {
    font-family: Ubuntu;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
  }

  input[type="text"],
  select,
  textarea {
    height: 40px;
    background: #fafafa;
    border: 1px solid #cfd8dc;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;
    color: #37474f;
    font-size: 12px;
  }

  input:disabled {
    color: #607d8b;
  }

  textarea {
    height: 80px;
    padding: 16px;
    font-size: 12px;
  }
`;

export const ResendSucessModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 60px 0px 60px;

  .new-order-title {
    font-family: Ubuntu;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: center;
    color: #40aa60;
    margin-bottom: 25px;
  }

  .new-order-label {
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    color: #b0bec5;
  }

  .new-order-info {
    font-family: Ubuntu;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    color: #546e7a;
    margin-bottom: 25px;
  }

  .bottom-info-label {
    font-family: Ubuntu;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-directions: column;
  height: 40px;
  align-items: center;

  label {
    font-size: 14px;
    line-height: 16px;
    color: #37474f;
    margin-right: 32px;
  }
`;

export const FormContainer = styled.form`
  background: #ffffff;
  border: 1px solid #eceff1;
  box-sizing: border-box;
  border-radius: 4px;
`;
