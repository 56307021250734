import React, { useState } from "react";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { Column, Row } from "@components/utilities";
import {
  CardModal,
  Title,
  RowWithBorder,
  SmallTextBold,
  TextHighlighNormal,
  TextHighlighBold,
  Pipeline,
  Text,
  RowSimple,
} from "../styles";
import { formatMoney } from "@utils/formatUtils";

import { useSelector } from "react-redux";
import { AppState } from "@store/index";

type Props = {
  action?: (...args: any[]) => any;
  close?: (...args: any[]) => any;
};

const ModalRefund = (props: Props) => {
  const infoPayments = useSelector(
    (state: AppState) => state.returnItems.orderDetails.payments[0]
  );

  const refundMethodType = useSelector(
    (state: AppState) => state.returnItems.returnMethod
  );

  const totalValue = useSelector(
    (state: AppState) => state.order.orderDetail.totalValue
  );

  const infosCustomer = useSelector(
    (state: AppState) => state.order.orderDetail
  );

  const refundMethod = useSelector(
    (state: AppState) => state.returnItems.refundMethod
  );

  const infoCustomerFullName = `${infosCustomer.customerFirstName} ${infosCustomer.customerLastName}`;
  const infoCustomerEmail = `${infosCustomer.customerEmail}`;
  const infoPaymentsLastDigits = `${infosCustomer.payments[0].lastDigits}`;
  const infoPaymentsSystemName = `${infosCustomer.payments[0].paymentSystemName}`;
  const infoPaymentsInstallments = `${infosCustomer.payments[0].installments}`;
  const infoPaymentMethod = infoPayments.paymentMethod;

  return (
    <CardModal padding="50px 150px">
      <Column margin="0 auto" textAlign="center">
        <Title>
          {refundMethodType === "PARTIAL_REFUND" && (
            <b>{`Estorno Parcial |`}</b>
          )}
          {refundMethodType === "TOTAL_REFUND" && <b>{`Estorno Total |`}</b>}
          {refundMethodType === "FREIGHT_REFUND" && (
            <b>{`Estorno de Frete |`}</b>
          )}
          {infoPaymentMethod === "GIFT_CARD" && <b> {`Vale-troca`}</b>}
          {infoPaymentMethod === "CREDIT_CARD" && <b> {`Cartão de crédito`}</b>}
          {infoPaymentMethod === "DEBIT_CARD" && <b> {`Vale-troca`}</b>}
          {infoPaymentMethod === "ACCOUNT_DEPOSIT" && <b> {`Vale-troca`}</b>}
          {infoPaymentMethod === "BANK_SLIP" && <b> {`Vale-troca`}</b>}
          {infoPaymentMethod === "PAYMENT_ORDER" && <b> {`Vale-troca`}</b>}
          {infoPaymentMethod === "PIX" && <b> {`Pix`}</b>}
          {infoPaymentMethod === "PROMISSORY" && (
            <b> {`Cartão Carrefour Virtual`}</b>
          )}
        </Title>
      </Column>

      <RowWithBorder margin={"30px"} padding={"30px"} width={"544px"}>
        <Column>
          <SmallTextBold>{`Valor do estorno`}</SmallTextBold>
          <TextHighlighBold>
            <span>
              {formatMoney(parseFloat(localStorage.getItem("ValueRefund")))}
            </span>
          </TextHighlighBold>
        </Column>
        <Pipeline></Pipeline>
        <Column>
          <SmallTextBold>{`Valor da transação`}</SmallTextBold>
          <TextHighlighNormal>
            <span>{formatMoney(totalValue)}</span>
          </TextHighlighNormal>
        </Column>
      </RowWithBorder>
      <RowSimple>
        <Column>
          <SmallTextBold>{`Nome do Cliente:`}</SmallTextBold>
          <Text>{infoCustomerFullName}</Text>
        </Column>
        <Column>
          <SmallTextBold>{`Email:`}</SmallTextBold>
          <Text>{infoCustomerEmail}</Text>
        </Column>
      </RowSimple>

      <RowSimple>
        {infoPayments.paymentMethod === "CREDIT_CARD" && (
          <>
            <Column>
              <SmallTextBold>{`Número do cartão:`}</SmallTextBold>
              <Text>{`**** **** **** ${infoPaymentsLastDigits} `}</Text>
            </Column>
            <Column>
              <SmallTextBold>{`Bandeira do cartão:`}</SmallTextBold>
              <Text>{`${infoPaymentsSystemName} `}</Text>
            </Column>
            <Column>
              <SmallTextBold>{`Parcelas:`}</SmallTextBold>
              <Text>{infoPaymentsInstallments === "1" && `à vista`}</Text>
              <Text>
                {infoPaymentsInstallments !== "1" &&
                  `${infoPaymentsInstallments} x`}
              </Text>
            </Column>
          </>
        )}
      </RowSimple>
      <Row margin="30px 0 0 0">
        <Column margin="0 20px 0 0">
          <Button
            text="Cancelar"
            variation="primary"
            onClick={props.close}
            size="large"
            outline
          />
        </Column>
        <Button
          text="Confirmar"
          variation="confirm"
          onClick={props.action}
          size="large"
        />
      </Row>
    </CardModal>
  );
};

export default ModalRefund;
