import React from "react";
import { formatDate } from "@utils/formatUtils";
import { Card } from "carrefour-portal-backoffice-style-guide";
import { Row, Column } from "@components/utilities";
import {
  TrackingContainer,
  TabContainer,
  TabContent,
  TabLink,
  TrackUl,
  TrackLi,
  TrackDesc,
  Dot,
} from "./styles";

type Consignment = {
  status: Status[];
  reverse?: boolean;
  hiddenStatus?: string[];
};

type Status = {
  status: string;
  description: string;
  creationTime: string;
  color: string;
};

const Tracking = ({ status, reverse, hiddenStatus }: Consignment) => {
  return (
    <TrackingContainer>
      <Card background="#FCFDFF" width="auto" height="auto" padding="25px">
        <TabContent>
          <Row>
            <TabLink active={true}>
              {reverse ? "Tracking Reverso" : "Tracking"}
            </TabLink>
          </Row>
          <TabContainer>
            <TrackUl>
              {status?.length > 0 ? (
                status.map((tracking, index) => (
                  <>
                    {!hiddenStatus?.includes(tracking.status) ? (
                      <TrackLi key={index} data-testid="tracking-item">
                        <Row justify="flex-start">
                          <Dot background={tracking.color}>
                            <div></div>
                          </Dot>
                          <Column>
                            <TrackDesc>
                              <strong>{tracking.status}</strong>
                              <span>{tracking.description}</span>
                              <small>{formatDate(tracking.creationTime)}</small>
                            </TrackDesc>
                          </Column>
                        </Row>
                      </TrackLi>
                    ) : (
                      <></>
                    )}
                  </>
                ))
              ) : (
                <TrackLi data-testid="tracking-item-empty">
                  <Row justify="flex-start">
                    <strong>Items not found</strong>
                  </Row>
                </TrackLi>
              )}
            </TrackUl>
          </TabContainer>
        </TabContent>
      </Card>
    </TrackingContainer>
  );
};

export default Tracking;
