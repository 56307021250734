import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useModal from "react-hooks-use-modal";

import { AppState } from "@store/index";
import { Content, Container, Column, Row } from "@components/utilities";
import {
  Breadcrumbs,
  Card,
  Button,
} from "carrefour-portal-backoffice-style-guide";
import PageHeader from "@components/page-header";
import CancelSummary from "@components/cancel-summary";
import { OrderState } from "@store/order-reducer";
import { ContainerTitle, Title, Subtitle } from "@pages/styles";

import {
  FormContainer,
  FormGroup,
  RadioGroup,
  ResendSucessModalStyle,
} from "./styles";

import { getForceInstaceReasons, executeLink } from "@services/forceInstance";
import { ActionBar } from "./components/action-bar";
import { ConfirmRefundModalContent } from "./components/modal-confirm-refund";
import { ConfirmResendModalContent } from "./components/modal-confirm-resend";
import { CloseModal } from "./components/modal-confirm-refund/styles";
import {
  MessageModalContent,
  ModalType,
} from "./components/message-modal/index";
import { RetryModalContent } from "./components/modal-retry";

export enum ForceInstanceType {
  FORCE_DEVOLUTION_REFUND = "FORCE_DEVOLUTION_REFUND",
  FORCE_DELIVERY_FAILURE_REFUND = "FORCE_DELIVERY_FAILURE_REFUND",
  FORCE_DELIVERY_FAILURE_RESEND = "FORCE_DELIVERY_FAILURE_RESEND",
}

export interface ForceInstanceReason {
  reasonCode: string;
  description: string;
  isObservationMandatory: boolean;
}

export interface ForceInstanceForm {
  reason?: string;
  ticket?: string;
  observation?: string;
  returnToWarehouse?: boolean;
  type?: ForceInstanceType;
}

export const ForceInstanceFlow: React.FC = () => {
  const { orderDetail } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  const { selectedConsignment } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  const { forceInstance } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState<ForceInstanceForm>({});
  const [isValid, setIsValid] = useState(false);
  const [observationSize, setObservationSize] = useState(250);
  const [observationNotTrim, setObservationNotTrim] = useState(250);
  const [reasons, setReasos] = useState<ForceInstanceReason[]>([]);
  const [isLoadingReasons, setIsLoadingReasons] = useState(true);
  const [resendOrderCode, setResendOrderCode] = useState("");

  /**
   * Modals and auxiliary variables to control click outside modal
   */
  const [shouldCloseSuccessModal, setShouldCloseSuccessModal] = useState(false);
  const [
    shouldCloseResendSuccessModal,
    setShouldCloseResendSuccessModal,
  ] = useState(false);
  const [shouldCloseErrorModal, setShouldCloseErrorModal] = useState(false);
  const [shouldCloseRetryModal, setShouldCloseRetryModal] = useState(false);

  const [
    ConfirmRefundModal,
    openConfirmRefundModal,
    closeConfirmRefundModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });

  const [
    ConfirmResendModal,
    openConfirmResendModal,
    closeConfirmResendModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });

  const [
    SuccessModal,
    openSuccessModal,
    closeSuccessModal,
    isOpenSuccessModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });

  const [
    ResendSuccessModal,
    openResendSuccessModal,
    closeResendSuccessModal,
    isOpenResendSuccessModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });

  const [
    ErrorModal,
    openErrorModal,
    closeErrorModal,
    isOpenErrorModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });

  const [
    RetryModal,
    openRetryModal,
    closeRetryModal,
    isOpenRetryModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });
  /********************************/

  /**
   * Behavior on click outside modal
   */
  useEffect(() => {
    if (!isOpenSuccessModal && shouldCloseSuccessModal) {
      handleCloseMessageModal("success");
      setShouldCloseSuccessModal(false);
    }
  }, [isOpenSuccessModal]);

  useEffect(() => {
    if (!isOpenResendSuccessModal && shouldCloseResendSuccessModal) {
      handleCloseMessageModal("success");
      setShouldCloseResendSuccessModal(false);
    }
  }, [isOpenSuccessModal]);

  useEffect(() => {
    if (!isOpenErrorModal && shouldCloseErrorModal) {
      handleCloseMessageModal("error");
      setShouldCloseErrorModal(false);
    }
  }, [isOpenErrorModal]);

  useEffect(() => {
    if (!isOpenRetryModal && shouldCloseRetryModal) {
      handleCloseRetryModal(RetryModalAction.CLOSE);
      setShouldCloseRetryModal(false);
    }
  }, [isOpenRetryModal]);

  /********************************/

  const goToDetail = () => {
    dispatch({ type: "CLEAR_RETURN_CONSIGNMENTS", payload: null });
    dispatch({ type: "ORDER_SELECTED", payload: orderDetail.orderCode });
    history.push(`/atendimento/pedido/detalhe/${orderDetail.orderCode}`);
  };

  useEffect(() => {
    loadForceInstanceReasons();
  }, []);

  useEffect(() => {
    setData({
      type: forceInstance.forceInstanceType,
    });
  }, [forceInstance]);

  useEffect(() => {
    let isValid =
      data.observation &&
      data.observation.trim().length > 10 &&
      data.ticket &&
      data.ticket.trim().length > 0 &&
      data.reason &&
      data.reason.trim().length > 0 &&
      data.returnToWarehouse !== undefined;
    setIsValid(isValid);

    if (data.observation) {
      setObservationSize(250 - data.observation.trim().length);
      setObservationNotTrim(
        250 + (data.observation.length - data.observation.trimLeft().length)
      );
    } else {
      setObservationSize(250);
      setObservationNotTrim(250);
    }
  }, [data]);

  const onChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    let propertyName: string = event.target.name;
    let propertyValue: string | boolean =
      event.target.type === "radio"
        ? event.target.value === "1"
        : event.target.value;
    let obj: any = {};
    obj[propertyName] = propertyValue;
    setData({ ...data, ...obj });
  };

  const loadForceInstanceReasons = async () => {
    const response = await getForceInstaceReasons();
    await setReasos(response.data);
    await setIsLoadingReasons(false);
  };

  const handleCloseFlow = () => {
    dispatch({ type: "CLEAR_RETURN_CONSIGNMENTS", payload: null });
    history.goBack();
  };

  const handleContinueFlow = () => {
    if (data.type == ForceInstanceType.FORCE_DELIVERY_FAILURE_RESEND) {
      handleOpenConfirmResendModal();
    } else {
      handleOpenConfirmRefundModal();
    }
  };

  const handleSaveForceInstance = (saveType: string) => {
    let forceLink;
    if (saveType === "refund") {
      forceLink = selectedConsignment.links.forceInstance;
    } else {
      forceLink = selectedConsignment.links.forceInstanceResend;
    }

    executeLink(forceLink, data)
      .then((response) => {
        if (saveType === "resend") {
          setResendOrderCode(response.data.resendOrderCode);
          handleOpenMessageModal("resendSuccess");
        } else {
          handleOpenMessageModal("success");
        }
      })
      .catch(() => {
        handleOpenMessageModal("error");
      });
  };

  const handleOpenMessageModal = (modalType: ModalType) => {
    switch (modalType) {
      case "success":
        setShouldCloseSuccessModal(true);
        openSuccessModal();
        break;
      case "resendSuccess":
        setShouldCloseResendSuccessModal(true);
        openResendSuccessModal();
        break;
      case "error":
        setShouldCloseErrorModal(true);
        openErrorModal();
        break;
    }
  };

  const handleCloseMessageModal = (modalType: ModalType) => {
    switch (modalType) {
      case "success":
        closeSuccessModal();
        goToDetail();
        break;
      case "resendSuccess":
        closeResendSuccessModal();
        goToDetail();
        break;
      case "error":
        closeErrorModal();
        goToDetail();
        break;
    }
  };

  const handleOpenRetryModal = async () => {
    await setShouldCloseRetryModal(true);
    await openRetryModal();
    setTimeout(() => {
      executeLink(selectedConsignment.links.forceInstance, data)
        .then((response) => {
          handleCloseRetryModal(RetryModalAction.CLOSE);
          handleOpenMessageModal("success");
        })
        .catch((error) => {
          console.error(error);
          handleCloseRetryModal(RetryModalAction.ERROR);
        });
    }, 5000);
  };

  enum RetryModalAction {
    CLOSE,
    ERROR,
  }
  const handleCloseRetryModal = (action: RetryModalAction) => {
    switch (action) {
      case RetryModalAction.ERROR:
        setShouldCloseRetryModal(false);
        closeRetryModal();
        handleOpenMessageModal("error");
        break;
      case RetryModalAction.CLOSE:
        goToDetail();
        break;
    }
  };

  const handleOpenConfirmRefundModal = () => {
    openConfirmRefundModal();
  };

  const handleOpenConfirmResendModal = () => {
    openConfirmResendModal();
  };

  const validate = (event: React.KeyboardEvent<HTMLInputElement>) => {
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento", label: "Atendimento" },
            { to: "/atendimento/forcar-instancia", label: "Forçar Instância" },
          ]}
        />
        <PageHeader title="PEDIDO" subtitle="Forçar Instância" button={false} />
        <CancelSummary data={orderDetail} />
        <Card
          width="auto"
          margin="50px 0 150px 0"
          padding="35px 150px 100px 150px"
        >
          <ContainerTitle>
            <Title>Formulário de requisição</Title>
            <Subtitle>
              <strong>Atenção:</strong> Preencha os campos para justificar os
              motivos da instância forçada.
            </Subtitle>

            <FormContainer>
              <Row justify="space-evenly">
                <Column width="330px">
                  <FormGroup>
                    <label className="form-field-label" htmlFor="orderCode">
                      Nº do pedido
                    </label>
                    <input
                      type="text"
                      id="orderCode"
                      name="orderCode"
                      value={orderDetail.orderCode}
                      disabled
                    />
                  </FormGroup>
                </Column>
                <Column width="330px">
                  <FormGroup>
                    <label
                      className="form-field-label"
                      htmlFor="consignmentCode"
                    >
                      Nº da entrega
                    </label>
                    <input
                      type="text"
                      id="consignmentCode"
                      name="consignmentCode"
                      value={selectedConsignment.consignmentCode}
                      disabled
                    />
                  </FormGroup>
                </Column>
              </Row>
              <Row justify="space-evenly">
                <Column width="330px">
                  <FormGroup>
                    <label className="form-field-label" htmlFor="ticket">
                      Nº do ticket *
                    </label>
                    <input
                      type="text"
                      id="ticket"
                      name="ticket"
                      onKeyPress={(e) => {
                        return validate(e);
                      }}
                      onChange={(e) => onChange(e)}
                    />
                  </FormGroup>
                </Column>
                <Column width="330px">
                  <FormGroup>
                    <label
                      className="form-field-label"
                      htmlFor="returnToWarehouse_yes"
                    >
                      Os produtos retornarão? *
                    </label>
                    <RadioGroup>
                      <input
                        type="radio"
                        name="returnToWarehouse"
                        id="returnToWarehouse_yes"
                        value="1"
                        onChange={(e) => onChange(e)}
                      />
                      <label htmlFor="returnToWarehouse_yes">Sim</label>
                      <input
                        type="radio"
                        name="returnToWarehouse"
                        id="returnToWarehouse_no"
                        value="0"
                        onChange={(e) => onChange(e)}
                      />
                      <label htmlFor="returnToWarehouse_no">Não</label>
                    </RadioGroup>
                  </FormGroup>
                </Column>
              </Row>
              <Row justify="space-evenly">
                <Column width="710px">
                  <FormGroup>
                    <label className="form-field-label" htmlFor="reason">
                      Motivo da exceção *
                    </label>
                    <select
                      name="reason"
                      id="reason"
                      disabled={isLoadingReasons}
                      onChange={(e) => onChange(e)}
                    >
                      {isLoadingReasons ? (
                        <option value="">Carregando...</option>
                      ) : (
                        <>
                          <option value="">Selecione um motivo</option>
                          {reasons.map((reason) => (
                            <option value={reason.reasonCode}>
                              {reason.description}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                  </FormGroup>
                </Column>
              </Row>
              <Row justify="space-evenly">
                <Column width="710px">
                  <FormGroup>
                    <label className="form-field-label" htmlFor="observation">
                      Comentário *
                    </label>
                    <textarea
                      name="observation"
                      id="observation"
                      maxLength={observationNotTrim}
                      onChange={(e) => onChange(e)}
                    />
                    <span className="form-field-info">
                      {observationSize} caracteres disponíveis.
                    </span>
                  </FormGroup>
                </Column>
              </Row>
            </FormContainer>
          </ContainerTitle>
        </Card>
      </Container>

      <ActionBar
        actionTitle={"Forçar Instância de:"}
        customerName={`${orderDetail.customerFirstName} ${orderDetail.customerLastName}`}
        consignmentCode={selectedConsignment.consignmentCode}
        isValidToContinue={isValid}
        onClose={() => handleCloseFlow()}
        onContinue={() => handleContinueFlow()}
      />

      <ConfirmRefundModal preventScroll={true}>
        <CloseModal onClick={closeConfirmRefundModal}>X</CloseModal>
        <ConfirmRefundModalContent
          onClose={closeConfirmRefundModal}
          onConfirm={() => handleSaveForceInstance("refund")}
          orderCode={orderDetail.orderCode}
          consignmentCode={selectedConsignment.consignmentCode}
          items={selectedConsignment.items}
        />
      </ConfirmRefundModal>

      <ConfirmResendModal preventScroll={true}>
        <CloseModal onClick={closeConfirmResendModal}>X</CloseModal>
        <ConfirmResendModalContent
          onClose={closeConfirmResendModal}
          onConfirm={() => handleSaveForceInstance("resend")}
        />
      </ConfirmResendModal>

      <SuccessModal preventScroll={true}>
        <CloseModal onClick={() => handleCloseMessageModal("success")}>
          X
        </CloseModal>
        <MessageModalContent
          modalType={"success"}
          iconAlt={"Instância forçada criada com sucesso"}
        >
          <div>
            A solicitação de restituição de valor foi concluída! Acompanhe a
            evolução do processo através do tracking da entrega.
          </div>
        </MessageModalContent>
      </SuccessModal>

      <ResendSuccessModal preventScroll={true}>
        <CloseModal onClick={() => handleCloseMessageModal("resendSuccess")}>
          X
        </CloseModal>
        <MessageModalContent
          modalType={"resendSuccess"}
          iconAlt={"Instância forçada criada com sucesso"}
        >
          <ResendSucessModalStyle>
            <Row className="new-order-title">
              A solicitação para liberação do novo pedido foi concluída!
            </Row>
            <Row className="new-order-label">NOVO PEDIDO</Row>
            <Row className="new-order-info">{resendOrderCode}</Row>
            <Row className="bottom-info-label">
              Acompanhe a evolução do processo através do tracking da entrega.
            </Row>
          </ResendSucessModalStyle>
        </MessageModalContent>
      </ResendSuccessModal>

      <ErrorModal preventScroll={true}>
        <CloseModal onClick={() => handleCloseMessageModal("error")}>
          X
        </CloseModal>
        <MessageModalContent
          modalType={"error"}
          iconAlt={"Erro ao criar instância forçada"}
        >
          <Column>
            <Row>
              Ops, não foi possível forçar a instância, tente novamente.
            </Row>
            <Row className="buttons">
              <Button
                text="Cancelar"
                variation="primary"
                margin="0 10px 0 0"
                size="medium"
                onClick={() => handleCloseMessageModal("error")}
                outline
              />

              <Button
                text={"Tentar novamente"}
                variation="confirm"
                margin="0 10px 0 0"
                size="medium"
                onClick={() => handleOpenRetryModal()}
              />
            </Row>
          </Column>
        </MessageModalContent>
      </ErrorModal>

      <RetryModal>
        <CloseModal
          onClick={() => handleCloseRetryModal(RetryModalAction.CLOSE)}
        >
          X
        </CloseModal>
        <RetryModalContent />
      </RetryModal>
    </Content>
  );
};
