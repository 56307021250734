import http from "./http";

type OrderRequest = {
  orderCode: string;
  customerEmail: string;
  consignmentCode: string;
  observation: string;
  reason: string;
  refund: Refund;
};

type Refund = {
  type: string;
  bankAccount: BankAccount;
};

type BankAccount = {
  type: string;
  bank: string;
  branch: string;
  account: string;
  digit: string;
};

export async function requestPartialCancellation(order: OrderRequest) {
  try {
    let data;
    if (order.refund.type === "ACCOUNT_DEPOSIT") {
      data = {
        observation: order.observation,
        reason: order.reason,
        refund: order.refund,
        customerEmail: order.customerEmail,
      };
    } else {
      data = {
        observation: order.observation,
        reason: order.reason,
        customerEmail: order.customerEmail,
        refund: {
          type: order.refund.type,
        },
      };
    }

    const response = await http.put(
      `/v1/orders/${order.orderCode}/consignments/${order.consignmentCode}/cancel`,
      {
        ...data,
        attendanceChannel: 1,
      }
    );

    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}

interface RefundPayload {
  type: string;
  bankAccount: {
    type: string;
    bank: string;
    branch: string;
    account: string;
    digit: string;
  };
}

export async function refund(
  orderCode: string,
  consignmentCode: string,
  payload: RefundPayload
) {
  try {
    const response = await http.put<RefundPayload>(
      `/v1/orders/${orderCode}/consignments/${consignmentCode}/refund`,
      payload
    );

    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}

interface CancelPayload {
  reason: string;
  observation: string;
  customerEmail: string;
  refund: {
    type: string;
  };
}

export async function cancel(orderCode: string, payload: CancelPayload) {
  try {
    const response = await http.put<CancelPayload>(
      `/v1/orders/${orderCode}/cancel`,
      {
        ...payload,
        attendanceChannel: 1,
      }
    );

    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}

export async function shipFromStoreActionRegister(
  orderCode: string,
  consignmentCode: string
) {
  try {
    const response = await http.post<RefundPayload>(
      `/v1/orders/${orderCode}/consignments/${consignmentCode}/return/shipFromStore/status/insert`
    );

    return response.status;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}
