import styled from "styled-components";

/*Modal*/
type TCardModal = {
  padding?: string;
};

export const CardModal = styled.div<TCardModal>`
  width: auto;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "20px"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
`;

export const TitleAlert = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #1e5bc6;
`;

export const DescAlert = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #263238;
`;

export const Subtitle = styled.p`
  margin: 32px 10px;
  font-size: 15px;
  font-weight: 400;

  span {
    color: #1e5bc6;
  }
`;

export const SmallText = styled.small`
  color: #607d8b;
  margin-top: 12px;
  font-weight: 300;
`;
