import React from "react";

import {
  CardModal,
  Container,
  IconDownloadFail,
  CloseModalButton,
  TextFailReport,
} from "../styles";
import { useHistory } from "react-router-dom";

type Props = {
  successMessage?: string;
};

const ModalErrorReport: React.FC = () => {
  const history = useHistory();
  function closeModalError() {
    history.push(`/home`);
  }
  return (
    <CardModal>
      <Container>
        <IconDownloadFail onClick={closeModalError} />
        <TextFailReport>Download não realizado, tente novamente</TextFailReport>
        <CloseModalButton onClick={closeModalError}>Fechar</CloseModalButton>
      </Container>
    </CardModal>
  );
};

export default ModalErrorReport;
