import React, { useState } from "react";
import { cancelContentCard } from "@services/cancelContentCard";
import { CardModal, TitleRedeemGift } from "../../styles";
import { Column, Row } from "@components/utilities";
import { Button, Text } from "carrefour-portal-backoffice-style-guide";

interface cancelContentCard {
  orderId: string;
  orderCode: string;
  sequence: string;
  userId: string;
  numId: string;
  giftcardSKU: string;
  emailTo: string;
  productName: string;
  userName: string;
  price: string;
  refund: Refund;
  close?: any;
}
type Refund = {
  type: string;
};

const ModalCancelGift = ({
  orderId,
  orderCode,
  sequence,
  userId,
  numId,
  giftcardSKU,
  emailTo,
  productName,
  userName,
  price,
  refund,
  close,
}: cancelContentCard) => {
  const [isLoading, setIsLoading] = useState(false);

  const cancelCard = async ({
    orderId,
    orderCode,
    sequence,
    userId,
    numId,
    giftcardSKU,
    emailTo,
    productName,
    userName,
    price,
    refund,
    close,
  }: cancelContentCard) => {
    setIsLoading(true);
    await cancelContentCard({
      orderId,
      orderCode,
      sequence,
      userId,
      numId,
      giftcardSKU,
      emailTo,
      productName,
      userName,
      price,
      refund,
    });

    window.location.reload();
    close();
    setIsLoading(false);
  };

  return (
    <>
      <CardModal
        data-testid="cancel-modal"
        padding="16px"
        style={{ maxWidth: `280px`, maxHeight: `265px`, textAlign: "center" }}
      >
        <TitleRedeemGift margin="0 0 16px">
          Tem certeza de que deseja cancelar o Gift Card do cliente?
        </TitleRedeemGift>
        <Text
          type="body"
          size="medium"
          text={`Esta ação é irreversível. Após o cancelamento, não será possível utilizar o cartão.`}
          margin="0"
        />
        <Row margin="24px 0 0 0" justify="center">
          <Column margin="0 16px 0 0">
            <Button
              text="Não"
              variation="primary"
              onClick={() => close()}
              size="large"
              outline
            />
          </Column>
          <Button
            text={isLoading ? "Cancelando..." : "Sim"}
            variation="primary"
            disabled={isLoading}
            onClick={() =>
              cancelCard({
                orderId,
                orderCode,
                sequence,
                userId,
                numId,
                giftcardSKU,
                emailTo,
                productName,
                userName,
                price,
                refund,
                close,
              })
            }
            size="large"
            outline
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalCancelGift;
