import React, { useEffect } from "react";
import { Column } from "@components/utilities";
import { TitleAlert, CardModal } from "../../styles";
import iconAlert from "@assets/exclamation.svg";

const ModalError = () => {
  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        <img src={iconAlert} alt="iconAlert" />
        <Column margin="30px 0 0 0" textAlign="center">
          <TitleAlert>
            Ocorreu um erro com <br />
            sua solicitação
          </TitleAlert>
        </Column>
      </CardModal>
    </>
  );
};

export default ModalError;
