import http from "./http";

export async function getReport(
  initialPeriod: string,
  endPeriod: string,
  reportType: string
) {
  const url = "/v1/reports";
  try {
    const response = await http.get(url, {
      params: {
        initialPeriod: initialPeriod,
        endPeriod: endPeriod,
        reportType: reportType,
      },
    });
    return response;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        return error.response.status;
      }
      if (error.response.status === 422) {
        const errorStatus = error.response.status;
        return errorStatus;
      }
    }
  }
}
