export enum modalStatusEnum {
  SelectShipping = "SELECT_SHIPPING",
  Shipping = "SHIPPING",
  BilletStatus = "BILLET_STATUS",
  BilletConfirmation = "BILLET_CONFIRMATION",
  StockBreak = "STOCK_BREAK",
  ForceInstance = "FORCE_INSTANCE",
  RedeemGift = "REDEEM_GIFT",
  CancelGift = "CANCEL_CONTENT_CARD",
  shipFromStoreRegister = "SHIP_FROM_STORE_REGISTER",
  shipFromStoreLearnMore = "SHIP_FROM_STORE_LEARN_MORE",
}
