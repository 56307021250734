import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Card,
  Button,
} from "carrefour-portal-backoffice-style-guide";

import firebase from "../../services/firebase";
import PageHeader from "@components/page-header";

import { Content, Container } from "@components/utilities";

import { totalRefund } from "../../services/totalRefund";

import { Row, Label, Input } from "./styles";

export const TotalRefund = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const authorizedPersons = [
    "arthur_cardoso_ext@carrefour.com",
    "ana_lucia_ramos_da_silva@carrefour.com",
    "matheus_novais_ext@carrefour.com",
    "rodrigo_zani@carrefour.com",
    "ramon_ramos_ext@carrefour.com",
    "arthur_abreu_ext@carrefour.com",
    "renan_gomes_ext@carrefour.com",
  ];

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    const response = await totalRefund(formData);
    setStatus(response);
    setIsLoading(false);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) window.location.href = "/";
    });

    const user = JSON.parse(
      localStorage.getItem("firebaseui::rememberedAccounts")
    );

    if (user) {
      setUserEmail(user[0].email);
    }
  }, []);

  if (!authorizedPersons.includes(userEmail)) {
    return <></>;
  }
  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            {
              to: "#",
              label: "Reembolso Total",
            },
          ]}
        />
        <PageHeader
          title="Reembolso Total"
          subtitle={"Formulário para reembolso total"}
          button={false}
        />

        <Card>
          <form onSubmit={handleSubmit}>
            <Row>
              <Label>Upload da planilha</Label>
              <Input
                name="test"
                type="file"
                required
                onChange={(e) => setSelectedFile(e.target.files[0])}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </Row>
            <Row>
              <Button
                text={isLoading ? "Enviando" : "Enviar"}
                size="large"
                onClick={() => {}}
                variation="primary"
              />

              <div>
                {status === 200 &&
                  "Arquivo enviado com sucesso. Processamento em andamento"}
                {status && status !== 200 && "Ocorreu um erro ao enviar"}
              </div>
            </Row>
          </form>
        </Card>
      </Container>
    </Content>
  );
};
