// @ts-nocheck
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setBankInfo, setRefundMethod } from "@store/refund-items-reducer";
import { AppState } from "@store/index";
import { getBanks } from "@services/banks";
import { Row, selectStyle } from "@components/utilities";
import { formatMoney } from "@utils/formatUtils";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";
import {
  BorderContainer,
  InputGroup,
  Input,
  Label,
  PaymentMethods,
  PaymentMethod,
  PaymentMethodBtn,
  PaymentMethodLabel,
  SwitchForm,
  ButtonSwitch,
} from "./styles";

import { cpfMask, validateBankInfo } from "@utils/masks";

const AccountRefund = (props: any) => {
  const { orderDetails } = props;
  const dispatch = useDispatch();
  const paymentOption = useSelector(
    (state: AppState) => state.returnItems.refundMethod
  );
  const refundInfo = useSelector(
    (state: AppState) => state.returnItems.refundInfo
  );
  const restituitionValue = refundInfo?.find(
    (r: any) => r.type === paymentOption
  );

  const [paymentAccount, setPaymentAccount] = useState("");
  const [bankOptions, setBankOptions] = useState([]);
  const [dataBank, setDataBank] = useState({});
  const [customer, setCustomer] = useState("");
  const [document, setDocument] = useState("");
  const [bank, setBank] = useState({});
  const [branch, setBranch] = useState("");
  const [account, setAccount] = useState("");
  const [digit, setDigit] = useState("");

  async function requestBanks() {
    const response = await getBanks();
    const bankOptions = response.data.map((bank) => ({
      label: bank.fullName,
      value: bank.code,
    }));

    setBankOptions(bankOptions);
  }

  useEffect(() => {
    requestBanks();
  }, []);

  useEffect(() => {
    const constumerName = `${orderDetails?.customerFirstName} ${orderDetails?.customerLastName}`;
    const costumerDocument = orderDetails?.customerDocument;
    setDataBank({
      customer: constumerName,
      document: costumerDocument,
      paymentAccount,
      bank: bank.value,
      branch,
      account,
      digit,
    });
    setCustomer(constumerName);
    setDocument(costumerDocument);
  }, [bank, branch, account, digit, paymentAccount]);

  useEffect(() => {
    const {
      customer,
      document,
      paymentAccount,
      bank,
      branch,
      account,
      digit,
    } = dataBank;
    const hasCustomerData = customer && document;
    const hasAllData =
      hasCustomerData && paymentAccount && bank && branch && account && digit;

    if (hasAllData || hasCustomerData) {
      dispatch(setBankInfo(dataBank));
    }
  }, [dataBank]);

  const setRefund = (refund) => {
    dispatch(setRefundMethod(refund));
  };

  const handleBankChange = (option) => {
    setBank(option);
  };

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>3</Badge>
          {paymentOption === "ACCOUNT_DEPOSIT"
            ? "Reembolso em conta"
            : "Ordem de pagamento"}
        </Title>
        <Subtitle>
          {paymentOption === "ACCOUNT_DEPOSIT" ? (
            <>
              O crédito estará disponível em até 10 dias após todo o processo de
              recebimento e análise do produto em nosso armazém, e o depósito
              ocorrerá apenas na conta do titular e CPF que estiver vinculado ao
              pedido.
            </>
          ) : (
            <>
              <Subtitle>
                <b>
                  <strong>
                    Esta opção deve ser oferecida em caso de o titular do pedido
                    no Carrefour, não possuir conta-corrente ou poupança.
                  </strong>
                </b>
              </Subtitle>
              <p>
                O crédito estará disponível{" "}
                <strong>
                  em até 10 dias após todo o processo de recebimento e análise
                  do produto em nosso armazém
                </strong>{" "}
                e deverá ser sacado pelo Titular do CPF cadastrado no Carrefour,
                munido do documento de identificação com foto.
              </p>
            </>
          )}
        </Subtitle>
        <BorderContainer>
          <Row>
            <SwitchForm>
              <ButtonSwitch
                data-testid="btnRefundAccount"
                onClick={() => setRefund("ACCOUNT_DEPOSIT")}
                active={paymentOption === "ACCOUNT_DEPOSIT"}
              >
                Reembolso em conta
              </ButtonSwitch>
              <ButtonSwitch
                data-testid="btnRefundPayment"
                onClick={() => setRefund("PAYMENT_ORDER")}
                active={paymentOption === "PAYMENT_ORDER"}
              >
                Ordem de pagamento
              </ButtonSwitch>
            </SwitchForm>
          </Row>
          {paymentOption === "ACCOUNT_DEPOSIT" ? (
            <>
              <Row>
                <InputGroup data-testid="inputName">
                  <Label htmlFor="name">Nome completo</Label>
                  <Input
                    type="text"
                    name="name"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
                <InputGroup data-testid="inputDocument">
                  <Label htmlFor="document">CPF</Label>
                  <Input
                    type="text"
                    name="document"
                    value={cpfMask(document)}
                    onChange={(e: any) => {
                      let document = e.target.value
                        .replaceAll(".", "")
                        .replace("-", "");

                      if (validateBankInfo(document) && document.length <= 11) {
                        setDocument(document);
                      }
                    }}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
              </Row>
              <Row>
                <InputGroup data-testid="inputBank">
                  <Label htmlFor="bank">Código do banco</Label>
                  <Select
                    options={bankOptions}
                    placeholder="Selecionar banco..."
                    value={bank}
                    onChange={handleBankChange}
                    styles={selectStyle}
                  />
                </InputGroup>
                <InputGroup width="16%" data-testid="inputAgency">
                  <Label htmlFor="agency">Agência</Label>
                  <Input
                    type="text"
                    name="agency"
                    value={branch}
                    onChange={(e) => {
                      if (validateBankInfo(e.target.value)) {
                        setBranch(e.target.value);
                      }
                    }}
                    autoComplete="off"
                  />
                </InputGroup>
                <InputGroup width="30%" data-testid="inputAccount">
                  <Label htmlFor="account">Conta</Label>
                  <Input
                    type="text"
                    name="account"
                    value={account}
                    onChange={(e) => {
                      if (validateBankInfo(e.target.value)) {
                        setAccount(e.target.value);
                      }
                    }}
                    autoComplete="off"
                  />
                </InputGroup>
                <InputGroup width="10%" data-testid="inputDigit">
                  <Label htmlFor="digit">Dígito</Label>
                  <Input
                    type="text"
                    name="digit"
                    value={digit}
                    onChange={(e) => {
                      if (validateBankInfo(e.target.value)) {
                        setDigit(e.target.value);
                      }
                    }}
                    autoComplete="off"
                  />
                </InputGroup>
              </Row>
              <Row justify="space-between">
                <PaymentMethods>
                  <PaymentMethod inactive={paymentAccount === "cp"}>
                    <PaymentMethodBtn
                      type="radio"
                      name="payment"
                      id="cc"
                      onClick={() => setPaymentAccount("cc")}
                    />
                    <PaymentMethodLabel htmlFor="cc">
                      Conta corrente
                    </PaymentMethodLabel>
                  </PaymentMethod>

                  <PaymentMethod inactive={paymentAccount === "cc"}>
                    <PaymentMethodBtn
                      type="radio"
                      name="payment"
                      id="cp"
                      onClick={() => setPaymentAccount("cp")}
                    />
                    <PaymentMethodLabel htmlFor="cp">
                      Conta Poupança
                    </PaymentMethodLabel>
                  </PaymentMethod>
                </PaymentMethods>
                <Subtitle>
                  Total do reembolso:{" "}
                  <span>
                    <strong>
                      {formatMoney(localStorage.getItem("ValueRefund"))}
                    </strong>
                  </span>
                </Subtitle>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <InputGroup>
                  <Label htmlFor="name">Nome completo</Label>
                  <Input
                    type="text"
                    name="name"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="document">CPF</Label>
                  <Input
                    type="text"
                    name="document"
                    value={cpfMask(document)}
                    onChange={(e: any) => {
                      let document = e.target.value
                        .replaceAll(".", "")
                        .replace("-", "");

                      if (validateBankInfo(document) && document.length <= 11) {
                        setDocument(document);
                      }
                    }}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
              </Row>
              <Row justify="flex-end">
                <Subtitle>
                  Total do reembolso:{" "}
                  <span>
                    <strong>
                      {formatMoney(localStorage.getItem("ValueRefund"))}
                    </strong>
                  </span>
                </Subtitle>
              </Row>
            </>
          )}
        </BorderContainer>
      </ContainerTitle>
    </>
  );
};

export default AccountRefund;
