import React, { useState, useEffect } from "react";
import iconMaestro from "@assets/maestro.svg";
import iconCarrefourCard from "@assets/icon-payment-carrefour-card.svg";
import iconCarrefourCardVirtual from "@assets/virtual-card-carrefour.svg";
import iconAlelo from "@assets/icon-payment-alelo.svg";
import iconVisa from "@assets/icon-payment-visa.svg";
import iconVisaCheckout from "@assets/icon-payment-visa-checkout.svg";
import iconMastercard from "@assets/icon-payment-mastercard.svg";
import iconElo from "@assets/icon-payment-elo.svg";
import iconDiners from "@assets/icon-payment-diners.svg";
import iconAmericanExpress from "@assets/icon-payment-american-express.svg";
import iconHipercard from "@assets/icon-payment-hipercard.svg";
import iconAmex from "@assets/icon-payment-amex.svg";
import iconCartaoPresente from "@assets/icon-payment-cartao-presente.svg";
import iconValeTroca from "@assets/icon-payment-vale-troca.svg";
import iconAccountDeposit from "@assets/icon-payment-account-deposit.svg";
import iconPaymentOrder from "@assets/icon-payment-payment-order.svg";
import iconPix from "@assets/icon-payment-pix.svg";

import {
  RowBetweenCenter,
  PaymentItem,
  TextPayment,
  PaymentDetailName,
} from "./styles";

type RenderPaymentTypeProps = {
  paymentType?: string;
  lastDigits?: string;
};

export function RenderPaymentType({
  paymentType,
  lastDigits,
}: RenderPaymentTypeProps) {
  switch (paymentType) {
    case "Mastercard":
      return (
        <>
          <img src={iconMastercard} alt="Mastercard" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Maestro":
      return (
        <>
          <img src={iconMaestro} alt="Maestro" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Visa":
      return (
        <>
          <img src={iconVisa} alt="Visa" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Pix":
      return (
        <>
          <img src={iconPix} alt="Pix" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>
          </TextPayment>
        </>
      );
    case "Visa Electron":
      return (
        <>
          <img src={iconVisa} alt="Visa Electron" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Visa Checkout":
      return (
        <>
          <img src={iconVisaCheckout} alt="Visa Checkout" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Diners":
      return (
        <>
          <img src={iconDiners} alt="Diners" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "American Express":
      return (
        <>
          <img src={iconAmericanExpress} alt="American Express" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Amex":
      return (
        <>
          <img src={iconAmex} alt="Amex" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Hipercard":
      return (
        <>
          <img src={iconHipercard} alt="Hipercard" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Elo":
      return (
        <>
          <img src={iconElo} alt="Elo" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Alelo":
      return (
        <>
          <img src={iconAlelo} alt="Alelo" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Vale":
      return (
        <>
          <img src={iconValeTroca} alt="Vale presente" />
          <TextPayment className="payment-label">
            {paymentType} presente
          </TextPayment>
        </>
      );
    case "Vale troca":
      return (
        <>
          <img src={iconValeTroca} alt="Vale troca" />
          <TextPayment className="payment-label">{paymentType}</TextPayment>
        </>
      );
    case "Cartão Presente":
      return (
        <>
          <img src={iconCartaoPresente} alt="Cartão Presente" />
          <TextPayment className="payment-label">{paymentType}</TextPayment>
        </>
      );
    case "Cartão Carrefour":
      return (
        <>
          <img src={iconCarrefourCard} alt="Cartão Carrefour" />
          <TextPayment className="payment-label">
            <span>{paymentType}</span>&nbsp;Final {lastDigits}
          </TextPayment>
        </>
      );
    case "Cartão Carrefour Virtual":
      return (
        <>
          <img src={iconCarrefourCardVirtual} alt="Cartão Carrefour Virtual" />
          <TextPayment className="payment-label">{paymentType}</TextPayment>
        </>
      );
    case "Depósito em conta":
      return (
        <>
          <img src={iconAccountDeposit} alt="Depósito em conta" />
          <TextPayment className="payment-label">{paymentType}</TextPayment>
        </>
      );
    case "Ordem de pagamento":
      return (
        <>
          <img src={iconPaymentOrder} alt="Ordem de pagamento" />
          <TextPayment className="payment-label">{paymentType}</TextPayment>
        </>
      );
    default:
      return (
        <>
          <TextPayment className="payment-label">
            <span>{paymentType}</span>
          </TextPayment>
        </>
      );
  }
}

type PaymentProps = {
  item: any;
};

const Payments = ({ item }: PaymentProps) => {
  const [data, setData] = useState(null);
  const [exist, setExists] = useState({
    BANK_SLIP: false,
    CREDIT_CARD: false,
    DEBIT_CARD: false,
    GIFT_CARD: false,
    VIRTUAL_GIFT_CARD: false,
    ALELO_CARD: false,
    CARREFOUR_CARD: false,
    PROMISSORY: false,
    PIX: false,
  });

  useEffect(() => {
    let group = item.reduce((r: any, a: any) => {
      r[a.paymentMethod] = [...(r[a.paymentMethod] || []), a];
      return r;
    }, {});

    const types = {
      BANK_SLIP: "",
      CREDIT_CARD: "",
      DEBIT_CARD: "",
      GIFT_CARD: "",
      VIRTUAL_GIFT_CARD: "",
      ALELO_CARD: "",
      CARREFOUR_CARD: "",
      PROMISSORY: "",
      PIX: "",
    };
    types.BANK_SLIP = group["BANK_SLIP"];
    types.CREDIT_CARD = group["CREDIT_CARD"];
    types.DEBIT_CARD = group["DEBIT_CARD"];
    types.GIFT_CARD = group["GIFT_CARD"];
    types.VIRTUAL_GIFT_CARD = group["VIRTUAL_GIFT_CARD"];
    types.ALELO_CARD = group["ALELO_CARD"];
    types.CARREFOUR_CARD = group["CARREFOUR_CARD"];
    types.PROMISSORY = group["PROMISSORY"];
    types.PIX = group["PIX"];

    setData(types);
  }, []);

  useEffect(() => {
    if (data) {
      setExists({
        BANK_SLIP: !!data?.BANK_SLIP,
        CREDIT_CARD: !!data?.CREDIT_CARD,
        DEBIT_CARD: !!data?.DEBIT_CARD,
        GIFT_CARD: !!data?.GIFT_CARD,
        VIRTUAL_GIFT_CARD: !!data?.VIRTUAL_GIFT_CARD,
        ALELO_CARD: !!data?.ALELO_CARD,
        CARREFOUR_CARD: !!data?.CARREFOUR_CARD,
        PROMISSORY: !!data?.PROMISSORY,
        PIX: !!data?.PIX,
      });
    }
  }, [data]);

  return (
    <>
      {exist?.CREDIT_CARD && (
        <>
          <PaymentDetailName>CARTÃO DE CRÉDITO</PaymentDetailName>
          {data?.CREDIT_CARD.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}

      {exist?.DEBIT_CARD && (
        <>
          <PaymentDetailName>CARTÃO DE DÉBITO</PaymentDetailName>
          {data?.DEBIT_CARD.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}

      {exist?.BANK_SLIP && (
        <>
          <PaymentDetailName>BOLETO</PaymentDetailName>
          {data?.BANK_SLIP.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}

      {exist?.GIFT_CARD && (
        <>
          <PaymentDetailName>VALE-TROCA</PaymentDetailName>
          {data?.GIFT_CARD.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}

      {exist?.VIRTUAL_GIFT_CARD && (
        <>
          <PaymentDetailName>CARTÃO PRESENTE</PaymentDetailName>
          {data?.VIRTUAL_GIFT_CARD.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}

      {exist?.ALELO_CARD && (
        <>
          <PaymentDetailName>ALELO_CARD</PaymentDetailName>
          {data?.ALELO_CARD.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}

      {exist?.CARREFOUR_CARD && (
        <>
          <PaymentDetailName>CARTÃO CARREFOUR</PaymentDetailName>
          {data?.CARREFOUR_CARD.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}

      {exist?.PROMISSORY && (
        <>
          <PaymentDetailName>CARTÃO CARREFOUR VIRTUAL</PaymentDetailName>
          {data?.PROMISSORY.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}
      {exist?.PIX && (
        <>
          {data?.PIX.map((i: any) => {
            return (
              <PaymentItem>
                <RowBetweenCenter>
                  <div>
                    <RenderPaymentType
                      paymentType={i.paymentSystemName}
                      lastDigits={i.lastDigits}
                    />
                  </div>
                  <TextPayment color="#37474F" weight="500">
                    {new Intl.NumberFormat("pt", {
                      style: "currency",
                      currency: "BRL",
                    }).format(i.refundValue)}
                  </TextPayment>
                </RowBetweenCenter>
              </PaymentItem>
            );
          })}
        </>
      )}
    </>
  );
};

export default Payments;
