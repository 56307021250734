import http from "./http";

export async function getOrder(
  email: string,
  offset: number = 0,
  limit: number
) {
  try {
    const response = await http.get("/v1/orders", {
      params: {
        email: email,
        limit: limit,
        offset: offset,
        viewType: "PORTAL_BACKOFFICE",
      },
    });

    return response;
  } catch (error) {
    throw new Error("Error");
  }
}
