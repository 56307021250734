import styled from "styled-components";

export const ReturnMethodCard = styled.button`
  display: flex;
  width: 100%;
  max-width: 352px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  font-family: "Ubuntu";
`;

export const ReturnMethodCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 24px;
  height: 24px;

  border: 1px solid #e5e5e5;
  border-radius: 24px;
`;

export const ReturnMethodCheckboxInner = styled.div`
  min-width: 16px;
  height: 16px;
  border-radius: 16px;

  background-color: #0970e6;
`;

export const ReturnMethodInfos = styled.div`
  margin-left 8px;:
`;

export const ReturnMethodTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  height: 24px;
`;

export const ReturnMethodTitle = styled.span`
  font-size: 14px;
  font-weight: 700;

  color: #121212;
`;

export const ReturnMethodDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: #575757;
`;
