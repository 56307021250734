import React, { useState, useEffect } from "react";
import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import homeIcon from "@assets/home.svg";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";
import { Column } from "@components/utilities";
import {
  InfoLabel,
  InfoTitle,
  AddressSelectorsWrapper,
  LabelText,
  Input,
  AddressSelectorContent,
} from "./style";

const AddressSelector = (props: any) => {
  const { consignment, orderDetails } = props;
  const { delivery }: Consignment = consignment;
  const { shippingAddress, modality } = delivery;

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>2</Badge>Esses são os dados de coleta no cliente
        </Title>
      </ContainerTitle>
      <Column margin="0 auto">
        <AddressSelectorsWrapper>
          <AddressSelectorContent>
            <LabelText>Endereço de entrega</LabelText>
            <InfoLabel>
              <InfoTitle>
                {orderDetails.customerFirstName} {orderDetails.customerLastName}
              </InfoTitle>
              <p>
                CEP {shippingAddress.postalCode}
                <br />
                {shippingAddress.street}, {shippingAddress.number},{" "}
                {shippingAddress.complement} <br />
                {shippingAddress.neighborhood} - {shippingAddress.city} -{" "}
                {shippingAddress.state}
              </p>
            </InfoLabel>
          </AddressSelectorContent>
        </AddressSelectorsWrapper>
      </Column>
    </>
  );
};

export default AddressSelector;
