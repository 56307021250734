import http from "./http";
import handleLogout from "./session";

export async function getOrderDetail(orderCode: string) {
  try {
    const response = await http.get(`/v1/orders/${orderCode}`, {
      params: {
        viewType: "PORTAL_BACKOFFICE",
      },
    });

    return response;
  } catch (error) {
    return error.response;
  }
}
