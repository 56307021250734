import React from "react";

import Success from "../../../icons/Success";
import Error from "../../../icons/Error";
import {
  ExchangeFeedbackButton,
  ExchangeFeedbackDescription,
  ExchangeFeedbackTitle,
  ExchangeFeedbackWrapper,
} from "./styles";

interface ExchangeFeedbackProps {
  type: "SUCCESS" | "ERROR";
  closeModal: () => void;
}

const ExchangeFeedback = ({ type, closeModal }: ExchangeFeedbackProps) => {
  return (
    <ExchangeFeedbackWrapper>
      {type === "SUCCESS" && <Success />}
      {type === "ERROR" && <Error />}

      <ExchangeFeedbackTitle>
        {type === "SUCCESS" && "Tudo certo!"}
        {type === "ERROR" && "Erro na troca!"}
      </ExchangeFeedbackTitle>

      <ExchangeFeedbackDescription>
        {type === "SUCCESS" &&
          "Sua solicitação de troca pelo mesmo produto foi realizada com sucesso. Um novo pedido será criado."}
        {type === "ERROR" &&
          "Houve um erro na geração de troca pelo mesmo produto. Favor tentar novamente."}
      </ExchangeFeedbackDescription>

      <ExchangeFeedbackButton onClick={closeModal}>
        Voltar
      </ExchangeFeedbackButton>
    </ExchangeFeedbackWrapper>
  );
};

export default ExchangeFeedback;
