import React, { useState } from "react";
import { Breadcrumbs } from "carrefour-portal-backoffice-style-guide";

import {
  Content,
  Container,
  Column,
  Row,
  TitleReport,
} from "@components/utilities";
import {
  AsideContainer,
  Label,
  Select,
  Option,
  SubTitle,
  Info,
} from "./styles";
import Report from "./components/reports";

const Reports = () => {
  const [reportType, setReportType] = useState("");

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/home/atendimento", label: "Atendimento" },
            { to: "/atendimento/relatorios", label: "Relatórios" },
          ]}
        />
        <Row>
          <Column width="100%">
            <TitleReport>Relatório</TitleReport>
          </Column>
        </Row>
        <Row>
          <Column width="40%">
            <AsideContainer>
              <Label>Tipo</Label>
              <Select
                name="reportsTypes"
                value={reportType}
                onChange={(reportSelected) =>
                  setReportType(reportSelected.target.value)
                }
              >
                <Option value="" className="customOption">
                  Selecione o relatório
                </Option>
                <Option value="REFUND_REPORT">Estorno parcial</Option>
                <Option value="CANCELLATION_REPORT">Cancelamento</Option>
                <Option value="GIFT_CARD_REPORT">Vale troca</Option>
                <Option value="EXCEL_REPORT">Estorno massivo</Option>
                <Option value="EXCHANGE_REPORT">Troca</Option>
              </Select>
            </AsideContainer>
          </Column>
          <Column width="60%" justify="flex-start">
            {reportType ? (
              <Report reportType={reportType} />
            ) : (
              <Content>
                <Container>
                  <Row justify="center">
                    <Info />
                  </Row>
                  <Row justify="center">
                    <SubTitle>Selecione o tipo de relatório.</SubTitle>
                  </Row>
                </Container>
              </Content>
            )}
          </Column>
        </Row>
      </Container>
    </Content>
  );
};

export default Reports;
