import React from "react";
import useModal from "react-hooks-use-modal";
import { Button } from "carrefour-portal-backoffice-style-guide";

import ModalAccountRefund from "../modals/modal-account-refund";

import { Container } from "@components/utilities";
import { CloseModal } from "../modals/styles";
import { ContainerBar, RowBetween, CustomerInfo, Buttons } from "./styles";

const ActionBar = () => {
  const [Modal, open, close, isOpen] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  function openModal() {
    open();
  }

  return (
    <>
      <ContainerBar>
        <Container>
          <RowBetween>
            <CustomerInfo>
              <h5>
                Tratando ocorrência de: <span>Vinícius Tifoski Ianoni</span>
              </h5>
              <strong>PEDIDO</strong>
              <p>
                #026956327-75<span>Pedido VTEX</span>
              </p>
            </CustomerInfo>
            <Buttons>
              <Button
                data-testid="button-close"
                text="Fechar"
                onClick={() => {}}
                outline
                margin="0 15px 0 0"
              />
              <Button
                data-testid="button-confirm"
                text="Confirmar solicitação de cancelamento"
                onClick={() => openModal()}
                variation="confirm"
              />
            </Buttons>
          </RowBetween>
        </Container>
      </ContainerBar>

      <Modal preventScroll={true}>
        <CloseModal onClick={close}>X</CloseModal>
        <ModalAccountRefund />
      </Modal>
    </>
  );
};

export default ActionBar;
