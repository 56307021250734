import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import store from "./store";
import { PersistGate } from "redux-persist/integration/react";

import Home from "./pages/home";
import GiftCards from "./pages/gift-cards";
import BalanceAvailable from "./pages/balance-available";
import Customers from "./pages/customers";
import Orders from "./pages/orders";
import OrderDetail from "./pages/order-detail";
import CancellationFlow from "./pages/cancellation-flow";
import ReturnFlow from "./pages/return-flow";
import { TotalRefund } from "./pages/total-refund";
import TransportOccurence from "./pages/transport-occurrence";
import { ForceInstanceFlow } from "./pages/force-instance-flow";
import RefundFlow from "./pages/refund-flow/index";
import Reports from "./pages/reports/index";
import ExchangeFlow from "./pages/exchange-flow";

const Root: React.FC = () => {
  return (
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/atendimento/pedidos" component={Orders} />
            <Route
              exact
              path="/atendimento/pedido/detalhe/:orderId"
              component={OrderDetail}
            />
            <Route exact path="/atendimento/clientes" component={Customers} />
            <Route
              exact
              path="/atendimento/cancelamento"
              component={CancellationFlow}
            />
            <Route
              exact
              path="/atendimento/devolucao/:orderCode/:consignmentsCode"
              component={ReturnFlow}
            />
            <Route
              exact
              path="/atendimento/estorno/:orderCode/:consignmentsCode"
              component={RefundFlow}
            />
            <Route
              exact
              path="/atendimento/troca/:orderCode/:consignmentCode"
              component={ExchangeFlow}
            />
            <Route
              exact
              path="/atendimento/forcar-instancia"
              component={ForceInstanceFlow}
            />
            <Route exact path="/atendimento" component={Home} />
            <Route exact path="/atendimento/vale-troca" component={GiftCards} />
            <Route
              exact
              path="/atendimento/saldo-disponivel"
              component={BalanceAvailable}
            />
            <Route
              exact
              path="/atendimento/ocorrencia-entrega"
              component={TransportOccurence}
            />
            <Route
              exact
              path="/atendimento/ocorrencia-entrega"
              component={TransportOccurence}
            />
            <Route
              exact
              path="/atendimento/reembolso-total"
              component={TotalRefund}
            />
            <Route exact path="/atendimento/relatorios" component={Reports} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default Root;
