import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

/*Modal*/
type TCardModal = {
  padding?: string;
};

export const Title = styled.h2`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: #ff7a14;
  margin: 0 28px;
`;

export const SubTititle = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-size: 14px;
  text-align: center;
  color: #263238;
  margin: 16px 28px;
`;

export const CardModal = styled.div<TCardModal>`
  width: 724px;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "20px"};
  overflow-y: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
`;

export const TitleAlert = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #1e5bc6;
`;

export const DescAlert = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #263238;
`;

export const Subtitle = styled.p`
  margin: 32px 10px;
  font-size: 15px;
  font-weight: 400;

  span {
    color: #1e5bc6;
  }
`;

export const SmallText = styled.small`
  color: #607d8b;
  margin-top: 12px;
  line-height: 24px;
  font-weight: 300;
`;

export const CardItemPayment = styled.div`
  background: #fcfdff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 25px;
  width: 340px;
  margin: 15px auto 0px;

  p {
    display: none;
  }
`;

export const TitleSuccess = styled.h4`
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  color: #40aa60;
  margin: 0;
`;

export const TitleError = styled.h4`
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  color: #e81e26;
  margin: 0;
`;

export const LoadingPaymentData = styled.div`
  padding: 32px 0;
  text-align: center;
  width: 350px;
`;

type PaymentActive = {
  inactive?: boolean;
  active?: boolean;
};

export const PaymentGroupContainer = styled.div`
  width: 350px;
`;

export const PaymentDetailName = styled.p<PaymentActive>`
  font-size: 16px;
  margin: 0;
  color: #02296d;
  text-align: center;
  font-weight: 700;
`;

export const BodyPayment = styled.div`
  background: #fcfdff;
  border-radius: 8px;
`;

export const PaymentItem = styled.div`
  border-bottom: 1px solid #eceff1;

  img {
    margin-right: 5px;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const RowBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px 0;

  * {
    display: flex;
    align-items: center;
  }

  .payment-label {
    color: #333333;
  }

  .payment-label span {
    font-weight: bold;
  }
`;

type TextPayment = {
  color?: string;
  weight?: string;
  margin?: string;
  fontSize?: string;
};

export const TextPayment = styled.span<TextPayment>`
  font-size: ${(props: TextPayment) => props.fontSize || "14px"};
  font-weight: ${(props: TextPayment) => props.weight || "300"};
  color: ${(props: TextPayment) => props.color || "#353A40"};
  margin: ${(props: TextPayment) => props.margin || "0px"};
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 12px;
    font-size: 14px;
  }
`;

export const RefundInfoContainer = styled.div`
  width: 250px;
  font-family: Ubuntu;
  margin: 16px 0;

  .title {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #02296d;
    margin-bottom: 16px;
  }

  .label {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    width: 150px;
  }

  .value {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #1e5bc6;
    width: 100px;
  }
`;

export const InfoAccountBank = styled.div`
  margin: 24px 0 8px 0;
  .info-bank {
    &_text {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-decoration-line: underline;
      color: #353a40;
      text-align: left;
      margin: 0;
    }
    &_line {
      border-bottom: 1px solid #eceff1;
      padding: 8px 0 8px 0;
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      &:last-child {
        border-bottom: none;
      }
      &--item {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #353a40;
      }
    }
  }
`;
