import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { searchReducer } from "./search-reducer";
import { modalReducer } from "./modal-reducer";
import { customerReducer } from "./customer-reducer";
import { orderReducer } from "./order-reducer";
import { returnItemsReducer } from "./return-items-reducer";
import { cancelReducer } from "./cancellation-reducer";
import { authoritiesReducer } from "./authorities-reducer";
import { giftcardReducer } from "./giftcard-reducer";
import { exchangeReducer } from "./exchange-reducer";

const rootReducer = combineReducers({
  search: searchReducer,
  modal: modalReducer,
  customer: customerReducer,
  order: orderReducer,
  returnItems: returnItemsReducer,
  cancel: cancelReducer,
  rules: authoritiesReducer,
  giftcard: giftcardReducer,
  exchange: exchangeReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: "root",
  blacklist: ["returnItems", "cancel"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//const store = createStore(rootReducer);
/* eslint-disable no-underscore-dangle */
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
/* eslint-enable */
const persistor = persistStore(store);

export default { store, persistor };
