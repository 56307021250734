/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { setBankInfo, setRefundMethod } from "@store/return-items-reducer";
import { AppState } from "@store/index";
import { getBanks } from "@services/banks";
import { Row, selectStyle } from "@components/utilities";
import { formatMoney } from "@utils/formatUtils";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";
import InfoTracking from "@assets/info-tracking.svg";

import {
  BorderContainer,
  InputGroup,
  Input,
  Label,
  PaymentMethods,
  PaymentMethod,
  PaymentMethodBtn,
  PaymentMethodLabel,
  SwitchForm,
  ButtonSwitch,
  ErrorMessage,
  TooltipContainer,
  TooltipText,
} from "./styles";

import {
  cpfMask,
  validateBankInfo,
  validateInputBranch,
  validateInputBranchDigit,
  validateInputAccount,
  validateInputAccountDigit,
} from "@utils/masks";

const customStyles = {
  control: (styles: any, { isFocused }: any) => {
    return {
      ...styles,
      border: isFocused ? "1px solid #ccc" : "1px solid #ccc",
      boxShadow: isFocused ? "none" : "none",
      fontSize: "12px",
      textAlign: "left",
    };
  },
  option: (styles: any) => ({ ...styles, fontSize: "12px", textAlign: "left" }),
};

const AccountRefund = (props: any) => {
  const { orderDetails } = props;
  const dispatch = useDispatch();
  const [typeRefund, setTypeRefund] = useState("ACCOUNT_DEPOSIT");

  const paymentOption = useSelector(
    (state: AppState) => state.returnItems.refundMethod
  );

  const refundInfo = useSelector(
    (state: AppState) => state.returnItems.refundInfo
  );
  const restituitionValue = refundInfo?.find(
    (r: any) => r.type === paymentOption
  );

  const [paymentAccount, setPaymentAccount] = useState("");
  const [bankOptions, setBankOptions] = useState([]);
  const [dataBank, setDataBank] = useState({});
  const [customer, setCustomer] = useState("");
  const [document, setDocument] = useState("");
  const [bankName, setBankName] = useState({});
  const [bank, setBank] = useState({});
  const [branch, setBranch] = useState("");
  const [branchDigit, setBranchDigit] = useState("");
  const [account, setAccount] = useState("");
  const [digit, setDigit] = useState("");
  const [validateBankName, setValidateBankName] = useState(false);
  const [validateBankBranch, setValidateBankBranch] = useState(false);
  const [validateBankDV, setValidateBankDV] = useState(false);
  const [validateBankAccount, setValidateBankAccount] = useState(false);
  const [validateBankAccountDig, setValidateBankAccountDig] = useState(false);
  const [toolTipActive, setToolTipActive] = useState(false);

  async function requestBanks() {
    const response = await getBanks();

    const _option: { value: string; label: string; className: string }[] = [];
    response?.data.map(
      (item: { code: string; shortName: string; className: string }) => {
        _option.push({
          value: item.code,
          label: `${item.shortName}`,
          className: "custom-class",
        });
        setBankName(item.shortName);
      }
    );
    setBankOptions(_option);
  }

  useEffect(() => {
    requestBanks();
  }, []);

  useEffect(() => {
    const constumerName = `${orderDetails?.customerFirstName} ${orderDetails?.customerLastName}`;
    const costumerDocument = orderDetails?.customerDocument;
    setDataBank({
      customer: constumerName,
      document: costumerDocument,
      paymentAccount,
      bank: bank.value,
      bankName: bank.label,
      branch,
      branchDigit,
      account,
      digit,
    });
    setCustomer(constumerName);
    if (costumerDocument) {
      setDocument(costumerDocument);
    }
  }, [bank, bankName, branch, branchDigit, account, digit, paymentAccount]);

  useEffect(() => {
    const {
      customer,
      document,
      paymentAccount,
      bank,
      bankName,
      branch,
      branchDigit,
      account,
      digit,
    } = dataBank;
    const hasCustomerData = customer && document;
    const hasAllData =
      hasCustomerData &&
      paymentAccount &&
      bank &&
      bankName &&
      branch &&
      branchDigit &&
      account &&
      digit;

    if (hasAllData || hasCustomerData) {
      dispatch(setBankInfo(dataBank));
    }
  }, [dataBank]);

  const setRefund = (refund) => {
    dispatch(setRefundMethod(refund));
  };

  const handleBankChange = (option) => {
    setBank(option);
  };

  useEffect(() => {
    dispatch({ type: "BANK_ACCOUNT", payload: dataBank });
  }, [dataBank]);

  useEffect(() => {
    dispatch({ type: "REFUND_METHOD", payload: typeRefund });
  }, [typeRefund]);

  useEffect(() => {
    setValidateBankName(false);
  }, [bank]);

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>3</Badge>
          {typeRefund === "ACCOUNT_DEPOSIT"
            ? "Reembolso em conta"
            : "Ordem de pagamento"}
        </Title>
        <Subtitle>
          {typeRefund === "ACCOUNT_DEPOSIT" ? (
            <>
              {
                "O crédito estará disponível em até 10 dias após todo o processo de recebimento e análise do produto em nosso armazém, e o depósito ocorrerá apenas na conta do titular e CPF que estiver vinculado ao pedido."
              }
            </>
          ) : (
            <>
              <Subtitle>
                <b>
                  <strong>
                    {
                      "Esta opção deve ser oferecida em caso de o titular do pedido no Carrefour, não possuir conta-corrente ou poupança."
                    }
                  </strong>
                </b>
              </Subtitle>
              <p>
                {"O crédito estará disponível "}
                <strong>
                  {
                    "em até 10 dias após todo o processo de recebimento e análise do produto em nosso armazém "
                  }
                </strong>
                {
                  "e deverá ser sacado pelo Titular do CPF cadastrado no Carrefour, munido do documento de identificação com foto."
                }
              </p>
            </>
          )}
        </Subtitle>
        <BorderContainer>
          <Row>
            <SwitchForm>
              <ButtonSwitch
                data-testid="btnRefundAccount"
                onClick={() => setRefund("ACCOUNT_DEPOSIT")}
                active={paymentOption === "ACCOUNT_DEPOSIT"}
              >
                {"Reembolso em conta"}
              </ButtonSwitch>
              <ButtonSwitch
                data-testid="btnRefundPayment"
                onClick={() => setRefund("PAYMENT_ORDER")}
                active={paymentOption === "PAYMENT_ORDER"}
              >
                {"Ordem de pagamento"}
              </ButtonSwitch>
            </SwitchForm>
          </Row>
          {paymentOption === "ACCOUNT_DEPOSIT" ? (
            <>
              <Row>
                <InputGroup data-testid="inputName">
                  <Label htmlFor="name">{"Nome completo"}</Label>
                  <Input
                    type="text"
                    name="name"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
                <InputGroup data-testid="inputDocument">
                  <Label htmlFor="document">{"CPF"}</Label>
                  <Input
                    type="text"
                    name="document"
                    value={cpfMask(document)}
                    onChange={(e: any) => {
                      let document = e.target.value
                        .replaceAll(".", "")
                        .replace("-", "");

                      if (validateBankInfo(document) && document.length <= 11) {
                        setDocument(document);
                      }
                    }}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
              </Row>
              <Row>
                <InputGroup
                  width="50%"
                  data-testid="inputBank"
                  onClick={() => {
                    if (dataBank.bankName === "" || !dataBank.bankName) {
                      setValidateBankName(true);
                    } else {
                      setValidateBankName(false);
                    }
                  }}
                >
                  <Label htmlFor="bank">{"Nome do banco"}</Label>
                  <Select
                    options={bankOptions}
                    placeholder={"Selecionar banco..."}
                    value={bank}
                    onChange={handleBankChange}
                    styles={(customStyles, selectStyle)}
                  />
                  <ErrorMessage
                    active={validateBankName ? true : false}
                  >{`Selecione o banco`}</ErrorMessage>
                </InputGroup>
                <InputGroup width="20%" data-testid="inputBranch">
                  <Label htmlFor="branch">{"Agência"}</Label>
                  <Input
                    type="text"
                    name="branch"
                    value={branch}
                    maxLength="4"
                    onChange={(e) => {
                      if (validateInputBranch(e.target.value)) {
                        setBranch(e.target.value);
                      }
                    }}
                    onKeyUp={(e) => {
                      let branchNumberQt = e.currentTarget.value;
                      if (
                        dataBank.branch === "" ||
                        !dataBank.branch ||
                        branchNumberQt.length !== e.currentTarget.maxLength
                      ) {
                        setValidateBankBranch(true);
                      } else {
                        setValidateBankBranch(false);
                      }
                    }}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    active={validateBankBranch ? true : false}
                  >{`Código da agência inválido`}</ErrorMessage>
                </InputGroup>
                <InputGroup width="10%" data-testid="inputBranchDigit">
                  <Label htmlFor="branchDigit">
                    <span>
                      {"DV"}

                      <img
                        onClick={() =>
                          !toolTipActive
                            ? setToolTipActive(true)
                            : setToolTipActive(false)
                        }
                        src={InfoTracking}
                        alt="Info"
                      />
                    </span>
                  </Label>
                  <Input
                    type="text"
                    name="branchDigit"
                    value={branchDigit}
                    maxLength="1"
                    onChange={(e) => {
                      if (validateInputBranchDigit(e.target.value)) {
                        setBranchDigit(e.target.value);
                      }
                    }}
                    onKeyUp={(e) => {
                      let DVNumberQt = e.currentTarget.value;
                      if (
                        dataBank.branchDigit === "" ||
                        !dataBank.branchDigit ||
                        DVNumberQt.length !== e.currentTarget.maxLength
                      ) {
                        setValidateBankDV(true);
                      } else {
                        setValidateBankDV(false);
                      }
                    }}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    active={validateBankDV ? true : false}
                  >{`DV inválido`}</ErrorMessage>
                  {toolTipActive && (
                    <TooltipContainer>
                      <Row>
                        <TooltipText>
                          Este campo refere-se ao dígito verificador da agência
                          a qual o cliente possui a conta bancária. Caso a
                          agência informada pelo cliente não possua dígito ou o
                          mesmo seja “x”, por favor, informar “0” para
                          prosseguir com a solicitação.
                        </TooltipText>
                      </Row>
                    </TooltipContainer>
                  )}
                </InputGroup>
                <InputGroup width="25%" data-testid="inputAccount">
                  <Label htmlFor="account">{"Conta"}</Label>
                  <Input
                    type="text"
                    name="account"
                    value={account}
                    minLength="4"
                    maxLength="13"
                    onChange={(e) => {
                      if (validateInputAccount(e.target.value)) {
                        setAccount(e.target.value);
                      }
                    }}
                    onKeyUp={(e) => {
                      let bankAccountNumberQt = e.currentTarget.value;
                      if (
                        dataBank.account === "" ||
                        !dataBank.account ||
                        bankAccountNumberQt.length >
                          e.currentTarget.maxLength ||
                        bankAccountNumberQt.length < e.currentTarget.minLength
                      ) {
                        setValidateBankAccount(true);
                      } else {
                        setValidateBankAccount(false);
                      }
                    }}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    active={validateBankAccount ? true : false}
                  >{`Conta inválida`}</ErrorMessage>
                </InputGroup>
                <InputGroup width="10%" data-testid="inputDigit">
                  <Label htmlFor="digit">{"Dígito"}</Label>
                  <Input
                    type="text"
                    name="digit"
                    value={digit}
                    maxLength="1"
                    onChange={(e) => {
                      if (validateInputAccountDigit(e.target.value)) {
                        setDigit(e.target.value);
                      }
                    }}
                    onKeyUp={(e) => {
                      let bankDigitAccountNumberQt = e.currentTarget.value;
                      if (
                        dataBank.digit === "" ||
                        !dataBank.digit ||
                        bankDigitAccountNumberQt.length >
                          e.currentTarget.maxLength
                      ) {
                        setValidateBankAccountDig(true);
                      } else {
                        setValidateBankAccountDig(false);
                      }
                    }}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    active={validateBankAccountDig ? true : false}
                  >{`Dígito inválido`}</ErrorMessage>
                </InputGroup>
              </Row>
              <Row justify="space-between">
                <PaymentMethods>
                  <PaymentMethod inactive={paymentAccount === "cp"}>
                    <PaymentMethodBtn
                      type="radio"
                      name="payment"
                      id="cc"
                      onClick={() => setPaymentAccount("cc")}
                    />
                    <PaymentMethodLabel htmlFor="cc">
                      {"Conta corrente"}
                    </PaymentMethodLabel>
                  </PaymentMethod>

                  <PaymentMethod inactive={paymentAccount === "cc"}>
                    <PaymentMethodBtn
                      type="radio"
                      name="payment"
                      id="cp"
                      onClick={() => setPaymentAccount("cp")}
                    />
                    <PaymentMethodLabel htmlFor="cp">
                      {"Conta Poupança"}
                    </PaymentMethodLabel>
                  </PaymentMethod>
                </PaymentMethods>
                <Subtitle>
                  {"Total do reembolso: "}
                  <span>
                    <strong>{formatMoney(restituitionValue.totalValue)}</strong>
                  </span>
                </Subtitle>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <InputGroup>
                  <Label htmlFor="name">{"Nome completo"}</Label>
                  <Input
                    type="text"
                    name="name"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="document">{"CPF"}</Label>
                  <Input
                    type="text"
                    name="document"
                    value={cpfMask(document)}
                    onChange={(e: any) => {
                      let document = e.target.value
                        .replaceAll(".", "")
                        .replace("-", "");

                      if (validateBankInfo(document) && document.length <= 11) {
                        setDocument(document);
                      }
                    }}
                    autoComplete="off"
                    disabled
                  />
                </InputGroup>
              </Row>
              <Row justify="flex-end">
                <Subtitle>
                  {"Total do reembolso: "}
                  <span>
                    <strong>
                      {formatMoney(restituitionValue?.totalValue)}
                    </strong>
                  </span>
                </Subtitle>
              </Row>
            </>
          )}
        </BorderContainer>
      </ContainerTitle>
    </>
  );
};

export default AccountRefund;
