import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Breadcrumbs } from "carrefour-portal-backoffice-style-guide";
import { getCustomer } from "@services/customer";
import { Content, Container, Column } from "@components/utilities";
import PageHeader from "@components/page-header";
import Table from "./components/table";
import { AppState } from "src/store";
import { SearchState } from "../../store/search-reducer";

const Customers = () => {
  const dispatch = useDispatch();
  const [customersList, setCustomersList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { searchTerm, searchParam } = useSelector<AppState, SearchState>(
    (state) => state.search
  );

  async function handleLoadCustomer() {
    setLoading(true);

    const response = await getCustomer({
      searchParam,
      searchTerm,
    });

    if (response.status !== 200) {
      setLoading(false);
      setCustomersList([]);
      return;
    }

    setCustomersList(response.data);
    setLoading(false);
  }

  useEffect(() => {
    if (searchTerm) {
      handleLoadCustomer();
    }
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento", label: "Atendimento" },
          ]}
        />
        <Column>
          <PageHeader title="VISÃO GERAL" subtitle="Resultado" />
          <Table data={customersList} loading={loading} />
        </Column>
      </Container>
    </Content>
  );
};

export default Customers;
