import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "carrefour-portal-backoffice-style-guide";
import Skeleton from "react-loading-skeleton";

import { Column, Row } from "@components/utilities";
import { CardModal, DescAlert, TitleError, TitleSuccess } from "../styles";

import ModalContent from "../modal-content";

import iconCheck from "@assets/check.svg";
import iconError from "@assets/exclamation.svg";

import { useSelector } from "react-redux";
import { AppState } from "src/store";
import { OrderState } from "../../../../../store/order-reducer";

type ModalRefundType = {
  totalValue: number;
  refundMethod: string;
  handleCancellation: any;
  close: any;
  finalStatus?: number | null;
};

const ModalAccountRefund = ({
  totalValue,
  refundMethod,
  handleCancellation,
  close,
  finalStatus,
}: ModalRefundType) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { orderSelected } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  useEffect(() => {
    return () => {
      history.push(`/atendimento/pedido/detalhe/${orderSelected}`);
    };
  }, [history]);

  return (
    <CardModal padding="50px 150px">
      {!finalStatus ? (
        <>
          <Column margin="0 auto" textAlign="center">
            <DescAlert>
              Você tem certeza que deseja cancelar esta entrega?
            </DescAlert>
            <ModalContent refundMethod={refundMethod} totalValue={totalValue} />
          </Column>
          {loading ? (
            <Skeleton height={55} />
          ) : (
            <Row margin="30px 0 0 0">
              <Column margin="0 20px 0 0">
                <Button
                  text="Cancelar"
                  variation="primary"
                  onClick={() => close()}
                  size="large"
                  outline
                />
              </Column>
              <Button
                text="Prosseguir"
                variation="confirm"
                onClick={() => {
                  handleCancellation();
                  setLoading(true);
                }}
                size="large"
              />
            </Row>
          )}
        </>
      ) : (
        <>
          {finalStatus === 204 ? (
            <>
              <img src={iconCheck} alt="check" />
              <Column margin="30px 0 0 0" textAlign="center">
                <TitleSuccess>
                  Sua solicitação de cancelamento <br />
                  foi realizada com sucesso!
                </TitleSuccess>
              </Column>
            </>
          ) : (
            <>
              <img src={iconError} alt="erro" />
              <Column margin="30px 0 0 0" textAlign="center">
                <TitleError>
                  Sua solicitação de cancelamento <br />
                  não foi realizada!
                </TitleError>
              </Column>
            </>
          )}
        </>
      )}
    </CardModal>
  );
};

export default ModalAccountRefund;
