import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Breadcrumbs } from "carrefour-portal-backoffice-style-guide";
import { getGiftCards } from "@services/giftCards";
import { Content, Container, Column } from "@components/utilities";
import PageHeader from "@components/page-header";
import Table from "./components/table";
import Summary from "./components/summary";
import BottomBar from "./components/bottom-bar";
import { AppState } from "src/store";
import { SearchState } from "../../store/search-reducer";
import { ContainerLoad } from "./styles";

const BalanceAvailable = () => {
  const [customerData, setCustomerData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [virtualGiftCards, setVirtualGiftCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const [term, setTerm] = useState("");
  const [msgError, setMsgError] = useState("");
  const { searchGift } = useSelector<AppState, SearchState>(
    (state) => state.search
  );

  async function loadCustomer(offset: number = 0) {
    setLoading(true);

    if (offset > 0) {
      offset = offset - 1;
    }

    const response = await getGiftCards(searchGift, offset, ordersPerPage);

    if (String(response) === "404") {
      dispatch({ type: "SEARCH_GIFT_ERROR", payload: searchGift });
      history.push(`/atendimento/vale-troca`);
    }

    if (String(response) !== "404") {
      setCustomerData(response.customer);
      setTotalRecords(response.paging?.totalRecords);
      setVirtualGiftCards(response.virtualGiftCards);
    }

    setLoading(false);
  }

  const handlePerRowsChange = async (perPage: number) => {
    setOrdersPerPage(perPage);
  };

  useEffect(() => {
    if (searchGift) {
      loadCustomer();
    }
  }, [searchGift]);

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento", label: "Atendimento" },
            { to: "", label: "Consulta de Vale-troca" },
          ]}
        />
        <Column>
          <PageHeader
            title="CONSULTA"
            subtitle="Saldo disponível"
            button={false}
          />
          <Summary
            isLoading={loading}
            customerData={customerData}
            totalRecords={totalRecords}
          />
          <Table
            data={virtualGiftCards}
            loading={loading}
            loadCustomer={loadCustomer}
            LoadingComponent={
              <ContainerLoad>Carregando informações...</ContainerLoad>
            }
            handlePerRowsChange={handlePerRowsChange}
            totalRecords={totalRecords}
          />
        </Column>
      </Container>
      {!loading && <BottomBar customerData={customerData} />}
    </Content>
  );
};

export default BalanceAvailable;
