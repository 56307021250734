export interface SearchState {
  searchGift: string;
  searchGiftError: string;
  searchTerm: string;
  searchParam: string;
  searchTermOrder: string;
}

export const SEARCH_GIFT = "SEARCH_GIFT";
export const SEARCH_TERM = "SEARCH_TERM";
export const SEARCH_PARAM = "SEARCH_PARAM";
export const SEARCH_TERM_ORDER = "SEARCH_TERM_ORDER";
export const SEARCH_GIFT_ERROR = "SEARCH_GIFT_ERROR";

export interface SearchActionsParams {
  type: string;
  payload: any;
}

type SearchActions = SearchActionsParams;

const initialState: SearchState = {
  searchGift: "",
  searchGiftError: "",
  searchTerm: "",
  searchParam: "",
  searchTermOrder: "",
};

export function searchReducer(
  state = initialState,
  action: SearchActions
): SearchState {
  switch (action.type) {
    case SEARCH_GIFT:
      return {
        ...state,
        searchGift: action.payload,
      };

    case SEARCH_GIFT_ERROR:
      return {
        ...state,
        searchGiftError: action.payload,
      };

    case SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };

    case SEARCH_PARAM:
      return {
        ...state,
        searchParam: action.payload,
      };

    case SEARCH_TERM_ORDER:
      return {
        ...state,
        searchTermOrder: action.payload,
      };
    default:
      return state;
  }
}
