import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { AppState } from "src/store";
import { OrderState } from "@store/order-reducer";
import { CancelState } from "@store/cancellation-reducer";
import { deliveryRefundMethods } from "@services/deliveryRefundMethods";
import { CancellationType } from "@pages/cancellation-flow/enums/cancellation-type";
import iconBarcode from "@assets/barcode.svg";

import { ContainerTitle, Badge, Title, Subtitle } from "../../styles";
import {
  RowBetweenCenter,
  PaymentMethods,
  PaymentMethod,
  PaymentMethodBtn,
  PaymentMethodLabel,
  PaymentDescription,
  PaymentDetails,
  PaymentDetailName,
  BodyPayment,
  PaymentItem,
  TextPayment,
} from "./styles";

const BilletRefund = () => {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [refunds, setRefunds] = useState(null);
  const [totalValue, setTotalValue] = useState(0);

  const { orderSelected, selectedConsignment } = useSelector<
    AppState,
    OrderState
  >((state) => state.order);

  const cancelState = useSelector<AppState, CancelState>(
    (state) => state.cancel
  );

  async function loadDeliveryRefundMethods() {
    const params = {
      consignmentCode: selectedConsignment.consignmentCode,
      orderCode: orderSelected,
    };
    const response = await deliveryRefundMethods(params);

    if (response) {
      let obj: any = {};
      response?.map((refund: any) => {
        if (refund.type === "GIFT_CARD") {
          obj.gift = { type: "GIFT_CARD", value: refund.totalValue };
        } else {
          obj.other = { type: "OTHER", value: refund.totalValue };
        }
      });
      setRefunds(obj);
    }
  }

  useEffect(() => {
    loadDeliveryRefundMethods();
  }, []);

  useEffect(() => {
    dispatch({ type: "REFUND_METHOD", payload: paymentMethod });
    dispatch({ type: "TOTAL_VALUE", payload: totalValue });
  }, [paymentMethod]);

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>
            {cancelState.cancellationType === CancellationType.DELIVERY_FAILURE
              ? 1
              : 2}
          </Badge>
          Escolha a forma de reembolso
        </Title>
        <Subtitle>
          A sua compra foi paga com boleto, então o reembolso será feito por
          <strong>
            {" "}
            depósito em conta corrente, ordem de pagamento ou vale-troca.
          </strong>
        </Subtitle>
        {refunds ? (
          <PaymentMethods>
            <PaymentMethod
              inactive={paymentMethod === "GIFT_CARD" ? true : false}
            >
              <PaymentMethodBtn
                type="radio"
                name="payment"
                id="REFUND"
                onClick={() => {
                  setPaymentMethod("REFUND");
                  setTotalValue(refunds.other.value);
                }}
              />
              <PaymentMethodLabel htmlFor="REFUND">
                Reembolso em conta
              </PaymentMethodLabel>
              <PaymentDescription active={paymentMethod === "REFUND"}>
                O cliente receberá um crédito na conta indicada. O titular da
                conta deve ser o titular do pedido.
              </PaymentDescription>
              <PaymentDetails active={paymentMethod === "REFUND"}>
                <PaymentDetailName active={paymentMethod === "REFUND"}>
                  REEMBOLSO EM CONTA
                </PaymentDetailName>
                <BodyPayment>
                  <PaymentItem>
                    <RowBetweenCenter>
                      <div>
                        <img src={iconBarcode} alt="Master" />
                        <TextPayment>Pagamento por boleto</TextPayment>
                      </div>
                      <TextPayment color="#37474F" weight="500">
                        {new Intl.NumberFormat("pt", {
                          style: "currency",
                          currency: "BRL",
                        }).format(refunds.other.value)}
                      </TextPayment>
                    </RowBetweenCenter>
                  </PaymentItem>
                  <PaymentItem>
                    <RowBetweenCenter>
                      <TextPayment
                        margin="15px 0"
                        color="#1E5BC6"
                        fontSize="16px"
                        weight="500"
                      >
                        Total do reembolso
                      </TextPayment>
                      <TextPayment
                        margin="15px 0"
                        color="#1E5BC6"
                        fontSize="16px"
                        weight="500"
                      >
                        {new Intl.NumberFormat("pt", {
                          style: "currency",
                          currency: "BRL",
                        }).format(refunds.other.value)}
                      </TextPayment>
                    </RowBetweenCenter>
                  </PaymentItem>
                </BodyPayment>
              </PaymentDetails>
            </PaymentMethod>

            {refunds.gift && (
              <PaymentMethod inactive={paymentMethod === "REFUND"}>
                <PaymentMethodBtn
                  type="radio"
                  name="payment"
                  id="GIFT_CARD"
                  onClick={() => {
                    setPaymentMethod("GIFT_CARD");
                    setTotalValue(refunds.gift.value);
                  }}
                />
                <PaymentMethodLabel htmlFor="GIFT_CARD">
                  Vale-troca
                </PaymentMethodLabel>
                <PaymentDescription active={paymentMethod === "GIFT_CARD"}>
                  Um crédito será liberado para utilização em novas compras no
                  site Carrefour.
                </PaymentDescription>
                <PaymentDetails active={paymentMethod === "GIFT_CARD"}>
                  <PaymentDetailName active={paymentMethod === "GIFT_CARD"}>
                    VALE-TROCA
                  </PaymentDetailName>
                  <BodyPayment>
                    <PaymentItem>
                      <RowBetweenCenter>
                        <TextPayment
                          margin="15px 0"
                          color="#1E5BC6"
                          fontSize="16px"
                          weight="500"
                        >
                          Valor
                        </TextPayment>
                        <TextPayment
                          margin="15px 0"
                          color="#1E5BC6"
                          fontSize="16px"
                          weight="500"
                        >
                          {new Intl.NumberFormat("pt", {
                            style: "currency",
                            currency: "BRL",
                          }).format(refunds.gift.value)}
                        </TextPayment>
                      </RowBetweenCenter>
                    </PaymentItem>
                  </BodyPayment>
                </PaymentDetails>
              </PaymentMethod>
            )}
          </PaymentMethods>
        ) : (
          <PaymentMethods>
            <PaymentMethod>
              <Skeleton height={340} width={340} />
            </PaymentMethod>
            <PaymentMethod>
              <Skeleton height={340} width={340} />
            </PaymentMethod>
          </PaymentMethods>
        )}
      </ContainerTitle>
    </>
  );
};

export default BilletRefund;
