import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store";
import {
  CollectAddressStatement,
  CollectAddressTitle,
  CollectReceiverName,
  InformativeStatement,
} from "./styles";
import { Consignment } from "../../../../order-detail/OrderDetailsResponse";

interface InformativeContentProps {
  selectedConsignment: Consignment;
}

const InformativeContent = ({
  selectedConsignment,
}: InformativeContentProps) => {
  const returnMethod = useSelector(
    (appState: AppState) => appState.exchange.returnMethod
  );

  const orderToExchange = useSelector(
    (appState: AppState) => appState.exchange.orderToExchange
  );

  const {
    postalCode,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
  } = selectedConsignment.delivery.shippingAddress;

  return (
    <>
      {returnMethod === "COLLECT" && (
        <div>
          <CollectAddressTitle>Endereço e dados de coleta</CollectAddressTitle>

          <CollectReceiverName>
            {orderToExchange.customerFirstName}{" "}
            {orderToExchange.customerLastName}
          </CollectReceiverName>

          <CollectAddressStatement>CEP {postalCode}</CollectAddressStatement>

          <CollectAddressStatement>
            {street}, {number} {complement && `, ${complement}`}
          </CollectAddressStatement>

          <CollectAddressStatement>
            {neighborhood} - {city} - {state}
          </CollectAddressStatement>
        </div>
      )}

      <InformativeStatement>
        Para efetuarmos a sua troca, passamos por algumas etapas.
      </InformativeStatement>

      {returnMethod === "COLLECT" && (
        <>
          <InformativeStatement>
            1 - Buscando os produtos
            <br />
            Desta forma encaminharemos os seus dados para que em breve você
            receba um contato com as informações da data de agendamento da
            coleta.
          </InformativeStatement>
          <InformativeStatement>
            2 - Da transportadora ao nosso armazém
            <br />
            Depois da coleta, acompanharemos a devolução do seu pedido até a
            chegada em nosso armazém, o que pode levar o mesmo prazo da primeira
            entrega.
          </InformativeStatement>
        </>
      )}

      {returnMethod === "POSTAGE" && (
        <>
          <InformativeStatement>
            1 - Da sua casa a uma agência dos Correios
            <br />
            Enviaremos um código de postagem para o seu e-mail que expira em 5
            dias. Dentro desse prazo, você deve encaminhar o produto a uma
            agência dos Correios.
          </InformativeStatement>
          <InformativeStatement>
            2 - Dos Correios ao nosso armazém
            <br />
            Depois que você entrega o produto nos Correios, acompanharemos a
            devolução do seu pedido até a chegada em nosso armazém o que pode
            levar o mesmo prazo da primeira entrega.
          </InformativeStatement>
        </>
      )}

      <InformativeStatement>
        3 - Análise do produto
        <br />
        Após recebermos o seu pedido em nosso armazém, o produto passa por uma
        breve análise, podendo levar até 3 dias, para verificarmos se está na
        condição informada do checklist que acabamos de preencher.
      </InformativeStatement>
    </>
  );
};

export default InformativeContent;
