import styled from "styled-components";

const handleColor = (color: string) => {
  switch (color) {
    case "YELLOW":
      return { color: "#1E5BC6", background: "#FFEEE0" };
    case "GREEN":
      return { color: "#40AA60", background: "#D9FFE4" };
    case "RED":
      return { color: "#E81E26", background: "#FFE4E5" };
    case "ORANGE":
      return { color: "#FF7A14", background: "#FFEEE0" };
    default:
      return { color: "#333", background: "#F5F5F5" };
  }
};

export const Label = styled.div<{ color: string }>`
  border-radius: 15px;
  font-weight: 300;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  ${({ color }) => handleColor(color)};
`;

export const MultiplasEntregas = styled.p`
  background: #fff;
  border-radius: 15px;
  font-weight: 300;
  padding: 4px 0px 4px 6px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #78909c;
  flex-wrap: wrap;

  b {
    margin-right: 3px;
    margin-left: 3px;
  }
`;
