import styled from "styled-components";

import imgBag from "../../../../assets/bag.svg";
import imgUp from "../../../../assets/up.svg";
import imgDown from "../../../../assets/down.svg";
import imgClock from "../../../../assets/clock.svg";

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    background-position: right top;
  }
`;

export const CardContentTotal = styled.div`
  background-image: url(${imgBag});
  background-repeat: no-repeat;
`;
export const CardContentUp = styled.div`
  background-image: url(${imgUp});
  background-repeat: no-repeat;
  h4 {
    color: #40aa60;
  }
`;
export const CardContentDown = styled.div`
  background-image: url(${imgDown});
  background-repeat: no-repeat;
  h4 {
    color: #e81e26;
  }
`;
export const CardContentLast = styled.div`
  background-image: url(${imgClock});
  background-repeat: no-repeat;
`;

export const CardTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  margin: 0px;
  text-transform: uppercase;
  color: #90a4ae;
`;

export const UserImg = styled.img`
  margin-right: 22px;
`;

export const UserName = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #546e7a;
  margin: 0px 0px 10px 0px;
`;

export const UserInfo = styled.p`
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #78909c;
  margin: 0px;
`;

export const UserValue = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #546e7a;
  margin: 10px 0 0 0;
`;
