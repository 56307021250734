export const shipFromStoreTable: any = {
  "5": {
    acronym: "CSS",
    name: "SAO JOSE RIO PRETO",
  },
  "8": {
    acronym: "CMS",
    name: "MANAUS SHOPPING",
  },
  "102": {
    acronym: "RJB",
    name: "RIO BARRA",
  },
  "103": {
    acronym: "SPP",
    name: "PINHEIROS",
  },
  "104": {
    acronym: "BSB",
    name: "BRASILIA SUL",
  },
  "105": {
    acronym: "CPS",
    name: "CAMPINAS DOM PEDRO",
  },
  "106": {
    acronym: "SPT",
    name: "TIETE",
  },
  "107": {
    acronym: "POA",
    name: "PORTO ALEGRE",
  },
  "108": {
    acronym: "BHC",
    name: "CONTAGEM",
  },
  "109": {
    acronym: "CWB",
    name: "PINHAIS",
  },
  "110": {
    acronym: "SPA",
    name: "ANCHIETA",
  },
  "111": {
    acronym: "RJS",
    name: "NORTE SHOPPING",
  },
  "112": {
    acronym: "SPO",
    name: "RAPOSO TAVARES",
  },
  "113": {
    acronym: "BHS",
    name: "SHOPPING",
  },
  "114": {
    acronym: "CWC",
    name: "CHAMPAGNAT",
  },
  "115": {
    acronym: "SPR",
    name: "ARICANDUVA",
  },
  "116": {
    acronym: "SPI",
    name: "INTERLAGOS",
  },
  "117": {
    acronym: "RPS",
    name: "RIBEIRAO PRETO",
  },
  "120": {
    acronym: "SAV",
    name: "SAO VICENTE",
  },
  "121": {
    acronym: "GOS",
    name: "GOIANIA SUL",
  },
  "122": {
    acronym: "SPS",
    name: "IMIGRANTES",
  },
  "123": {
    acronym: "UBL",
    name: "UBERLANDIA",
  },
  "124": {
    acronym: "STA",
    name: "SANTO ANDRE",
  },
  "125": {
    acronym: "SOR",
    name: "SOROCABA",
  },
  "126": {
    acronym: "TBE",
    name: "TAMBORE",
  },
  "127": {
    acronym: "CPV",
    name: "CAMPINAS VALINHOS",
  },
  "128": {
    acronym: "LDB",
    name: "LONDRINA",
  },
  "129": {
    acronym: "SJC",
    name: "SAO JOSE CAMPOS",
  },
  "131": {
    acronym: "RJM",
    name: "MANILHA",
  },
  "132": {
    acronym: "SPB",
    name: "BUTANTA",
  },
  "133": {
    acronym: "SCS",
    name: "SAO CAETANO DO SUL",
  },
  "136": {
    acronym: "SJP",
    name: "SAO JOSE RIO PRETO",
  },
  "139": {
    acronym: "JDI",
    name: "JUNDIAI",
  },
  "140": {
    acronym: "CXS",
    name: "CAXIAS",
  },
  "142": {
    acronym: "SBC",
    name: "SAO BERNADO DO CAMPO",
  },
  "143": {
    acronym: "OSC",
    name: "OSASCO",
  },
  "144": {
    acronym: "RJJ",
    name: "SULACAP",
  },
  "145": {
    acronym: "GRU",
    name: "GUARULHOS",
  },
  "146": {
    acronym: "TTE",
    name: "TATUAPE ANALIA FRANCO",
  },
  "149": {
    acronym: "SPL",
    name: "LIMAO",
  },
  "150": {
    acronym: "CNS",
    name: "CANOAS",
  },
  "152": {
    acronym: "REC",
    name: "RECIFE",
  },
  "154": {
    acronym: "SPG",
    name: "GIOVANI GRONCHI",
  },
  "158": {
    acronym: "SPE",
    name: "PESSEGO",
  },
  "160": {
    acronym: "NTL",
    name: "NATAL",
  },
  "161": {
    acronym: "BHP",
    name: "PAMPULHA",
  },
  "162": {
    acronym: "JFO",
    name: "JUIZ DE FORA",
  },
  "164": {
    acronym: "PIR",
    name: "NOVA PIRACICABA",
  },
  "165": {
    acronym: "MNS",
    name: "MANAUS MANPAR",
  },
  "166": {
    acronym: "CWP",
    name: "PAROLIM",
  },
  "178": {
    acronym: "RJD",
    name: "HIPER DUQUE CAXIAS",
  },
  "179": {
    acronym: "PPA",
    name: "PASSO D AREIA",
  },
  "180": {
    acronym: "DMA",
    name: "DIADEMA",
  },
  "185": {
    acronym: "CBR",
    name: "BELFORD ROXO",
  },
  "201": {
    acronym: "EST",
    name: "SANTOS",
  },
  "202": {
    acronym: "EPL",
    name: "PAMPLONA",
  },
  "203": {
    acronym: "ERE",
    name: "REBOUCAS",
  },
  "204": {
    acronym: "ECN",
    name: "CENTER NORTE",
  },
  "206": {
    acronym: "ECP",
    name: "CAMPINAS",
  },
  "207": {
    acronym: "EPP",
    name: "PRUDENTE",
  },
  "208": {
    acronym: "ECG",
    name: "CAMPO GRANDE",
  },
  "235": {
    acronym: "BHD",
    name: "DEL REY",
  },
  "300": {
    acronym: "DDC",
    name: "DUQUE DE CAXIAS BRIGADEIROSON",
  },
  "322": {
    acronym: "SON",
    name: "SOROCABA NORTE",
  },
  "327": {
    acronym: "CCP",
    name: "CAMPO GRANDE",
  },
  "330": {
    acronym: "STS",
    name: "TABOAO DA SERRA",
  },
  "331": {
    acronym: "CAL",
    name: "ALCANTARA",
  },
  "335": {
    acronym: "STO",
    name: "SANTO ANDRE RHODIA",
  },
  "338": {
    acronym: "SPC",
    name: "CAMBUCI LION",
  },
  "339": {
    acronym: "SPV",
    name: "VILLA LOBOS",
  },
  "346": {
    acronym: "BST",
    name: "TAGUATINGA",
  },
  "347": {
    acronym: "GSD",
    name: "GOIANIA SUDOESTE",
  },
  "351": {
    acronym: "RPN",
    name: "RIBEIRAO PRETO VIA NO",
  },
  "355": {
    acronym: "MPN",
    name: "MANAUS PONTA NEGRA",
  },
  "380": {
    acronym: "JPE",
    name: "JOAO PESSOA",
  },
  "382": {
    acronym: "FOR",
    name: "FORTALEZA",
  },
  "389": {
    acronym: "BPI",
    name: "PIRITUBA",
  },
  "391": {
    acronym: "BTA",
    name: "TATUAPE CELSO GARCI",
  },
  "393": {
    acronym: "BSA",
    name: "SANTO ANDRE",
  },
  "394": {
    acronym: "BSP",
    name: "SANTOS",
  },
  "396": {
    acronym: "BCV",
    name: "CASA VERDE",
  },
  "398": {
    acronym: "BGU",
    name: "GUARULHOS DUTRA",
  },
  "416": {
    acronym: "FOB",
    name: "FORTALEZA II",
  },
  "418": {
    acronym: "NZN",
    name: "NATAL ZONA NORTE",
  },
  "420": {
    acronym: "TAU",
    name: "TAUBATE CHARLES",
  },
  "422": {
    acronym: "SBD",
    name: "DEMARCHI",
  },
  "427": {
    acronym: "SBP",
    name: "SAO B DO CAMPO PAUL",
  },
  "440": {
    acronym: "GUA",
    name: "GUARUJA",
  },
  "442": {
    acronym: "SBK",
    name: "BROOKLIN",
  },
  "449": {
    acronym: "GOP",
    name: "ANAPOLIS",
  },
  "459": {
    acronym: "SJA",
    name: "METROCAR",
  },
  "460": {
    acronym: "SPH",
    name: "PENHA",
  },
  "486": {
    acronym: "SMA",
    name: "SANTA MARIA",
  },
  "491": {
    acronym: "GRV",
    name: "GRAVATAI",
  },
  "493": {
    acronym: "BAN",
    name: "BRASILIA TERMINAL N",
  },
  "614": {
    acronym: "MAD",
    name: "MANAUS ADRIANOPOLIS",
  },
  "617": {
    acronym: "RDM",
    name: "RECIFE DOMINGOS FER",
  },
  "632": {
    acronym: "JPB",
    name: "JOAO PESSOA BANCARIOS",
  },
  "662": {
    acronym: "BHA",
    name: "BH AMERICA",
  },
  "776": {
    acronym: "VVS",
    name: "VILA VELHA SHOPPING",
  },
  "960": {
    acronym: "TPA",
    name: "OUTLET PORTO ALEGRE",
  },
  "1063": {
    acronym: "BWL",
    name: "Hiper Washington Luis",
  },
  "1064": {
    acronym: "BDP",
    name: "Hiper Dom Pedro II",
  },
  "1065": {
    acronym: "BCO",
    name: "Hiper Campinas Outlet",
  },
  "2517": {
    acronym: "TRD",
    name: "CARREFOUR MATRIZ",
  },
  "1067": {
    acronym: "BBT",
    name: "Hiper Barueri Tamboré",
  },
  "1068": {
    acronym: "BCG",
    name: "Hiper Campos de Goytacazes",
  },
  "1069": {
    acronym: "BUB",
    name: "Hiper Uberlândia Shopping Sonae",
  },
  "1070": {
    acronym: "BMC",
    name: "Hiper Macaé",
  },
  "1071": {
    acronym: "BOS",
    name: "Hiper Osasco",
  },
  "1073": {
    acronym: "BPO",
    name: "Hiper Portão",
  },
  "1074": {
    acronym: "BJO",
    name: "Hiper Joinville",
  },
  "1076": {
    acronym: "BBO",
    name: "Hiper Balneário Camboriú",
  },
  "1077": {
    acronym: "BFL",
    name: "Hiper Florianópolis Shopping",
  },
  "1078": {
    acronym: "BCA",
    name: "Hiper Cabral",
  },
  "1079": {
    acronym: "BLA",
    name: "Hiper Lages",
  },
  "1080": {
    acronym: "BFB",
    name: "Hiper Fortaleza Bezerra",
  },
  "1081": {
    acronym: "BMF",
    name: "Hiper Maceió Farol",
  },
  "1082": {
    acronym: "BMJ",
    name: "Hiper Maceió Jatiúca",
  },
  "1083": {
    acronym: "BFW",
    name: "Hiper Fortaleza Washington",
  },
  "1085": {
    acronym: "BRP",
    name: "Hiper Novo Shopping Ribeirão",
  },
  "1086": {
    acronym: "BAC",
    name: "Hiper Águas Claras",
  },
  "1160": {
    acronym: "BCH",
    name: "Hiper Cachoeirinha",
  },
  "1152": {
    acronym: "BES",
    name: "Hiper Esteio",
  },
  "1169": {
    acronym: "BNH",
    name: "Hiper Novo Hamburgo",
  },
  "1135": {
    acronym: "BPS",
    name: "Hiper Sertório",
  },
  "1155": {
    acronym: "BPC",
    name: "Hiper Cristal",
  },
  "1136": {
    acronym: "BLP",
    name: "Hiper Pinheiro",
  },
  "1156": {
    acronym: "BVA",
    name: "Hiper Viamão",
  },
  "1151": {
    acronym: "BPR",
    name: "Hiper Pelotas",
  },
  "1153": {
    acronym: "BRG",
    name: "Hiper Senador Corrêa",
  },
  "1161": {
    acronym: "BCS",
    name: "Hiper Santa Cruz do Sul",
  },
  "1117": {
    acronym: "BNL",
    name: "Hiper Santa Maria",
  },
  "1281": {
    acronym: "BFE",
    name: "Hiper Feira de Santana",
  },
  "1286": {
    acronym: "BIT",
    name: "Hiper Itabuna",
  },
  "1280": {
    acronym: "BSI",
    name: "Hiper Iguatemi",
  },
  "1282": {
    acronym: "BSG",
    name: "Hiper Garibaldi",
  },
  "1285": {
    acronym: "BVC",
    name: "Hiper Vitória da Conquista",
  },
  "1288": {
    acronym: "BSR",
    name: "Hiper Salvador Norte",
  },
  "1268": {
    acronym: "BJP",
    name: "Hiper Bessa",
  },
  "1278": {
    acronym: "BMS",
    name: "Hiper Mossoró",
  },
  "1275": {
    acronym: "BTR",
    name: "Hiper Teresina",
  },
  "1272": {
    acronym: "BSL",
    name: "Hiper Shopping São Luís",
  },
  "1277": {
    acronym: "BFT",
    name: "Hiper Fátima",
  },
  "1265": {
    acronym: "BCP",
    name: "Hiper Campina Grande",
  },
  "1266": {
    acronym: "BCM",
    name: "Hiper Mirante",
  },
  "1269": {
    acronym: "BJL",
    name: "Hiper João Pessoa Lagoa",
  },
  "1273": {
    acronym: "BTC",
    name: "Hiper Shopping Tacaruna",
  },
  "1276": {
    acronym: "BPE",
    name: "Hiper Shopping Petrolina",
  },
  "1267": {
    acronym: "BRE",
    name: "Hiper Avenida Recife",
  },
  "1271": {
    acronym: "BJG",
    name: "Hiper Jaboatão",
  },
  "1274": {
    acronym: "BSC",
    name: "Hiper Shopping Caruaru",
  },
};
