import { selectStyle } from "@components/utilities";
import React from "react";
import Select, { components } from "react-select";
import styled from "styled-components";

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eceff1;
  padding: 18px 0;
`;

const TextOptionWrapper = styled.div`
  margin-left: 6px;
`;

const OptionTitle = styled.h5`
  margin: 0;
`;

const OptionCode = styled.p`
  margin: 0;
  color: #d2d8db;
`;

type Props = {
  children: {
    title: string;
    imageUrl: string;
    code: string;
  };
  props: never;
};

// todo: fix type check on this component

const Option = ({ children, ...props }: Props) => {
  return (
    // @ts-ignore
    <components.Option {...props}>
      <OptionWrapper>
        <img src={children.imageUrl} alt={children.title} />
        <TextOptionWrapper>
          <OptionTitle>{children.title}</OptionTitle>
          <OptionCode>{children.code}</OptionCode>
        </TextOptionWrapper>
      </OptionWrapper>
    </components.Option>
  );
};

const SingleValue = ({ children, ...props }: Props) => (
  // @ts-ignore
  <components.SingleValue {...props}>{children.title}</components.SingleValue>
);

const ProductSelect = (props: any) => {
  return (
    <Select
      components={{ Option, SingleValue }}
      {...props}
      styles={selectStyle}
    />
  );
};

export default ProductSelect;
