import React, { Dispatch } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import StepHeader from "../step-header";
import { AppState } from "../../../../store";
import ItemOption from "./item-option";
import { ProductsTitle } from "./styles";
import { CurrentModal } from "../..";

interface SelectItemsProps {
  setCurrentModal: Dispatch<React.SetStateAction<CurrentModal>>;
  selectedConsignment?: Consignment;
}

const SelectItems = ({
  setCurrentModal,
  selectedConsignment,
}: SelectItemsProps) => {
  const history = useHistory();
  const orderToExchange = useSelector(
    (state: AppState) => state.exchange.orderToExchange
  );

  let allItems = selectedConsignment?.items || [];

  return (
    <div>
      <StepHeader
        length={4}
        activeStep={1}
        title="Selecione os produtos a serem trocados"
        backButtonOnClick={() => {
          history.push(
            `/atendimento/pedido/detalhe/${orderToExchange.orderCode}`
          );
        }}
      />

      <div>
        <ProductsTitle>Produtos</ProductsTitle>

        {allItems.map((item) => (
          <ItemOption
            item={item}
            key={item.vtexItemId}
            setCurrentModal={setCurrentModal}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectItems;
