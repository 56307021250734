import React from "react";
import { Column, Row } from "@components/utilities";
import { Button } from "carrefour-portal-backoffice-style-guide";
import {
  TitleAlert,
  CardModal,
  PaymentInfo,
  PaymentInfoLabel,
  PaymentInfoValue,
} from "../styles";

const ModalPaymentData = () => {
  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 50px">
        <Column textAlign="left">
          <TitleAlert>Dados do cliente</TitleAlert>
        </Column>
        <Column margin="15px auto 0 auto" textAlign="left">
          <PaymentInfo>
            <Row justify="space-between">
              <Column margin="0 40px 0 0">
                <div>
                  <PaymentInfoLabel>Nome</PaymentInfoLabel>
                  <PaymentInfoValue>Vinícius Tifoski Ianoni</PaymentInfoValue>
                </div>
                <div>
                  <PaymentInfoLabel>CPF</PaymentInfoLabel>
                  <PaymentInfoValue>Vinícius Tifoski Ianoni</PaymentInfoValue>
                </div>
              </Column>
              <Column>
                <div>
                  <PaymentInfoLabel>Dados Bancários</PaymentInfoLabel>
                  <PaymentInfoValue>
                    Conta: 0102 <br />
                    Agência: 12345-6 <br />
                    Banco: Nubank S.A <br />
                  </PaymentInfoValue>
                </div>
              </Column>
            </Row>
          </PaymentInfo>
        </Column>
        <Row margin="30px 0 0 0" justify="center">
          <Column margin="0 20px 0 0">
            <Button
              text="Fechar"
              variation="primary"
              onClick={() => {}}
              size="large"
              outline
            />
          </Column>
        </Row>
      </CardModal>
    </>
  );
};

export default ModalPaymentData;
