import React, { Dispatch } from "react";

import StepHeader from "../step-header";
import { CurrentPage } from "../..";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store";
import SelectedItem from "../common/selected-item";
import { InformativeTitle } from "./styles";
import InformativeContent from "./informative-content";
import { Consignment } from "../../../order-detail/OrderDetailsResponse";

interface ExchangeReviewProps {
  setCurrentPage: Dispatch<React.SetStateAction<CurrentPage>>;
  selectedConsignment: Consignment;
}

const ExchangeReview = ({
  setCurrentPage,
  selectedConsignment,
}: ExchangeReviewProps) => {
  const selectedItems = useSelector(
    (state: AppState) => state.exchange.selectedItems
  );

  const returnMethod = useSelector(
    (state: AppState) => state.exchange.returnMethod
  );

  return (
    <div>
      <StepHeader
        length={4}
        activeStep={4}
        title="Revise as informações"
        backButtonOnClick={() => {
          setCurrentPage("SELECT_COLLECT_OR_POSTAGE");
        }}
      />

      <div>
        {selectedItems.map((selectedItem) => (
          <SelectedItem item={selectedItem} />
        ))}
      </div>

      <InformativeTitle>
        Informativo para
        {returnMethod === "COLLECT" && " coleta"}
        {returnMethod === "POSTAGE" && " postagem"}
      </InformativeTitle>

      <InformativeContent selectedConsignment={selectedConsignment} />
    </div>
  );
};

export default ExchangeReview;
