import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import { Column, Row } from "@components/utilities";
import { Button } from "carrefour-portal-backoffice-style-guide";
import {
  TitleAlert,
  CardModal,
  InfoTextWrapper,
  TitleWrapper,
  TitleIcon,
  Subtitle,
  InfoAddress,
  InfoSubhead,
} from "../styles";
import iconExclamation from "@assets/exclamation.svg";

const ModalInfoPackage = (props: any) => {
  const { consignmentsCode } = useParams();
  const consignments: Consignment[] = useSelector(
    (state: any) => state.returnItems.orderDetails?.consignments
  );
  const [consignment] = consignments?.filter(
    (c) => c.consignmentCode === consignmentsCode
  ) ?? [null];
  const {
    delivery: { shippingAddress },
  } = consignment;

  const { invoiceAddress, delivery }: Consignment = consignment;
  const { modality } = delivery;

  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 50px">
        <TitleWrapper>
          <TitleIcon src={iconExclamation} alt="exclamation" />
          <TitleAlert>Atenção</TitleAlert>
        </TitleWrapper>
        <Column margin="0 auto" textAlign="center">
          <Subtitle>Entenda o prazo de coleta!</Subtitle>
          <InfoSubhead>Endereço</InfoSubhead>
          <InfoAddress>
            <p>
              CEP {shippingAddress.postalCode}
              <br />
              {shippingAddress.street}, {shippingAddress.number},{" "}
              {shippingAddress.complement} <br />
              {shippingAddress.neighborhood} - {shippingAddress.city} -{" "}
              {shippingAddress.state}
            </p>
          </InfoAddress>
          <InfoSubhead>Informativo para coleta</InfoSubhead>
          <InfoTextWrapper>
            Para efetuarmos a sua troca ou devolução, passamos por algumas
            etapas.
            <p>
              1.Buscando os produtos Desta forma encaminharemos os seus dados
              para que em breve você receba um contato com as informações da
              data de agendamento da coleta.
            </p>
            <p>
              2.Da transportadora ao nosso armazém Depois da coleta,
              acompanharemos a devolução do seu pedido até a chegada em nosso
              armazém, o que pode levar o mesmo prazo da primeira entrega.
            </p>
            <p>
              3. Análise do produto Após recebermos o seu pedido em nosso
              armazém, o produto passa por uma breve análise, podendo levar até
              3 dias, para verificarmos se está na condição informada do
              checklist que acabamos de preencher.
            </p>
            <p>
              Ao final desse processo, se você optar por reembolso, o prazo da
              restituição começa a contar; se optar pela troca do produto,
              inicia o prazo da nova entrega.
            </p>
          </InfoTextWrapper>
        </Column>
        <Row margin="30px 0 0 0" justify="flex-end">
          <Column margin="0 20px 0 0">
            <Button
              text="Cancelar"
              variation="primary"
              onClick={() => props.close()}
              size="large"
              outline
            />
          </Column>
          <Button
            text="Prosseguir"
            variation="confirm"
            onClick={props.action}
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalInfoPackage;
