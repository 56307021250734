import styled from "styled-components";

export const ContainerTitle = styled.div`
  text-align: center;
`;

export const Badge = styled.span`
  color: #ffffff;
  background: #1e5bc6;
  font-size: 16px;
  border-radius: 50%;
  margin-right: 10px;
  padding: 4px;
  position: relative;
  display: inline-block;
  height: 15px;
  width: 15px;
  line-height: 16px;
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  color: #1e5bc6;
  margin: 0px auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #eceff1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Subtitle = styled.p`
  margin: 32px 10px;
  font-size: 15px;
  font-weight: 400;

  span {
    color: #1e5bc6;
  }

  b {
    color: #ff7a14;
    font-size: 15px;
    font-weight: 400;
  }
`;
export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
