import styled from "styled-components";

export const Card = styled.div`
  justify-items: center;
  background: #e4f1ff;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 45px 40px;
`;

export const Row = styled.div`
  display: block;
  margin-top: 20px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  display: block;
`;
