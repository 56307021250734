import React from "react";
import { Button } from "carrefour-portal-backoffice-style-guide";

import { formatMoney } from "@utils/formatUtils";
import { Column, Row } from "@components/utilities";
import { CardModal, TitleAlert, DescAlert, Subtitle } from "../styles";

type Props = {
  value?: number;
  returnTitle?: string;
  action?: (...args: any[]) => any;
  close?: (...args: any[]) => any;
  cancelText?: string;
  confirmText?: string;
  askText?: string;
};

const ModalRefund = (props: Props) => {
  return (
    <CardModal padding="50px 150px">
      <Column margin="0 auto" textAlign="center">
        <DescAlert>
          {props.askText ?? "Você tem certeza que deseja estornar esse pedido?"}
        </DescAlert>
        {props.returnTitle && <TitleAlert>{props.returnTitle}</TitleAlert>}
        {props.value && (
          <Subtitle>
            Valor total de: <span>{formatMoney(props.value)}</span>
          </Subtitle>
        )}
      </Column>

      <Row margin="30px 0 0 0">
        <Column margin="0 20px 0 0">
          <Button
            text={props.cancelText ?? "Cancelar"}
            variation="primary"
            onClick={props.close}
            size="large"
            outline
          />
        </Column>
        <Button
          text={props.confirmText ?? "Prosseguir"}
          variation="confirm"
          onClick={props.action}
          size="large"
        />
      </Row>
    </CardModal>
  );
};

export default ModalRefund;
