import React from "react";
import { useDispatch } from "react-redux";
import { Column, Row } from "@components/utilities";
import {
  CardModal,
  TitleAlert,
  TextLight,
  ModalActionButton,
} from "../../../styles";
import { shipFromStoreActionRegister } from "@services/cancellation";

interface TItems {
  close?: any;
  orderCode: string;
  consignmentCode: string;
}

const ShipFromStoreRegister = ({
  orderCode,
  consignmentCode,
  close,
}: TItems) => {
  useDispatch();
  const handleRegisterShipFromStore = async () => {
    await shipFromStoreActionRegister(orderCode, consignmentCode).then(() => {
      location.reload();
    });
  };

  return (
    <>
      <CardModal maxWidth="650px" padding="35px">
        <div>
          <TitleAlert color="#1E5BC6" size="26px">
            Cancelamento/Devolução Ship From Store
          </TitleAlert>
          <TextLight
            fontSize="16px"
            fontWeight={400}
            color="#5A646E"
            margin="30px 0px 15px 0px"
            textAlign="center"
          >
            Clique em “prosseguir”, para registrar o processo de
            cancelamento/devolução,
            <b style={{ marginLeft: "3px" }}>
              apenas se a loja já tiver sido acionada via e-mail.
            </b>
          </TextLight>
        </div>
        <Row margin="30px 0 0 0" justify="center">
          <Column margin="0 20px 0 0">
            <ModalActionButton onClick={() => close()}>
              Voltar
            </ModalActionButton>
          </Column>
          <ModalActionButton
            onClick={() => handleRegisterShipFromStore()}
            background="#40AA60"
            color="#FFFFFF"
          >
            Prosseguir
          </ModalActionButton>
        </Row>
      </CardModal>
    </>
  );
};

export default ShipFromStoreRegister;
