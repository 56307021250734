import styled from "styled-components";
import iconSearch from "../../assets/search-blue.svg";

export const Content = styled.div`
  padding-top: 45px;
`;

export const Container = styled.div`
  max-width: 1112px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
`;

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eceff1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled.button`
  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  background: transparent;
  background-image: url(${iconSearch});
  background-position: left 15px center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;
  padding: 12px 24px 12px 35px;
  color: #1e5bc6;
  white-space: nowrap;
  cursor: pointer;
`;
