export interface CustomerState {
  birthDate: string;
  document: string;
  email: string;
  firstName: string;
  homePhone: string;
  lastName: string;
  ordersQuantity: number;
  returnedOrdersValue: number;
  totalOrdersValue: number;
  updatedIn: string;
}

export const CUSTOMER_DATA = "CUSTOMER_DATA";

export interface CustomerActionsParams {
  type: string;
  payload: any;
}

type CustomerActions = CustomerActionsParams;

const initialState: CustomerState = {
  birthDate: null,
  document: "",
  email: "",
  firstName: "",
  homePhone: "",
  lastName: "",
  ordersQuantity: 0,
  returnedOrdersValue: 0,
  totalOrdersValue: 0,
  updatedIn: null,
};

export function customerReducer(
  state = initialState,
  action: CustomerActions
): CustomerState {
  switch (action.type) {
    case CUSTOMER_DATA:
      return action.payload;
    default:
      return state;
  }
}
