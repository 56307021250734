const numberRegex = new RegExp("^[0-9]*$");
const numberAndLetterXRegex = new RegExp("^[0-9|x|X]*$");
const orderCodeRegex = new RegExp("^(SNG)?[0-9]{9}$");

function cpfMask(value: string) {
  if (value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  }
  return value;
}

function cnpjMask(value: string) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

function moneyMask(value: string) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d)(\d{2})$/, "$1,$2")
    .replace(/(?=(\d{3})+(\D))\B/g, ".")
    .replace("", "R$ ");
}

function phoneMask(value: string) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
}

function cepMask(value: string) {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
}

function validateEmail(value: string) {
  var re = /\S+@\S+\.\S+/;
  return re.test(value);
}

function validateBankInfo(value: string) {
  return numberRegex.test(value);
}

function validateInputBranch(value: string) {
  return numberRegex.test(value);
}

function validateInputBranchDigit(value: string) {
  return numberAndLetterXRegex.test(value);
}

function validateInputAccount(value: string) {
  return numberRegex.test(value);
}

function validateInputAccountDigit(value: string) {
  return numberAndLetterXRegex.test(value);
}
function validateOrderCode(value: string) {
  return orderCodeRegex.test(value);
}

function clearDocument(value: string) {
  return value.replace(/\D+/g, "");
}

export {
  cpfMask,
  cnpjMask,
  moneyMask,
  phoneMask,
  cepMask,
  validateEmail,
  validateInputBranch,
  validateInputBranchDigit,
  validateInputAccount,
  validateInputAccountDigit,
  validateBankInfo,
  validateOrderCode,
  clearDocument,
};
