import React from "react";
import {
  CardModal,
  Container,
  IconDownloadSucess,
  TextConfirmedReport,
  CloseModalButton,
} from "../styles";
import { useHistory } from "react-router-dom";

const ModalSuccessReport: React.FC = () => {
  const history = useHistory();
  function closeModalSuccess() {
    history.push(`/home`);
  }
  return (
    <CardModal>
      <Container>
        <IconDownloadSucess />
        <TextConfirmedReport>
          Seu download foi realizado com sucesso
        </TextConfirmedReport>
        <CloseModalButton onClick={closeModalSuccess}>Fechar</CloseModalButton>
      </Container>
    </CardModal>
  );
};

export default ModalSuccessReport;
