import styled from "styled-components";

export const Content = styled.div`
  display: flow-root;
`;

export const Container = styled.div`
  max-width: 1112px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
`;

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  // margin-bottom: 50px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorAlert = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;

  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff;

  border-radius: 8px;
  box-shadow: rgb(176 190 197 / 30%) 0px 0px 8px;
`;

export const ErrorButton = styled.button`
  border: none;
  margin: 24px auto 0 auto;
  display: block;
  width: 240px;
  height: 40px;
  max-width: 100%;

  background-color: #eb4141;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
  font-size: 0.9rem;
  text-transform: none;
  color: #fff;

  padding: 0.625rem 0;
  border-radius: 0.5rem;
`;
