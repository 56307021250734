import styled from "styled-components";

export const RowBetweenCenter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  * {
    display: flex;
    align-items: center;
  }
`;

export const PaymentMethods = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
`;

type PaymentActive = {
  inactive?: boolean;
  active?: boolean;
};

export const PaymentMethod = styled.li<PaymentActive>`
  position: relative;
  list-style:none;
  flex:1;
  max-width:340px;
  opacity:${(props: PaymentActive) => (props.inactive ? 0.3 : 1)};

  label::before {
    border: 0.1rem solid #B0BEC5;
    background: #fff;
    box-shadow: 0px 0px 0px 2px #fff;
}
  }
`;

export const PaymentMethodBtn = styled.input`
  position: absolute;
  opacity: 0;
  visibility: hidden;

  &:checked + label {
    color: #1e5bc6;
  }

  &:checked + label::before {
    margin-right: 10px;
    border: 0.1rem solid #fff;
    background: #1e5bc6;
    box-shadow: 0px 0px 0px 2px #1e5bc6;
  }
`;

export const PaymentMethodLabel = styled.label`
  display: -webkit-box;
  display: flex;
  -webkit-box-align: flex-start;
  align-items: flex-start;
  position: absolute;
  padding: 0.75rem 0;
  color: #263238;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  width: 100%;
  height: 100%;

  &::before {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 10px;
    border-radius: 50%;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
    border: 0.1rem solid #fff;
    background: #1e5bc6;
    box-shadow: 0px 0px 0px 2px #1e5bc6;
  }
`;

export const PaymentDescription = styled.p<PaymentActive>`
  font-weight: 300;
  margin-top: 50px;
  font-size: 12px;
  line-height: 16px;
  color: ${(props: PaymentActive) => (props.active ? "#263238" : "#b0bec5")};
  text-align: left;
`;

export const PaymentDetails = styled.div<PaymentActive>`
  border: ${(props: PaymentActive) =>
    props.active ? "1px solid #1e5bc6" : "1px solid #eceff1"};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 25px;
  text-align: left;
`;

export const PaymentDetailName = styled.p<PaymentActive>`
  font-size: 18px;
  margin: 0px;
  color: #546e7a;
  padding: 10px 0;
  color: ${(props: PaymentActive) => (props.active ? "#1e5bc6" : "#546e7a")};
`;

export const BodyPayment = styled.div`
  padding: 5px 15px;
  background: #fcfdff;
  border-radius: 8px;
`;

export const PaymentItem = styled.div`
  border-bottom: 1px solid #eceff1;
  padding: 15px 0;

  img {
    margin-right: 5px;
  }

  &:last-child {
    border-bottom: none;
    padding: 0px;
  }
`;

export const StatusPayment = styled.h3`
  font-weight: 400;
  font-size: 18px;
  color: #40aa60;
  margin: 0px;
`;

type TextPayment = {
  color?: string;
  weight?: string;
  margin?: string;
  fontSize?: string;
};

export const TextPayment = styled.span<TextPayment>`
  font-size: ${(props: TextPayment) => props.fontSize || "12px"};
  font-weight: ${(props: TextPayment) => props.weight || "300"};
  color: ${(props: TextPayment) => props.color || "#78909c"};
  margin: ${(props: TextPayment) => props.margin || "0px"};
`;
