import styled from "styled-components";
import iconDownloadSucess from "@assets/download-success.svg";
import iconDownloadFail from "@assets/download-fail.svg";
import iconDownloadEmpy from "@assets/download-empty.svg";

/*Modal*/
type TCardModal = {
  padding?: string;
};
type TContainer = {
  flexDirection?: string;
};

export const CardModal = styled.div<TCardModal>`
  width: auto;
  max-width: 328px;
  max-height: 85vh;
  background: #fff;
  border-radius: 8px;
  padding: ${(props: TCardModal) => props.padding || "24px"};
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
`;

export const CloseModal = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
  background: transparent;
  border: none;
`;

export const Title = styled.h1`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #353a40;
`;
export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.button`
  width: 160px;
  padding: 10px 16px;
  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  background: #ffffff;
  background-position: left 15px center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;

  color: #1e5bc6;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #1e5bc6;
    color: #ffffff;
  }
`;

export const TitleAlert = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #ff7a14;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 328px;
  height: 195px;
  align-items: center;
  flex-direction: ${(props: TContainer) => props.flexDirection || "column"};
`;

export const IconDownloadSucess = styled.div`
  background-image: url(${iconDownloadSucess});
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const IconDownloadEmpy = styled.div`
  background-image: url(${iconDownloadEmpy});
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

export const IconDownloadFail = styled.div`
  background-image: url(${iconDownloadFail});
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const TextConfirmedReport = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #40aa60;
`;
export const TextEmpyReport = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #e89b28;
`;
export const TextFailReport = styled.p`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #eb4141;
`;

export const CloseModalButton = styled.button`
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #1e5bc6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  background: #1e5bc6;
  background-position: left 15px center;
  background-repeat: no-repeat;
  align-items: center;
  text-align: center;
  color: #ffffff;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background: #1f5cc7;
  }
`;
