import React from "react";
import { Column, Row } from "@components/utilities";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { TitleAlert, CardModal, DescAlert } from "./../styles";
import { Subheading } from "./../../return-items/style";
import iconExclamation from "@assets/exclamation.svg";

const ModalClickStatus = () => {
  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        <img src={iconExclamation} alt="exclamation" />
        <Column margin="0 auto" textAlign="center">
          <TitleAlert>Pedido de Click&amp;Retire</TitleAlert>
          <Subheading>Os produtos são do estoque da loja física</Subheading>
          <DescAlert>
            Se houve confirmação com o cliente que este boleto não <br /> foi
            pago, basta orientá-lo que seu pedido será cancelado <br />
            automaticamente em caso de não pagamento.
          </DescAlert>
        </Column>
      </CardModal>
    </>
  );
};

export default ModalClickStatus;
