import styled from "styled-components";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  padding: 24px;
  border-radius: 16px;

  max-width: 400px;
`;

export const Loader = styled.div`
  border: 0.25rem solid #f3f3f3; /* Light grey */
  border-top: 0.25rem solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
`;

export const Span = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const CloseButton = styled.button`
  border: 1px solid transparent;
  flex: 1;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
  width: 100%;
  min-height: 2.5rem;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  background-color: #0970e6;
  color: #fff;
  cursor: pointer;
`;
