import styled from "styled-components";

export const ProductsTitle = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color: #575757;
  margin-top: 24px;
  margin-bottom: 0px;
`;
