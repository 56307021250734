import styled from "styled-components";

export const ProductCard = styled.button<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  border: none;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid ${(props) => (props.isSelected ? "#173EB4" : "#d9d9d9")};
  background-color: #fff;
  box-shadow: ${(props) =>
    props.isSelected ? "none" : "2px 2px 8px 0px rgba(18, 18, 18, 0.16)"};
  padding: 16px 24px;
  margin-top: 24px;

  max-width: 302px;

  font-family: "Ubuntu";
`;

export const ProductCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 16px;
`;

export const ProductCardName = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  color: #454545;

  margin: 0;
`;

export const ProductCardCode = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  color: #575757;

  margin: 0;
  margin-top: 8px;
`;
