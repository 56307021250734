import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "carrefour-portal-backoffice-style-guide";
import Skeleton from "react-loading-skeleton";

import { Container } from "@components/utilities";
import { ContainerBar, RowBetween, CustomerInfo, Buttons } from "./styles";
import { AppState } from "@store/index";

interface ActionBarProps {
  nextPageOrAction: () => void;
  canGoNext: boolean;
  inLastScreen: boolean;
}

const ActionBar = ({
  inLastScreen,
  nextPageOrAction,
  canGoNext,
}: ActionBarProps) => {
  const history = useHistory();

  const orderDetails = useSelector(
    (state: AppState) => state.exchange.orderToExchange
  );

  return (
    <>
      <ContainerBar>
        <Container>
          <RowBetween>
            <CustomerInfo>
              <h5>
                Troca pelo mesmo produto de:{" "}
                {orderDetails?.customerFirstName ? (
                  <span>{`${orderDetails?.customerFirstName} ${orderDetails?.customerLastName}`}</span>
                ) : (
                  <Skeleton width={80} height={22} />
                )}
              </h5>

              <strong>PEDIDO</strong>

              <p>
                {orderDetails?.orderCode ? (
                  `#${orderDetails.orderCode}`
                ) : (
                  <Skeleton width={80} height={16} />
                )}

                <span>Pedido VTEX</span>
              </p>
            </CustomerInfo>
            <Buttons>
              <Button
                data-testid="button-close"
                text="Fechar"
                outline
                margin="0 15px 0 0"
                onClick={() =>
                  history.push(
                    `/atendimento/pedido/detalhe/${orderDetails.orderCode}`
                  )
                }
              />

              <Button
                data-testid="button-confirm"
                text={inLastScreen ? "Confirmar" : "Próximo"}
                onClick={nextPageOrAction}
                variation="confirm"
                disabled={!canGoNext}
              />
            </Buttons>
          </RowBetween>
        </Container>
      </ContainerBar>
    </>
  );
};

export default ActionBar;
