import React, { useState } from "react";
import { redeemGiftCard } from "@services/redeemContentCard";
import { CardModal, TitleRedeemGift } from "../../styles";
import { Column, Row } from "@components/utilities";
import { Button, Text } from "carrefour-portal-backoffice-style-guide";
import { useDispatch } from "react-redux";

interface redeemGiftPayload {
  orderId: string;
  orderCode: string;
  sequence: string;
  userId: string;
  numId: string;
  giftcardSKU: string;
  emailTo: string;
  productName: string;
  userName: string;
  price: string;
  origem: string;
  close: any;
  dispatch?: any;
}

const ModalRedeemGift = ({
  orderId,
  orderCode,
  sequence,
  userId,
  numId,
  giftcardSKU,
  emailTo,
  productName,
  userName,
  price,
  origem,
  close,
}: redeemGiftPayload) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const redeemGift = async ({
    orderId,
    orderCode,
    sequence,
    userId,
    numId,
    giftcardSKU,
    emailTo,
    productName,
    userName,
    price,
    origem,
    close,
    dispatch,
  }: redeemGiftPayload) => {
    setIsLoading(true);

    try {
      const response = await redeemGiftCard({
        orderId,
        orderCode,
        sequence,
        userId,
        numId,
        giftcardSKU,
        emailTo,
        productName,
        userName,
        price,
        origem,
      });

      if (response && response.status >= 400 && response.status < 600) {
        throw new Error(response.message);
      }

      window.location.reload();
      close();
    } catch (error) {
      dispatch({
        type: "REDEEM_GIFT",
        payload: [{ error: error?.message, hasError: true, item: sequence }],
      });
      close();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CardModal
        data-testid="cancel-modal"
        padding="16px"
        style={{ maxWidth: `280px`, maxHeight: `265px`, textAlign: "center" }}
      >
        <TitleRedeemGift margin="0 0 16px">
          Tem certeza de que deseja desbloquear o Gift Card do cliente?
        </TitleRedeemGift>
        <Text
          type="body"
          size="medium"
          text={`Esta ação é irreversível. Após o desbloqueio, não será possível cancelar o cartão.`}
          margin="0"
        />
        <Row margin="24px 0 0 0" justify="center">
          <Column margin="0 16px 0 0">
            <Button
              text="Não"
              variation="primary"
              onClick={() => close()}
              size="large"
              outline
            />
          </Column>
          <Button
            text={isLoading ? "Desbloqueando..." : "Sim"}
            variation="primary"
            disabled={isLoading}
            onClick={() =>
              redeemGift({
                orderId,
                orderCode,
                sequence,
                userId,
                numId,
                giftcardSKU,
                emailTo,
                productName,
                userName,
                price,
                origem,
                close,
                dispatch,
              })
            }
            size="large"
            outline
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalRedeemGift;
