import { CancellationType } from "@pages/cancellation-flow/enums/cancellation-type";

export interface CancelState {
  orderCode: string;
  consignmentCode: string;
  cancelReason: string;
  paymentType: string;
  cancelScreen: string;
  refundMethod: string;
  totalValue: number;
  bankAccount: any;
  paymentMethods: any;
  modalTotalCancel: number;
  cancellationType: CancellationType;
}

export const ORDER_CODE = "ORDER_CODE";
export const CONSIGNMENT_CODE = "CONSIGNMENT_CODE";
export const CANCEL_REASON = "CANCEL_REASON";
export const PAYMENT_TYPE = "PAYMENT_TYPE";
export const CANCEL_SCREEN = "CANCEL_SCREEN";
export const REFUND_METHOD = "REFUND_METHOD";
export const TOTAL_VALUE = "TOTAL_VALUE";
export const BANK_ACCOUNT = "BANK_ACCOUNT";
export const PAYMENT_METHODS = "PAYMENT_METHODS";
export const MODAL_TOTAL_CANCEL = "MODAL_TOTAL_CANCEL";
export const SET_CANCELLATION_TYPE = "SET_CANCELLATION_TYPE";

export interface CancelParams {
  type: string;
  payload: any;
}

export interface SetCancellationType {
  type: string;
  payload: CancellationType;
}

type Cancel = CancelParams | SetCancellationType;

const initialState: CancelState = {
  orderCode: "",
  consignmentCode: "",
  cancelReason: "",
  paymentType: "",
  cancelScreen: "",
  refundMethod: "",
  totalValue: 0,
  bankAccount: null,
  paymentMethods: null,
  modalTotalCancel: 1,
  cancellationType: CancellationType.DELIVERY_CANCELLATION,
};

export function cancelReducer(
  state = initialState,
  action: Cancel
): CancelState {
  switch (action.type) {
    case ORDER_CODE:
      return {
        ...state,
        orderCode: action.payload,
      };
    case CONSIGNMENT_CODE:
      return {
        ...state,
        consignmentCode: action.payload,
      };
    case CANCEL_REASON:
      return {
        ...state,
        cancelReason: action.payload,
      };
    case PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload,
      };
    case CANCEL_SCREEN:
      return {
        ...state,
        cancelScreen: action.payload,
      };
    case REFUND_METHOD:
      return {
        ...state,
        refundMethod: action.payload,
      };
    case TOTAL_VALUE:
      return {
        ...state,
        totalValue: action.payload,
      };
    case BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: action.payload,
      };
    case PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case MODAL_TOTAL_CANCEL:
      return {
        ...state,
        modalTotalCancel: action.payload,
      };
    case SET_CANCELLATION_TYPE:
      return {
        ...state,
        cancellationType: action.payload,
      };
    default:
      return state;
  }
}
