import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { formatDate } from "@utils/formatUtils";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { customStyles } from "@utils/customStyles";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { ButtonLink } from "./styles";
import { cpfMask } from "@utils/masks";

interface Customers {
  data: Customer[];
  loading: boolean;
}

interface Customer {
  birthDate: string;
  document: string;
  email: string;
  firstName: string;
  homePhone: string;
  lastName: string;
  ordersQuantity: number;
  returnedOrdersValue: number;
  totalOrdersValue: number;
  updatedIn: string;
}

const Table = ({ data, loading }: Customers) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAction = (emailClient: string, row: any) => {
    dispatch({ type: "SEARCH_TERM_ORDER", payload: emailClient });
    dispatch({ type: "CUSTOMER_DATA", payload: row });
    history.push(`/atendimento/pedidos`);
  };

  const columns = useMemo(
    () => [
      {
        name: "ÚLTIMA ATUALIZAÇÃO",
        cell: (row: { updatedIn: string }) => (
          <div style={{ fontWeight: 300 }} data-testid="row-data-date">
            {formatDate(row.updatedIn)}
          </div>
        ),
        sortable: false,
      },
      {
        name: "NOME DE CLIENTE",
        cell: (row: { firstName: string; lastName: string; email: string }) => (
          <ButtonLink onClick={() => handleAction(row.email, row)}>
            {row.firstName
              ? row.lastName
                ? `${row.firstName} ${row.lastName}`
                : `${row.firstName}`
              : "Nome não identificado"}
          </ButtonLink>
        ),
        sortable: false,
      },
      {
        name: "CPF",
        cell: (row: { document: string }) => (
          <div style={{ fontWeight: 300 }}>{cpfMask(row.document)}</div>
        ),
        sortable: false,
      },
      {
        name: "E-MAIL",
        cell: (row: { email: string }) => (
          <div style={{ fontWeight: 300 }}>{row.email}</div>
        ),
        sortable: false,
        allowOverflow: false,
        grow: 2,
      },
      {
        cell: (row: any) => (
          <Button
            text="Visualizar"
            onClick={() => handleAction(row.email, row)}
            outline
          />
        ),
        ignoreRowClick: false,
        allowOverflow: false,
        button: false,
        name: "",
        right: true,
      },
    ],
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {data ? (
        <DataTable
          noHeader={true}
          columns={columns}
          progressPending={loading}
          progressComponent={<h3>Carregando</h3>}
          noDataComponent={
            <h3>
              Ops... parece que este cliente ainda <br /> não fez nenhuma
              compra!
            </h3>
          }
          data={data}
          style={{
            borderRadius: "8px",
            boxShadow: "0px 0px 8px rgba(176, 190, 197, 0.3)",
          }}
          customStyles={customStyles}
        />
      ) : (
        "Carregando"
      )}
    </>
  );
};

export default Table;
