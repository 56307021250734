import React from "react";
import { RowBetween, Container, Column } from "@components/utilities";
import { formatMoney } from "@utils/formatUtils";
import { BottomBarStyle, CustomerName, TitleValue, Value } from "./styles";
import { Customer } from "../../BalanceAvaliableResponse";

type BarType = {
  customerData: Customer;
};

const BottomBar = ({ customerData }: BarType) => {
  return (
    <BottomBarStyle>
      <Container>
        <RowBetween>
          <Column>
            <CustomerName>
              <strong>Consulta de vales: </strong>{" "}
              {`${customerData.firstName} - ${customerData.lastName}`}
            </CustomerName>
            <TitleValue>Saldo total</TitleValue>
            <Value>{formatMoney(customerData.totalOrdersValue)}</Value>
          </Column>
        </RowBetween>
      </Container>
    </BottomBarStyle>
  );
};

export default BottomBar;
