import React from "react";
import Skeleton from "react-loading-skeleton";

import {
  ExchangeLoadingButton,
  ExchangeLoadingDescription,
  ExchangeLoadingIcon,
  ExchangeLoadingTitle,
  ExchangeLoadingWrapper,
} from "./styles";

const ExchangeLoading = () => {
  return (
    <ExchangeLoadingWrapper>
      <ExchangeLoadingIcon>
        <Skeleton height={48} />
      </ExchangeLoadingIcon>

      <ExchangeLoadingTitle>
        <Skeleton height={24} />
      </ExchangeLoadingTitle>

      <ExchangeLoadingDescription>
        <Skeleton height={14} />
      </ExchangeLoadingDescription>

      <ExchangeLoadingDescription>
        <Skeleton height={14} />
      </ExchangeLoadingDescription>

      <ExchangeLoadingButton>
        <Skeleton height={40} />
      </ExchangeLoadingButton>
    </ExchangeLoadingWrapper>
  );
};

export default ExchangeLoading;
