import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import DropDown from "./components/drop-down";
import { Breadcrumbs } from "carrefour-portal-backoffice-style-guide";
import PageHeader from "@components/page-header";

import { Content, Container, Column } from "@components/utilities";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const access = JSON.parse(localStorage.getItem("access"));
    if (access) {
      dispatch({ type: "AUTH_DATA", payload: access.authorities });
    }
  }, []);

  useEffect(() => {
    console.log("v=0.0.1");
  }, []);

  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento", label: "Atendimento" },
          ]}
        />
        <Column>
          <PageHeader
            title="ATENDIMENTO"
            subtitle="Faça sua busca"
            button={false}
          />
          <DropDown />
        </Column>
      </Container>
    </Content>
  );
};

export default Home;
