import http from "./http";
import handleLogout from "./session";

type CustomerRequest = {
  searchParam: string;
  searchTerm: string;
};

export async function getCustomer({
  searchParam,
  searchTerm,
}: CustomerRequest) {
  try {
    const response = await http.get("/v1/customers", {
      params: {
        [searchParam]: searchTerm,
      },
    });

    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}
