import { FlowType, Status, Payments } from "./types";
import {
  availabilityType,
  DeliveryModalityType,
} from "@store/return-items-reducer";

type deliverySelectorNonFoodParams = {
  deliveryOption?: DeliveryModalityType;
  hasStock?: boolean;
  consignmentType?: FlowType;
  hasDeliveryScheduler?: boolean;
};

export const deliverySelectorNonFood = ({
  deliveryOption,
  hasStock,
  consignmentType,
  hasDeliveryScheduler,
}: deliverySelectorNonFoodParams) => {
  if (
    deliveryOption === "POSTAGE" ||
    deliveryOption === "NO_RETURN" ||
    !hasStock
  ) {
    if (consignmentType === "MARKETPLACE_OUT") return null;

    return "Refund";
  }

  if (deliveryOption === "RESEND" && hasDeliveryScheduler) {
    return "DeliveryScheduler";
  }

  if (deliveryOption === "COLLECT" || deliveryOption === "COLLECT_IN_STORE") {
    return "AddressSelector";
  }
};

export function nextScreenModal(
  currentFlow: FlowType,
  currentScreen: Status,
  {
    deliveryOption,
    paymentOption,
    requestReturnStatus,
    hasStock,
    availabilityInfo,
    consignmentType,
    hasConfirmation,
  }: {
    deliveryOption?: DeliveryModalityType;
    paymentOption?: Payments;
    availabilityInfo?: availabilityType;
    requestReturnStatus?: string;
    hasStock?: boolean;
    consignmentType?: FlowType;
    hasConfirmation?: boolean;
  }
) {
  const hasDeliveryScheduler = availabilityInfo?.slas?.reduce((acc, sla) => {
    return acc || !!sla.deliveryWindows.length;
  }, false);

  switch (currentFlow) {
    case "FOOD": {
      if (
        currentScreen === "AddressSelector" &&
        deliveryOption === "COLLECT_IN_STORE"
      ) {
        return "packageCollect";
      }

      if (
        currentScreen === "DeliverySelector" &&
        !requestReturnStatus &&
        deliveryOption === "RESEND"
      ) {
        if (!hasStock) {
          return "resendRequestFailed";
        }
      }

      if (
        currentScreen === "DeliverySelector" &&
        !hasDeliveryScheduler &&
        !requestReturnStatus &&
        deliveryOption === "RESEND"
      ) {
        return "resendConfirm";
      }

      if (
        (currentScreen === "DeliveryScheduler" ||
          (currentScreen === "ReturnItems" && !hasDeliveryScheduler)) &&
        !requestReturnStatus
      ) {
        return "resendConfirm";
      }

      if (currentScreen === "Refund" && !requestReturnStatus) {
        if (paymentOption === "REFUND") {
          return "creditCardRefund";
        }

        if (paymentOption === "GIFT_CARD") {
          return "voucherRefund";
        }
      }

      if (currentScreen === "AccountRefund" && !requestReturnStatus) {
        if (paymentOption === "ACCOUNT_DEPOSIT") {
          return "bankAccountRefund";
        }

        if (paymentOption === "PAYMENT_ORDER") {
          return "refundOrder";
        }
      }

      if (requestReturnStatus === "LOADING") {
        return "loadingRequest";
      }

      if (requestReturnStatus === "SUCCESS") {
        return "successRequest";
      }

      if (requestReturnStatus === "ERROR") {
        return "returnRequestFailed";
      }

      return null;
    }
    default: {
      if (
        currentScreen === "AddressSelector" &&
        deliveryOption === "COLLECT_IN_STORE"
      ) {
        return "packageCollect";
      }

      if (
        currentScreen === "AddressSelector" &&
        deliveryOption === "POSTAGE" &&
        consignmentType != "MARKETPLACE_OUT"
      ) {
        return "postInfo";
      }

      if (
        currentScreen === "AddressSelector" &&
        deliveryOption === "COLLECT" &&
        !hasConfirmation
      ) {
        return "packageCollect";
      }

      if (
        currentScreen === "DeliverySelector" &&
        !requestReturnStatus &&
        deliveryOption === "RESEND"
      ) {
        if (!hasStock) {
          return "resendRequestFailed";
        }
      }

      if (
        currentScreen === "DeliverySelector" &&
        !hasDeliveryScheduler &&
        !requestReturnStatus &&
        deliveryOption === "RESEND"
      ) {
        return "resendConfirm";
      }

      if (
        currentScreen === "DeliverySelector" &&
        !hasDeliveryScheduler &&
        !requestReturnStatus &&
        consignmentType === "MARKETPLACE_OUT" &&
        (deliveryOption === "NO_RETURN" ||
          (deliveryOption === "RESEND" && !hasStock))
      ) {
        return "refundConfirm";
      }

      if (
        currentScreen === "DeliverySelector" &&
        !hasDeliveryScheduler &&
        !requestReturnStatus &&
        consignmentType === "MARKETPLACE_OUT" &&
        deliveryOption === "POSTAGE"
      ) {
        return "refundConfirm";
      }

      if (
        currentScreen === "AddressSelector" &&
        !hasDeliveryScheduler &&
        !requestReturnStatus &&
        consignmentType === "MARKETPLACE_OUT" &&
        deliveryOption === "COLLECT"
      ) {
        return "refundMktOutConfirm";
      }

      if (
        (currentScreen === "DeliveryScheduler" ||
          (currentScreen === "ReturnItems" && !hasDeliveryScheduler)) &&
        !requestReturnStatus
      ) {
        return "resendConfirm";
      }

      if (currentScreen === "Refund" && !requestReturnStatus) {
        if (paymentOption === "REFUND") {
          return "creditCardRefund";
        }

        if (paymentOption === "GIFT_CARD") {
          return "voucherRefund";
        }
      }

      if (currentScreen === "AccountRefund" && !requestReturnStatus) {
        if (paymentOption === "ACCOUNT_DEPOSIT") {
          return "bankAccountRefund";
        }

        if (paymentOption === "PAYMENT_ORDER") {
          return "refundOrder";
        }
      }

      if (requestReturnStatus === "LOADING") {
        return "loadingRequest";
      }

      if (requestReturnStatus === "SUCCESS") {
        return "successRequest";
      }

      if (requestReturnStatus === "ERROR") {
        return "returnRequestFailed";
      }

      return null;
    }
  }
}
