import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "@store/index";

import TruckIcon from "./icons/truck";
import HomeIcon from "./icons/home";
import PackageIcon from "./icons/package";
import MoneyIcon from "./icons/money";

import {
  OptionButton,
  OptionsWrapper,
  OptionsText,
  OptionRadio,
  OptionContainer,
} from "./style";
import { ContainerTitle, Badge, Title, Subtitle } from "@pages/styles";
import { Column } from "@components/utilities";
import {
  setReturnMethod,
  DeliveryModalityType,
  requestAvailability,
} from "@store/return-items-reducer";

const DeliverySelector = (props: any) => {
  const { consignment } = props;
  const isReturn = useSelector(
    (state: AppState) => state.returnItems.hasReturnReason
  );
  const returnMethod = useSelector(
    (state: AppState) => state.returnItems.returnMethod
  );
  const dispatch = useDispatch();

  const isFoodSubdued =
    (consignment?.status?.status == "FPR" &&
      consignment?.delivery?.modality == "DRIVE") ||
    (consignment?.delivery?.modality == "DRIVE" &&
      consignment.consignmentStatus == "FPR");
  const isClickSubdued =
    (consignment?.status?.status == "FPR" &&
      consignment?.delivery?.modality == "CLICK_RETIRE_CD") ||
    (consignment?.delivery?.modality == "CLICK_RETIRE_CD" &&
      consignment.consignmentStatus == "FPR");

  const isSubdued = isFoodSubdued || isClickSubdued;
  const hasStoreCollect = isSubdued;
  const hasClientCollect =
    (consignment.type == "ECOMMERCE_PRINCIPAL" ||
      consignment.type == "MARKETPLACE_IN" ||
      consignment.type == "MARKETPLACE_OUT") &&
    isReturn &&
    !isSubdued;
  const hasPostOption =
    (consignment.type == "ECOMMERCE_PRINCIPAL" ||
      consignment.type == "MARKETPLACE_IN" ||
      consignment.type == "MARKETPLACE_OUT") &&
    isReturn &&
    !isSubdued;
  const hasRefund = (consignment.type == "FOOD" || !isReturn) && !isSubdued;
  const hasResend = (consignment.type == "FOOD" || !isReturn) && !isSubdued;

  const handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setReturnMethod(e.target.value as DeliveryModalityType));
  };

  const Text = hasRefund
    ? "Você pode escolher se prefere o reembolso ou se prefere o reenvio dos produtos."
    : "Você pode escolher se prefere postar gratuitamente o produto nos Correios ou esperar a nossa transportadora retirar o produto.";

  useEffect(() => {
    if (hasResend) {
      dispatch(requestAvailability());
    }
  }, []);

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>1</Badge>Método de devolução
        </Title>
        <Subtitle>{Text}</Subtitle>
      </ContainerTitle>
      <Column margin="0 auto">
        <OptionsWrapper onChange={handleDeliveryChange}>
          {hasStoreCollect && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`delivery-selector`}
                name="checklist"
                value={"COLLECT_IN_STORE"}
                checked={returnMethod === "COLLECT_IN_STORE"}
              />
              <OptionButton htmlFor={`delivery-selector`}>
                <TruckIcon />
                <OptionsText>Coleta na loja</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}
          {hasResend && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`delivery-selector6`}
                name="checklist"
                value={"RESEND"}
                checked={returnMethod === "RESEND"}
              />
              <OptionButton htmlFor={`delivery-selector6`}>
                <TruckIcon />
                <OptionsText>Reenvio</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}
          {hasPostOption && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`delivery-selector2`}
                name="checklist"
                value={"POSTAGE"}
                checked={returnMethod === "POSTAGE"}
              />
              <OptionButton htmlFor={`delivery-selector2`}>
                <PackageIcon />
                <OptionsText>Postagem</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}
          {hasClientCollect && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`delivery-selector3`}
                name="checklist"
                value={"COLLECT"}
                checked={returnMethod === "COLLECT"}
              />
              <OptionButton htmlFor={`delivery-selector3`}>
                <HomeIcon />
                <OptionsText>Coleta no cliente</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}
          {hasRefund && (
            <OptionContainer>
              <OptionRadio
                type="radio"
                id={`delivery-selector4`}
                name="checklist"
                value={"NO_RETURN"}
                checked={returnMethod === "NO_RETURN"}
              />
              <OptionButton htmlFor={`delivery-selector4`}>
                <MoneyIcon />
                <OptionsText>Reembolso</OptionsText>
              </OptionButton>
            </OptionContainer>
          )}
        </OptionsWrapper>
      </Column>
    </>
  );
};

export default DeliverySelector;
