import React from "react";

const ShippingContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function ShippingContainer({ children, ...otherProps }, ref) {
  return (
    <div ref={ref} {...otherProps}>
      {children}
    </div>
  );
});

export default ShippingContainer;
