import React from "react";
import { Column } from "@components/utilities";
import { CardModal, IconModal, SuccessText } from "./../styles";
import iconSuccess from "@assets/success.svg";

type Props = {
  successMessage: string;
};

const ModalClickStatus = (props: Props) => {
  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        <IconModal src={iconSuccess} alt="success" />
        <Column margin="0 auto" textAlign="center">
          <SuccessText>{props.successMessage}</SuccessText>
        </Column>
      </CardModal>
    </>
  );
};

export default ModalClickStatus;
