import styled from "styled-components";

export const SelectOtherProductsButton = styled.button`
  width: 245px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 24px;

  cursor: pointer;
  border: 1px solid #0970e6;
  background: transparent;
  border-radius: 4px;

  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 700;

  color: #0970e6;
`;
