// Service to get the gift cards PIN
import contentCard from "./contentCard";

export async function getRedemptionCode(
  orderCode: string,
  consignmentCode: string
) {
  try {
    const response = await contentCard.get(
      `/v1/firebase/authorization/contentCards/${orderCode}/${consignmentCode}/returnRedemptionCode`
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}
