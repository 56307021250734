import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { modalStatusEnum } from "../../../../../../enum/modal-status";
import { Column, Row } from "@components/utilities";
import { Button } from "carrefour-portal-backoffice-style-guide";
import { TitleAlert, CardModal, DescAlert } from "../../styles";
import iconExclamation from "@assets/exclamation.svg";

const ModalBilletStatus = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        <img src={iconExclamation} alt="exclamation" />
        <Column margin="0 auto" textAlign="center">
          <TitleAlert>
            O pedido ainda não teve confirmação <br /> do pagamento
          </TitleAlert>
          <DescAlert>
            Se houve confirmação com o cliente que este boleto não <br /> foi
            pago, basta orientá-lo que seu pedido será cancelado <br />
            automaticamente em caso de não pagamento.
          </DescAlert>
        </Column>

        <Row margin="30px 0 0 0">
          <Column margin="0 20px 0 0">
            <Button
              text="O boleto NÃO foi pago"
              variation="primary"
              onClick={() =>
                dispatch({
                  type: "MODAL_STATUS",
                  payload: modalStatusEnum.BilletConfirmation,
                })
              }
              size="large"
              outline
            />
          </Column>
          <Button
            text="O boleto foi pago"
            variation="confirm"
            onClick={() => history.push("/atendimento/cancelamento")}
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalBilletStatus;
