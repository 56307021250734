import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Consignment } from "@pages/order-detail/OrderDetailsResponse";
import {
  fetchOrder,
  setReturnItems,
  setHasReturnReason,
  setFlowType,
  setConsingmentCode,
} from "@store/return-items-reducer";

import { modalStatusEnum } from "@enum/modal-status";

import { AppState } from "src/store";
import { OrderState } from "@store/order-reducer";

import { Column, Row } from "@components/utilities";
import { Text, Button } from "carrefour-portal-backoffice-style-guide";
import {
  TitleAlert,
  TitleShipping,
  RowBetween,
  CardModal,
  CardTotalContent,
  CardTotals,
  CardText,
  CardTitle,
  UserValue,
  ItemsOver,
} from "../../styles";

import iconExclamation from "@assets/exclamation.svg";

import Items from "@components/items";
import { formatDate } from "@utils/formatUtils";

interface TItems {
  items: TItem[];
  width?: string;
  consignmentCode?: string;
  totalFreight?: number;
  totalValue?: number;
  totalItemValue?: number;
  close?: any;
  isReturn?: any;
  orderData?: any;
  flowType?: string;
}

interface TItem {
  ean?: string;
  name?: string;
  quantity?: number;
  price?: number;
  discount?: number;
  basePrice?: number;
  imageUrl?: string;
  warranty?: boolean;
}

const ModalShipping = ({
  items,
  width,
  consignmentCode,
  totalFreight,
  totalValue,
  totalItemValue,
  close,
  isReturn,
  flowType,
  orderData,
}: TItems) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderDetail } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  function returnItems() {
    const consignment: Consignment = orderData.consignments.find(
      (c: Consignment) => c.consignmentCode === consignmentCode
    );
    const returnItemsArray = consignment.items.map((i) => ({
      productValue: i.id,
      quantity: i.quantity,
    }));
    dispatch(setConsingmentCode(consignmentCode));
    dispatch(fetchOrder(orderData));
    dispatch(setFlowType(flowType));
    dispatch(setHasReturnReason(false));
    dispatch(setReturnItems(returnItemsArray));

    history.push(
      `/atendimento/devolucao/${orderData.orderCode}/${consignment.consignmentCode}`
    );
  }

  function handleCancelOrder() {
    if (isReturn) {
      returnItems();
      return;
    }

    orderDetail.payments.forEach((item) => {
      if (item.paymentSystemName === "Boleto Bancário") {
        if (item.paymentStatus === "Pagamento Confirmado") {
          history.push("/atendimento/cancelamento");
        } else {
          dispatch({
            type: "MODAL_STATUS",
            payload: modalStatusEnum.BilletStatus,
          });
          return;
        }
      } else {
        history.push("/atendimento/cancelamento");
      }
    });
  }

  return (
    <>
      <CardModal data-testid="cancel-modal">
        <div>
          <Column margin="0 auto" textAlign="center">
            <TitleAlert color="#ff7a15" size="28px">
              <img src={iconExclamation} alt="exclamation" height="21px" />
              Atenção
            </TitleAlert>
            <br />
            <Text
              type="body"
              size="medium"
              text="Vamos interromper o processo de entrega do pedido após sua solicitação."
              margin="0"
            />
          </Column>
          <CardTotalContent>
            <TitleShipping>Entrega 1</TitleShipping>
            <RowBetween>
              <CardTotals>
                <CardTitle>PEDIDO</CardTitle>
                <UserValue>{consignmentCode}</UserValue>
              </CardTotals>
              <CardTotals>
                <CardTitle>CRIAÇÃO DO PEDIDO</CardTitle>
                <UserValue>
                  {orderDetail?.creationDate
                    ? formatDate(orderDetail?.creationDate)
                    : ""}
                </UserValue>
              </CardTotals>
              <CardTotals>
                <CardTitle>VALOR TOTAL DA ENTREGA</CardTitle>
                <UserValue color="#2E8CF6">
                  {new Intl.NumberFormat("pt", {
                    style: "currency",
                    currency: "BRL",
                  }).format(totalValue)}
                </UserValue>
              </CardTotals>
              <CardTotals>
                <CardTitle>VALOR TOTAL DO FRETE</CardTitle>
                <UserValue color="#2E8CF6">
                  {new Intl.NumberFormat("pt", {
                    style: "currency",
                    currency: "BRL",
                  }).format(totalFreight)}
                </UserValue>
              </CardTotals>
            </RowBetween>
          </CardTotalContent>
          <Row justify="flex-start">
            <TitleShipping margin="20px 0px 0px">Itens do pedido</TitleShipping>
          </Row>
          <ItemsOver>
            <Items items={items} width="100%" />
          </ItemsOver>
        </div>
        <Row margin="30px 0 0 0" justify="flex-end">
          <Column margin="0 20px 0 0">
            <Button
              text="Cancelar"
              variation="primary"
              onClick={() => close()}
              size="large"
              outline
            />
          </Column>
          <Button
            text="Prosseguir"
            variation="confirm"
            onClick={() => handleCancelOrder()}
            size="large"
          />
        </Row>
      </CardModal>
    </>
  );
};

export default ModalShipping;
