import styled from "styled-components";

export const SelectPostageOrCollectDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  color: #454545;

  max-width: 540px;
  margin: 24px auto;
`;

export const ReturnMethodWrapper = styled.div`
  display: flex;
  justify-content: center;

  column-gap: 32px;
`;
