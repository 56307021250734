import React from "react";
import { Column, Row } from "@components/utilities";
import { TitleAlert, CardModal, DescAlert } from "../styles";
import iconForbidden from "@assets/forbidden.svg";
import { useHistory } from "react-router-dom";
import { Button } from "carrefour-portal-backoffice-style-guide";

type Props = {
  errorMessage: string;
  action: () => any;
  close: () => any;
  actionText?: string;
};

import { useSelector } from "react-redux";
import { AppState } from "src/store";
import { OrderState } from "../../../../../store/order-reducer";

const ModalResendForbidden = (props: Props) => {
  const {
    close,
    action,
    errorMessage,
    actionText = "Ir para reembolso",
  } = props;
  const history = useHistory();

  const { orderSelected } = useSelector<AppState, OrderState>(
    (state) => state.order
  );

  return (
    <>
      <CardModal data-testid="cancel-modal" padding="50px 150px">
        <img src={iconForbidden} alt="exclamation" />
        <Column margin="0 auto" textAlign="center">
          <Row>
            <DescAlert>{errorMessage}</DescAlert>
          </Row>
          <Row>
            <Column margin="0 20px 0 0">
              <Button
                text="Cancelar"
                variation="primary"
                onClick={() => {
                  history.push(`/atendimento/pedido/detalhe/${orderSelected}`);
                }}
                size="large"
                outline
              />
            </Column>
            <Button
              text={actionText}
              variation="confirm"
              onClick={action}
              size="large"
            />
          </Row>
        </Column>
      </CardModal>
    </>
  );
};

export default ModalResendForbidden;
