/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useModal from "react-hooks-use-modal";
import {
  Card,
  Text,
  Button as ButtonComponent,
} from "carrefour-portal-backoffice-style-guide";
import {
  formatDate,
  addDaysFormat,
  estimateTime,
  formatDateOnly,
} from "@utils/formatUtils";
import { AppState } from "src/store";
import { ModalState } from "@store/modal-reducer";
import { AuthState } from "@store/authorities-reducer";

import Button from "@components/button";

import { modalStatusEnum } from "@enum/modal-status";
import { Column, Row } from "@components/utilities";
import {
  SectionShipping,
  TitleAlert,
  ModalityTitle,
  TextLight,
  Strong,
  Label,
  RowBetween,
  CloseModal,
  ButtonNF,
  ConsignmentOptionButton,
  AlertDisruption,
  ButtonDetails,
  ConsignmenOptions,
  TrackingCodeWrapper,
  TrackingCode,
  TrackingCodeCopy,
  TrackingCodeLink,
  TrackingCodeInfo,
  TrackingCodeInfoText,
  CloseComponent,
  ClipboardAlert,
  HighlightShippingModality,
  CardShipping,
  DeliveryDetail,
  TooltipText,
  TooltipContainer,
  NewTooltipContainer,
  NewTooltipText,
  HowExchangeWorks,
} from "./styles";

import iconDownload from "@assets/download.svg";
import TruckSvg from "@assets/truck-blue.svg";
import InfoTracking from "@assets/info-tracking.svg";
import trackOrderIcon from "@assets/track-order-icon.svg";
import CloseIcon from "@assets/close-icon.svg";
import CloseIconRounded from "@assets/close-icon-rounded.svg";
import HowExchangesWorksIcon from "@assets/how-exchange-works-icon.svg";

import Items from "@components/items";
import Tracking from "../tracking";
import ModalShipping from "./components/modal-shipping";
import ModalBilletStatus from "./components/modal-billet-status";
import ModalBilletConfirmation from "./components/modal-billet-confirmation";
import ModalStockBreak from "./components/modal-stock-break";
import ModalForbidden from "@pages/return-flow/components/modals/modal-forbidden";

import {
  OrderDetailsResponse,
  LinkItem,
  Consignment,
} from "@pages/order-detail/OrderDetailsResponse";
import { TrackingResponse } from "@pages/order-detail/TrackingResponse";
import { OrderHistoryResponse } from "@pages/order-detail/OrderHistoryResponse";

import { CancellationType } from "@pages/cancellation-flow/enums/cancellation-type";
import ModalForceInstance from "./components/modal-force-instance";

import { MessageModalContent } from "@pages/force-instance-flow/components/message-modal";
import { ForceInstanceType } from "@pages/force-instance-flow";

import { executeLink } from "@services/forceInstance";
import { getOrderHistory } from "@services/orderHistory";
import { getRedemptionCode } from "@services/getRedemptionCode";

import ShipFromStoreRegister from "./components/modal-ship-from-store/ship-from-store-register";
import ShipFromStoreLearnMore from "./components/modal-ship-from-store/ship-from-store-learn-more";

import {
  ForceInstanceDetail,
  ForceInstanceDetailContent,
} from "./components/modal-force-instance-detail";
import { shipFromStoreTable } from "./config";
import iconInfoSmall from "@assets/icon-info-small-white.svg";
import iconInfoMedium from "@assets/icon-info-medium-white.svg";
import ModalRedeemGift from "./components/modal-redeem-gift";
import ModalCancelGift from "./components/modal-cancel-gift";
import {
  GiftCardsArray,
  IGiftCard,
} from "@pages/order-detail/GiftCardResponse";
import iconInfoMediumBlack from "@assets/icon-info-medium-black.svg";
import ModalReturnOrExchange from "./components/modal-return-or-exchange";
import { ModalExchangeMarketpalceInContent } from "./components/modal-exchange-marketplace-in";

type Shipping = {
  orderData: OrderDetailsResponse;
  trackingData: TrackingResponse;
  consignment: Consignment;
  exchangeRequestInfos: ExchangeRequestInfos;
  index: number;
  giftCards?: GiftCardsArray;
  customerData: any;
};

type ExchangeRequestInfos = {
  hasExchangeRequest: boolean;
  exchangeOrderCode: string;
};

type InfoStatusRDSProps = {
  statusFound: boolean;
  statusDate: string | null;
};

type RedemptionCode = {
  redemptionCode: string;
};

type processType =
  | "cancel"
  | "stockBreak"
  | "forceInstance"
  | "redeemGift"
  | "cancelContentCard"
  | "shipFromStoreRegister"
  | "shipFromStoreLearnMore";

const Shipping = ({
  orderData,
  trackingData,
  consignment,
  exchangeRequestInfos,
  index,
  giftCards,
  customerData,
}: Shipping) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [consignmentCode, setConsignmentCode] = useState("");
  const [flowType, setFlowType] = useState("");
  const [isReturn, setIsReturn] = useState(false);
  const [totalFreight, setTotalFreight] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [totalItemValue, setTotalItemValue] = useState(0);
  const [processType, setProcessType] = useState<processType>();
  const [allowedProductReturn, setAllowedProductReturn] = useState(false);
  const [trackingTooltip, setTrackingTooltip] = useState(true);
  const [openClipboardAlert, setOpenClipboardAlert] = useState(false);
  const [statusDisruption, setStatusDisruption] = useState("RUT");
  const [orderHistory, setOrderHistory] = useState<OrderHistoryResponse>();
  const [forceInstanceDetail, setForceInstanceDetail] = useState<
    ForceInstanceDetail
  >();
  const [toolTipActive, setToolTipActive] = useState(false);
  const [shipFromStoreToolTipActive, setShipFromStoreToolTipActive] = useState(
    false
  );

  const [infoStatusRDS, setInfoStatusRDS] = useState<InfoStatusRDSProps>({
    statusFound: false,
    statusDate: null,
  });

  const [hiddenStatus, setHiddenStatus] = useState<string[]>([]);
  const [showRedeemGift, setShowRedeemGift] = useState(false);
  const [showCancelGift, setShowCancelGift] = useState(false);
  const [giftCard, setGiftCard] = useState<IGiftCard>();
  const [redemptionCode, setRedemptionCode] = useState<RedemptionCode>(null);

  const [Modal, open, close, isOpen] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  const [
    ForceInstanceNotAllowedModal,
    openForceInstanceNotAllowedModal,
    closeForceInstanceNotAllowedModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });

  const [
    ForceInstanceDetailModal,
    openForceInstanceDetailModal,
    closeForceInstanceDetailModal,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: false,
  });

  const [
    ModalExchangeMarketpalceIn,
    openModalExchangeMarketpalceIn,
    closeModalExchangeMarketpalceIn,
  ] = useModal("single-spa-application:@carrefour/micro-atendimento", {
    preventScroll: true,
  });

  const { modalStatus } = useSelector<AppState, ModalState>(
    (state) => state.modal
  );

  const rules = useSelector<AppState, AuthState>((state) => state.rules);

  useEffect(() => {
    if (giftCards && giftCards.length > 0) {
      const gift = giftCards?.find(
        (r: IGiftCard) => r.sequenceOrder === consignment?.consignmentCode
      );

      setGiftCard(gift);

      const contentCardCode = async () => {
        const response = await getRedemptionCode(
          gift.orderCode,
          consignment?.consignmentCode
        );

        setRedemptionCode(response);
      };
      contentCardCode();
    }
  }, [giftCards, consignment]);

  useEffect(() => {
    const validateAccess = (authoritie: string) => {
      const key = "access";
      const value = JSON.parse(localStorage.getItem(key));

      if (value) {
        const hasAuthority: boolean = value?.authorities.some(
          (item: string) => item === authoritie
        );
        return hasAuthority;
      }

      return false;
    };

    const keyUnlockButton = "cs-services.content-card.unlock";
    const keyCancelButton = "cs-services.content-card.cancel";

    const showUnlockButton = validateAccess(keyUnlockButton);
    const showCancelButton = validateAccess(keyCancelButton);

    if (giftCard && giftCard?.statusCard == "Bloqueado") {
      if (showUnlockButton) {
        setShowRedeemGift(true);
      }
      if (showCancelButton) {
        setShowCancelGift(true);
      }
    }
  }, [giftCard]);

  const modalContent = (key: string) => {
    switch (key) {
      case "SHIPPING":
        return (
          <ModalShipping
            items={selectedItems}
            consignmentCode={consignmentCode}
            totalFreight={totalFreight}
            totalValue={totalValue}
            totalItemValue={totalItemValue}
            close={close}
            isReturn={isReturn}
            flowType={flowType}
            orderData={orderData}
          />
        );
      case "BILLET_STATUS":
        return <ModalBilletStatus />;
      case "BILLET_CONFIRMATION":
        return <ModalBilletConfirmation />;
      case "STOCK_BREAK":
        return (
          <ModalStockBreak
            items={selectedItems}
            consignmentCode={consignmentCode}
            totalFreight={totalFreight}
            totalValue={totalValue}
            totalItemValue={totalItemValue}
            status={statusDisruption}
            close={close}
          />
        );
      case "INVALID_RETURN":
        return (
          <ModalForbidden
            errorMessage={
              "Você não pode solicitar devolução ou troca fora do prazo."
            }
          />
        );
      case "FORCE_INSTANCE":
        return (
          <ModalForceInstance
            items={selectedItems}
            consignmentCode={consignmentCode}
            totalFreight={totalFreight}
            totalValue={totalValue}
            totalItemValue={totalItemValue}
            close={close}
            isReturn={isReturn}
            flowType={flowType}
            orderData={orderData}
          />
        );
      case "REDEEM_GIFT":
        return (
          <ModalRedeemGift
            orderId={orderData?.principalCode}
            orderCode={orderCode}
            sequence={consignment?.consignmentCode}
            numId={orderItems[0]?.sellerSku}
            giftcardSKU={orderItems[0]?.sellerSku}
            productName={orderItems[0]?.name}
            price={String(orderItems[0]?.basePrice)}
            origem={"CSG"}
            close={close}
            emailTo={customerData?.email}
            userName={`${customerData?.firstName} ${customerData?.lastName}`}
            userId={customerData?.document}
          />
        );
      case "CANCEL_CONTENT_CARD":
        return (
          <ModalCancelGift
            orderId={orderData?.principalCode}
            orderCode={orderCode}
            sequence={consignment?.consignmentCode}
            userId={customerData?.document}
            numId={orderItems[0]?.sellerSku}
            giftcardSKU={orderItems[0]?.sellerSku}
            emailTo={customerData?.email}
            productName={orderItems[0]?.name}
            userName={`${customerData?.firstName} ${customerData?.lastName}`}
            price={String(orderItems[0]?.basePrice)}
            refund={{ type: "REFUND" }}
            close={close}
          />
        );
      case "SHIP_FROM_STORE_REGISTER":
        return (
          <ShipFromStoreRegister
            orderCode={orderData.orderCode}
            consignmentCode={consignmentCodeShipping}
            close={close}
          />
        );

      case "SHIP_FROM_STORE_LEARN_MORE":
        return <ShipFromStoreLearnMore close={close} />;

      case "RETURN_OR_EXCHANGE":
        return (
          <ModalReturnOrExchange
            orderCode={orderCode}
            consigment={consignment}
            close={close}
            openModalExchangeMarketpalceIn={openModalExchangeMarketpalceIn}
          />
        );
      default:
        return null;
    }
  };

  const isEmpty = (value: string) => {
    return value.length === 0 || !value.trim();
  };

  function forceInstanceToShipping(
    orderItems: any,
    consignmentCode: string,
    totalFreight: number,
    totalValue: number,
    totalItemValue: number,
    consignment: Consignment,
    forceInstanceLink: LinkItem,
    hasDeliveryFailure: boolean
  ) {
    if (
      isEmpty(forceInstanceLink.href) ||
      isEmpty(forceInstanceLink.rel) ||
      isEmpty(forceInstanceLink.type)
    ) {
      openForceInstanceNotAllowedModal();
    } else {
      open();
      setSelectedItems(orderItems);
      setConsignmentCode(consignmentCode);
      setTotalFreight(totalFreight);
      setTotalValue(totalValue);
      setTotalItemValue(totalItemValue);

      dispatch({
        type: "SELECTED_CONSIGNMENT",
        payload: consignment,
      });

      dispatch({
        type: "CONSIGNMENT_CODE",
        payload: consignmentCode,
      });

      let forceInstanceType: ForceInstanceType;
      if (hasDeliveryFailure) {
        if (forceInstanceLink == consignment?.links?.forceInstanceResend) {
          forceInstanceType = ForceInstanceType.FORCE_DELIVERY_FAILURE_RESEND;
        } else {
          forceInstanceType = ForceInstanceType.FORCE_DELIVERY_FAILURE_REFUND;
        }
      } else {
        forceInstanceType = ForceInstanceType.FORCE_DEVOLUTION_REFUND;
      }

      dispatch({
        type: "SET_FORCE_INSTANCE",
        payload: { forceInstanceType: forceInstanceType },
      });
    }
  }

  function cancelShipping(
    orderItems: any,
    consignmentCode: string,
    totalFreight: number,
    totalValue: number,
    totalItemValue: number,
    consignment: any,
    cancellationType: CancellationType = CancellationType.DELIVERY_CANCELLATION
  ) {
    dispatch({
      type: "SET_CANCELLATION_TYPE",
      payload: cancellationType,
    });

    open();
    setSelectedItems(orderItems);
    setConsignmentCode(consignmentCode);
    setTotalFreight(totalFreight);
    setTotalValue(totalValue);
    setTotalItemValue(totalItemValue);

    dispatch({
      type: "SELECTED_CONSIGNMENT",
      payload: consignment,
    });
    dispatch({
      type: "CONSIGNMENT_CODE",
      payload: consignmentCode,
    });
  }

  const handleOpenForceInstanceDetailModal = (link: LinkItem) => {
    executeLink(link)
      .then((response) => {
        const forceInstance = response.data as ForceInstanceDetail;
        setForceInstanceDetail(forceInstance);
        openForceInstanceDetailModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getOrderHistoryFunc = (orderCode: string, consignmentCode: string) => {
    getOrderHistory({
      orderCode: orderCode,
      consignmentCode: consignmentCode,
    })
      .then((res) => {
        setOrderHistory(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (processType) {
      if (processType === "cancel") {
        dispatch({
          type: "MODAL_STATUS",
          payload: modalStatusEnum.Shipping,
        });
      }

      if (processType === "stockBreak") {
        dispatch({
          type: "MODAL_STATUS",
          payload: modalStatusEnum.StockBreak,
        });
      }

      if (processType === "forceInstance") {
        dispatch({
          type: "MODAL_STATUS",
          payload: modalStatusEnum.ForceInstance,
        });
      }
      if (processType === "redeemGift") {
        dispatch({
          type: "MODAL_STATUS",
          payload: modalStatusEnum.RedeemGift,
        });
      }
      if (processType === "cancelContentCard") {
        dispatch({
          type: "MODAL_STATUS",
          payload: modalStatusEnum.CancelGift,
        });
      }

      if (processType === "shipFromStoreRegister") {
        dispatch({
          type: "MODAL_STATUS",
          payload: modalStatusEnum.shipFromStoreRegister,
        });
      }

      if (processType === "shipFromStoreLearnMore") {
        dispatch({
          type: "MODAL_STATUS",
          payload: modalStatusEnum.shipFromStoreLearnMore,
        });
      }
    }
    // to-do: fix exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (rules) {
      const exists = rules.authorities.find(
        (element) => element === "cs-order.out-of-date.exchange-or-return"
      );
      setAllowedProductReturn(!!exists);
    }
  }, [rules]);

  useEffect(() => {
    setTimeout(() => {
      setOpenClipboardAlert(false);
    }, 2000);
  }, [openClipboardAlert]);

  let warehouseName = "";
  let warehouseId = "";
  let modality = "";
  let modalityColor = "";
  let addressType = "";
  let number = "";
  let serial = "";
  let invoiceUrl = "";
  let creationDate = "";
  let key = "";
  let statusColor = "";
  let statusTitle = "";
  let type = "";
  let carrierName = "";
  let trackingCode = "";
  let carrierCnpj = "";
  let hasTrackingCode = false;
  let deliveredByCorreios = false;
  let orderItems: any = [];
  let showDisruption = false;
  let disruptionStatus = "";
  let canCancelDisruption = "";
  let orderGetByCustomer = false;
  let hideRefund = false;
  let showRefund = false;
  let consignmentCodeShipping = "";
  let totalFreightShipping = 0;
  let totalValueShipping = 0;
  let totalItemValueShipping = 0;
  let isCarrefourEnvios = false;

  if (consignment) {
    warehouseName = consignment.delivery?.warehouseName;
    warehouseId = consignment.delivery?.warehouseId;
    modality = consignment.delivery?.modality;
    addressType = consignment.delivery?.shippingAddress.addressType;
    type = consignment.type;
    isCarrefourEnvios = consignment.isCarrefourEnvios;

    if (isCarrefourEnvios) {
      modality = "MARKETPLACE_IN CE";
    }

    switch (modality) {
      case "HOME_DELIVERY": {
        modalityColor = "#333333";
        break;
      }
      case "CLICK_RETIRE_LOJA": {
        if (addressType == "residential") {
          modalityColor = "#6407C2";
        } else {
          modalityColor = "#1E5BC6";
        }
        break;
      }
      case "CLICK_RETIRE_CD": {
        modalityColor = "#00A3BC";
        break;
      }
      case "MARKETPLACE": {
        modalityColor = "red";
        break;
      }
      case "MARKETPLACE_OUT": {
        modalityColor = "#FF7000";
        break;
      }
      case "SERVICE": {
        modalityColor = "#FFC209";
        break;
      }
      case "MARKETPLACE_IN CE": {
        modalityColor = "#3C9457";
        break;
      }
      case "REINTEGRATE_STOCK": {
        modalityColor = "#CC4800";
        break;
      }

      default: {
        modalityColor = "#333333";
        break;
      }
    }

    if (consignment?.invoices && consignment.invoices.length) {
      number = consignment.invoices[0]?.number;
      serial = consignment.invoices[0]?.serial;
      creationDate = consignment.invoices[0]?.creationDate;
      key = consignment.invoices[0]?.key;
      invoiceUrl = consignment.invoices[0].invoiceUrl;
    }

    if (consignment?.delivery) {
      carrierName = consignment.delivery?.carrierName;
      trackingCode = consignment.delivery?.trackingCode || "";
      hasTrackingCode = !!trackingCode;
      deliveredByCorreios = carrierName === "Correios";
      carrierCnpj = consignment.delivery?.carrierCnpj;
    }

    if (consignment?.items && consignment.items.length) {
      orderItems = orderData?.consignments[index]?.items;
      if (orderItems) {
        orderItems.map((item: { disruptionQuantity: number }) => {
          if (item.disruptionQuantity > 0) {
            showDisruption = true;
          }
        });
      }
    }

    if (consignment?.consignmentCode) {
      consignmentCodeShipping = consignment.consignmentCode;
      totalFreightShipping = consignment.totalFreight;
      totalValueShipping = consignment.totalValue;
      totalItemValueShipping = consignment.totalItemValue;
    }
  }

  if (!orderHistory) {
    getOrderHistoryFunc(orderData.orderCode, consignmentCodeShipping);
  }

  if (consignment.status) {
    statusColor = consignment.status.color;
    statusTitle = consignment.status.description;
  }

  let statusTracking;
  let find = false;
  trackingData?.consignments.map((trackingItem) => {
    if (trackingItem.consignmentCode === consignment.consignmentCode) {
      statusTracking = trackingItem.status;

      statusTracking.map((i) => {
        if (!find) {
          if (i.status === "PPA") {
            disruptionStatus = "PPA";
            find = true;
          }
          if (i.status === "RUP") {
            disruptionStatus = "RUP";
            find = true;
          }
          if (i.status === "RUT") {
            disruptionStatus = "RUT";
            find = true;
          }
          if (i.status === "IEM") {
            disruptionStatus = "IEM";
            find = true;
          }
          if (i.status === "PPM") {
            canCancelDisruption = "PPM";
          }
        }
        if (i.status === "CAS" && orderHistory?.cancelDetail?.selfAttendance) {
          i.description =
            "Cancelamento solicitado pelo cliente no autoatendimento";
        }

        if (i.status === "PRC") {
          if (modality === "CLICK_RETIRE_LOJA" && type === "ECOMMERCE_FILIAL") {
            orderGetByCustomer = true;
          }
        }

        if (i.status === "RDS") {
          if (!infoStatusRDS.statusFound || infoStatusRDS.statusDate === null) {
            setInfoStatusRDS((infoStatusRDS) => ({
              ...infoStatusRDS,
              statusFound: true,
              statusDate: formatDateOnly(i.creationTime),
            }));
          }
          if (!hiddenStatus.includes("RDS"))
            setHiddenStatus([...hiddenStatus, "RDS"]);
        }
      });
    }
  });

  const consigmentTracking = trackingData?.consignments.find((c) => {
    if (c.consignmentCode === consignmentCodeShipping) {
      return true;
    }
  });
  const trackingDelivered = consigmentTracking?.status.find(
    (s) => s.status === "ETR" || s.status === "ERS"
  );

  const deliveryDate = trackingDelivered?.creationTime.replace("[UTC]", "");
  const deliveryDateObject = moment(deliveryDate);
  const deliveryLimit = deliveryDateObject.add(7, "days");
  let cantReturnOrExchangeConsigment = deliveryLimit.isBefore(moment());

  let validTransportOccurrence = false;
  let hasDeliveryOccurrence = false;
  let canCancel = true;
  let fullReturnValue = false;
  let allowedProductReturnOcurrence = false;

  const trackingStatus = trackingData?.consignments.find(
    (c) => c.consignmentCode === consignment.consignmentCode
  );

  const orderAvailable = trackingStatus?.status?.find(
    (s) => s.status === "PDR"
  );
  const orderRemovedBycustomer = trackingStatus?.status.find(
    (s) => s.status === "PRC"
  );
  const dateOrderRemovedByCustomer = orderRemovedBycustomer?.creationTime;

  const validateInvoiceCancelButtonModality = () =>
    modality === "CLICK_RETIRE_CD" ||
    modality === "HOME_DELIVERY" ||
    modality === "MARKETPLACE_OUT";

  const isMktpIn = () => modality === "MARKETPLACE";

  consigmentTracking?.status.map((item) => {
    if (
      item?.status == "MDC" ||
      item?.status == "ENL" ||
      item?.status == "ARO" ||
      item?.status == "MDP" ||
      item?.status == "RPE" ||
      item?.status == "TRA" ||
      item?.status == "EAE" ||
      item?.status == "EBC" ||
      item?.status == "AUE" ||
      item?.status == "MFC" ||
      item?.status == "CME" ||
      item?.status == "MDG" ||
      item?.status == "MDO" ||
      item?.status == "DRE" ||
      item?.status == "RPC" ||
      item?.status == "PFC" ||
      item?.status == "ETS" ||
      item?.status == "RSS" ||
      item?.status == "GVS"
    ) {
      validTransportOccurrence = true;
    }
    if (
      item.status == "EXT" ||
      item.status == "AVA" ||
      item.status == "SNT" ||
      item.status == "ROU"
    ) {
      hasDeliveryOccurrence = true;
      allowedProductReturnOcurrence = true;
    }
    if (
      item.status == "CAS" ||
      item.status == "PNA" ||
      item.status == "ETR" ||
      item.status == "ERS" ||
      (item.status == "PED" && canCancelDisruption != "PPM" && !isMktpIn()) ||
      (item.status == "PAP" && canCancelDisruption != "PPM" && !isMktpIn()) ||
      item.status == "PRC" ||
      item.status == "IEP" ||
      item.status == "IEM" ||
      item.status == "RUP" ||
      (item.status == "NFE" && validateInvoiceCancelButtonModality()) ||
      item.status == "RUT" ||
      item.status == "RPS" ||
      consignment.delivery.selectedSla == "Serviço"
    ) {
      canCancel = false;
    }
    if (
      item.status == "ETS" ||
      item.status == "RSS" ||
      item.status == "GVS" ||
      item.status == "ELP" ||
      item.status == "RPS"
    ) {
      hasDeliveryOccurrence = false;
    }

    if (item.status === "CAS" && orderHistory?.cancelDetail?.selfAttendance) {
      statusTitle = `Cancelamento solicitado pelo cliente no autoatendimento`;
    }

    if (
      item?.status == "ERF" ||
      item?.status == "ETS" ||
      item?.status == "POG" ||
      item?.status == "DEV" ||
      item?.status == "CAS" ||
      item?.status == "CAN" ||
      item?.status == "CAR" ||
      orderData.sellerId === "carrefourbrservicos" ||
      orderData.sellerId === "carrefourbrqaservicos"
    ) {
      hideRefund = true;
    }
    if (item?.status == "CPR") {
      showRefund = true;
    }
  });

  if (globalThis.returnValue == totalValueShipping) {
    fullReturnValue = true;
  }

  const hasTransportOccurrence =
    validTransportOccurrence &&
    (consignment?.consignmentStatus === "RPS" ||
      consignment?.consignmentStatus === "RTS");
  const isPickupInPoint =
    (modality == "CLICK_RETIRE_LOJA" &&
      consignment.delivery.shippingAddress.addressType === "pickup") ||
    modality == "CLICK_RETIRE_CD" ||
    modality == "DRIVE";

  const deliveryEstimate = consignment?.delivery?.estimate;
  const deliveryEstimateDate = consignment?.delivery?.estimateDate;
  const orderCode = orderData?.orderCode;

  const exchangeOrReturnIsAvailable =
    (consignment?.links?.returnConsignment ||
      consignment?.links?.consignmentExchange) &&
    !consignment?.links?.getForceInstance;

  const addsReturnInformation = (
    hasExchange: boolean,
    state: string,
    hasExchangePropertie: boolean
  ) => {
    let returnInformation = "";
    if (hasExchange && state === "RETURNING" && !hasExchangePropertie) {
      returnInformation = "- referente à devolução da solicitação de troca";
    }
    return returnInformation;
  };

  return (
    <>
      <SectionShipping key={index} id={consignmentCodeShipping}>
        <Column>
          <Row justify="flex-start">
            <Column>
              <Text
                type="display"
                size="20"
                text={`Entrega ${index + 1} ${addsReturnInformation(
                  exchangeRequestInfos.hasExchangeRequest,
                  consignment.consignmentState,
                  !!consignment.exchangeOrder
                )}`}
                margin="20px 0px 10px 5px"
              />
            </Column>
            {consignment.links?.getForceInstance && (
              <ConsignmenOptions>
                <ConsignmentOptionButton
                  data-testid="get-force-instance-button"
                  onClick={() =>
                    handleOpenForceInstanceDetailModal(
                      consignment.links?.getForceInstance
                    )
                  }
                >
                  Histórico da entrega
                </ConsignmentOptionButton>
              </ConsignmenOptions>
            )}
          </Row>
        </Column>
        <CardShipping
          padding="25px 25px 25px 50px"
          width="auto"
          position="relative"
        >
          <HighlightShippingModality background={modalityColor} />
          <RowBetween>
            <Row justify="flex-start">
              <TitleAlert margin="0px" color={statusColor}>
                {statusTitle}
              </TitleAlert>
            </Row>
            <Row justify="flex-end">
              {consignment?.links?.deliveryFailureResend && (
                <Button
                  leftIcon={TruckSvg}
                  text="Ocorrência de entrega/Insucesso de entrega"
                  variation="primary"
                  size="medium"
                  margin="0 10px 0 0"
                  onClick={() => {
                    setIsReturn(true);
                    setFlowType("DeliveryOccurrence");
                    setProcessType("cancel");
                    cancelShipping(
                      orderItems,
                      consignmentCodeShipping,
                      totalFreightShipping,
                      totalValueShipping,
                      totalItemValueShipping,
                      consignment,
                      CancellationType.DELIVERY_FAILURE
                    );
                  }}
                  outline
                />
              )}

              {exchangeOrReturnIsAvailable && (
                <ButtonComponent
                  text="Troca ou Devolução"
                  variation="primary"
                  size="medium"
                  margin="0 10px 0 0"
                  onClick={() => {
                    if (cantReturnOrExchangeConsigment) {
                      if (
                        !allowedProductReturn ||
                        allowedProductReturnOcurrence
                      ) {
                        dispatch({
                          type: "MODAL_STATUS",
                          payload: "INVALID_RETURN",
                        });

                        open();

                        return;
                      }
                    }

                    dispatch({
                      type: "MODAL_STATUS",
                      payload: "RETURN_OR_EXCHANGE",
                    });

                    open();
                  }}
                  outline
                />
              )}

              {consignment?.status?.status === "RUP" ||
              consignment?.status?.status === "RUT" ||
              consignment?.status?.status === "IEM" ||
              (disruptionStatus === "IEM" &&
                consignment?.status?.status !== "ERF" &&
                consignment?.status?.status !== "CAR" &&
                consignment?.status?.status !== "ETS" &&
                consignment?.status?.status !== "NVG" &&
                consignment?.status?.status !== "GVS") ||
              consignment?.status?.status === "IEP" ? (
                <Button
                  text="Tratar indisponibilidade"
                  variation="primary"
                  size="medium"
                  margin="0 10px 0 0"
                  onClick={() => {
                    setProcessType("cancel");
                    cancelShipping(
                      orderItems,
                      consignmentCodeShipping,
                      totalFreightShipping,
                      totalValueShipping,
                      totalItemValueShipping,
                      consignment,
                      CancellationType.DELIVERY_FAILURE
                    );
                  }}
                  outline
                />
              ) : null}

              {(consignment?.links?.consignmentCancel ||
                consignment?.links?.cancel) &&
              canCancel &&
              disruptionStatus !== "RUP" &&
              disruptionStatus !== "RUT" &&
              disruptionStatus !== "IEM" &&
              disruptionStatus !== "IEP" ? (
                <ButtonComponent
                  data-testid="cancel-button"
                  text="Cancelar entrega"
                  variation="delete"
                  size="medium"
                  margin="0 10px 0 0"
                  onClick={() => {
                    setProcessType("cancel");
                    cancelShipping(
                      orderItems,
                      consignmentCodeShipping,
                      totalFreightShipping,
                      totalValueShipping,
                      totalItemValueShipping,
                      consignment
                    );
                  }}
                />
              ) : null}
              {consignment?.links?.forceInstance && (
                <ButtonNF
                  data-testid="force-instance-button"
                  onClick={() => {
                    setProcessType("forceInstance");
                    forceInstanceToShipping(
                      orderItems,
                      consignmentCodeShipping,
                      totalFreightShipping,
                      totalValueShipping,
                      totalItemValueShipping,
                      consignment,
                      consignment?.links?.forceInstance,
                      hasTransportOccurrence
                    );
                  }}
                >
                  Forçar Instância
                </ButtonNF>
              )}
              {consignment?.links?.forceInstanceResend && (
                <ButtonNF
                  data-testid="force-instance-button"
                  onClick={() => {
                    setProcessType("forceInstance");
                    forceInstanceToShipping(
                      orderItems,
                      consignmentCodeShipping,
                      totalFreightShipping,
                      totalValueShipping,
                      totalItemValueShipping,
                      consignment,
                      consignment?.links?.forceInstanceResend,
                      hasTransportOccurrence
                    );
                  }}
                >
                  Forçar Instância
                </ButtonNF>
              )}

              {showRefund && !hideRefund && (
                <div id="btn-estorno">
                  <ButtonComponent
                    text="Estorno"
                    variation="primary"
                    size="medium"
                    margin="0 10px 0 0"
                    onClick={() => {
                      if (cantReturnOrExchangeConsigment) {
                        if (
                          !allowedProductReturn ||
                          allowedProductReturnOcurrence
                        ) {
                          dispatch({
                            type: "MODAL_STATUS",
                            payload: "REFUND",
                          });
                          open();
                          return;
                        }
                      }
                      history.push(
                        `/atendimento/estorno/${orderData.orderCode}/${consignment.consignmentCode}`
                      );
                    }}
                    outline
                  />
                </div>
              )}
              {showRedeemGift && (
                <ButtonComponent
                  text="Desbloquear Gift Card"
                  variation="primary"
                  size="medium"
                  margin="0 10px 0 0"
                  onClick={() => {
                    dispatch({
                      type: "MODAL_STATUS",
                      payload: "REDEEM_GIFT",
                    });
                    open();
                    return;
                  }}
                  outline
                />
              )}
              {showCancelGift && (
                <ButtonComponent
                  text="Cancelar Gift Card"
                  variation="primary"
                  size="medium"
                  margin="0 10px 0 0"
                  onClick={() => {
                    dispatch({
                      type: "MODAL_STATUS",
                      payload: "CANCEL_CONTENT_CARD",
                    });
                    open();
                    return;
                  }}
                  outline
                />
              )}

              {consignment?.links?.returnShipFromStoreStatusInsert && (
                <div
                  onMouseEnter={() =>
                    !shipFromStoreToolTipActive
                      ? setShipFromStoreToolTipActive(true)
                      : setShipFromStoreToolTipActive(false)
                  }
                  onMouseLeave={() => setShipFromStoreToolTipActive(false)}
                  style={{ position: "relative" }}
                >
                  <Button
                    text="Registrar cancelamento/devolução"
                    variation="primary"
                    size="medium"
                    margin="0"
                    leftIcon={CloseIconRounded}
                    onClick={() => {
                      setProcessType("shipFromStoreRegister");
                      open();
                    }}
                    outline
                  />
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {shipFromStoreToolTipActive && (
                        <NewTooltipContainer>
                          <Row>
                            <NewTooltipText>
                              <img src={iconInfoMediumBlack} alt="Info" />
                              <span>
                                <b>Após a loja ser acionada por e-mail</b>,
                                clique acima para <br /> registrar o processo de
                                cancelamento/devolução.
                                <button
                                  onClick={() => {
                                    setProcessType("shipFromStoreLearnMore");
                                    open();
                                  }}
                                >
                                  Saiba mais
                                </button>
                              </span>
                            </NewTooltipText>
                          </Row>
                        </NewTooltipContainer>
                      )}
                    </div>
                  </>
                </div>
              )}
            </Row>
          </RowBetween>
          <Row justify="flex-start" margin="10px 0 30px 0">
            <Row justify="flex-start" width="auto" height="max-content">
              <TextLight margin="0px 5px 0px 0px">Modalidade:</TextLight>
              <ModalityTitle backgroundColor={modalityColor}>
                {modality == "CLICK_RETIRE_LOJA" &&
                  addressType !== "residential" &&
                  modality}
                {modality == "CLICK_RETIRE_LOJA" &&
                  addressType == "residential" &&
                  "SHIP_FROM_STORE"}
                {modality == "CLICK_RETIRE_CD" && modality}
                {modality == "HOME_DELIVERY" && modality}
                {modality == "MARKETPLACE" && modality}
                {modality == "MARKETPLACE_OUT" && modality}
                {modality == "SERVICE" && modality}
                {modality == "MARKETPLACE_IN CE" && (
                  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                  <span
                    onMouseOver={() =>
                      !toolTipActive
                        ? setToolTipActive(true)
                        : setToolTipActive(false)
                    }
                    onMouseOut={() => setToolTipActive(false)}
                  >
                    {modality}
                    <img src={iconInfoSmall} alt="Info" />
                  </span>
                )}
                {modality == "REINTEGRATE_STOCK" && modality}
              </ModalityTitle>
              {toolTipActive && (
                <TooltipContainer>
                  <Row>
                    <TooltipText>
                      <img src={iconInfoMedium} alt="Info" />
                      <p>
                        Carrefour Envios: Produtos vendidos pelo Seller e
                        entregues pelo Carrefour.
                      </p>
                    </TooltipText>
                  </Row>
                </TooltipContainer>
              )}
            </Row>
            <TextLight margin="0px 5px 0px 0px">
              {isPickupInPoint
                ? orderAvailable?.creationTime === undefined
                  ? "Data Prevista:"
                  : orderRemovedBycustomer
                  ? "Retirado pelo cliente:"
                  : "Retire:"
                : "Previsão de entrega:"}
            </TextLight>
            <Strong>
              {isPickupInPoint ||
              modality == "FOOD_HOME_DELIVERY" ||
              (modality == "HOME_DELIVERY" &&
                consignment.delivery.selectedSla == "Agendada") ? (
                orderAvailable?.creationTime === undefined ? (
                  <div>
                    <div>
                      {formatDate(deliveryEstimateDate)} (considerando{" "}
                      {estimateTime(deliveryEstimate)} para preparo)
                    </div>
                    <DeliveryDetail>
                      Cliente será informado por email da data de retirada
                    </DeliveryDetail>
                  </div>
                ) : (
                  <div>
                    {orderRemovedBycustomer ? (
                      <div>
                        <div>{formatDate(dateOrderRemovedByCustomer)}</div>
                        <DeliveryDetail>
                          Cliente retirou o pedido na data acima
                        </DeliveryDetail>
                      </div>
                    ) : deliveryEstimate === "2h" ? (
                      <div>
                        <div>
                          A partir de {formatDate(deliveryEstimateDate)}
                        </div>
                        <DeliveryDetail>
                          até {addDaysFormat(deliveryEstimateDate, 7)}
                        </DeliveryDetail>
                      </div>
                    ) : (
                      <div>
                        <div>{formatDate(orderAvailable?.creationTime)}</div>
                        <DeliveryDetail>
                          O cliente tem até{" "}
                          <strong>
                            {addDaysFormat(orderAvailable?.creationTime, 7)}
                          </strong>{" "}
                          para retirar o pedido
                        </DeliveryDetail>
                      </div>
                    )}
                  </div>
                )
              ) : (
                formatDate(deliveryEstimateDate)
              )}
            </Strong>
          </Row>
          {exchangeRequestInfos.hasExchangeRequest && (
            <HowExchangeWorks>
              <img src={HowExchangesWorksIcon} alt="How Works" />
              <div>
                <h3>Como funciona a troca</h3>
                <p>
                  No processo de troca pelo mesmo produto, são gerados: uma
                  devolução (do produto a ser trocado) e um novo pedido para
                  atender o cliente.{" "}
                  <strong>
                    Não há necessidade de reembolso ou geração de vale-trocas.
                  </strong>
                </p>
              </div>
              <a
                href={`/atendimento/pedido/detalhe/${exchangeRequestInfos.exchangeOrderCode}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver novo pedido
              </a>
            </HowExchangeWorks>
          )}
          {infoStatusRDS.statusFound && infoStatusRDS.statusDate ? (
            <Row justify="flex-start" margin="0 0 30px 0" align="center">
              <div
                style={{
                  backgroundColor: "#E2F1FE",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "15px 10px",
                  borderRadius: "8px",
                }}
              >
                <img src={iconInfoMediumBlack} alt="Info" />
                <TextLight
                  color="#353A40"
                  fontWeight={400}
                  fontSize="12px"
                  margin="0 0 0 10px"
                >
                  <b>{infoStatusRDS.statusDate}</b> | Loja acionada e processo
                  de cancelamento/devolução da entrega registrado com sucesso.
                </TextLight>
              </div>
            </Row>
          ) : (
            <></>
          )}
          <Row justify="flex-start">
            {orderGetByCustomer && (
              <AlertDisruption>
                <div>
                  <strong>Atenção:</strong>{" "}
                  <span>
                    Para troca e devolução, oriente o cliente a dirigir-se à
                    loja escolhida no momento da compra
                  </span>
                </div>
              </AlertDisruption>
            )}
          </Row>
          <Row justify={"space-between"} align={"start"}>
            <Column data-testid="shipping-address">
              <Strong>Vendido e entregue por:</Strong>

              {orderData?.marketplaceOutSellerName && (
                <TextLight>{orderData?.marketplaceOutSellerName}</TextLight>
              )}

              {orderData?.sellerName && (
                <TextLight>
                  {modality == "CLICK_RETIRE_LOJA" &&
                  addressType == "residential" ? (
                    <>
                      {orderData?.sellerName} <br />
                      {warehouseId !== "" && shipFromStoreTable[warehouseId] && (
                        <>
                          {shipFromStoreTable[warehouseId].acronym} <br />
                          {shipFromStoreTable[warehouseId].name} <br />
                        </>
                      )}
                    </>
                  ) : (
                    <>{orderData?.sellerName}</>
                  )}
                </TextLight>
              )}

              {!orderData?.marketplaceOutSellerName &&
                !orderData?.sellerName && <TextLight>-</TextLight>}

              <Strong>Tipo de venda:</Strong>
              <TextLight>{type}</TextLight>
              <Strong>Tipo de entrega:</Strong>
              <TextLight>{consignment.delivery.selectedSla}</TextLight>
              <Strong>Armazém</Strong>
              <TextLight>{warehouseName}</TextLight>
            </Column>
            <Card
              background="#FCFDFF"
              width="270px"
              padding="25px"
              height="190px"
            >
              <Column data-testid="invoice-card">
                <Strong>Nota Fiscal</Strong>
                <Row justify="normal">
                  <Column margin="0 25px 0 0">
                    <Label>NÚMERO</Label>
                    <TextLight margin="0px">{number ?? "-"}</TextLight>
                  </Column>
                  <Column margin="0 25px 0 0">
                    <Label>SÉRIE</Label>
                    <TextLight margin="0px">{serial ?? "-"}</TextLight>
                  </Column>
                  <Column margin="0 25px 0 0">
                    <Label>DATA DE EMISSÃO</Label>
                    <TextLight margin="0px">
                      {creationDate && number ? formatDate(creationDate) : "-"}
                    </TextLight>
                  </Column>
                </Row>
                <Row justify="normal">
                  <Column margin="0 25px 0 0">
                    <Label>CHAVE DE ACESSO</Label>
                    <TextLight margin="0px" fontSize="11px">
                      {key ?? "-"}
                    </TextLight>
                  </Column>
                </Row>
                {invoiceUrl?.length > 0 ? (
                  <Row justify="flex-start">
                    <Column margin="20px 25px 0 0">
                      <ButtonNF href={invoiceUrl} target="_blank">
                        Baixar nota fiscal{" "}
                        <img src={iconDownload} alt="download" />
                      </ButtonNF>
                    </Column>
                  </Row>
                ) : (
                  ""
                )}
              </Column>
            </Card>
            <Card
              background="#FCFDFF"
              width="400px"
              padding="25px"
              //height="190px"
              margin="0 0 0 20px"
            >
              <Column data-testid="shipping-company">
                <Strong>Transportadora</Strong>
                <Row justify="normal">
                  <Column margin="0 25px 0 0">
                    <Label>NOME</Label>
                    <TextLight>{carrierName}</TextLight>
                  </Column>
                  {!deliveredByCorreios && (
                    <Column margin="0 25px 0 0">
                      <Label>CNPJ</Label>

                      <TextLight>{carrierCnpj}</TextLight>
                    </Column>
                  )}
                </Row>
                {!deliveredByCorreios && hasTrackingCode && (
                  <Row justify="normal">
                    <Column margin="5px 0px 0 0">
                      <ButtonNF
                        href={trackingCode}
                        target="_blank"
                        imgMargin="0px 5px 0px 0px"
                        padding="7px 28px"
                      >
                        <img src={trackOrderIcon} alt="rastrear pedido" />
                        Rastrear pedido
                      </ButtonNF>
                    </Column>
                  </Row>
                )}
                {deliveredByCorreios && hasTrackingCode && (
                  <>
                    <TrackingCodeWrapper>
                      <Column>
                        <Row justify="start">
                          <TrackingCode>{trackingCode}</TrackingCode>

                          <TrackingCodeCopy
                            onClick={() => {
                              navigator.clipboard.writeText(trackingCode);
                              setOpenClipboardAlert(true);
                            }}
                          >
                            COPIAR
                            <ClipboardAlert active={openClipboardAlert}>
                              Copiado para a área de transferência
                            </ClipboardAlert>
                          </TrackingCodeCopy>
                        </Row>

                        <Row>
                          <TrackingCodeLink
                            href="https://rastreamento.correios.com.br/app/index.php"
                            target={"_blank"}
                          >
                            Clique aqui para rastrear seu pedido
                          </TrackingCodeLink>
                        </Row>
                      </Column>
                    </TrackingCodeWrapper>

                    {trackingTooltip && (
                      <TrackingCodeInfo>
                        <Row>
                          <Column justify={"center"}>
                            <img src={InfoTracking} alt="Info" />
                          </Column>

                          <TrackingCodeInfoText>
                            Para consultar esse pedido primeiro copie o código
                            acima e em seguida clique no link.
                            <br /> <br />
                            Fique atento: Se ao consultar o pedido a entrega não
                            for encontrada, avise ao cliente que o sistema dos
                            Correios pode demorar de 1 a 2 dias para reconhecer
                            a informação. Peça para ele tentar novamente após
                            esse prazo.
                          </TrackingCodeInfoText>

                          <Column>
                            <CloseComponent
                              onClick={() => {
                                setTrackingTooltip(false);
                              }}
                            >
                              <img src={CloseIcon} alt="close" />
                            </CloseComponent>
                          </Column>
                        </Row>
                      </TrackingCodeInfo>
                    )}
                  </>
                )}
              </Column>
            </Card>
          </Row>
          <Row justify="flex-start">
            <Text
              type="display"
              size="20"
              text="Itens do pedido"
              margin="50px 0 0 0"
            />
          </Row>
          <Row justify="flex-start">
            {showDisruption && (
              <AlertDisruption>
                <div>
                  {disruptionStatus === "RUT" ||
                    (disruptionStatus === "IEM" && (
                      <>
                        <span>Os itens desta entrega estão com</span>{" "}
                        <strong>indisponibilidade de estoque</strong>
                      </>
                    ))}
                  {disruptionStatus === "RUP" && (
                    <>
                      <span>Alguns itens desta entrega estão com </span>
                      <strong>indisponibilidade de estoque</strong>
                    </>
                  )}
                  {disruptionStatus === "PPA" && (
                    <>
                      <span>
                        Houve o cancelamento de alguns itens dessa entrega por{" "}
                      </span>
                      <strong>indisponibilidade de estoque</strong>
                    </>
                  )}
                </div>

                {disruptionStatus === "RUP" || disruptionStatus === "PPA" ? (
                  <ButtonDetails
                    onClick={() => {
                      setProcessType("stockBreak");
                      setStatusDisruption(disruptionStatus);
                      cancelShipping(
                        orderItems,
                        consignmentCodeShipping,
                        totalFreightShipping,
                        totalValueShipping,
                        totalItemValueShipping,
                        consignment
                      );
                    }}
                  >
                    Ver detalhes
                  </ButtonDetails>
                ) : null}
              </AlertDisruption>
            )}
          </Row>
          <Row>
            <Items
              items={orderItems}
              consignment={consignment}
              giftCard={giftCard}
              redemptionCode={redemptionCode}
            />
            {/* <Tracking status={statusTracking} />
            <Items items={orderItems} /> */}
            <Tracking status={statusTracking} hiddenStatus={hiddenStatus} />
          </Row>
        </CardShipping>
      </SectionShipping>

      <Modal preventScroll={true}>
        {modalStatus !== "REDEEM_GIFT" &&
        modalStatus !== "CANCEL_CONTENT_CARD" ? (
          <CloseModal onClick={close}>X</CloseModal>
        ) : null}
        {modalContent(modalStatus)}
      </Modal>

      <ForceInstanceNotAllowedModal preventScroll={true}>
        <CloseModal onClick={closeForceInstanceNotAllowedModal}>X</CloseModal>
        <MessageModalContent
          modalType="error"
          iconAlt="Sem permissão para inciar a instância forçada"
        >
          <Column>
            <Row>
              Ops, você não tem permissão para esta ação! Procure seu gestor.
            </Row>
          </Column>
        </MessageModalContent>
      </ForceInstanceNotAllowedModal>

      <ForceInstanceDetailModal preventScroll={true}>
        <CloseModal onClick={closeForceInstanceDetailModal}>X</CloseModal>
        <ForceInstanceDetailContent forceInstance={forceInstanceDetail} />
      </ForceInstanceDetailModal>

      <ModalExchangeMarketpalceIn>
        <ModalExchangeMarketpalceInContent
          consigment={consignment}
          customerEmail={customerData?.email}
          close={closeModalExchangeMarketpalceIn}
        />
      </ModalExchangeMarketpalceIn>
    </>
  );
};

export default Shipping;
