import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { AppState } from "src/store";
import { OrderState } from "@store/order-reducer";
import { CancelState } from "@store/cancellation-reducer";
import { deliveryRefundMethods } from "@services/deliveryRefundMethods";

import Payments from "../payments/index";

import {
  ContainerTitle,
  Badge,
  Title,
  Subtitle,
  LoadingContainer,
} from "../../styles";
import {
  RowBetweenCenter,
  PaymentMethods,
  PaymentMethod,
  PaymentMethodBtn,
  PaymentMethodLabel,
  PaymentDescription,
  PaymentDetails,
  BodyPayment,
  PaymentItem,
  TextPayment,
} from "./styles";
import { CancellationType } from "@pages/cancellation-flow/enums/cancellation-type";
import { Loading } from "@pages/return-flow/components/card-refund/styles";

const CardRefund = () => {
  const dispatch = useDispatch();
  const [refunds, setRefunds] = useState(null);
  const [usedPayments, setUsedPayments] = useState([]);

  const { orderSelected, selectedConsignment, orderDetail } = useSelector<
    AppState,
    OrderState
  >((state) => state.order);

  const cancelState = useSelector<AppState, CancelState>(
    (state) => state.cancel
  );

  async function loadDeliveryRefundMethods() {
    const params = {
      consignmentCode: selectedConsignment.consignmentCode,
      orderCode: orderSelected,
    };
    const response = await deliveryRefundMethods(params);
    setRefunds(response);
  }

  useEffect(() => {
    paymentsString();
  }, [usedPayments]);

  useEffect(() => {
    loadDeliveryRefundMethods();
    setUsedPayments(orderDetail?.payments);
  }, []);

  type PaymentMethodType =
    | "BANK_SLIP"
    | "CREDIT_CARD"
    | "GIFT_CARD"
    | "VIRTUAL_GIFT_CARD"
    | "ALELO_CARD"
    | "CARREFOUR_CARD"
    | "PROMISSORY"
    | "DEBIT_CARD"
    | "PIX";

  const getPaymentName = (paymentName: PaymentMethodType): string => {
    switch (paymentName) {
      case "BANK_SLIP":
        return "boleto bancário";
      case "ALELO_CARD":
        return "cartão Alelo";
      case "CARREFOUR_CARD":
        return "cartão Carrefour";
      case "PROMISSORY":
        return "cartão Carrefour Virtual";
      case "GIFT_CARD":
        return "cartão presente";
      case "VIRTUAL_GIFT_CARD":
        return "cartão presente";
      case "CREDIT_CARD":
        return "cartão de crédito";
      case "DEBIT_CARD":
        return "cartão de débito";
      case "PIX":
        return "Pix";
    }
  };

  const paymentsString = () => {
    let paymentsArray: any[] = [];
    let uniqueArray: any[] = [];
    let finalString = "";

    if (usedPayments) {
      usedPayments.map((item) => {
        paymentsArray.push(item.paymentMethod);
      });

      if (paymentsArray) {
        uniqueArray = paymentsArray.filter(function (item, pos) {
          return paymentsArray.indexOf(item) == pos;
        });
      }

      if (uniqueArray.length > 1) {
        uniqueArray.map((i) => {
          finalString = finalString + getPaymentName(i) + " e ";
        });
      } else {
        uniqueArray.map((i) => {
          finalString = getPaymentName(i);
        });
      }
    }
    return finalString;
  };

  return (
    <>
      <ContainerTitle>
        <Title>
          <Badge>
            {cancelState.cancellationType === CancellationType.DELIVERY_FAILURE
              ? 1
              : 2}
          </Badge>
          {`Escolha a forma de reembolso`}
        </Title>
        <Subtitle>
          {` A sua compra foi paga com `} <strong>{paymentsString()}</strong>.{" "}
          <br />
          {` Escolha a forma de restituição abaixo:`}
        </Subtitle>
      </ContainerTitle>
      {refunds ? (
        <PaymentMethods>
          {refunds?.map((refund: any) => (
            <PaymentMethod>
              <PaymentMethodBtn
                type="radio"
                name="payment"
                id={refund.type}
                onClick={() => {
                  dispatch({ type: "REFUND_METHOD", payload: refund.type });
                  dispatch({
                    type: "TOTAL_VALUE",
                    payload: refund.totalValue,
                  });
                  dispatch({
                    type: "PAYMENT_METHODS",
                    payload: refund.paymentMethods,
                  });
                }}
              />
              <PaymentMethodLabel htmlFor={refund.type}>
                {refund.type === "REFUND"
                  ? "Estorno"
                  : refund.type === "GIFT_CARD"
                  ? "Vale troca"
                  : refund.type}
              </PaymentMethodLabel>
              <PaymentDescription
                active={cancelState.refundMethod === refund.type}
              >
                {refund.type === "REFUND"
                  ? "Será feito um estorno de acordo com a forma de pagamento utilizada."
                  : refund.type === "GIFT_CARD"
                  ? "Será feito um estorno com vale-troca."
                  : ""}
              </PaymentDescription>
              <PaymentDetails active={cancelState.refundMethod === refund.type}>
                <BodyPayment>
                  <Payments item={refund?.paymentMethods} />
                  <PaymentItem>
                    <RowBetweenCenter>
                      <TextPayment
                        margin="15px 0"
                        color="#1E5BC6"
                        fontSize="16px"
                        weight="500"
                      >{`Total do reembolso`}</TextPayment>
                      <TextPayment
                        margin="15px 0"
                        color="#1E5BC6"
                        fontSize="16px"
                        weight="500"
                      >
                        {new Intl.NumberFormat("pt", {
                          style: "currency",
                          currency: "BRL",
                        }).format(refund.totalValue)}
                      </TextPayment>
                    </RowBetweenCenter>
                  </PaymentItem>
                </BodyPayment>
              </PaymentDetails>
            </PaymentMethod>
          ))}
        </PaymentMethods>
      ) : (
        <LoadingContainer>
          <Skeleton width="30vw" height={400} />
          <Loading width="30vw" height={400} />
        </LoadingContainer>
      )}
    </>
  );
};

export default CardRefund;
