import styled from "styled-components";

export const ModalWarrantyWarning = styled.div`
  width: 400px;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;

  text-align: center;
  box-sizing: border-box;
`;

export const ModalWarrantyWarningTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  color: #454545;
`;

export const ModalWarrantyWarningDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  color: #575757;
`;

export const ModalWarrantyWarningActions = styled.div`
  display: flex;
  column-gap: 8px;

  margin-top: 24px;
`;

export const ModalWarrantyWarningBack = styled.div`
  flex: 1;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #0970e6;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 700;

  color: #0970e6;
`;

export const ModalWarrantyWarningNext = styled.div`
  flex: 1;
  height: 40px;
  border-radius: 4px;
  background-color: #0970e6;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Ubuntu";
  font-size: 16px;
  font-weight: 700;

  color: #ffffff;
`;
