import React, { useState } from "react";
import {
  Button,
  ButtonModal,
  CloseModal,
  CardModal,
  Title,
  TextConfirmReport,
  ContainerButtons,
} from "../../styles";
import { Content, Container, Column, Row } from "@components/utilities";
import useModal from "react-hooks-use-modal";
import { getReport } from "@services/reports";
import LoadingReport from "../modals/modal-loading-report";
import ModalSuccessReport from "../modals/modal-success-report";
import ModalEmpyReport from "../modals/modal-empy-report";
import ModalErrorReport from "../modals/modal-error-report";
import { formatDateOnly } from "@utils/formatUtils";
import { Subtitle } from "@pages/styles";

interface Props {
  reportType: string;
}

const getReportName = (reportType: string) => {
  let reportName: string | null;
  switch (reportType) {
    case "CANCELLATION_REPORT":
      return (reportName = "Cancelamento");
    case "REFUND_REPORT":
      return (reportName = "Estorno parcial");
    case "GIFT_CARD_REPORT":
      return (reportName = "Vale troca");
    case "EXCEL_REPORT":
      return (reportName = "Estorno massivo");
    case "EXCHANGE_REPORT":
      return (reportName = "Troca");
    default:
      return (reportName = null);
  }
};
const Report = ({ reportType }: Props) => {
  const reportName = getReportName(reportType);

  const [Modal, open, close] = useModal(
    "single-spa-application:@carrefour/micro-atendimento",
    {
      preventScroll: false,
    }
  );

  const [textReport, setTextReport] = useState("");
  const [handleLoading, setHandleLoad] = useState(false);
  const [showMessageSucess, setShowMessageSucess] = useState(false);
  const [showMessageEmpy, setShowMessageEmpy] = useState(false);
  const [showMessageFail, setShowMessageFail] = useState(false);
  const [dateInitial, setDateInitial] = useState(null);
  const [dateEnd, setDateEnd] = useState("");

  function subtractDays(dataOld: any, dias: any) {
    return new Date(dataOld.getTime() - dias * 24 * 60 * 60 * 1000);
  }

  function setDatesRequest(e: any) {
    const targetValue: string = e.target.value;
    if (targetValue === "reportDay") {
      const currentDate: Date = new Date();
      const currentDateFormatted = formatDateOnly(currentDate.toString());
      setDateInitial(currentDateFormatted);
      setDateEnd(currentDateFormatted);
      setTextReport("Relatório dia");
    }
    if (targetValue === "reportMonth") {
      const currentDate: Date = new Date();
      const currentDateFormatted = formatDateOnly(currentDate.toString());
      const oldDay = subtractDays(new Date(), 30);
      const oldDayFormatted = formatDateOnly(oldDay.toString());
      setDateInitial(oldDayFormatted);
      setDateEnd(currentDateFormatted);
      setTextReport("Relatório mensal");
    }
    open();
  }

  async function loadReport() {
    setHandleLoad(true);
    try {
      const response = await getReport(dateInitial, dateEnd, reportType);
      if (response.status === 200) {
        setHandleLoad(false);
        setShowMessageSucess(true);
        let blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        let url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
      if (response === 404) {
        setHandleLoad(false);
        setShowMessageFail(true);
      }
      if (response === 422) {
        setHandleLoad(false);
        setShowMessageEmpy(true);
      }
    } catch (error) {
      setHandleLoad(false);
      setShowMessageFail(true);
    }
  }

  if (reportName === null) return <></>;

  return (
    <Content>
      <Container>
        <Row justify="space-evenly">
          <Subtitle>{reportName}</Subtitle>
        </Row>
        <Row justify="space-evenly">
          <Column width="180px">
            <Button onClick={setDatesRequest} value="reportDay">
              Relatório dia
            </Button>
          </Column>
          <Column width="180px">
            <Button onClick={setDatesRequest} value="reportMonth">
              Relatório mensal
            </Button>
          </Column>
          <Modal preventScroll={false}>
            {!handleLoading ? (
              <>
                {!showMessageSucess && !showMessageFail && !showMessageEmpy && (
                  <CardModal>
                    <CloseModal onClick={close}>X</CloseModal>
                    <Title>{textReport} </Title>
                    <TextConfirmReport>
                      Clique em “confirmar” para iniciar o download.
                    </TextConfirmReport>
                    <ContainerButtons>
                      <ButtonModal value="Confirmar" onClick={loadReport}>
                        Confirmar
                      </ButtonModal>
                      <ButtonModal onClick={close}>Cancelar</ButtonModal>
                    </ContainerButtons>
                  </CardModal>
                )}
                {showMessageSucess && <ModalSuccessReport />}
                {showMessageFail && <ModalErrorReport />}
                {showMessageEmpy && <ModalEmpyReport />}
              </>
            ) : (
              <LoadingReport />
            )}
          </Modal>
        </Row>
      </Container>
    </Content>
  );
};

export default Report;
