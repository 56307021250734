import React from "react";
import { Button } from "carrefour-portal-backoffice-style-guide";

import { Column, Row } from "@components/utilities";
import { CardModal, TitleAlert, DescAlert, Subtitle } from "../styles";

const ModalAccountRefund = () => {
  return (
    <CardModal padding="50px 150px">
      <Column margin="0 auto" textAlign="center">
        <TitleAlert>O pedido será reenviado para o endereço</TitleAlert>
        <TitleAlert>da entrega original.</TitleAlert>
      </Column>

      <Row margin="30px 0 0 0">
        <Column margin="0 20px 0 0">
          <Button
            text="Cancelar"
            variation="primary"
            onClick={() => {}}
            size="large"
            outline
          />
        </Column>
        <Button
          text="Prosseguir"
          variation="confirm"
          onClick={() => {}}
          size="large"
        />
      </Row>
    </CardModal>
  );
};

export default ModalAccountRefund;
