import React, { useState, useEffect } from "react";
import { Breadcrumbs, Card } from "carrefour-portal-backoffice-style-guide";
import { deliveryRefundMethods } from "@services/deliveryRefundMethods";

import PageHeader from "@components/page-header";
import { Content, Container } from "@components/utilities";
import CancelSummary from "@components/cancel-summary";

import CancelReasons from "./components/cancel-reasons";
import ActionBar from "./components/action-bar";

const TransportOccurence = () => {
  return (
    <Content>
      <Container>
        <Breadcrumbs
          variation="primary"
          items={[
            { to: "/home", label: "Home" },
            { to: "/atendimento/pedido", label: "Pedido" },
            {
              to: "/atendimento/cancelamento",
              label: "Ocorrência de transporte",
            },
          ]}
        />
        <PageHeader
          title="PEDIDO"
          subtitle="Ocorrência de transporte"
          button={false}
        />
        {/* <CancelSummary data={orderData} /> */}
        <Card
          width="auto"
          margin="50px 0 150px 0"
          padding="35px 150px 100px 150px"
        >
          <CancelReasons />
        </Card>
      </Container>
      <ActionBar />
    </Content>
  );
};

export default TransportOccurence;
