// Service to cancel content card
import contentCard from "./contentCard";

type cancelContentCard = {
  orderId: string;
  orderCode: string;
  sequence: string;
  userId: string;
  numId: string;
  giftcardSKU: string;
  emailTo: string;
  productName: string;
  userName: string;
  price: string;
  refund: Refund;
};

type Refund = {
  type: string;
};

export async function cancelContentCard(cancelInfo: cancelContentCard) {
  try {
    let data = {
      orderId: cancelInfo.orderId,
      orderCode: cancelInfo.orderCode,
      sequence: cancelInfo.sequence,
      userId: cancelInfo.userId,
      numId: cancelInfo.numId,
      giftcardSKU: cancelInfo.giftcardSKU,
      emailTo: cancelInfo.emailTo,
      productName: cancelInfo.productName,
      userName: cancelInfo.userName,
      price: cancelInfo.price,
      observation: "Cancelamento solicitado pelo Cliente",
      reason: "OTHERS",
      attendanceChannel: "BACKOFFICE",
      refund: cancelInfo.refund,
    };
    const response = await contentCard.post(
      "/v1/firebase/authorization/cancelContentCard",
      {
        ...data,
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      return error.response.status;
    }
  }
}
