import React from "react";

import { CardModal, Container } from "../styles";
import Spinner from "react-spinner-material";

type Props = {
  successMessage?: string;
};

const LoadingReport: React.FC = () => {
  return (
    <>
      <CardModal>
        <Container>
          <Spinner radius={60} color={"#1E5BC6"} stroke={2} visible={true} />
        </Container>
      </CardModal>
    </>
  );
};

export default LoadingReport;
